import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import {IonItem, IonTextarea} from '@ionic/react';
import ProfileImage from '../../ProfileImage';
import {Keyboard} from '@capacitor/keyboard';
import CanParticipateInForum from '../../../models/can-participate-in-forum';
import Thread from '../../../models/user/thread';
import Message from '../../../models/user/message';

interface CommentInputProps {
    onCommentSubmitted: (comment: string) => void,
    commentingEntity: CanParticipateInForum,
    replyingToThread?: Thread,
    replyingToMessage?: Message,
}

const CommentInput: React.FC<CommentInputProps> = ({onCommentSubmitted, replyingToThread, replyingToMessage, commentingEntity}) => {

    const [comment, setComment] = useState('');
    const commentInput = useRef<HTMLIonTextareaElement>(null)
    const submit = () => {
        Keyboard.hide();
        onCommentSubmitted(comment);
    }

    const handleKeyPress = (event: any) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            submit();
            setComment('');
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress)

        return () => {
            document.removeEventListener('keydown', handleKeyPress)
        }
    })

    const replyingToEntity = replyingToMessage ? replyingToMessage.from :
        (replyingToThread ? replyingToThread.created_by : undefined);

    useEffect(() => {

        commentInput.current?.setFocus();
        if (replyingToEntity) {
            let re = / /gi;
            const handle = "@" + replyingToEntity.full_name.replace(re, "")
            const commentContent = comment.startsWith("@") ? comment.slice(comment.indexOf(" ")) : comment;
            const newComment = handle + " " + commentContent;
            setComment(newComment);
            commentInput.current?.getInputElement().then(el => {
                el.setSelectionRange(newComment.length, newComment.length)
            });
        }

    }, [replyingToEntity])

    const handleInput = (event: any) => {
        let newComment = event.currentTarget.value!;
        if (newComment.startsWith('@') && newComment.indexOf(' ') == newComment.length - 2) {
            const char = newComment.slice(-1)
            newComment = newComment.slice(0, newComment.length - 1) + char.toUpperCase();
        }
        setComment(newComment as string)
        commentInput.current?.getInputElement().then(el => {
            el.setSelectionRange(newComment.length, newComment.length)
        });
    }

    return (
        <IonItem className={'comment-input'} lines={'none'}>
            <div slot={'start'}>
                <ProfileImage url={commentingEntity.profile_image_url} name={commentingEntity.full_name ?? ''}/>
            </div>

            <IonTextarea
                inputMode={'text'}
                autocapitalize={'sentence'}
                autoCorrect={'on'}
                value={comment}
                onInput={handleInput}
                placeholder={'Add comment...'}
                enterkeyhint={'send'}
                rows={1}
                autoGrow
                ref={commentInput}
            />
        </IonItem>
    )
}

export default CommentInput;
