import Business from '../../models/organization/business';
import Location, {placeholderLocation} from '../../models/location/location';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import MyBusinessContextProvider, {MyBusinessContext, MyBusinessContextState} from './MyBusinessContext';

interface LocationEditorContextState {
    location: Location,
    dirtyLocation: Location,
    isLocationDirty: boolean,
}

export interface LocationEditorContextConsumerState extends LocationEditorContextState {
    setLocation: (location: Location) => void,
    updateCompleted: (location: Location) => void,
}

let persistedState = {
    location: placeholderLocation(),
    dirtyLocation: placeholderLocation(),
} as LocationEditorContextState;

function createDefaultState(): LocationEditorContextConsumerState {
    return {
        ...persistedState,
        setLocation: (location: Location) => {},
        updateCompleted: (location: Location) => {},
    }
}

export const LocationEditorContext = React.createContext<LocationEditorContextConsumerState>(createDefaultState());

function createSetLocationCallback(
    locationEditorContext: LocationEditorContextState,
    setLocationEditorContext: Dispatch<SetStateAction<LocationEditorContextState>>) {
    return (location: Location) => {

        persistedState = {
            ...locationEditorContext,
            isLocationDirty: true,
            dirtyLocation: {...location},
        };

        setLocationEditorContext({...persistedState});
    }
}

/**
 * The callback produced from this will need to be called whenever the pieces of data are updated
 * in order to clear out our temporary data, and also to update the main persistent context
 * @param locationEditorContext
 * @param setLocationEditorContext
 * @param business
 * @param setMyBusiness
 */
function createUpdateCompletedCallback(
    locationEditorContext: LocationEditorContextState,
    setLocationEditorContext: Dispatch<SetStateAction<LocationEditorContextState>>,
    business: Business,
    setMyBusiness: (business: Business) => void)
{
    return (location: Location) => {

        const businessLocations = business.locations ? business.locations : [];

        const locationIndex = businessLocations.findIndex(i => i.id == location.id);
        if (locationIndex == -1) {
            businessLocations.push(location);
        } else {
            businessLocations[locationIndex] = location;
        }

        const constructedBusiness = {
            ...business,
            locations: businessLocations,
        }

        persistedState = {
            ...locationEditorContext,
            location: {...location},
            dirtyLocation: {...location},
        };

        setMyBusiness(constructedBusiness);
        setLocationEditorContext(persistedState);
    }
}

interface StateProps {
    locationId: number
}

interface LocationEditorContextWithBusinessReadyProps extends StateProps{
    myBusinessContext: MyBusinessContextState,
}

const LocationEditorContextWithBusinessReady: React.FC<LocationEditorContextWithBusinessReadyProps> = ({myBusinessContext, locationId, ...props}) => {

    const [locationEditorContext, setLocationEditorContext] = useState(persistedState);
    /**
     * Updates our local instances to the editing business whenever
     */
    useEffect(() => {
        if (persistedState.location.id != locationId) {
            const location = myBusinessContext.business.locations ?
                myBusinessContext.business.locations.find(i => i.id == locationId) : undefined;

            persistedState = {
                location: location ? location : placeholderLocation(),
                dirtyLocation: location ? location : placeholderLocation(),
                isLocationDirty: false,
            };
            setLocationEditorContext(persistedState);
        }
    }, [locationId, myBusinessContext.business.id])

    const fullContext = {
        ...persistedState,
        setLocation: createSetLocationCallback(persistedState, setLocationEditorContext),
        updateCompleted: createUpdateCompletedCallback(persistedState, setLocationEditorContext, myBusinessContext.business, myBusinessContext.setBusiness),
    } as LocationEditorContextConsumerState;

    return (
        <LocationEditorContext.Provider value={fullContext}>
            {persistedState.location.id ? props.children : ''}
        </LocationEditorContext.Provider>
    )
}

interface LocationEditorContextProviderProps extends StateProps {}

/**
 * Allows child components the ability to easily use the information of the current business and location the user is editing
 */
const LocationEditorContextProvider: React.FC<LocationEditorContextProviderProps> = ({locationId, ...props}) => {

    return (
        <MyBusinessContextProvider>
            <MyBusinessContext.Consumer>
                {myBusinessContext =>
                    <LocationEditorContextWithBusinessReady myBusinessContext={myBusinessContext} locationId={locationId} {...props}/>
                }
            </MyBusinessContext.Consumer>
        </MyBusinessContextProvider>
    )
};

export default LocationEditorContextProvider;
