import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, FilterProps,
    prepareContextState, SearchProps
} from './BasePaginatedContext';
import Post, {PublisherEndpointType} from '../models/post/post';
import React, {useContext, useEffect, useState} from 'react';
import {number, string} from 'yup';

/**
 * The state interface for our state
 */
export interface PublishedPostsContextState extends BasePaginatedContextState<Post> {}

const defaultState = (): BasePaginatedContextState<Post> => {

    return setPublishedAtSearch({
        ...defaultBaseContext(),
        expands: [
            'locations',
            'postLocations',
        ],
        order: {
            "updated_at": 'desc',
        }
    });
}

/**
 * Updates the published at search field
 * @param currentState
 */
const setPublishedAtSearch = (currentState: BasePaginatedContextState<Post>) : BasePaginatedContextState<Post> => {
    currentState.search['published_at'] = ['lt,' + (new Date()).toISOString()];
    return {...currentState};
}

const cachedStates: { [key: string]: PublishedPostsContextState; } = {};

/**
 * The actual context component
 */
export const PublishedPostsContext = React.createContext<PublishedPostsContextState>(defaultState());

/**
 * The props for the provider with optional locations
 */
export interface PublishedPostsContextProviderProps extends BasePaginatedContextProviderProps {
    publisherType: PublisherEndpointType,
    publisherId: number,
    includeRemoved?: boolean,
    skipCache?: boolean,
    locations?: number[],
    refresh?: boolean,
    onRefreshComplete?: () => void,
}

// Shares our state setter in order to run updates across all consumers
let setters: any = {};

export const PublishedPostsContextProvider: React.FC<PublishedPostsContextProviderProps> = ({publisherType, skipCache, publisherId, includeRemoved, locations, refresh, onRefreshComplete, children}) => {

    const cacheKey = publisherType + publisherId;
    if (!skipCache && !cachedStates[cacheKey]) {
        cachedStates[cacheKey] = setPublishedAtSearch({
            ...defaultState(),
        });
    }

    const [publishedPostsState, setPublishedPostsState] = useState<BasePaginatedContextState<any>>(
        !skipCache ? cachedStates[cacheKey]: setPublishedAtSearch({
        ...defaultState(),
    }));

    useEffect(() => {
        if (!setters[cacheKey]) {
            setters[cacheKey] = [];
        }
        setters[cacheKey].push(setPublishedPostsState)
        let params = {} as any
        if (locations && locations.length === 1) {
            params["filter[postLocations.reference_id]"] = locations[0]
            params["filter[postLocations.reference_type]"] = "location"
            params["filter[published_at]"] = "lte," + new Date().toISOString()
        }
        if (!includeRemoved) {
            params["filter[removed_at]"] = "null"
        }
        const store = skipCache ? publishedPostsState : cachedStates[cacheKey];
        prepareContextState(setPublishedPostsState, store,'/' + publisherType + '/' + publisherId + '/posts', params)
    }, [publisherId, publisherType, locations, includeRemoved]);

    useEffect(() => {
        if (refresh && onRefreshComplete) {
            const newState = setPublishedAtSearch(publishedPostsState);
            newState.refreshData(false).then(() => onRefreshComplete());
        }
    }, [refresh])

    useEffect(() => {
        if (!skipCache) {
            cachedStates[cacheKey] = publishedPostsState;
            setters[cacheKey].forEach((setter: any) => {
                setter(publishedPostsState);
            })
        }
    }, [publishedPostsState])

    return (
        <PublishedPostsContext.Provider value={createCallbacks(setPublishedPostsState, publishedPostsState, '/' + publisherType + '/' + publisherId + '/posts')}>
            {children}
        </PublishedPostsContext.Provider>
    )
}
