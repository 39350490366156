import Post from '../../models/post/post';
import {api} from '../api';
import Thread from '../../models/user/thread';
import Message from '../../models/user/message';
import Business from '../../models/organization/business';

/**
 * Convenient functions for all tasks related to interacting with messaging data
 */
export default class MessagingRequests {

    /**
     * Creates a root thread within a post for us
     * @param business
     * @param post
     * @param topic
     */
    static async createBusinessPostThread(business: Business, post: Post, topic: string): Promise<Thread> {
        const {data} = await api.post('/businesses/' + business.id + '/posts/' + post.id + '/threads', {topic})
        return data as Thread;
    }

    /**
     * Creates a message within a thread for us
     * @param business
     * @param thread
     * @param message
     * @param replyingToId
     */
    static async createBusinessMessage(business: Business, thread: Thread, message: string, replyingToId?: number): Promise<Message> {
        const requestData: any = {message};

        if (replyingToId) {
            requestData['replying_to_id'] = replyingToId;
        }

        const {data} = await api.post('/businesses/' + business.id + '/posts/' + thread.subject_id + '/threads/' + thread.id + '/messages', requestData)
        return data as Message;
    }

    /**
     * Creates a root thread within a post for us
     * @param post
     * @param topic
     */
    static async createPostThread(post: Post, topic: string): Promise<Thread> {
        const {data} = await api.post('/posts/' + post.id + '/threads', {topic})
        return data as Thread;
    }

    /**
     * Creates a message within a thread for us
     * @param thread
     * @param message
     * @param replyingToId
     */
    static async createMessage(thread: Thread, message: string, replyingToId?: number): Promise<Message> {
        const requestData: any = {message};

        if (replyingToId) {
            requestData['replying_to_id'] = replyingToId;
        }

        const {data} = await api.post('/threads/' + thread.id + '/messages', requestData)
        return data as Message;
    }
}
