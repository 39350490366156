import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Payment from '../../models/payment/payment';

/**
 * The state interface for our state
 */
export interface OrganizationPaymentsContextState extends BasePaginatedContextState<Payment> {}

const defaultContext = {
    ...defaultBaseContext(),
    expands: [
        'lineItems',
        'lineItems.item',
    ],
    order: {
        'created_at': 'desc',
    }
} as OrganizationPaymentsContextState;

interface OrganizationPaymentsLoadedContextStates {
    initiatedStates: {[key: number]: OrganizationPaymentsContextState},
}

const persistentContext = {
    initiatedStates: {},
} as OrganizationPaymentsLoadedContextStates

/**
 * The actual context component
 */
export const OrganizationPaymentContext = React.createContext<OrganizationPaymentsContextState>(defaultContext);

export interface OrganizationPaymentContextProviderProps extends BasePaginatedContextProviderProps {
    organizationId: number,
}

export const OrganizationPaymentContextProvider: React.FC<OrganizationPaymentContextProviderProps> = ({organizationId, children}) => {
    const [organizationPaymentsLoadedState, setOrganizationPaymentsLoadedState] = useState(persistentContext);

    const organizationPaymentsContextState = organizationPaymentsLoadedState.initiatedStates[organizationId] ?
        organizationPaymentsLoadedState.initiatedStates[organizationId] : defaultContext;

    useEffect(() => {
        if (!organizationPaymentsContextState.initiated) {
            const setOrganizationPaymentsContextState = (updatedContext : any) => {
                persistentContext.initiatedStates[organizationId] = updatedContext;
                setOrganizationPaymentsLoadedState({...persistentContext});
            }
            prepareContextState(setOrganizationPaymentsContextState, organizationPaymentsContextState,'/organizations/' + organizationId + '/payments')
        }
    }, [organizationId]);

    return (
        <OrganizationPaymentContext.Provider value={organizationPaymentsContextState}>
            <OrganizationPaymentContext.Consumer>
                {() => children}
            </OrganizationPaymentContext.Consumer>
        </OrganizationPaymentContext.Provider>
    );
};
