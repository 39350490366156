import Post, {canUserRemovePost} from '../../../models/post/post';
import React, {useState} from 'react';
// @ts-ignore
import Swipeable from 'react-swipy'
import './index.scss';
import {IonActionSheet, IonToast} from '@ionic/react';
import Location from '../../../models/location/location';
import {
    UserFollowsContext,
    UserFollowsContextState
} from '../../../contexts/UserFollowsContext';
import {MeContext, MeContextStateConsumer} from '../../../contexts/MeContext';
import FollowerRequests from '../../../services/requests/FollowerRequests';
import Follower, {isFollowingEntity} from '../../../models/user/follower';
import User from '../../../models/user/user';
import {
    BusinessLocationContext,
    BusinessLocationContextConsumerState,
    BusinessLocationContextProvider
} from '../../../contexts/BusinessLocationContext';
import UserFollowersService from '../../../services/users/UserFollowersService';
import {ActionSheetButton} from '@ionic/react/dist/types/components/IonActionSheet';
import DeletePostConfirmationAlert from '../../DeletePostConfirmationAlert';

interface PostEllipsesMenuSharedProps {
    open: boolean
    post: Post,
    onDismissed: () => void,
    onFollow: (id: number) => void,
    onRemove: () => void,
    onShare: () => void,
    onReport: (postResponseData: any) => void,
    userFollowsContext?: UserFollowsContextState,
}

interface PostEllipsesMenuActionSheetProps extends PostEllipsesMenuSharedProps {
    meContext: MeContextStateConsumer,
    maybeLocation?: Location
}

const PostEllipsesMenuActionSheet: React.FC<PostEllipsesMenuActionSheetProps> = ({open, post, maybeLocation, onDismissed, onFollow, onReport, onRemove, onShare, userFollowsContext, meContext}) => {

    const [confirmDeletionShowing, setConfirmDeletionShowing] = useState(false);

    const canFollowUser = userFollowsContext && post.publisher && post.publisher_type == 'user' && post.publisher_id !== meContext.me.id &&
        (post.publisher as User).allow_users_to_add_me &&
        !isFollowingEntity(userFollowsContext.loadedData, post.publisher_id!, 'user')

    const reportButton = {
        text: 'Report',
        role: 'delete',
        handler: () => {
            onReport({
                liked: false,
                saved: false,
                clicked: false,
                reported: true,
            });
        }
    };

    const buttons = new Array<ActionSheetButton>().concat(
        (maybeLocation != undefined && userFollowsContext && post.publisher_type != 'user') && !isFollowingEntity(userFollowsContext.loadedData, maybeLocation.id!, 'location') ? [{
            text: 'Follow Location',
            role: '',
            handler: () => {
                UserFollowersService.follow(meContext.me, maybeLocation, maybeLocation.id!, 'location', userFollowsContext.loadedData, (follower: Follower) => {
                    userFollowsContext.addModel(follower);
                    onFollow(follower.id!);
                })
            }
        }] : [],
        canUserRemovePost(meContext.me, post) ? [{
            text: 'Remove Post',
            role: '',
            handler: () => setConfirmDeletionShowing(true),
        }] : [reportButton],
        canFollowUser ? [{
            text: 'Follow Member',
            role: '',
            handler: () => {
                FollowerRequests.follow(meContext.me, post.publisher as User, post.publisher_id!, 'user').then(follower => {
                    userFollowsContext.addModel(follower);
                    onFollow(follower.id!);
                })
            }
        }] : [],
        [{
            text: "Share Post",
            handler: () => onShare(),
        },{
            text: 'Cancel',
            role: 'cancel',
        }]
    )

    return (
        <React.Fragment>
            <IonActionSheet
                cssClass={'post-card-action-sheet-options'}
                onDidDismiss={() => onDismissed()}
                buttons={buttons}
                isOpen={open}
            />
            <DeletePostConfirmationAlert
                onDelete={() => onRemove()}
                isOpen={confirmDeletionShowing}
                onDidDismiss={() => setConfirmDeletionShowing(false)}
            />
        </React.Fragment>
    )
}

interface PostEllipsesMenuProps extends PostEllipsesMenuSharedProps {
    location?: Location
}

const PostEllipsesMenu: React.FC<PostEllipsesMenuProps> = ({onReport, location, ...rest}) => {
    const [reportToastShowing, setReportToastShowing] = useState(false);

    const dismissReportToast = () => {
        setReportToastShowing(false);
    }

    const handleReport = (postResponseData: any) => {
        setReportToastShowing(true);
        onReport(postResponseData);
    }

    return (
        <React.Fragment>
            <MeContext.Consumer>
                {meContext =>
                    <PostEllipsesMenuActionSheet
                        onReport={handleReport}
                        meContext={meContext}
                        maybeLocation={location}
                        {...rest}
                    />
                }
            </MeContext.Consumer>
            <IonToast
                isOpen={reportToastShowing}
                onDidDismiss={dismissReportToast}
                duration={2000}
                message={'Post reported successfully'}
            />
        </React.Fragment>
    )

}

export default PostEllipsesMenu;
