import React, {useState} from 'react';
import { useFormik } from 'formik';
import {IonButton, IonContent, IonInput, IonPage, useIonRouter} from '@ionic/react';
import * as Yup from 'yup';

import ResetPasswordRequests from '../../../services/requests/ResetPasswordRequests';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import InputWrapper from '../../../components/InputWrapper';
import './index.scss';
import WelcomeTemplate from '../../../components/WelcomeSection/WelcomeTemplate';

const ForgotPassword: React.FC = () => {
	const router = useIonRouter();
	const ForgotSchema = Yup.object().shape({
		email: Yup.string().required('Email is required').email('Invalid Email')
	})

	const form = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema: ForgotSchema,
		onSubmit: (values) => submit(values)
	});
	const [complete, setComplete] = useState(false);

	const submit = async (values: { email: string }) => {
		ResetPasswordRequests.forgotPassword(values.email).then(res => {
			setComplete(true);
		}).catch((error) => {
			form.setErrors({
				email: 'Email Address Not Found',
			});
		});
	}

	return (
		<IonPage className={'forgot-password-page'}>
			<LoggedOutHeaderComponent backText='Back' />
			<IonContent>
				{complete ?
					<div className={'complete'}>
						<h3>Email Sent!</h3>
						<p>If you don't find the email in your inbox, be sure to check your 'junk' or 'spam' folders.</p>
						<IonButton onClick={() => router.back()}>Return to Login</IonButton>
					</div>
					: <div>
						<h1>Forgot Your Password?</h1>
						<p>
							Enter your account email and we'll send you a link to reset your password.
						</p>
						<InputWrapper
							label='Email'
							error={form.errors.email && form.touched.email ? form.errors.email : undefined}
							>
							<IonInput
								type='text'
								name='email'
								onIonChange={form.handleChange}
								value={form.values.email}
								/>
						</InputWrapper>
						<IonButton
							onClick={() => form.handleSubmit()}>
							Send Link
						</IonButton>
					</div>
				}
			</IonContent>
		</IonPage>
	)
}

export default ForgotPassword
