import {IonPage} from '@ionic/react';
import React from 'react';
import PrivacyPolicyText from '../../../components/PrivacyPolicyText';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import './index.scss';
import WelcomeTemplate from '../../../components/WelcomeSection/WelcomeTemplate';

const PrivacyPolicy: React.FC = () => {

    return (
        <IonPage className={'privacy-page'}>
            <LoggedOutHeaderComponent>
                Privacy Policy
            </LoggedOutHeaderComponent>
            <PrivacyPolicyText
                contactUsUrl={'/welcome/contact-us'}
            />
        </IonPage>
    );
}

export default PrivacyPolicy;
