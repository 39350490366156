import React, {HTMLAttributes} from 'react';
import './index.scss';
import {IonIcon} from '@ionic/react';

interface OwnProps extends HTMLAttributes<HTMLImageElement> {
}

interface ApplicationLogoProps extends OwnProps {
}

/**
 * @param url
 * @param showPlus
 * @param props
 * @constructor
 */
const ApplicationLogo: React.FC<ApplicationLogoProps> = ({...props})  => (
	<IonIcon className={'application-logo'} src={'/assets/main-logo.svg'}/>
)

export default ApplicationLogo;
