import React, {useState} from 'react';
import Business, {getBusinessQuickInformationLine} from '../../../models/organization/business';
import Location from '../../../models/location/location';
import './index.scss';
import ProfileImage from '../../ProfileImage';
import {IonIcon, useIonRouter} from '@ionic/react';
import {pencilOutline} from 'ionicons/icons';
import {NativePageTransitions} from '@ionic-native/native-page-transitions';
import FeaturedImages from '../../FeaturedImages';
import LocationsList from '../LocationsList';

interface Props {
    business: Business,
    displayLocations: boolean,
    canAdd?: boolean,
}

const BusinessInformation: React.FC<Props> = ({ business, displayLocations, canAdd }) => {

    const router = useIonRouter();

    /**
     * Our function that will open the location editor
     */
    const locationClicked = (location: Location) => {
        NativePageTransitions.slide({
            direction: 'up',
        }).catch(console.error);
        router.push('/organization/location-editor/' + location.id, 'forward');
    }

    /**
     * Either goes to the editor if there is only one or asks the user which editor to go to
     */
    const editBusiness = () => {
        NativePageTransitions.slide({
            direction: 'up',
        }).catch(console.error);
        router.push('/organization/business-editor', 'forward');
    }

    return (
        <div className={'business-header'}>
            <FeaturedImages images={business.featured_images ?? []}/>
            <div className={'business-details'}>
                <h5>{business.name}</h5>
                <p className={'business-info-line'}>{getBusinessQuickInformationLine(business)}</p>
                {canAdd &&
                    <div className={'edit-business-line'} onClick={editBusiness}><IonIcon icon={pencilOutline}/> Edit Profile</div>
                }
            </div>
            {displayLocations &&
                <LocationsList business={business} locationSelected={locationClicked} addEnabled={canAdd} />
            }
        </div>
    )
}

export default BusinessInformation
