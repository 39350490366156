import React from 'react';
import ProfileImage from '../../ProfileImage';
import './index.scss';
import Post from '../../../models/post/post';
import {IonIcon, IonItem} from '@ionic/react';
import {ellipsisText} from '../../../util/strings';
import {heart} from 'ionicons/icons';
import {formatTimeAgo} from '../../../util/time';
import {useHistory} from 'react-router';
import User from '../../../models/user/user';

interface PostItemProps {
    post: Post,
    lines?: "full" | "inset" | "none",
    baseUrl?: string,
}

const PostItem: React.FC<PostItemProps> = ({post, lines, baseUrl }) => {

    const history = useHistory();
    const link = (baseUrl ? baseUrl : '/home/dashboard') + ('/post/' + post.id);

    const openPost = () => {
        history.push(link);
    }

    return (
        <IonItem lines={lines ? lines : 'full'} className={'post-item'} detailIcon={'none'} onClick={openPost}>
            <ProfileImage url={post.main_image_url} noBorder slot={'start'} name={post.title ?? ''}/>
            <div className={'post-item-details'}>
                <p className={'post-date'}>{formatTimeAgo(post.published_at!)}</p>
                <p><strong>{post.publisher_type == 'user' && post.publisher ? (post.publisher as User).full_name : post.title}</strong></p>
                <p dangerouslySetInnerHTML={{__html : post.article_content ? ellipsisText(post.article_content, 40, '...') : ''}}></p>
                <p className={'likes-count'}>
                    <IonIcon icon={heart}/> {post.like_count} {post.publisher_type == 'user' ? 'Agree' : 'Likes'}
                </p>
            </div>
        </IonItem>
    )
}

export default PostItem;
