import React from 'react';
import {IonContent, IonIcon, IonTabBar, IonTabButton} from '@ionic/react';
import {search} from 'ionicons/icons';
import "./index.scss";

interface DummyTabsContentProps {
    className?: string
    onLinkClicked: () => void,
}

const DummyTabsContent : React.FC<DummyTabsContentProps> = ({className, onLinkClicked, children}) => {
    return (
        <IonContent className={'dummy-tab-content ' + className}>
            <div className={'content-wrapper'}>
                {children}
            </div>
            <div slot="fixed" className={'dummy-tabs hide-in-desktop'} >
                <IonTabButton tab='explore' onClick={onLinkClicked}>
                    <IonIcon icon={search} />
                </IonTabButton>
                <IonTabButton tab='news' onClick={onLinkClicked}>
                    <IonIcon className={'primary-tab'} src={'/assets/app-icons/logo.svg'} />
                </IonTabButton>
                {/*<IonTabButton tab='events' href={`/home/dashboard/events`}>*/}
                {/*    <IonIcon src={'/assets/app-icons/events.svg'} />*/}
                {/*</IonTabButton>*/}
                {/*<IonTabButton tab='member-post-creator'>*/}
                {/*    <IonIcon src={'/assets/app-icons/add.svg'} className={'primary-tab'} onClick={addPostClick}/>*/}
                {/*</IonTabButton>*/}
                {/*<IonTabButton tab='offers' href={`/home/dashboard/offers`}>*/}
                {/*    <IonIcon src={'/assets/app-icons/shop.svg'} />*/}
                {/*</IonTabButton>*/}
                <IonTabButton tab='account' onClick={onLinkClicked}>
                    <IonIcon src={'/assets/app-icons/account.svg'} />
                </IonTabButton>
            </div>
        </IonContent>
    )
}

export default DummyTabsContent;
