import React, {useEffect} from 'react';
import './index.scss';
import {
    IonContent,
    IonPage,
} from '@ionic/react';
import {
    OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import Post from '../../../models/post/post';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';
import BusinessPostEditorWrapper from '../../../components/PostManagement/BusinessPostEditorWrapper';

type RouteParams = {
    postId?: string,
}

interface PostCreatorContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const PostCreatorContent: React.FC<PostCreatorContentProps> = ({setHeaderProps}) => {

    const {postId} = useParams<RouteParams>()
    const navigate = useHistory();

    const previewPost = (post: Post) => {

        if (window.outerWidth < 800) {
            navigate.push(`/organization/post-creator/preview/${post.id!}`);
        }
    }

    useEffect(() => {
        setHeaderProps({
            children: 'Post Ads',
        });
    }, [])

    return (
        <BusinessPostEditorWrapper
            onPostPreview={previewPost}
            postId={postId}
        />
    );
}

const PostCreator: React.FC = () => {

    return (
        <IonPage id={'post-editor-page'}>
            <IonContent>
                <OrganizationHeaderContext.Consumer>
                    {organizationHeaderContext =>
                        <PostCreatorContent
                            setHeaderProps={organizationHeaderContext.setSharedProps}
                        />
                    }
                </OrganizationHeaderContext.Consumer>
            </IonContent>
        </IonPage>
    )
}

export default PostCreator;
