import React, {PropsWithChildren, useEffect, useState} from 'react';
import {setFirebaseToken} from '../../../data/persistent/persistent.actions';
import {setHasRespondedToNotificationPrompt} from '../../../data/persistent/persistent.actions';
import {connect} from '../../../data/connect';
import {IonButton, IonIcon, IonItem, IonLabel, IonToggle, useIonViewWillEnter} from '@ionic/react';
import "./index.scss";
import AuthRequests from '../../../services/requests/AuthRequests';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import {OpenNativeSettings} from '@ionic-native/open-native-settings';
import {App} from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

interface OwnProps extends PropsWithChildren<any> {
    userId: number,
    onResponseGiven: (permissionGranted: boolean) => void,
    forceAsk?: boolean,
    simpleMode?: boolean,
}

interface StateProps {
    firebaseToken?: string
}

interface DispatchProps {
    setFirebaseToken: typeof setFirebaseToken,
    setHasRespondedToNotificationPrompt: typeof setHasRespondedToNotificationPrompt,
}

interface NotificationPermissionProviderProps extends OwnProps, StateProps, DispatchProps {}

let inSettings = false

const NotificationsPermissionProvider: React.FC<NotificationPermissionProviderProps> = ({children, firebaseToken, forceAsk, onResponseGiven, setHasRespondedToNotificationPrompt, setFirebaseToken, simpleMode, userId}) => {

    const [needsResponse, setNeedsResponse] = useState(!firebaseToken || forceAsk);

    const uploadToken = (token: string) =>
        AuthRequests.updateMe(userId, {push_notification_key: token});

    useEffect(() => {

        if (Capacitor.isNativePlatform()) {
            document.addEventListener("resume", () => {
                if (inSettings) {
                    requestPermission();
                    inSettings = false
                }
            }, false);

            App.addListener('appRestoredResult', data => {}).then(result => {
                if (inSettings) {
                    requestPermission();
                }
            });

            PushNotifications.addListener('registration', (token: Token) => {
                handleTokenResponse(token.value)
            });

            // Some issue with your setup and push will not work
            PushNotifications.addListener('registrationError', (error: any) => {
                onResponseGiven(false)
            });
        }
    }, [])

    const handleTokenResponse = (token: string) => {
        uploadToken(token).then(() => {
            setFirebaseToken(token);
            onResponseGiven(true);
            setNeedsResponse(false);
        }).catch(error => {
            onResponseGiven(true);
            // alert('update error = ' + JSON.stringify(error));
            console.error(error);
        });
    }

    const requestPermission = (insist: boolean = false) => {
        PushNotifications.requestPermissions().then(response => {
            if (response.receive == 'denied') {
                if (insist) {
                    openNotificationSettings();
                }
            } else {
                PushNotifications.register().catch(error => {
                    if (firebaseToken) {
                        handleTokenResponse(firebaseToken);
                    }
                    console.error(error);
                });
            }
        }).catch(console.error);
    }

    const disableNotifications = () => {
        setHasRespondedToNotificationPrompt(true);
        setNeedsResponse(false);
        onResponseGiven(false);
    }

    const openNotificationSettings = () => {
        inSettings = true
        OpenNativeSettings.open('application_details');
    }

    return ( needsResponse ?
        (simpleMode ?
            <IonItem>
                <IonLabel>Enable Push Notifications</IonLabel>
                <IonToggle
                    checked={false}
                    onClick={() => requestPermission(true)}
                    slot={'end'}
                />
            </IonItem> :
            <div className={'notification-permission-content'}>
                <IonIcon src={'/assets/app-icons/push-notifications.svg'} color={'medium'}/>
                <p>Never miss a message from your favorite local spots</p>
                <IonButton onClick={() => requestPermission(false)} color={'light'}>
                    Enable Notifications
                </IonButton>
                <IonButton onClick={disableNotifications} color={'light'}>
                    Skip For Now
                </IonButton>
            </div>
        )
        : <>{children}</>
    );
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
        firebaseToken: state.persistent.firebaseToken
    }),
    mapDispatchToProps: ({
        setFirebaseToken,
        setHasRespondedToNotificationPrompt,
    }),
    component: NotificationsPermissionProvider
});
