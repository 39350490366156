import React, {PropsWithChildren} from 'react';
import {IonIcon, IonItem} from '@ionic/react';
import {pencilOutline} from 'ionicons/icons';

interface ProfileEditorLinkProps extends PropsWithChildren<any> {
    iconType: string,
    addMessage: string,
    routerLink: string,
}

const ProfileEditorLink: React.FC<ProfileEditorLinkProps> = ({iconType, addMessage, routerLink, children}) => {
    return (
        <IonItem routerLink={routerLink} lines={'none'} detail={false}>
            <IonIcon slot={'start'} src={`/assets/app-icons/${iconType}.svg`}/>
            {children ?
                <span className={'current-value'}>{children}</span> :
                <span className={'add-link'}>+ Add {addMessage}</span>
            }
            <IonIcon className={'edit-icon'} slot={'end'} icon={pencilOutline}/>
        </IonItem>
    );
}

export default ProfileEditorLink;
