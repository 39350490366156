import React, {PropsWithChildren} from 'react'
import './index.scss';

interface Props extends PropsWithChildren<any> {
}

const GreyBackgroundSection: React.FC<Props> = ({ children }) => {
	return (
		<div className={'grey-background-section'}>
			{children}
		</div>
	)
}

export default GreyBackgroundSection
