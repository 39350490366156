import React, {useState} from 'react';
import { useFormik } from 'formik';
import {IonButton, IonContent, IonInput, IonPage, useIonRouter} from '@ionic/react';
import * as Yup from 'yup';

import ResetPasswordRequests from '../../services/requests/ResetPasswordRequests';
import LoggedOutHeaderComponent from '../../components/WelcomeSection/LoggedOutHeader';
import InputWrapper from '../../components/InputWrapper';
import './index.scss';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router';

type RouteParams = {
	token: string
}

const ResetPassword: React.FC = () => {

	const {token} = useParams<RouteParams>()

	const ForgotSchema = Yup.object().shape({
		email: Yup.string().required('Email is required').email('Invalid Email'),
		password: Yup.string().required('New Password is required').min(6, 'New Password must be at least 6 characters')
	})

	const form = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: ForgotSchema,
		onSubmit: (values) => submit(values)
	});
	const [complete, setComplete] = useState(false);

	const submit = async (values: { email: string, password: string }) => {
		ResetPasswordRequests.resetPassword(token!, values.email, values.password).then(res => {
			setComplete(true);
		}).catch((error) => {
			form.setErrors({
				email: 'Email Address Not Found',
			});
		});
	}

	return (
		<IonPage className={'reset-password-page'}>
			<LoggedOutHeaderComponent backText='Cancel' />
			<IonContent>
				{complete ?
				<div className={'complete'}>
					<h3>All Set!</h3>
					<IonButton onClick={() => window.location.replace('/')}>Return to Login</IonButton>
				</div>
				: <div>
					<h1>Reset Your Password</h1>
					<p>
						Enter your account email and a new password, and you will be all set!
					</p>
					<InputWrapper
						label='Email'
						error={form.errors.email && form.touched.email ? form.errors.email : undefined}
						>
						<IonInput
							type='text'
							name='email'
							onIonChange={form.handleChange}
							value={form.values.email}
							/>
					</InputWrapper>
					<InputWrapper
						label='New Password'
						error={form.errors.password && form.touched.password ? form.errors.password : undefined}
					>
						<IonInput
							type='password'
							name='password'
							onIonChange={form.handleChange}
							value={form.values.password}
						/>
					</InputWrapper>
					<IonButton
						onClick={() => form.handleSubmit()}>
						Reset Password
					</IonButton>
				</div>
				}
			</IonContent>
		</IonPage>
	)
}

export default ResetPassword
