import React from 'react';
import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonRouterLink,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import './index.scss';
import ApplicationLogo from '../ApplicationLogo'
import LoadingIndicatorComponent from '../LoadingIndicator'
import {chevronBack, closeOutline} from 'ionicons/icons';
import { setManagingBusinessId } from '../../data/persistent/persistent.actions';
import {connect} from "../../data/connect";



interface OwnProps {
	routerLink?: string,
	closeButton?: boolean,
	noLogo?: boolean,
	onBackArrowClick?: () => void
}

interface DispatchProps {
	setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessCreationHeaderProps extends OwnProps, DispatchProps {
}
/**
 * @param url
 * @param showPlus
 * @param props
 * @constructor
 */
const BusinessCreationHeader: React.FC<BusinessCreationHeaderProps> = ({routerLink, closeButton, noLogo, onBackArrowClick, setManagingBusinessId})  => {

	const goToRouterLink = () => {
		if(!routerLink) {
			setManagingBusinessId()
		}
		window.location.replace(routerLink ?? '/home/dashboard/account')
	}

	const button = closeButton ? (
		<IonRouterLink slot="start" onClick={goToRouterLink}>
			<IonIcon className={'close-icon'} icon={closeOutline}/>
		</IonRouterLink>
	) : (
		onBackArrowClick ? (
			<IonRouterLink slot="start" onClick={onBackArrowClick}>
				<IonIcon className={'back-icon'} icon={chevronBack}/>
			</IonRouterLink>
		) : <IonBackButton icon={chevronBack} text='' />
    )

	return (
		<IonHeader className={"logged-in-header ion-no-border business-creation-header" + (noLogo ? ' no-logo' : '')}>
			<IonToolbar className={'closeToolbar'}>
				<IonButtons slot="start">
					{button}
				</IonButtons>
				<IonTitle>
					{!noLogo ? <ApplicationLogo /> : null}
				</IonTitle>
				<IonButtons slot="end">
					<LoadingIndicatorComponent/>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	);
}

export default connect<OwnProps, {}, DispatchProps >({
	mapDispatchToProps: ({
		setManagingBusinessId,
	}),
	component: BusinessCreationHeader
});
