import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Location from '../models/location/location';
import LoadingScreen from '../components/LoadingScreen';

/**
 * The state interface for our state
 */
export interface LocationsContextState extends BasePaginatedContextState<Location> {
    lastSearch: string,
}

const defaultContext = {
    ...defaultBaseContext(),
    expands: [
        'business',
        'business.featuredImages'
    ],
    lastSearch: '',
} as LocationsContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const LocationsContext = React.createContext<LocationsContextState>(defaultContext);

export interface LocationsContextProviderProps extends BasePaginatedContextProviderProps {
    latitude: number,
    longitude: number,
    searchText: string|null,
    limit?: number,
}

let lastSearch = '';

export const LocationsContextProvider: React.FC<LocationsContextProviderProps> = (({latitude, longitude, searchText, limit, ...props}) => {
    const [locationsState, setLocationsState] = useState(persistentContext);

    useEffect(() => {

        const params = {
            latitude,
            longitude,
        } as any;

        if (!searchText?.length) {
            params.radius = 80.4672;
        }

        if (limit) {
            params.limit = limit;
        }

        prepareContextState(setLocationsState as any, locationsState,'/locations', params)
    }, [latitude, longitude, searchText, limit]);

    useEffect(() => {
        lastSearch = searchText ? searchText : '';
        persistentContext.setSearch('resource.content', searchText);
    }, [searchText])

    persistentContext = {
        ...createCallbacks(setLocationsState as any, locationsState, '/locations'),
        lastSearch: lastSearch,
    };
    return (
        <LocationsContext.Provider value={persistentContext}>
            <LocationsContext.Consumer>
                {context => {
                    return context.initialLoadComplete ? props.children : <LoadingScreen/>;
                }}
            </LocationsContext.Consumer>
        </LocationsContext.Provider>
    )
});
