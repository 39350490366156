import BaseModel from '../base-model';
import MembershipPlanRate from './membership-plan-rate';
import MembershipPlan, {
    calculateProratedCost,
    MembershipPlanDuration
} from './membership-plan';
import Business from '../organization/business';
import Organization from "../organization/organization";
import moment from "moment";


export default interface Subscription extends BaseModel {

    /**
     * The id of the payment method that was used for this
     */
    payment_method_id?: number;

    /**
     * The last renewed at date time in string form
     */
    last_renewed_at: string;

    /**
     * The date time for the initial subscription date
     */
    subscribed_at?: string;

    /**
     * The date time for when this subscription expires
     */
    expires_at: string;

    /**
     * The date for when the user cancelled their subscription
     */
    canceled_at?: string;

    /**
     * Whether or not this subscription will renew at the cancellation date
     */
    recurring: boolean;

    /**
     * Whether or not this subscription is a trial
     */
    is_trial: number;

    /**
     * The amount of locations the user is paying for
     */
    location_count: number;

    /**
     * The rate that the subscription is connected to
     */
    membership_plan_rate: MembershipPlanRate;

    /**
     * The rate that this subscription was last successfully renewed at
     */
    last_renewal_rate: MembershipPlanRate;
}

/**
 * calculates the total number of days until a subscription expires
 * @param subscription
 */
export function calculateDaysToExpiration(subscription: Subscription) {

    const now = moment()
    const expiresAtDate = new Date(subscription.expires_at)
    expiresAtDate.setHours(23)
    expiresAtDate.setMinutes(59)
    expiresAtDate.setSeconds(59)
    const expiresAt = moment(expiresAtDate)
    return Math.floor(moment.duration(expiresAt.diff(now)).asDays())

}

/**
 * calculates the total amount the subscription will be renewed for
 * @param locationCount
 * @param locationCost
 */
export function calculateSubscriptionTotal(locationCount: number, locationCost: number) : number {
    return locationCount * locationCost
}

/**
 * calculates the total due today based on any changes the user may have selected
 * @param maybeSubscription
 * @param selectedDuration
 * @param locationCount
 * @param selectedMembershipPlan
 */
export function calculateTotalToday(maybeSubscription: Subscription|undefined, selectedDuration: MembershipPlanDuration, locationCount: number, selectedMembershipPlan: MembershipPlan) : number {
    if ( maybeSubscription && maybeSubscription.expires_at ) {
        return calculateProratedCost(selectedMembershipPlan, maybeSubscription, locationCount);
    }
    return calculateSubscriptionTotal(locationCount, selectedMembershipPlan.current_cost);
}

/**
 * changes the selected duration to show the opposite of what's currently selected
 * @param selectedDuration
 */
export function changeSelectedDuration(selectedDuration: MembershipPlanDuration) : MembershipPlanDuration {
    return (selectedDuration == 'month' ? 'year' : 'month') as MembershipPlanDuration
}

/**
 * determines the default duration that should be displayed
 * @param maybeSubscription
 * @param defaultDuration
 */
export function determineDefaultDuration(maybeSubscription: Subscription|undefined, defaultDuration: MembershipPlanDuration) : MembershipPlanDuration {
    if ( maybeSubscription ) {
        if (isSubscriptionInTrialWithoutRenewal(maybeSubscription)) {
            return defaultDuration;
        }
        return maybeSubscription.membership_plan_rate.membership_plan.duration;
    }
    return defaultDuration;
}

/**
 * Convenience function for determining throughout the app whether or not the user paid for this particular subscription
 * @param subscription
 */
export function didPayForSubscription(subscription: Subscription) : boolean {
    return !subscription.is_trial && new Date(subscription.expires_at) > new Date();
}

/**
 * Looks at a list of subscriptions, and determines which one will be the current subscription for the user
 * @param subscriptions
 * @param expiresAfter
 */
export function findCurrentActiveSubscription(subscriptions: Subscription[], expiresAfter: Date|null = null) : Subscription|undefined {
    expiresAfter = expiresAfter ?? new Date();
    return subscriptions.sort((a, b) => {
        return (new Date(b.created_at!) as any) - (new Date(a.created_at!) as any);
    }).find(subscription => {
        const isTrial = subscription.is_trial;
        const isLifetime = isSubscriptionForLifeTime(subscription);
        const hasNotExpired = new Date(subscription.expires_at) > (expiresAfter as Date);
        return (isTrial && hasNotExpired) || isLifetime || hasNotExpired;
    });
}

/**
 * Tells us whether or not the passed through subscription will be charged the next time it expires
 * @param subscription
 */
export function hasPendingSubscriptionCharge(subscription?: Subscription): boolean {
    return subscription != undefined && subscription.recurring && subscription.membership_plan_rate.cost > 0;
}

/**
 * This functions determines whether or not the passed in subscription the ability to access all pro features
 * @param subscription
 */
export function hasProFeatures(subscription: Subscription) : boolean {
    return isSubscriptionInTrial(subscription) || didPayForSubscription(subscription);
}

/**
 * Returns whether or not the passed in subscription is a lifetime subscription
 * @param subscription
 */
export function isSubscriptionForLifeTime(subscription: Subscription) : boolean {
    return !subscription.expires_at || subscription.membership_plan_rate.membership_plan?.duration == MembershipPlanDuration.Lifetime;
}

/**
 * Simple function for controlling whether or not the passed in subscription is a trial
 * @param subscription
 */
export function isSubscriptionInTrial(subscription: Subscription) : boolean {
    return subscription.is_trial == 1;
}

/**
 * Tells us whether or not the subscription passed in is currently in trial period
 * with a plan already selected for renewal
 * @param subscription
 */
export function isSubscriptionInTrialWithoutRenewal(subscription: Subscription): boolean {
    return isSubscriptionInTrial(subscription) && !hasPendingSubscriptionCharge(subscription);
}

/**
 * returns the greater of the current number of business location or 1 as the default location count
 * @param maybeSubscription
 * @param business
 */
export function locationCountDefault(maybeSubscription: Subscription|undefined, business: Business) : number {
    if ( maybeSubscription && maybeSubscription.last_renewal_rate && maybeSubscription.is_trial && maybeSubscription.last_renewal_rate.cost > 0 ) {
        return maybeSubscription.location_count;
    }
    return (business.locations?.length ? business.locations.length : 1);
}

/**
 * Gets the most recent subscription that was active
 * @param subscriptions
 */
export function mostRecentActiveSubscription(subscriptions: Subscription[]) : Subscription|undefined {
    return subscriptions.length ? subscriptions.sort((a, b) => {
        return (new Date(b.expires_at) as any) - (new Date(a.expires_at) as any);
    })[0] : undefined;
}

/**
 * determines if more locations need to be paid for if the plan is a paid plan auto-renews
 * @param maybeSubscription
 * @param membershipPlan
 * @param business
 * @param locationCount
 */
export function needsToPayForMoreLocations(maybeSubscription: Subscription|undefined, membershipPlan: MembershipPlan, business: Business, locationCount: number) : boolean {
    const isTrial = maybeSubscription ? maybeSubscription.is_trial : false;
    const isRecurring = maybeSubscription ? maybeSubscription.recurring : false;
    const willAutoRenew = !isTrial || isRecurring;
    const tooManyLocationsExist = business.locations != undefined && business.locations.length > locationCount
    const membershipPlanIsPaid = membershipPlan.current_cost > 0;
    return willAutoRenew && membershipPlanIsPaid && tooManyLocationsExist
}

/**
 * Takes in an organization and iterates over it's list of subscriptions, the input subscription, the input subscription
 * replaces the subscription currently at that spot in the array
 * @param organization
 * @param subscription
 */
export function replaceOldSubscription(organization: Organization, subscription: Subscription) : Subscription[]|undefined {
   return organization.subscriptions?.map(i => i.id == subscription.id ? subscription : i)
}
