import React, {useState} from 'react';
import OrganizationDashboardTabbedPage
    from '../../../../components/OrganizationManagement/OrganizationDashboardTabbedPage';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../../contexts/organization/MyBusinessContext';
import {
    BusinessFollowersContext,
    BusinessFollowersContextProvider, BusinessFollowersContextState
} from '../../../../contexts/organization/BusinessFollowersContext';
import './index.scss';
import Follower from '../../../../models/organization/follower';
import {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonActionSheet, IonToast
} from '@ionic/react';
import ProfileImage from '../../../../components/ProfileImage';
import moment from 'moment';
import Location from '../../../../models/location/location';
import { Clipboard } from '@capacitor/clipboard';
import {Capacitor} from '@capacitor/core';

interface FollowerItemProps {
    follower: Follower
}

const FollowerItem: React.FC<FollowerItemProps> = ({follower}) => {

    const startedFollowingDate = (dateString: string) => {
        return moment(dateString).format('MMMM YYYY');
    }

    return (
        <IonItem lines={'none'} routerLink={`/organization/user/${follower.id}`}>
            <ProfileImage url={follower.profile_image_url} name={follower.full_name} />
            <div className={'follower-details'}>
                <p><strong>{follower.full_name}</strong></p>
                <p>Follower since {startedFollowingDate(follower.started_following_at)}</p>
            </div>
        </IonItem>
    )
}

interface FollowersListProps {
    followers: Follower[],
    hasMore: boolean,
    totalFollowers: number,
    loadNextPage: () => {},
}

const FollowersList: React.FC<FollowersListProps> = ({followers, totalFollowers, hasMore,loadNextPage}) => {

    async function searchNext($event: CustomEvent<void>) {
        await loadNextPage();

        ($event.target as HTMLIonInfiniteScrollElement).complete();
    }


    const orderedFollowers = followers
        .sort((a, b) => Date.parse(b.created_at!) - Date.parse(a.created_at!));

    return (
        <div className={'business-followers-list'}>
            {totalFollowers > 0 && <p className={'total-followers'}>{totalFollowers} Followers</p>}
            <IonList>
                {orderedFollowers.map(follower => <FollowerItem key={follower.id} follower={follower}/>)}
            </IonList>
            <IonInfiniteScroll threshold="100px" disabled={!hasMore} onIonInfinite={searchNext}>
                <IonInfiniteScrollContent
                    loadingText="Loading...">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </div>
    )
}

interface NoFollowersProps {
    location?: Location
}

const NoFollowers: React.FC<NoFollowersProps> = ({location}) => {

    const [copiedToastShowing, setCopiedToastShowing] = useState(false);

    const locationUrl = location ? `app.gedditlocal.com/business/${location.business_id}/location/${location.id}` : null;

    const copyShareLink = () => {
        Clipboard.write({
            string: 'https://' + locationUrl,
        }).catch(console.error);
        setCopiedToastShowing(true);
    }

    return (
        <div className={'no-business-followers'}>
            <h3>You have no followers yet!</h3>
            <p>Invite customers to follow your business on Geddit Local.</p>

            <ul>
                <li><strong>Followers</strong> get notified whenever you post something new.</li>
                <li><strong>Followers</strong> don't need to be nearby in order to see your ads.</li>
                <li><strong>Followers</strong> are more likely to share your ads with others.</li>
            </ul>

            {(locationUrl && false) &&
                <div className={'share-link-section'}>
                    <p>Copy and share the link to your Geddit Local business page.</p>
                    <div className={'location-url-display'}>{locationUrl}</div>
                    <IonButton onClick={copyShareLink}>Copy Link</IonButton>
                </div>
            }

            <IonToast
                isOpen={copiedToastShowing}
                onDidDismiss={() => setCopiedToastShowing(false)}
                duration={2000}
                position={"top"}
                message={"Link copied to clipboard"}
            />
        </div>
    )
}

interface FollowersLoadedSelectorProps {
    context: BusinessFollowersContextState,
    locations: Location[],
}

const FollowersLoadedSelector: React.FC<FollowersLoadedSelectorProps> = ({context, locations}) => {

    const [locationFilterId, setLocationFilterId] = useState<number>(0);

    const filterLocations = (locationId: number) => {
        setLocationFilterId(locationId);
        context.setFilter('follows.follows_id', locationId == 0 ? null : locationId).catch(console.error);
        context.setFilter('follows.follows_type', locationId == 0 ? null : 'location').catch(console.error);
    }

    return (
        <>
            {locations.length > 1 ?
                <IonSelect value={locationFilterId} onIonChange={e => filterLocations(e.detail.value)}>
                    <IonSelectOption value={0}>All Locations</IonSelectOption>
                    {locations.map(location => (
                        <IonSelectOption key={location.id} value={location.id}>{location.name}</IonSelectOption>
                    ))}
                </IonSelect> : ''
            }
            {context.initialLoadComplete && context.total === 0 ?
                <NoFollowers location={locations[0] ?? undefined}/> :
                <FollowersList
                    totalFollowers={context.total!}
                    followers={context.loadedData}
                    hasMore={context.hasAnotherPage}
                    loadNextPage={context.loadNext}
                />
            }
        </>
    )
}

interface FollowersLoaderProps {
    businessId: number,
    locations: Location[],
}

const FollowersLoader: React.FC<FollowersLoaderProps> = ({businessId, locations}) => {
    return (
        <BusinessFollowersContextProvider businessId={businessId}>
            <BusinessFollowersContext.Consumer>
                {businessFollowersContext => (
                    <FollowersLoadedSelector
                        context={businessFollowersContext}
                        locations={locations}
                    />
                )}

            </BusinessFollowersContext.Consumer>
        </BusinessFollowersContextProvider>
    );
}

const Followers: React.FC = () => {
    return (
        <OrganizationDashboardTabbedPage>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext =>
                        <FollowersLoader
                            businessId={myBusinessContext.business.id!}
                            locations={myBusinessContext.business.locations ? myBusinessContext.business.locations : []}
                        />
                    }
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </OrganizationDashboardTabbedPage>
    );
}

export default Followers
