import React, {useEffect, useState} from 'react';
import GlobalGooglePlacesContextProvider, {
    GlobalGooglePlacesContext,
    GoogleGlobalPlacesProviderContextConsumerState, neededFields
} from '../GlobalGooglePlacesContextProvider';

let cachedResults: {[key: string]: google.maps.places.PlaceResult} = {}

interface FetchGooglePlaceDetailsContextProviderConsumerState {
    result: google.maps.places.PlaceResult|undefined,
    loaded: boolean,
}

const defaultState = {
    result: undefined,
    loaded: false,
};

export const FetchGooglePlaceDetailsContext = React.createContext<FetchGooglePlaceDetailsContextProviderConsumerState>(defaultState);

interface FetchGooglePlaceDetailsQueryManagerProps extends FetchGooglePlaceDetailsContextProviderProps {
    globalGooglePlacesContext: GoogleGlobalPlacesProviderContextConsumerState
}

export const getPlaceDetails = (service: google.maps.places.PlacesService, placeId: string, onReceived: (details: google.maps.places.PlaceResult) => void, onError: () => void) => {

    service.getDetails({
        placeId: placeId,
        fields: [
            ...neededFields,
            'address_component',
            'formatted_phone_number',
            'website',
        ],
    }, (result, status) => {
        if (status == 'OK') {
            cachedResults[placeId] = result;
            onReceived(result);
        } else {
            onError();
        }
    });
}

const FetchGooglePlaceDetailsQueryManager: React.FC<FetchGooglePlaceDetailsQueryManagerProps> = ({placeId, globalGooglePlacesContext, children}) => {

    const [currentResults, setCurrentResults] = useState<FetchGooglePlaceDetailsContextProviderConsumerState>(defaultState);

    useEffect(() => {
        if (cachedResults[placeId]) {
            setCurrentResults({
                result: cachedResults[placeId],
                loaded: true,
            })
        } else if (globalGooglePlacesContext.service) {
            setCurrentResults({
                result: undefined,
                loaded: false,
            });
            getPlaceDetails(globalGooglePlacesContext.service, placeId, details => {
                setCurrentResults({
                    result: details,
                    loaded: true,
                });
            }, () => {
                // TODO handle error
            });
        }
    }, [placeId, globalGooglePlacesContext.service])

    return (
        <FetchGooglePlaceDetailsContext.Provider value={currentResults}>
            {children}
        </FetchGooglePlaceDetailsContext.Provider>
    )
}


interface FetchGooglePlaceDetailsContextProviderProps {
    placeId: string,
}

const FetchGooglePlaceDetailsContextProvider: React.FC<FetchGooglePlaceDetailsContextProviderProps> = ({ children, ...rest}) => {

    return (
        <GlobalGooglePlacesContextProvider>
            <GlobalGooglePlacesContext.Consumer>
                {globalGooglePlacesContext =>
                    <FetchGooglePlaceDetailsQueryManager globalGooglePlacesContext={globalGooglePlacesContext} {...rest}>
                        {children}
                    </FetchGooglePlaceDetailsQueryManager>
                }
            </GlobalGooglePlacesContext.Consumer>
        </GlobalGooglePlacesContextProvider>
    )
}

export default FetchGooglePlaceDetailsContextProvider;
