import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Post from '../../models/post/post';
import LoadingScreen from '../../components/LoadingScreen';
import {connect} from '../../data/connect';
import {FeedSettings} from '../../data/persistent/persistent.state';

/**
 * The state interface for our state
 */
export interface UserPostContextState extends BasePaginatedContextState<Post> {}

const defaultContext = {
    ...defaultBaseContext(),
    limit: 10,
    expands: [
        'locations',
        'locations.business',
        'postLocations',
        'publisher',
    ],
} as UserPostContextState;

/**
 * The actual context component
 */
export const UserPostsContext = React.createContext<UserPostContextState>(defaultContext);

export interface OwnProps extends BasePaginatedContextProviderProps {
    userId: number,
}

interface StateProps {
    feedSettings: FeedSettings,
}

interface UserPostsContextProviderProps extends OwnProps, StateProps {}

export const UserPostsContextProvider: React.FC<UserPostsContextProviderProps> = (({userId, feedSettings, ...props}) => {
    const [postsState, setPostsState] = useState({
        ...defaultContext,
        initialLoadComplete: false,
    });

    const endpoint = '/users/' + userId + '/recent-following-posts';
    useEffect(() => {
        const newContext = prepareContextState(setPostsState, postsState, endpoint)
        if (!feedSettings.showBusinesses || !feedSettings.showMembers) {
            newContext.setFilter('publisher_type', feedSettings.showMembers ? 'user' : 'business');
        } else {
            newContext.setFilter('publisher_type', undefined);
        }
        newContext.initialLoadComplete = false;
        setPostsState({...newContext})
        newContext.refreshData(false);
    }, [userId, feedSettings]);

    const context = createCallbacks(setPostsState, postsState, endpoint);

    return (
        <UserPostsContext.Provider value={context}>
            {context.initialLoadComplete ? props.children : <LoadingScreen/>}
        </UserPostsContext.Provider>
    )
});

export default connect<OwnProps, StateProps, {}>({
    mapStateToProps: (state) => ({
        feedSettings: state.persistent.feedSettings
    }),
    component: UserPostsContextProvider
});
