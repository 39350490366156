import Location from '../../../models/location/location';
import React from 'react';
import {calculateDistance, convertFromKMtoMiles} from '../../../util/location';
import FeaturedImages from '../../FeaturedImages';
import ProfileImage from '../../ProfileImage';
import './index.scss';
import Business from "../../../models/organization/business";
import {UserFollowsContextProvider, UserFollowsContextState, UserFollowsContext} from '../../../contexts/UserFollowsContext';
import FollowButton from '../FollowButton';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import {IonButton} from '@ionic/react';

interface LocationDetailsProps {
    latitude?: number,
    longitude?: number,
    business?: Business,
    location: Location,
    button?: {
        onClick: () => void,
        text: string,
    }
}

const LocationDetails: React.FC<LocationDetailsProps> = ({business, latitude, longitude, location, button}) => {

    const distance = latitude && longitude ? convertFromKMtoMiles(calculateDistance(latitude, longitude, location.latitude!, location.longitude!)) : null;

    return (
        <div className={'location-details-wrapper'}>
            <div className={'location-images'}>
                <FeaturedImages images={location.business?.featured_images ?? (business?.featured_images ?? [])}/>
                <ProfileImage url={location.logo ?? location.business?.logo_url} name={location.name}/>
            </div>
            <div className={'location-details-text'}>
                <div className={'location-description'}>
                    <p id={'location-name'}><strong>{location.name}</strong></p>
                    {business?.main_category_name && <p>{business.main_category_name}</p>}
                    {!business?.main_category_name && <p><small>{location.neighborhood ?? location.city}{location.business?.main_category_name && ' - ' + location.business?.main_category_name}</small></p>}
                    {distance && <p><small>{distance.toFixed(1)} mi</small></p>}
                </div>
                {button ?
                    <IonButton onClick={button.onClick}>
                        {button.text}
                    </IonButton>:
                    <MeContextProvider optional hideLoadingSpace>
                        <MeContext.Consumer>
                            {meContext =>
                                <UserFollowsContextProvider userId={meContext.me.id!}>
                                    <UserFollowsContext.Consumer>
                                        {userFollowsContext =>
                                            <FollowButton
                                                relatedId={location.id!}
                                                relatedType={'location'}
                                                related={location}
                                                userFollowsContext={userFollowsContext}
                                            />
                                        }
                                    </UserFollowsContext.Consumer>
                                </UserFollowsContextProvider>
                            }
                        </MeContext.Consumer>
                    </MeContextProvider>
                }
            </div>
        </div>
    )
}

export default LocationDetails;
