import React, {useContext} from 'react';
import './index.scss';
import {
    IonContent,
    IonPage,
    IonHeader,
    IonIcon,
    IonButton,
    NavContext,
    IonToolbar,
} from '@ionic/react';

interface PostPublishedContentProps {
    backUrl?: string,
}

const PostPublishedContent: React.FC<PostPublishedContentProps> = ({backUrl}) => {

    const {navigate} = useContext(NavContext);

    return (
        <IonPage className={'post-published-page'}>
            <IonHeader mode={'ios'}>
                <IonToolbar>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <h2>Scheduled!</h2>
                <IonIcon src={'/assets/app-icons/clock.svg'}/>
                <p>Your post will publish to the feeds according to your schedule.</p>
                {backUrl && <IonButton onClick={() => navigate(backUrl, 'back')}>Done</IonButton>}
            </IonContent>
        </IonPage>
    );
}

export default PostPublishedContent;
