import React, {PropsWithChildren, useState} from 'react';
import './index.scss';
import TutorialMessageComponent from "../Tutorials/TutorialMessageComponent";
import {connect} from "../../data/connect";
import User from '../../models/user/user';
import {PublishedPostsContext, PublishedPostsContextProvider} from '../../contexts/PublishedPostsContext';
import UserRecommendationList from '../UserRecommendationList';
import MessagingRequests from '../../services/requests/MessagingRequests';
import {useIonViewWillEnter} from '@ionic/react';

interface OwnProps {
    user: User,
    me: User,
    noPostsMessage: string,
}

interface UserRecommendationsProps extends OwnProps {}

const UserRecommendations: React.FC<UserRecommendationsProps>  = ({user, me, noPostsMessage}) => {

    const [refresh, setRefresh] = useState(false);
    useIonViewWillEnter(() => {
        setRefresh(true);
    })

    return (
        <div className={'referrals-content'}>
            <PublishedPostsContextProvider
                publisherType={'users'}
                publisherId={user.id!}
                refresh={refresh}
                onRefreshComplete={() => setRefresh(false)}
            >
                <PublishedPostsContext.Consumer>
                    {postsContext =>
                        <UserRecommendationList
                            refreshing={refresh}
                            onRefresh={() => setRefresh(true)}
                            postsContext={postsContext}
                            postBaseUrl={'/home/dashboard/post/'}
                            editUrl={'/home/member-post-editor/'}
                            publisherType={'users'}
                            publisherId={user.id!}
                            commentingEntity={me}
                            isMe={me.id === user.id}
                            createPostThread={MessagingRequests.createPostThread}
                            createMessage={MessagingRequests.createMessage}
                        >

                            <div className='no-referrals-content'>
                                <h4>{noPostsMessage}</h4>
                            </div>
                        </UserRecommendationList>
                    }
                </PublishedPostsContext.Consumer>
            </PublishedPostsContextProvider>
        </div>
    )
}

export default UserRecommendations;
