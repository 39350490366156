import React from 'react';
import { IonButton } from '@ionic/react';
import './index.scss';

export interface ConfirmationPageContentProps {
    onConfirm: () => void,
    confirmText: string,
}

const ConfirmationPageContent: React.FC<ConfirmationPageContentProps> = ({onConfirm, confirmText, children}) => {

    return (
        <div className={'confirmation-page-content'}>
            <div className={'confirmation-page-content-details'}>
                {children}
            </div>
            <IonButton onClick={onConfirm}>
                {confirmText}
            </IonButton>
        </div>
    );
}

export default ConfirmationPageContent;
