import React, {useState} from 'react';
import OrganizationCreationResultsPage from '../../../components/OrganizationCreationResultsPage';
import MyBusinessContextProvider, {MyBusinessContext} from "../../../contexts/organization/MyBusinessContext";
import Subscription, {
    calculateDaysToExpiration,
    findCurrentActiveSubscription
} from "../../../models/subscription/subscription";
import {IonAlert, IonButton, IonInput, IonToast} from '@ionic/react';
import './index.scss'
import {useHistory, useLocation, useParams} from 'react-router';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import UnderlinedInput from '../../../components/GeneralUIElements/UnderlinedInput';
import {emailRegExp, validateRegexMatch} from '../../../util/regex';
import User from '../../../models/user/user';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';

interface ResultContentProps {
    noun: 'Business' | 'Organization',
}

interface ProWelcomeContentProps extends ResultContentProps {
    subscriptions: Subscription[]
}

const ProWelcomeContent: React.FC<ProWelcomeContentProps> = ({subscriptions}) => {

    const currentSubscription = findCurrentActiveSubscription(subscriptions);
    const days = currentSubscription ? calculateDaysToExpiration(currentSubscription) : 30
    return (
        <>
            <h4>Congratulations!</h4>
            <p>Based on the information you've provided, your business qualifies for our <strong>Pro plan!</strong></p>
            <p>We're going to start you out with a <strong>{days}-day free trial (no credit card required)</strong> on our Pro plan. You can then choose the subscription that best fits your needs going forward.</p>
        </>
    )
}

const ProWelcome: React.FC<ResultContentProps> = ({noun}) => {
    return(
        <OrganizationCreationResultsPage id={'business-rejection-page'} linkText={'Preview Pricing Plans'} linkURL={'/organization-creator/plan-preview'} showGetStartedFooter>
            <MyBusinessContextProvider hideLoadingSpace={true}>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <ProWelcomeContent noun={noun} subscriptions={myBusinessContext.organization.subscriptions ? myBusinessContext.organization.subscriptions : []} />
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </OrganizationCreationResultsPage>
    )
}

const FreeWelcome: React.FC<{}> = () => {
    return(
        <OrganizationCreationResultsPage id={'business-rejection-page'} linkText={'Preview Pricing Plans'} showGetStartedFooter>
            <h4>Welcome!</h4>
            <p>Based on the information you've provided, your business qualifies for our <strong>Free plan</strong>.</p>
            <p>Once you have finished adding your first location, members can start finding your business on Geddit Local.</p>
            <p>We will keep you posted through the app as more features become available.</p>
        </OrganizationCreationResultsPage>
    )
}

const BetaWelcomeContent: React.FC<ResultContentProps> = ({noun}) => {

    return (
        <OrganizationCreationResultsPage className={'beta-page'} showGetStartedFooter>
            <h4>Welcome to Geddit Local for {noun}{noun == 'Organization' ? 's' : ''}!</h4>
            <h6>(Beta Version)</h6>
            <p>People will use Geddit Local to discover things to do in their local community. As a local {noun.toLowerCase()}, you are the reason people install this app, so let them know about the great things you've got going on!</p>
            <p>During this testing period, there are no fees, so please feel encouraged to grow your follower base and advertise as often as you like. We'll be interested in your feedback and advice, while we continue to improve the app.</p>
        </OrganizationCreationResultsPage>
    )
}

const WaitListContent: React.FC<ResultContentProps> = ({noun}) => {

    const [contactEmail, setContactEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successModalShowing, setSuccessModalShowing] = useState(false);
    const history = useHistory();

    const submit = (user: User) => {
        if (contactEmail && contactEmail.length > 0) {
            if (!validateRegexMatch(emailRegExp, contactEmail)) {
                setErrorMessage("Please enter a valid email address");
            } else {
                saveContactEmailAddress(user);
            }
        } else {
            setSuccessModalShowing(true);
        }
    }

    const saveContactEmailAddress = (user: User) => {
        const organizationManagers = user.organization_managers ? [...user.organization_managers] : []
        const organizationManager = organizationManagers.reverse().find(i =>
            i.organization.businesses && i.organization.businesses[0] &&
                !i.organization.businesses[0].approved_at
        )

        if (organizationManager && contactEmail) {
            OrganizationRequests.updateOrganizationManagerContactEmail(organizationManager, contactEmail).then(() =>{
                setSuccessModalShowing(true);
            });
        }
    }

    const finish = () => {
        history.replace("/home");
    }

    return (
        <OrganizationCreationResultsPage className={'pending-page'}>
            <h4>Welcome to Our Wait List!</h4>
            <p>While we're super excited for your interest in Geddit Local for your business, we're still vetting new business accounts one at a time at this point. Your business has been added to the wait list, and we'll email you when ready.</p>
            <MeContextProvider>
                <MeContext.Consumer>
                    {meContext =>
                        <React.Fragment>
                            <h5>Contact email</h5>
                            <UnderlinedInput>
                                <IonInput
                                    placeholder={meContext.me.email}
                                    value={contactEmail}
                                    onIonChange={e => setContactEmail(e.detail.value ?? "")}
                                />
                            </UnderlinedInput>
                            <IonButton
                                onClick={() => submit(meContext.me)}
                            >
                                Submit
                            </IonButton>
                        </React.Fragment>
                    }
                </MeContext.Consumer>
            </MeContextProvider>
            <IonToast
                message={errorMessage}
                onDidDismiss={() => setErrorMessage("")}
                isOpen={errorMessage.length > 0}
                duration={2000}
            />
            <IonAlert
                isOpen={successModalShowing}
                onDidDismiss={finish}
                message={'Your request has been submitted, and you can expect to hear back from our support team within 48 hours.'}
                buttons={['OK!']}
            />
        </OrganizationCreationResultsPage>
    )
}

type  BusinessCreatedResultRouteParams = {
    result: 'beta' | 'pending',
}

interface BusinessCreatedResultProps {
}

const BusinessCreatedResult: React.FC<BusinessCreatedResultProps> = () => {

    const {result} = useParams<BusinessCreatedResultRouteParams>();

    const location = useLocation();
    const noun = location.pathname == 'business-created' ? 'Business' : 'Organization';

    return ( result == 'beta' ?
        <BetaWelcomeContent noun={noun}/> :
        <WaitListContent noun={noun}/>
    );
}

export default BusinessCreatedResult
