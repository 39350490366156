import React, {useContext, useEffect} from 'react'
import {IonContent, IonPage, IonButton, NavContext} from '@ionic/react';
import './index.scss';
import {PostContext, PostContextConsumerState, PostContextProvider} from '../../../../contexts/PostContext';
import OrganizationHeader, {
	OrganizationHeaderContext,
	OrganizationHeaderProps
} from '../../../../components/OrganizationManagement/OrganizationHeader';
import MyBusinessContextProvider, {
	MyBusinessContext,
} from '../../../../contexts/organization/MyBusinessContext';
import {
	PublishedPostsContext,
	PublishedPostsContextProvider,
} from '../../../../contexts/PublishedPostsContext';
import PostPreviewContent from '../../../../components/PostManagement/PostPreviewContent'
import {useParams} from 'react-router';
import {PendingPostsContext, PendingPostsContextProvider} from '../../../../contexts/PendingPostsContext';


type RouteParams = {
	postId: string,
}

interface PostPreviewPageContentProps {
	setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const PostPreviewPageContent: React.FC<PostPreviewPageContentProps> = ({setHeaderProps, ...props}) => {

	const {postId} = useParams<RouteParams>()

	useEffect(() => {
		setHeaderProps({
			children: 'Preview',
		});
	}, [])

	return (
		<PostContextProvider postId={parseInt(postId!)} skipCache>
			<PostContext.Consumer>
				{postContext =>
					<MyBusinessContextProvider>
						<MyBusinessContext.Consumer>
							{myBusinessContext =>
								<PublishedPostsContextProvider publisherType={'businesses'} publisherId={myBusinessContext.business.id!}>
									<PublishedPostsContext.Consumer>
										{publishedPostsContext =>
											<PendingPostsContextProvider publisherType={'businesses'} publisherId={myBusinessContext.business.id!}>
												<PendingPostsContext.Consumer>
													{pendingPostsContext =>
														<PostPreviewContent
															publishedUrl={'/organization/post-creator/published'}
															publishedPostsContext={publishedPostsContext}
															pendingPostsContext={pendingPostsContext}
															postId={postId!}
															publisherType={'businesses'}
															post={postContext.post}
															publisher={myBusinessContext.business}
															{...props}
														/>
													}
												</PendingPostsContext.Consumer>
											</PendingPostsContextProvider>
										}
									</PublishedPostsContext.Consumer>
								</PublishedPostsContextProvider>
							}
						</MyBusinessContext.Consumer>
					</MyBusinessContextProvider>
				}
			</PostContext.Consumer>
		</PostContextProvider>
	)
}

const PostPreviewPage: React.FC = () => {

	return (
		<IonPage className={'post-preview-page'}>
			<IonContent className={'post-preview-main-content'}>
				<OrganizationHeaderContext.Consumer>
					{organizationHeaderContext =>
						<PostPreviewPageContent
							setHeaderProps={organizationHeaderContext.setSharedProps}
						/>
					}
				</OrganizationHeaderContext.Consumer>
			</IonContent>
		</IonPage>
	);
}

export default PostPreviewPage
