import React, {PropsWithChildren, useState} from 'react'
import './index.scss';
import {IonInput, IonTextarea} from '@ionic/react';
import {TextFieldTypes} from '@ionic/core';

interface Props extends PropsWithChildren<any> {
	label: string,
	charCount?: number,
	currentValueLength?: number,
	className?: string,
}

const LabelInBorder: React.FC<Props> = ({ label, charCount, currentValueLength, className, children }) => {

	return (
		<div className={'label-in-border ' + className}>
			<span className={'label'}>{label}</span>
			<div className={'container'}>
				{children}
			</div>
			{charCount &&
				<p className={'char-count ' + ((currentValueLength ?? 0) > charCount ? 'too-long' : '')}>
					{currentValueLength}/{charCount}
				</p>
			}
		</div>
	)
}

export default LabelInBorder
