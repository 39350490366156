import React, {useEffect, useState} from 'react'
import {IonContent, IonIcon, IonItem, IonList, IonPage, IonToast} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import './index.scss';
import {chevronBack, closeOutline} from "ionicons/icons";
import {UserContext, UserContextProvider} from '../../../contexts/UserContext';
import User from '../../../models/user/user';
import ProfileImage from '../../../components/ProfileImage';
import {PublishedPostsContext, PublishedPostsContextProvider} from '../../../contexts/PublishedPostsContext';
import Post from '../../../models/post/post';
import {ellipsisText} from '../../../util/strings';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import PostItem from '../../../components/MembersPages/PostItem';
import {useParams} from 'react-router';
import OrganizationHeader, {
	OrganizationHeaderContext,
	OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';


interface UserContentPostList{
	posts: Post[]
}

const UserPostList: React.FC<UserContentPostList> = ({posts}) => {

	const published_posts = posts
		.filter(i => i.published_at)
		.sort((a, b) => Date.parse(b.published_at!) - Date.parse(a.published_at!))

	return published_posts.length ? (
		<React.Fragment>
			<h4>Posts</h4>
			<IonList>
				{published_posts
					.map(post =>
						<PostItem baseUrl={'/organization'} post={post} key={post.id!}/>
					)
				}
			</IonList>
		</React.Fragment>
	) : null
}

interface UserContentProps {
	user: User,
	setUser: (user: User) => void,
	setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const UserContent: React.FC<UserContentProps> = ({user, setHeaderProps, setUser}) => {

	const [fullBioShowing, setFullBioShowing] = useState(false);

	useEffect(() => {
		setFullBioShowing(false)
	}, [user])

	const followersCount = user.followers_count ?? 0;
	const incrementFollowers = () => {
		setUser({
			...user,
			followers_count: followersCount + 1,
		})
	}
	const decrementFollowers = () => {
		setUser({
			...user,
			followers_count: followersCount > 0 ? followersCount - 1 : 0
		})
	}

	useEffect(() => {
		setHeaderProps({})
	}, [])


	return (
		<div className={'user-content'}>
			<div className={'user-info'}>
				<div className={'user-profile-image-line'}>
					<ProfileImage url={user.profile_image_url} noBorder name={user.full_name}/>
					{/*<MeContextProvider>*/}
					{/*	<MeContext.Consumer>*/}
					{/*		{meContext =>*/}
					{/*			<UserFollowsContextProvider userId={meContext.me.id!}>*/}
					{/*				<UserFollowsContext.Consumer>*/}
					{/*					{userFollowsContext =>*/}
					{/*						<FollowButton*/}
					{/*							relatedId={user.id!}*/}
					{/*							relatedType={'user'}*/}
					{/*							related={user}*/}
					{/*							onFollow={incrementFollowers}*/}
					{/*							onUnfollow={decrementFollowers}*/}
					{/*							userFollowsContext={userFollowsContext}*/}
					{/*						/>*/}
					{/*					}*/}
					{/*				</UserFollowsContext.Consumer>*/}
					{/*			</UserFollowsContextProvider>*/}
					{/*		}*/}
					{/*	</MeContext.Consumer>*/}
					{/*</MeContextProvider>*/}
				</div>
				<p><strong>{user.full_name}</strong></p>
				{(user.about_me && user.about_me.length > 0) &&
					user.about_me.length > 120 && !fullBioShowing ?
					<p>{ellipsisText(user.about_me, 120, '...')} <strong onClick={() => setFullBioShowing(true)}>More</strong></p>
					: <p>{user.about_me}</p>
				}
				<div className={'followers-statistics'}>
					<div className={'followers-statistic'}>
						<p><strong>{user.follows_count ?? 0}</strong> Following</p>
					</div>
					<div className={'followers-statistic'}>
						<p><strong>{user.followers_count ?? 0}</strong> Followers</p>
					</div>
				</div>
			</div>
			<div className={'referrals'}>
				<PublishedPostsContextProvider publisherType={'users'} publisherId={user.id!}>
					<PublishedPostsContext.Consumer>
						{publishedPostsContext =>
							<UserPostList posts={publishedPostsContext.loadedData}/>
						}
					</PublishedPostsContext.Consumer>
				</PublishedPostsContextProvider>
			</div>
		</div>
	)
}

type RouteParams = {
	userId: string,
}

const UserPage: React.FC = () => {

	const {userId} = useParams<RouteParams>();
	return (
		<IonPage className={'user-page'}>
			<IonContent>
				<UserContextProvider userId={parseInt(userId!)}>
					<UserContext.Consumer>
						{userContext =>
							<OrganizationHeaderContext.Consumer>
								{organizationHeaderContext =>
									<UserContent
										user={userContext.user}
										setUser={userContext.setUser}
										setHeaderProps={organizationHeaderContext.setSharedProps}
									/>
								}
							</OrganizationHeaderContext.Consumer>
						}
					</UserContext.Consumer>
				</UserContextProvider>
			</IonContent>
		</IonPage>
	)
}

export default UserPage
