import React from 'react';
import './index.scss';
import PaymentMethod from '../../../models/payment/payment-method';
import {IonItem, IonRadio} from '@ionic/react';


interface PaymentMethodProps {
    paymentMethod: PaymentMethod,
    defaultMethod?: boolean,
    onClick: () => void,
    onRemovePayment: () => void,
}

const PaymentMethodDetails: React.FC<PaymentMethodProps> = ({paymentMethod, defaultMethod, onRemovePayment }) => {
    const removePayment = (event: any) => {
        event.stopPropagation();
        onRemovePayment()
    }

    return (
        <div className={'payment-method-item'} key={paymentMethod.id}>
            <div className={'number'}>
                <strong>•••• •••• ••••</strong> <i>{paymentMethod.identifier}</i>
                <i className={'remove-link'} onClick={removePayment}>Remove</i>
            </div>
            <div className={'expiration-link'}>
                {paymentMethod.exp_year && paymentMethod.exp_month ?
                    `Expires ${paymentMethod.exp_month}/${paymentMethod.exp_year.substr(2)}` :
                    'Unknown Expiration Date'
                }
                {defaultMethod ? <i className={'default-method'}>default</i> : ''}
            </div>
        </div>
    )
}

const PaymentMethodItem: React.FC<PaymentMethodProps> = ({...props}) => {
    const {onClick} = props
    return (
        <IonItem lines={'none'} className={'payment-method-option'} onClick={onClick}>
            <PaymentMethodDetails {...props}/>
        </IonItem>
    )
}

export default PaymentMethodItem;
