import React, {PropsWithChildren, useEffect, useState} from 'react';
import './index.scss';
import Post, {isPostExpired, PublisherEndpointType} from '../../../models/post/post';
import {
    IonIcon,
    IonItem,
    IonList,
    IonActionSheet,
    IonToast,
    IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent
} from '@ionic/react';
import {chevronDownCircleOutline, ellipsisHorizontal} from 'ionicons/icons';
import PostManagementRequests from '../../../services/requests/PostManagementRequests';
import {
    PublishedPostsContextState
} from '../../../contexts/PublishedPostsContext';
import {PostResponseType} from "../../../models/post/post-response";
import PostComments from '../../Posts/PostComments';
import CanParticipateInForum from '../../../models/can-participate-in-forum';
import Thread from '../../../models/user/thread';
import Message from '../../../models/user/message';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router';
import LoadingScreen from '../../LoadingScreen';
import moment from 'moment';
import DeletePostConfirmationAlert from '../../DeletePostConfirmationAlert';

interface PostStatisticData {
    title: PostResponseType,
    amount: number,
}

interface PostStatisticProps extends PostStatisticData {
    postInteractionsPageBaseUrl: string,
    postId?: number,
}

const PostStatistic: React.FC<PostStatisticProps> = ({postId, postInteractionsPageBaseUrl, title, amount}) => (
    <Link to={`${postInteractionsPageBaseUrl}${postId}/${title.toLowerCase()}`} className={'post-statistic'}>
        <h4>{title}</h4>
        <h3>{amount}</h3>
    </Link>
)

interface PostItemProps {
    post: Post,
    setPost: (post: Post) => void,
    onDeletePost: () => void,
    postBaseUrl: string,
    postInteractionsPageBaseUrl: string,
    postStatisticsSettings: PostStatisticData[],
    editUrl: string,
    commentingEntity: CanParticipateInForum,
    createPostThread: (post: Post, topic: string) => Promise<Thread>,
    createMessage: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
}

const PostItem: React.FC<PostItemProps> = ({post, postStatisticsSettings, postBaseUrl, editUrl, postInteractionsPageBaseUrl, setPost, onDeletePost, commentingEntity, createPostThread, createMessage}) => {

    const [optionsShowing, setOptionsShowing] = useState(false);
    const [copiedToastShowing, setCopiedToastShowing] = useState(false);
    const [confirmDeletionShowing, setConfirmDeletionShowing] = useState(false);
    const [viewingComments, setViewingComments] = useState(false);

    const postUrl = "https://app.gedditlocal.com/post/" + post.id;
    const editLink = editUrl + post.id!;
    const postLink = `${postBaseUrl}${post.id}`!;

    const history = useHistory();

    const displayDate = (date: string) => {
        const momentDate = moment(date);
        const isToday = momentDate.isSame(moment(), 'day');

        if (isToday) {
            return momentDate.toDate().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit',  });
        } else {
            return momentDate.toDate().toLocaleDateString();
        }
    }

    const determinePostStatus = (): string => {

        if (!post.published_at || !post.expires_at) {
            return 'Not Scheduled';
        }

        const publishedAt = Date.parse(post.published_at);
        const now = Date.now();

        if (publishedAt > now) {
            return 'Scheduled for ' + displayDate(post.published_at);
        }

        const expiresAt = Date.parse(post.expires_at);

        if (expiresAt > now) {
            return 'Expires ' + displayDate(post.expires_at);
        }

        return 'Expired';
    }

    const isPublished = post.published_at && Date.parse(post.published_at) < Date.now();

    return (
        <IonItem className={'post-item'}>
            <Link to={isPublished ? postLink : editLink} >
                <div className={'post-preview-thumbnail'}  style={{backgroundImage: `url(${post.main_image_url})`}}>
                    {/*<div className={'comments-button'}>*/}
                    {/*    <p>*/}
                    {/*        <strong>*/}
                    {/*            {post.comment_count ?? 0}*/}
                    {/*        </strong>*/}
                    {/*    </p>*/}
                    {/*    <p>Comments</p>*/}
                    {/*</div>*/}
                </div>
            </Link>
            <div className={'post-preview-content'}>
                <div className={'post-status-line'}>
                    <p>
                        <strong>{determinePostStatus()}</strong>
                        {post.from_instagram ? <img className={'instagram-logo'} src={'/assets/instagram-logo.svg'}/> : ''}
                    </p>
                    <IonIcon onClick={() => setOptionsShowing(true)} icon={ellipsisHorizontal}/>
                </div>
                <div className={'post-statistics'}>
                    {postStatisticsSettings.map(postStatistic =>
                        <PostStatistic
                            key={postStatistic.title}
                            postInteractionsPageBaseUrl={postInteractionsPageBaseUrl}
                            postId={post.id}
                            {...postStatistic}
                        />
                    )}
                </div>
            </div>
            <PostComments
                post={post}
                isOpen={viewingComments}
                onDismissed={() => setViewingComments(false)}
                interactions={{
                    onCommentCountChanged: (commentCount) => {
                        setPost({
                            ...post,
                            comment_count: commentCount,
                        })
                    },
                    participant: commentingEntity,
                    createPostThread: createPostThread,
                    createMessage: createMessage,
                }}
            />
            <IonActionSheet
                cssClass={'post-list-action-sheet-options'}
                onDidDismiss={() => setOptionsShowing(false)}
                buttons={[{
                    text: 'Delete',
                    role: 'delete',
                    handler: () => setConfirmDeletionShowing(true),
                }, {
                    text: 'Open',
                    handler: () => history.push(postLink),
                }, {
                    text: isPostExpired(post) ? 'Resend' : 'Edit',
                    handler: () => history.push(editLink),
                // }, {
                //     text: 'Copy Link',
                //     handler: () => {
                //         Clipboard.write({
                //             string: postUrl,
                //         }).catch(console.error);
                //         setCopiedToastShowing(true);
                //     }
                // },{
                //     text: 'Share to…',
                //     handler: () => {
                //         Share.share({
                //             title: post.title,
                //             text: post.article_content,
                //             url: postUrl,
                //             dialogTitle: 'Share post'
                //         });
                //     }
                },{
                    text: 'Cancel',
                    role: 'cancel',
                }]}
                isOpen={optionsShowing}
            />
            <IonToast
                isOpen={copiedToastShowing}
                onDidDismiss={() => setCopiedToastShowing(false)}
                duration={2000}
                position={"top"}
                message={"Link copied to clipboard"}
            />
            <DeletePostConfirmationAlert
                onDelete={() => onDeletePost()}
                isOpen={confirmDeletionShowing}
                onDidDismiss={() => setConfirmDeletionShowing(false)}
            />
        </IonItem>
    )
}


interface PostListRendererProps {
    postsContext: PublishedPostsContextState
    publisherType: PublisherEndpointType,
    publisherId: number,
    postBaseUrl: string,
    onBuildPostStatistics: (post: Post) => PostStatisticData[],
    editUrl: string,
    postInteractionsPageBaseUrl: string,
    commentingEntity: CanParticipateInForum,
    createPostThread: (post: Post, topic: string) => Promise<Thread>,
    createMessage: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
}

const PostListRenderer: React.FC<PostListRendererProps> = ({postsContext, editUrl, publisherId, publisherType, onBuildPostStatistics, ...rest}) => {
    const [isDeleteErrorShowing, setIsDeleteErrorShowing] = useState(false);
    const deletePost = (removePost: (model: Post) => void, post: Post) => {
        PostManagementRequests.deletePost(publisherType, publisherId, post).catch(error => {
            setIsDeleteErrorShowing(true)
        });
        removePost(post);
    }

    const posts = postsContext.loadedData
        .filter((post, index, self) => self.findIndex(i => i.id == post.id) === index)
        .sort((a: Post, b: Post) => Date.parse(b.created_at!)- Date.parse(a.created_at!))

    return (
        <React.Fragment>
            <IonList className={'post-list'}>
                {posts.map(post =>
                    <PostItem
                        key={post.id}
                        post={post}
                        editUrl={editUrl}
                        postStatisticsSettings={onBuildPostStatistics(post)}
                        onDeletePost={() => deletePost(postsContext.removeModel, post)}
                        setPost={postsContext.addModel}
                        {...rest}
                    />
                )}
            </IonList>
            <IonInfiniteScroll
                onIonInfinite={(event: any) => {
                    postsContext.loadNext().then(() => {
                        event.target.complete();
                    })
                }}
                disabled={!postsContext.hasAnotherPage}
            >
                <IonInfiniteScrollContent title={'Loading more'}/>
            </IonInfiniteScroll>
            <IonToast
                isOpen={isDeleteErrorShowing}
                onDidDismiss={() => setIsDeleteErrorShowing(false)}
                duration={2000}
                message={'Error deleting post'}
            />
        </React.Fragment>
    )
}

interface PostListProps extends PostListRendererProps {
    refreshing: boolean,
    onRefresh: () => void,
}

const PostsList: React.FC<PropsWithChildren<PostListProps>> = ({postsContext, refreshing,  onRefresh, publisherType, publisherId, children, ...rest}) => {

    const [eventTarget, setEventTarget] = useState<any>(undefined);

    const doRefresh = (event: any) => {
        onRefresh();
        setEventTarget(event.detail);
    }

    useEffect(() => {
        if (!refreshing && eventTarget) {
            eventTarget.complete();
            setEventTarget(undefined);
        }
    }, [refreshing])

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Pull to refresh"
                    refreshingSpinner="circles"
                    refreshingText="Refreshing..."
                />
            </IonRefresher>
            {postsContext.initialLoadComplete ?
                (postsContext.noResults ? (
                        <>{children}</>
                    ) : (
                        <PostListRenderer
                            postsContext={postsContext}
                            publisherType={publisherType}
                            publisherId={publisherId}
                            {...rest}
                        />
                    )
                ) : <LoadingScreen/>
            }
        </>
    )
}

export default PostsList
