import React, {PropsWithChildren, useEffect, useState} from 'react';
import {IonInput, IonItem, IonList} from '@ionic/react';
import './index.scss';
import Category, {findCategory, removeCategoryFromList} from '../../models/category';
import CategoryRequests from '../../services/requests/CategoryRequests';


interface CurrentCategoryResults {
    matchingCategory?: Category,
    currentResults: Category[],
}

interface Props extends PropsWithChildren<any> {
    category?: Category,
    setCategory: (category: Category) => void,
    placeholder?: string,
}

const CategorySearchBar: React.FC<Props> = ({ category,setCategory, placeholder}) => {

    const [inputValue, setInputValue] = useState(category ? category.name : "");
    const [isFocused, setIsFocused] = useState(false);
    const [shouldClose, setShouldClose] = useState(false);
    const [currentCategoryResults, setCurrentCategoryResults] = useState({
        matchingCategory: undefined,
        currentResults: [],
    } as CurrentCategoryResults);

    useEffect( () => {
        if(!isFocused && shouldClose){
            CategoryRequests.searchCategories(inputValue).then(categories => {
                const match = findCategory(categories, inputValue);
                if (match) {
                    setCategory(match)
                } else {
                    createCategory();
                }
            });
        }
    }, [isFocused, shouldClose])


    function setSearchName(name: string) {
        setInputValue(name);
        CategoryRequests.searchCategories(name).then(categories => {
            setCurrentCategoryResults({
                matchingCategory: findCategory(categories, name),
                currentResults: removeCategoryFromList(categories, name),
            })
        });
    }

    function selectCategory(category: Category) {
        setCategory(category)
        setInputValue(category.name)
        setIsFocused(false)
        setShouldClose(false)
    }

    function createCategory() {
        setShouldClose(false)
        CategoryRequests.createCategory(inputValue).then(selectCategory);
    }

    /**
     * This is used in order to prevent the category list from closing before the click is registered with an item
     */
    function scheduleClose() {
        setIsFocused(false)
        setShouldClose(true)
    }

    return (
        <div className={'category-search-bar'}>
            <IonInput
                placeholder={placeholder}
                value={inputValue}
                onIonChange={e => setSearchName(e.detail.value!)}
                onIonBlur={() => scheduleClose()}
                onIonFocus={() => setIsFocused(true)}/>
            <IonList className={isFocused ? 'visible' : 'hidden'}>
                {currentCategoryResults.currentResults.map(category =>
                    <IonItem
                        key={category.id}
                        onMouseDown={() => selectCategory(category)}>
                        {category.name}
                    </IonItem>
                )}
            </IonList>
        </div>
    )
}

export default CategorySearchBar
