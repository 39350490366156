import React, {useEffect, useState} from 'react'
import {IonContent, IonHeader, IonPage, IonToast, IonToolbar} from '@ionic/react';
import './index.scss';
import {PostContext, PostContextProvider} from '../../../contexts/PostContext';
import PostDetails from '../../../components/Posts/PostDetails';
import Post from '../../../models/post/post';
import Location from '../../../models/location/location';
import {useParams} from 'react-router';
import NotLoggedInModal from '../../../components/WelcomeSection/NotLoggedInModal';
import DummyTabsContent from '../../../components/WelcomeSection/DummyTabsContent';
import {connect} from '../../../data/connect';
import {logOut} from '../../../data/persistent/persistent.actions';
import {clearLoadingCount} from '../../../data/session/session.actions';
import {TokenState} from '../../../data/persistent/persistent.state';

type RouteParams = {
	postId: string,
}

interface StateProps {
	tokenData?: TokenState;
}

interface PostPageProps extends StateProps {
}

const PostPage: React.FC<PostPageProps> = ({tokenData}) => {
	const {postId} = useParams<RouteParams>()
	const [notLoggedInModalShowing, setNotLoggedInModalShowing] = useState(false);

	const getLocation = (post: Post): Location|undefined => {
		return post.locations && post.locations.length > 0 ? post.locations[0] : undefined;
	}

	const handleAction = () => {
		setNotLoggedInModalShowing(true)
	}

	useEffect(() => {
		if (tokenData) {
			window.location.pathname = window.location.pathname.replace('/welcome', '/home/dashboard/account')
		}
	}, [])

	return (
		<IonPage className={'public-post-page'}>
			<IonHeader className="logged-in-header ion-no-border">
				<IonToolbar>
				</IonToolbar>
			</IonHeader>
			<DummyTabsContent className={'main-content'} onLinkClicked={handleAction}>
				<PostContextProvider postId={parseInt(postId!)}>
					<PostContext.Consumer>
						{postContext =>
							<IonContent className={'post-details-content-wrapper ion-no-padding'}>
								<PostDetails
									post={postContext.post}
									location={getLocation(postContext.post)}
									locationPageUrlRoot={'/welcome'}
									interactions={{
										onFollow: (id) => handleAction(),
										onLikeToggled: handleAction,
										onButtonClicked: () => {},
										onShare: () => {},
										onSaved: () => {},
										onRemove: () => {},
										onReport: handleAction,
									}}
									onOptionsClickedOverride={handleAction}
								/>
							</IonContent>
						}
					</PostContext.Consumer>
				</PostContextProvider>
				<NotLoggedInModal
					abilityMessage={'follow locations, or like and save posts'}
					isOpen={notLoggedInModalShowing}
					onDidDismiss={() => setNotLoggedInModalShowing(false)}
				/>
			</DummyTabsContent>
		</IonPage>
	)
}


const ConnectedPostPage = connect<{}, StateProps, {}>({
	mapStateToProps: (state) => ({
		tokenData: state.persistent.tokenData
	}),
	component: PostPage,
});

export default ConnectedPostPage;
