import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Category from '../models/category';

/**
 * The state interface for our state
 */
export interface IndustriesContextState extends BasePaginatedContextState<Category> {}

const defaultContext = {
    ...defaultBaseContext(),
    filter: {
        'can_be_primary': 1
    },
    limit: 100
} as IndustriesContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const IndustriesContext = React.createContext<IndustriesContextState>(defaultContext);

export interface IndustriesContextProviderProps extends BasePaginatedContextProviderProps{
}

export const IndustriesContextProvider: React.FC<IndustriesContextProviderProps> = (props => {
    const [industryState, setIndustriesState] = useState(persistentContext);

    useEffect(() => {
        prepareContextState(setIndustriesState, industryState, '/categories')
    }, []);

    return (
        <IndustriesContext.Provider value={industryState}>
            <IndustriesContext.Consumer>
                {context => {
                    /**
                     * set our persistent context whenever it is updated here
                     */
                    persistentContext = context;
                    return context.initialLoadComplete ? props.children : '';
                }}
            </IndustriesContext.Consumer>
        </IndustriesContext.Provider>
    )
});
