import React, {PropsWithChildren, useContext} from 'react';
import './index.scss';
import {IonPage, IonHeader, IonContent, IonIcon, IonButton, IonButtons, NavContext, IonToolbar} from '@ionic/react';

interface SubscriptionUpgradedProps extends PropsWithChildren<any>{
    className?: string,
}

const SubscriptionUpgraded: React.FC<SubscriptionUpgradedProps> = ({className, children}) => {

    const {navigate} = useContext(NavContext);

    const exit = () => {
        navigate('/organization/dashboard/profile', 'back');
    }

    return (
        <IonPage className={className ? className : 'subscription-upgraded-page'}>
            <IonContent>
                <h3>Success!</h3>
                <p>Your subscription has been updated</p>
                <IonIcon color={'primary'} src={'/assets/app-icons/handshake.svg'}/>
                {children}
                <IonButton onClick={exit}>Done</IonButton>
            </IonContent>
        </IonPage>
    );
}

export default SubscriptionUpgraded;
