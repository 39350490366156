import './index.scss';
import React, {
	createContext,
	Dispatch,
	ReactChildren,
	ReactElement,
	ReactNode,
	SetStateAction,
	useEffect,
	useState
} from 'react';
import {
	IonButton,
	IonButtons,
	IonHeader,
	IonIcon,
	IonMenuButton, IonPage,
	IonTitle,
	IonToolbar
} from '@ionic/react';
import LoadingIndicatorComponent from '../../LoadingIndicator';
import {addSharp, chevronBackOutline, closeOutline} from 'ionicons/icons';
import {useHistory, useLocation} from 'react-router';
import TrialInformationBanner from '../TrialInformationBanner';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import AccountSwitcher from '../../AccountSwitcher';

interface SaveButtonProps {
	action: () => void,
	active: boolean
}

export interface OrganizationHeaderProps {
	urlOverride?: string,
	isHome?: boolean,
	noBack?: boolean,
	closeButtonText?: string,
	closeButtonIcon?: string,
	createLink?: string,
	createButtonClicked?: () => void,
	saveButton?: SaveButtonProps,
	hideTrialInformation?: boolean,
	backClicked?: () => void,
	children?: ReactElement | any,
}

interface OrganizationHeaderContextProps {
	props: OrganizationHeaderProps,
	setSharedProps: (props: OrganizationHeaderProps) => void,
}

export const OrganizationHeaderContext = createContext<OrganizationHeaderContextProps>({
	props: {},
	setSharedProps: () => {}
})

let cachedProps: {[key: string]: OrganizationHeaderProps} = {};

export const OrganizationHeaderContextProvider: React.FC = ({children}) => {

	const history = useHistory()

	const [props, setProps] = useState({});

	useEffect(() => {

		return history.listen(location => {
			if (cachedProps[location.pathname] != undefined) {
				setProps({...cachedProps[location.pathname]})
			}
		})
	}, [cachedProps, history])

	const setSharedProps = (newProps: OrganizationHeaderProps) => {
		cachedProps[newProps.urlOverride ?? history.location.pathname] = newProps;
		setProps({...newProps});
	}

	return (
		<OrganizationHeaderContext.Provider value={{props, setSharedProps}}>
			{children}
		</OrganizationHeaderContext.Provider>
	)
}

const OrganizationHeaderContent: React.FC<OrganizationHeaderProps> = ({isHome, noBack, closeButtonText, closeButtonIcon, saveButton, createLink, createButtonClicked, backClicked, hideTrialInformation, children }) => {

	const history = useHistory();
	const back = backClicked ?? (() => history.goBack())
	return (
		<React.Fragment>
			<IonHeader mode={'ios'} className={'organization-header'}>
				<IonToolbar>
					{!noBack ? (
						<IonButtons slot="start">
							{isHome ? <IonMenuButton menu={'organization'}/> :
								<IonButton color={'medium'} onClick={back}>
									<IonIcon icon={closeButtonIcon ? closeButtonIcon : chevronBackOutline}/>
									{closeButtonText ? closeButtonText : ''}
								</IonButton>
							}
						</IonButtons>
						) : null }
					<IonTitle>
						{isHome ?
							<React.Fragment>
								<div className={'mobile-only'}>
									<MyBusinessContextProvider hideLoadingSpace={true}>
										<MyBusinessContext.Consumer>
											{myBusinessContext =>
												<AccountSwitcher selectedText={myBusinessContext.business.name}/>
											}
										</MyBusinessContext.Consumer>
									</MyBusinessContextProvider>
								</div>
								<h3 className={'desktop-only gagalin'}>Geddit Local</h3>
							</React.Fragment>:
							children
						}
					</IonTitle>
					<LoadingIndicatorComponent/>
					{saveButton ?
						<IonButtons slot="end">
							<IonButton color={'medium'} onClick={saveButton.action} disabled={!saveButton.active}>Save</IonButton>
						</IonButtons> :
						( createLink || createButtonClicked ?
							<IonButtons slot="end">
								<IonButton className={'create-button'} color={'transparent'} routerLink={createLink} onClick={createButtonClicked} >
									<IonIcon icon={addSharp}/>
								</IonButton>
							</IonButtons> : ''
						)
					}
				</IonToolbar>
			</IonHeader>
			{!hideTrialInformation && <TrialInformationBanner/>}
		</React.Fragment>
	)
}

const OrganizationHeader: React.FC<OrganizationHeaderProps> = (props) => {

	return (
		<OrganizationHeaderContext.Consumer>
			{context =>
				<OrganizationHeaderContent {...context.props} {...props}/>
			}
		</OrganizationHeaderContext.Consumer>
	)
}

export default OrganizationHeader
