import React, {PropsWithChildren} from 'react'
import './index.scss';
import {IonLabel} from '@ionic/react';

interface Props extends PropsWithChildren<any> {
	label: string
	subtext?: string
	error?: string
}

const InputWrapper: React.FC<Props> = ({  label, error, subtext, children }) => {
	return (
		<div className={'input-wrapper'}>
			<IonLabel position={'stacked'} color={error ? 'danger' : 'dark'}>{error ? error : label}</IonLabel>
			{children}
			{subtext && <p className={'subtext'}>{subtext}</p>}
		</div>
	)
}

export default InputWrapper
