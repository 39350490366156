import './index.scss';
import React, {HTMLAttributes, useEffect, useState} from 'react';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {IonButton, IonContent, IonModal, IonPage, IonToast} from '@ionic/react';
import OrganizationHeader, {OrganizationHeaderContext, OrganizationHeaderProps} from '../OrganizationHeader';
import SubscriptionRequired from '../SubscriptionRequired';
import BottomStickySection from '../../BottomStickySection';
import Business from '../../../models/organization/business';
import {useHistory, useLocation} from 'react-router';
import {isBusinessEligibleForProPlan} from "../../../models/organization/business";
import BusinessPostEditorWrapper from '../../PostManagement/BusinessPostEditorWrapper';
import Post from '../../../models/post/post';
import PostPreviewContent from '../../PostManagement/PostPreviewContent';
import {PublishedPostsContext, PublishedPostsContextProvider} from '../../../contexts/PublishedPostsContext';
import PostPublishedContent from '../../PostManagement/PostPublishedContent';
import {setManagingBusinessId} from '../../../data/persistent/persistent.actions';
import {PendingPostsContext, PendingPostsContextProvider} from '../../../contexts/PendingPostsContext';
import User, {canUserCreateBusiness} from '../../../models/user/user';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';

interface PostCreatorModalProps {
	postId?: string
	isPostCreatorModalOpen: boolean,
	setIsPostCreatorModalOpen: (isPostCreatorModalOpen: boolean) => void,
}

const PostCreatorModal: React.FC<PostCreatorModalProps> = ({postId, isPostCreatorModalOpen, setIsPostCreatorModalOpen}) => {

	const [previewData, setPreviewData] = useState<Post|undefined>(undefined);
	const [isPublishedModalShowing, setIsPublishedModalShowing] = useState(false);
	const location = useLocation()
	const history = useHistory();

	const onPostPublished = (post: Post) => {
		setPreviewData(undefined);
		setIsPostCreatorModalOpen(false);
		setIsPublishedModalShowing(true)
		if (location.pathname != '/organization/dashboard/activity') {
			history.push('/organization/dashboard/activity')
		}
	}

	console.log('previewData', previewData);

	return (
		<React.Fragment>
			<IonModal
				isOpen={isPostCreatorModalOpen}
				onDidDismiss={() => setIsPostCreatorModalOpen(false)}
				className={'post-creator-modal ' + (previewData ? 'preview' : '')}
			>
				<IonContent className={'business-form-content'}>
					<BusinessPostEditorWrapper
						onPostPreview={setPreviewData}
						postId={postId}
						skipPreviewSave={true}
						onPostPublished={onPostPublished}
					/>
				</IonContent>
				<IonContent className={'business-preview-content'}>
					<MyBusinessContext.Consumer>
						{myBusinessContext =>
							<PublishedPostsContextProvider
								publisherType={'businesses'}
								publisherId={myBusinessContext.business.id!}
							>
								<PublishedPostsContext.Consumer>
									{publishedPostsContext =>
										<PendingPostsContextProvider
											publisherType={'businesses'}
											publisherId={myBusinessContext.business.id!}
										>
											<PendingPostsContext.Consumer>
												{pendingPostsContext => previewData &&
													<PostPreviewContent
														post={previewData}
														publishedPostsContext={publishedPostsContext}
														pendingPostsContext={pendingPostsContext}
														publisher={myBusinessContext.business} publisherType={'businesses'}
														onPostPublished={onPostPublished}
														onBack={() => setPreviewData(undefined)}
													/>
												}
											</PendingPostsContext.Consumer>
										</PendingPostsContextProvider>
									}
								</PublishedPostsContext.Consumer>
							</PublishedPostsContextProvider>
						}
					</MyBusinessContext.Consumer>
				</IonContent>
			</IonModal>
			<IonModal
				isOpen={isPublishedModalShowing}
				onDidDismiss={() => setIsPublishedModalShowing(false)}
				className={'post-published-modal'}
			>
				<PostPublishedContent />
			</IonModal>
		</React.Fragment>
	)
}

interface Props extends HTMLAttributes<HTMLIonContentElement> {
	subscriptionNotRequired?: boolean
}

interface OrganizationDashboardPageContentProps extends Props {
	business: Business,
	me: User,
}

const OrganizationDashboardPageContent: React.FC<OrganizationDashboardPageContentProps> = ({me, business, subscriptionNotRequired, children}) => {

	const [isErrorToastShowing, setIsErrorToastShowing] = useState(false);

	const history = useHistory();

	const hasMainCategory = business.main_category_id !== undefined;
	const hasLocation = business.locations !== undefined && business.locations.length > 0;
	const hastLocationLogo = business.locations !== undefined && business.locations?.find(i => i.logo);

	useEffect(() => {
		if (!business.approved_at && !canUserCreateBusiness(me)) {
			setManagingBusinessId(undefined);
			window.location.replace('/organization-creator/business-created/pending');
		} else if (!hasMainCategory) {
			window.location.replace('/organization-creator/main-category-selector');
		} else if(!hasLocation) {
			history.push('/organization/location-creator');
		} else if (!hastLocationLogo) {
			const location = business.locations![0];
			history.push(`/organization/location-editor/${location.id}`);
		}

	}, [hasMainCategory, hasLocation, hastLocationLogo])

	return (
		<React.Fragment>
			{subscriptionNotRequired ?
				<React.Fragment>
					{children}
				</React.Fragment> :
				<SubscriptionRequired>
					{children}
				</SubscriptionRequired>
			}
			<IonToast
				onDidDismiss={() => setIsErrorToastShowing(false)}
				isOpen={isErrorToastShowing}
				message={"Please finish filling in your business details before you manage your business"}
				duration={2000}
			/>
		</React.Fragment>
	)
}

interface OrganizationDashboardTabbedPageContentProps extends OrganizationDashboardTabbedPageProps {
	setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const OrganizationDashboardTabbedPageContent: React.FC<OrganizationDashboardTabbedPageContentProps> = ({subscriptionNotRequired, setHeaderProps, locationId, children, ...rest}) => {


	useEffect(() => {
		setHeaderProps({
			isHome: true,
		})
	}, [])

	const [isPostCreatorModalOpen, setIsPostCreatorModalOpen] = useState(false)
	const navigate = useHistory();

	const openPostCreator = () => {

		if (window.outerWidth < 800) {
			navigate.push('/organization/post-creator')
		} else {
			setIsPostCreatorModalOpen(true);
		}
	}

	return (
		<IonPage className={'organization-dashboard-tabbed-page'}>
			<IonContent {...rest}>
				<MyBusinessContextProvider hideLoadingSpace={true}>
					<MyBusinessContext.Consumer>
						{myBusinessContext => (
							<MeContextProvider>
								<MeContext.Consumer>
									{meContext =>
										<OrganizationDashboardPageContent
											me={meContext.me}
											subscriptionNotRequired={subscriptionNotRequired}
											business={myBusinessContext.business}
										>
											{children}
										</OrganizationDashboardPageContent>
									}
								</MeContext.Consumer>
							</MeContextProvider>
						)}
					</MyBusinessContext.Consumer>
				</MyBusinessContextProvider>
			</IonContent>
			<SubscriptionRequired hideLoadingSpace={true} hideError={true}>
				<MyBusinessContextProvider hideLoadingSpace={true}>
					<MyBusinessContext.Consumer>
						{myBusinessContext => (
							<BottomStickySection>
								<IonButton
									onClick={openPostCreator}
									hidden={!isBusinessEligibleForProPlan(myBusinessContext.business)}
								>
									+ Post an Ad
								</IonButton>
							</BottomStickySection>
						)}
					</MyBusinessContext.Consumer>
					<PostCreatorModal
						isPostCreatorModalOpen={isPostCreatorModalOpen}
						setIsPostCreatorModalOpen={setIsPostCreatorModalOpen}
					/>
				</MyBusinessContextProvider>
			</SubscriptionRequired>
		</IonPage>
	);
}

interface OrganizationDashboardTabbedPageProps extends Props {
	locationId?: number
}

const OrganizationDashboardTabbedPage: React.FC<OrganizationDashboardTabbedPageProps> = (props) => {

	return (
		<OrganizationHeaderContext.Consumer>
			{organizationHeaderContext =>
				<OrganizationDashboardTabbedPageContent
					setHeaderProps={organizationHeaderContext.setSharedProps}
					{...props}
				/>
			}
		</OrganizationHeaderContext.Consumer>
	)
}

export default OrganizationDashboardTabbedPage
