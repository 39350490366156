import React, {useState, useEffect} from 'react';
import './index.scss';
import {IonButton} from '@ionic/react';
import SideBySideComponent from '../../SideBySide';
import PaymentMethod from '../../../models/payment/payment-method';
import StripeContextProvider, {StripeContext} from '../../StripeContext';
import PaymentMethodRequests from '../../../services/requests/PaymentMethodRequests';
import {AvailableEntityTypes} from '../../../models/entities';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';


const PaymentMethodFormDisplay: React.FC<PaymentMethodFormProps> = (props) => {
    const {entityId, entityType, onCancel, onPaymentMethodCreated, submitNewMethod, hideCancel, hideSubmit, submitText} = props

    const elements = useElements();
    const stripe = useStripe();

    const [error, setError] = useState('')

    const submit = async () => {


        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs

        const {token, error} = await stripe.createToken(cardElement as any);

        if (error) {
            console.log('[error]', error);
            setError(error.message!)
        } else {

            const paymentMethod = await PaymentMethodRequests.createPaymentMethod(entityType, entityId, {
                token: token?.id,
                default: true,
            })
            onPaymentMethodCreated(paymentMethod)
        }
    }

    useEffect(() => {
        if( submitNewMethod ) {
            submit()
        }
    }, [submitNewMethod] )

    return (

        <div className={'payment-method-form'}>
            <div className={'card-wrapper'}>
                <CardElement/>
            </div>
            {error ? <p className={'error'}>{error}</p> : null}
            <SideBySideComponent>
                {!hideCancel && <IonButton onClick={onCancel} color={'medium'}>Cancel</IonButton>}
                {!hideSubmit && <IonButton onClick={submit}>{submitText ? submitText : 'Submit'}</IonButton>}
            </SideBySideComponent>
        </div>
    )
}

interface PaymentMethodFormProps {
    entityId: number,
    entityType: AvailableEntityTypes,
    onCancel: () => void,
    onPaymentMethodCreated: (paymentMethod: PaymentMethod) => void,
    submitNewMethod?: boolean,
    hideCancel?: boolean,
    hideSubmit?: boolean,
    submitText?: string,
}

const PaymentMethodForm: React.FC<PaymentMethodFormProps> = (props) => {

    return (
        <StripeContextProvider>
            <StripeContext.Consumer>
                {() =>
                    <PaymentMethodFormDisplay {...props}/>
                }
            </StripeContext.Consumer>
        </StripeContextProvider>
    );
}

export default PaymentMethodForm
