import React, {useState} from 'react';
import {IonAlert, IonButton, IonIcon, IonToggle} from '@ionic/react';
import Subscription, {
    didPayForSubscription,
    determineDefaultDuration,
    calculateTotalToday,
    changeSelectedDuration,
    calculateSubscriptionTotal,
    isSubscriptionInTrialWithoutRenewal,
} from '../../../models/subscription/subscription';
import MembershipPlan, {
    MembershipPlanDuration
} from '../../../models/subscription/membership-plan';
import './index.scss';
import Moment from "react-moment";
import Business from '../../../models/organization/business';

interface ProPlanChangeMessageProps {
    subscriptionAspect: string,
    expiration: string,
}

const ProPlanChangeMessage: React.FC<ProPlanChangeMessageProps> = ({subscriptionAspect, expiration}) => (
    <>Your new {subscriptionAspect} will begin on <strong><Moment format={'MMMM\u00A0Do,\u00A0YYYY'}>{expiration}</Moment></strong>.</>
)

interface ProPlanMessagesProps {
    changingPlan: boolean,
    changingBilling: boolean,
    proratedAmount: number,
    expiration: string,
}

const ProPlanMessages: React.FC<ProPlanMessagesProps> = ({changingPlan, changingBilling, expiration, proratedAmount}) => (
    <>
        {proratedAmount > 0 && <>Your prorated charge today will be ${proratedAmount.toFixed(2)}. </>}

        {changingPlan ? (
            <ProPlanChangeMessage subscriptionAspect={'plan'} expiration={expiration}/>
        ) : (
            changingBilling && <ProPlanChangeMessage subscriptionAspect={'billing cycle'} expiration={expiration}/>
        )}
    </>
)

interface ProMembershipDetailsProps {
    selectedLocationAmount: number,
    onSubscribe: (membershipPlan: MembershipPlan, totalToday: number) => void,
    monthlyMembershipPlan?: MembershipPlan,
    yearlyMembershipPlan?: MembershipPlan,
    maybeSubscription?: Subscription,
    previewMode?: boolean,
    hasLogo: boolean,
    tierName: string,
    tierDescription: string,
    business: Business,
}

const PaidMembershipDetails: React.FC<ProMembershipDetailsProps> = ({hasLogo, tierName, tierDescription, previewMode, children, onSubscribe, business, maybeSubscription, monthlyMembershipPlan, yearlyMembershipPlan, selectedLocationAmount }) => {

    const hasMonthlyRate = monthlyMembershipPlan != undefined;
    const hasYearlyRate = yearlyMembershipPlan != undefined;
    const hasBothRates = hasMonthlyRate && hasYearlyRate
    const defaultDuration = determineDefaultDuration(
        maybeSubscription,
        hasMonthlyRate ? MembershipPlanDuration.Month : MembershipPlanDuration.Year,
    );
    const [selectedDuration, setSelectedDuration] = useState(defaultDuration);
    const [isNotAllowedAlertShowing, setIsNotAllowedAlertShowing] = useState(false);
    const selectedMembershipPlan = (selectedDuration == 'month' ? monthlyMembershipPlan : yearlyMembershipPlan) as MembershipPlan;
    const isTrial = maybeSubscription?.is_trial == 1;
    const currentCost = calculateSubscriptionTotal(selectedLocationAmount, selectedMembershipPlan.current_cost);
    const totalToday = calculateTotalToday(maybeSubscription, selectedDuration, selectedLocationAmount, selectedMembershipPlan);

    const isUpgrade = (maybeSubscription: Subscription): boolean => {
        const isChangingDuration = maybeSubscription.membership_plan_rate.membership_plan?.duration != selectedDuration;
        const isChangingLocationCount = maybeSubscription.location_count != selectedLocationAmount;
        return isSubscriptionInTrialWithoutRenewal(maybeSubscription) || isChangingDuration || isChangingLocationCount
    }

    let isUpgrading = maybeSubscription ? isUpgrade(maybeSubscription) : true;

    const changeDuration = () => {
        setSelectedDuration(changeSelectedDuration(selectedDuration));
    }
    const upgradePlan = () => {
        if (!business.approved_at) {
            setIsNotAllowedAlertShowing(true);
        } else {
            onSubscribe(selectedMembershipPlan, totalToday);
        }
    }

    return (
        <div className={'tab'}>
            <h4>
                {hasLogo && <IonIcon src={'/assets/main-logo.svg'} color={'primary'}/>}
                {tierName}
            </h4>
            <p>{tierDescription}</p>
            <h4 className={'price'}>${currentCost.toFixed(2)}</h4>
            <p>/{selectedDuration} for up to <strong>{selectedLocationAmount} business location{selectedLocationAmount > 1 ? 's' : ''}</strong></p>
            <p className={'duration-line'}>
                {hasMonthlyRate ? 'Billed monthly' : ''}
                {hasBothRates ? <IonToggle checked={selectedDuration == 'year'} onClick={changeDuration}/>: ''}
                {hasYearlyRate ? 'Billed annually' : ''}
            </p>
            {!previewMode && (
                <React.Fragment>
                    <p>
                        {isUpgrading && maybeSubscription && maybeSubscription.expires_at &&
                            <ProPlanMessages
                                changingPlan={isTrial || selectedLocationAmount < maybeSubscription.location_count}
                                changingBilling={maybeSubscription.membership_plan_rate.membership_plan.duration !== selectedDuration}
                                proratedAmount={totalToday}
                                expiration={maybeSubscription.expires_at}
                            />
                        }
                    </p>
                    <IonButton disabled={!isUpgrading} onClick={() => upgradePlan()}>
                        {!isUpgrading ? 'Your Current Plan' :
                            (!maybeSubscription || (!didPayForSubscription(maybeSubscription) && !maybeSubscription?.recurring) ? 'Select Plan' : 'Upgrade plan')
                        }
                    </IonButton>
                    <IonAlert
                        isOpen={isNotAllowedAlertShowing}
                        onDidDismiss={() => setIsNotAllowedAlertShowing(false)}
                        message={`Businesses pending approval are currently not able to subscribe to our Pro plan. Please keep an eye out for new features designed to better fit your business needs as we expand the Geddit Local services.`}
                        buttons={[
                            'OK'
                        ]}
                    />
                </React.Fragment>
            )}
            <h5 className={'features'}>Features</h5>
            {children}
        </div>
    )
}

export default PaidMembershipDetails;
