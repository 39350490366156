import React from 'react';
import './index.scss';
import {
    IonIcon, IonInfiniteScroll, IonInfiniteScrollContent,
    IonItem,
    IonList,
} from '@ionic/react';
import {isResponseOfType} from "../../../models/post/post-response";
import {
    AvailableInteractions,
    PostResponsesContext,
    PostResponsesContextProvider,
    PostResponsesContextState
} from '../../../contexts/PostResponsesContext';
import EntityDetails from '../../MembersPages/EntityDetails';
import {Capacitor} from '@capacitor/core';


interface NoResponsesProps{
    interaction: string,
}

const NoResponses: React.FC<NoResponsesProps> = ({interaction}) => {


    const interactionText = () : string => {
        if (interaction.endsWith('es')) {
            return interaction.replace(new RegExp('es$'), 'ed')
        }
        else if(interaction.endsWith('s')) {
            return interaction.replace(new RegExp('s$'), 'ed')
        } else if (interaction == 'agree') {
            return interaction + 'd with';
        }

        return interaction;
    }

    return (
        <div className={'no-interaction'}>
            <IonIcon src={"/assets/app-icons/post-it.svg"}/>
            <h4>Sorry!</h4>
            <p>No one has {interactionText().toLowerCase()} this post yet</p>
        </div>
    )
}

interface PostResponseListProps {
    responseContext: PostResponsesContextState,
    interaction: string,
    hasMore: boolean,
    nextPage: () => Promise<any>,
    memberPageBaseUrl: string,
}

const PostResponseList: React.FC<PostResponseListProps> = ({responseContext, interaction, hasMore, nextPage, memberPageBaseUrl}) => {
    const responses = responseContext.loadedData.filter(response => isResponseOfType(response, interaction))

    const uniqueResponses = Array.from(new Map(responses.map(x => [x.user_id, x])).values());
    return (uniqueResponses.length ?
        <React.Fragment>
            <IonList>
                {uniqueResponses.map(response => (
                    <IonItem key={response.user_id} detail={false} lines={'none'} routerLink={memberPageBaseUrl + '/user/' + response.user_id}>
                        <EntityDetails entity={response.user!} />
                    </IonItem>
                ))}
            </IonList>
            <IonInfiniteScroll
                threshold="100px"
                disabled={!hasMore}
                onIonInfinite={(event: any) => {
                    nextPage().then(() => {
                        event.target.complete();
                    })
                }}
            >
                <IonInfiniteScrollContent
                    loadingText="Loading...">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </React.Fragment> :
        responseContext.initialLoadComplete ? <NoResponses interaction={interaction}/> : null
    );

}

interface PostResponsesProps {
    postId: number,
    interaction: string,
    memberPageBaseUrl: string,
}

const PostResponses: React.FC<PostResponsesProps> = ({ postId, interaction, memberPageBaseUrl }) => {

    const transformInteraction = (interaction: string) : undefined|AvailableInteractions => {
        switch (interaction) {
            case 'likes':
                return 'liked';
            case 'agree':
                return 'liked';
            case 'shares':
                return 'shared';
            case 'saves':
                return 'saved';
            case 'clicks':
                return 'clicked';
        }

        return undefined;
    }

    return (
        <PostResponsesContextProvider
            postId={postId}
            interaction={transformInteraction(interaction)}
        >
            <PostResponsesContext.Consumer>
                {responseContext => (
                    <PostResponseList
                        responseContext={responseContext}
                        interaction={interaction}
                        hasMore={responseContext.hasAnotherPage}
                        nextPage={responseContext.loadNext}
                        memberPageBaseUrl={memberPageBaseUrl}
                    />
                )}
            </PostResponsesContext.Consumer>
        </PostResponsesContextProvider>
    )
}

export default PostResponses
