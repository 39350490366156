import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Thread from '../../models/user/thread';

/**
 * The state interface for our state
 */
export interface PostThreadsContextState extends BasePaginatedContextState<Thread> {}

const defaultContext = {
    ...defaultBaseContext(),
    expands: [
        'createdBy',
    ],
} as PostThreadsContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
const cachedStates: { [key: string]: PostThreadsContextState; } = {};

/**
 * The actual context component
 */
export const PostThreadsContext = React.createContext<PostThreadsContextState>(defaultContext);

export interface PostThreadsContextProviderProps extends BasePaginatedContextProviderProps{
    postId: number,
}

// Shares our state setter in order to run updates across all consumers
let setters: any = {};

export const PostThreadsContextProvider: React.FC<PostThreadsContextProviderProps> = (({postId, ...props}) => {

    if (!cachedStates[postId]) {
        cachedStates[postId] = {
            ...defaultBaseContext(),
            expands: [
                'createdBy',
            ],
        };
    }

    const [postThreadsState, setPostThreadsState] = useState(cachedStates[postId]);

    useEffect(() => {
        setters[postId] = setPostThreadsState

        prepareContextState(setPostThreadsState, cachedStates[postId],'/posts/' + postId + '/threads')
    }, [postId]);

    useEffect(() => {
        cachedStates[postId] = postThreadsState;
        Object.keys(setters).forEach((key: string) => {
            if (parseInt(key) === postId) {
                setters[key](postThreadsState)
            }
        })
    }, [postThreadsState])
    return (
        <PostThreadsContext.Provider value={createCallbacks(setPostThreadsState, postThreadsState, '/posts/' + postId + '/threads')}>
            {props.children}
        </PostThreadsContext.Provider>
    )
});
