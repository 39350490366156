import React from 'react';
import Post from '../../../models/post/post';
import './index.scss';
import GoogleLocalityDisplay from '../../GoogleLocalityDisplay';
import PostAgreeLink from "../PostAgreeLink";
import { Link } from 'react-router-dom';
import PostTextContentLocationDetails from '../PostTextContentLocationDetails';
import GooglePlaceLocationDisplay from '../GooglePlaceLocationDisplay';



interface PostTextContentProps {
    post: Post,
}

const PostTextContent: React.FC<PostTextContentProps> = ({post}) => {

    const minPrice = (post.price ? post.price : '')
    const maxPrice = (post.max_price ? post.max_price : '')
    const priceParts =
        [minPrice, (minPrice && maxPrice) ? '-' : '', maxPrice]
        .filter(i => i.length)
    const postLocation =
        post.post_locations
            ?.filter(i => ['user', 'google_place', 'location'].indexOf(i.reference_type) != -1)
            .sort().find(() => true);

    const isUserPost = post.publisher_type === 'user'

    const availableLocation = post.locations && post.locations.length ? post.locations[0] : undefined;

    return (
        <div className={'post-text-content'}>
            {isUserPost && postLocation &&
                (postLocation.reference_type == 'google_place' ?
                    <GooglePlaceLocationDisplay postLocation={postLocation}/> :
                    (postLocation.reference_type == 'location' && availableLocation ? (
                        <Link to={`/home/dashboard/business/${availableLocation.business_id}/location/${availableLocation.id}`}>
                            <PostTextContentLocationDetails
                                name={availableLocation.name}
                                neighborhood={availableLocation.neighborhood}
                                city={availableLocation.city}
                                state={availableLocation.state}
                            />
                        </Link>) :
                        ((postLocation.latitude && postLocation.latitude) &&
                            <h4>
                                <GoogleLocalityDisplay
                                    latitude={postLocation.latitude!}
                                    longitude={postLocation.longitude!}
                                />
                            </h4>
                        )
                    )
                )
            }
            {post.title && <h4>{post.title}</h4>}
            {(priceParts.length && post.post_type != 'news') ?
                <h6>{priceParts.join(' ')}</h6> : undefined
            }
            <p className={isUserPost ? 'user-post-text' : ''} dangerouslySetInnerHTML={{__html: post.article_content!}}/>
            {isUserPost ? <PostAgreeLink post={post}/> : null}
        </div>
    )
}

export default PostTextContent;
