import './index.scss';
import React, {useEffect, useState} from 'react';
import {IonContent, IonPage, IonSplitPane} from '@ionic/react';
import WelcomeHeader from '../WelcomeHeader';
import WelcomeMenu from '../WelcomeMenu';


interface WelcomeTemplateProps {
	className?: string,
}

const PromoTemplate: React.FC<WelcomeTemplateProps> = ({ className, children, ...rest}) => {

	const currentlySplitPane = () => window.outerWidth > 992;
	const [isSplitPane, setIsSplitPane] = useState(currentlySplitPane());
	const resetSplitPane = () => setIsSplitPane(currentlySplitPane());

	useEffect(resetSplitPane, [window.location.href])
	window.addEventListener('resize', resetSplitPane);

	return (
		<IonPage className={'welcome-template ' + className}>
			<WelcomeHeader {...rest}/>
			<div className={'split-pane-wrapper'}>

				<IonSplitPane contentId={"welcome"} when={isSplitPane}>
					<WelcomeMenu disabled={!isSplitPane}/>
					<IonContent id={'welcome'}>{children}</IonContent>
				</IonSplitPane>
			</div>
		</IonPage>
	);
}

export default PromoTemplate
