import {PersistentActions} from './persistent.actions';
import PersistentState from './persistent.state';

export function persistentReducer(state: PersistentState, action: PersistentActions): PersistentState {
    switch (action.type) {
        case 'set-has-seen-swipe-tutorial':
            return {...state, hasSeenSwipeTutorial: action.hasSeenSwipeTutorial};
        case 'set-has-seen-saved-tutorial':
            return {...state, hasSeenSavedTutorial: action.hasSeenSavedTutorial};
        case 'set-has-seen-referrals-tutorial':
            return {...state, hasSeenReferralsTutorial: action.hasSeenReferralsTutorial};
        case 'set-has-seen-introduction-tutorial':
            return {...state, hasSeenIntroductionTutorial: action.hasSeenIntroductionTutorial};
        case 'set-has-responded-to-location-prompt':
            return {...state, hasRespondedToLocationPrompt: action.hasRespondedToLocationPrompt};
        case 'set-has-responded-to-notification-prompt':
            return {...state, hasRespondedToNotificationPrompt: action.hasRespondedToNotificationPrompt};
        case 'set-firebase-token':
            return {
                ...state,
                firebaseToken: action.firebaseToken,
                hasRespondedToNotificationPrompt: true,
            };
        case 'set-token-data':
            return {...state, tokenData: action.tokenData};
        case 'set-managing-business-id':
            return {...state, managingBusinessId: action.managingBusinessId};
        case 'set-last-known-location':
            return {...state, lastKnownLocation: action.lastKnownLocation};
        case 'set-feed-settings':
            return {...state, feedSettings: action.feedSettings};
        case 'log-out':
            return {...state, firebaseToken: undefined, managingBusinessId: undefined, tokenData: undefined};
    }
}
