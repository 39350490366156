import React from 'react'
import './index.scss';
import {IonIcon, IonItem} from '@ionic/react';
import Location from '../../../models/location/location';
import ProfileImage from '../../ProfileImage';
import {pencil} from 'ionicons/icons';

interface LocationItemProps {
	location: Location,
	businessLogoUrl?: string,
	onClick?: () => void,
}

const LocationItem: React.FC<LocationItemProps> = ({ businessLogoUrl, location, onClick}) => {
	return (
		<IonItem onClick={onClick} button={!!onClick}>
			<ProfileImage slot={'start'} url={location.logo ? location.logo : businessLogoUrl} name={location.name}/>
			<p>
				<strong>{location.name}</strong>
				{!location.hide_address &&
					<React.Fragment>
						{location.address && <br/>}
						{location.address}
					</React.Fragment>
				}
				<br/>
				{location.city}{location.city && ', '}{location.state} {location.zip}
			</p>
			<IonIcon slot={'end'} icon={pencil}/>
		</IonItem>
	)
}

export default LocationItem
