import React, {PropsWithChildren, useState} from 'react'
import './index.scss';
import {IonInput, IonTextarea} from '@ionic/react';
import {TextFieldTypes} from '@ionic/core';
import LabelInBorder from "../LabelInBorder";

interface Props extends PropsWithChildren<any> {
	placeholder?: string,
	label: string,
	charCount?: number,
	value: string|number,
	type?: TextFieldTypes,
	onInputChange: (value: string) => void,
	multiline?: boolean,
	autoCapitalize?: string,
	className?: string,
}

const TextInputWithLabelInBorder: React.FC<Props> = ({ placeholder, label, value, type, charCount, onInputChange, multiline, className, autoCapitalize }) => {

	const [currentValueLength, setCurrentValueLength] = useState(0);

	const checkCharCount = (value: string) => {
		setCurrentValueLength(value.length);
		onInputChange(value);
	}

	return (
		<LabelInBorder label={label} className={"text-input-with-label-in-border " + className} charCount={charCount} currentValueLength={currentValueLength}>
			{type ?
				<IonInput
					type={type}
					placeholder={placeholder}
					value={value}
					autocapitalize={autoCapitalize ?? 'off'}
					onInput={event => checkCharCount(event.currentTarget.value! as string)}
				/> :
				<IonTextarea
					className={multiline ? 'multiline' : ''}
					rows={multiline ? 3 : 1}
					autoGrow={multiline}
					placeholder={placeholder}
					value={value as string}
					autocapitalize={autoCapitalize ?? 'off'}
					onInput={event => checkCharCount(event.currentTarget.value! as string)}
				/>
			}
		</LabelInBorder>
	)
}

export default TextInputWithLabelInBorder
