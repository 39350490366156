import React, {PropsWithChildren, useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import User from '../../models/user/user';

let stripeReady = false;

interface StripeContextStateConsumer {
    stripeConnection: any,
}

export const StripeContext = React.createContext<StripeContextStateConsumer>({} as StripeContextStateConsumer);

let stripeConnection: any|null = null;

/**
 * Allows all of our components that need stripe to be able to be assured that stripe has been initiated
 * @param children
 * @constructor
 */
const StripeContextProvider: React.FC<PropsWithChildren<any>> = ({children}) => {
    
    const [isStripeReady, setIsStripeReady] = useState(stripeReady);

    useEffect(() => {
        if (!isStripeReady) {
            loadStripe(process.env.REACT_APP_STRIPE_KEY!).then(stripe => {
                stripeConnection = stripe;
                stripeReady = true;
                setIsStripeReady(true);
            });
        }
    }, [])
    
    return (stripeConnection &&
        <StripeContext.Provider value={{stripeConnection}}>
            <Elements stripe={Promise.resolve(stripeConnection)}>
                {children}
            </Elements>
        </StripeContext.Provider>
    )
}

export default StripeContextProvider;
