import {findCurrentActiveSubscription, hasProFeatures} from "../../../models/subscription/subscription";
import React, {useState} from 'react';
import Location from "../../../models/location/location";
import {IonAlert} from "@ionic/react";
import Post, {
    PostType,
} from '../../../models/post/post';
import Business from '../../../models/organization/business';
import Organization from '../../../models/organization/organization';
import {useHistory} from 'react-router';
import PostEditorForm from "../PostEditorForm";
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';

interface PostBusinessLocationProps {
    business: Business,
    organization: Organization,
    post?: Post
    locations?: Location[]
    postId?: string,
    onPostSaved: (preview: boolean, post: Post) => void,
    savedPost: any
    setSavedPost: any
    skipPreviewSave?: boolean,
}

const BusinessPostEditor:React.FC<PostBusinessLocationProps> = ({onPostSaved, locations, business, organization, post, skipPreviewSave}) => {

    const availableLocations = (locations ? locations : business.locations ?? [])
    const hasNoLocations = availableLocations?.length == 0

    const selectLocations = () => {
        if (post && post.locations) {
            return post.locations.filter((value, index, array) => array.indexOf(value) === index)
        }
        return availableLocations;
    }

    const canPostAd = (): boolean => {
        const currentSubscription = findCurrentActiveSubscription(organization.subscriptions ?? [])
        return currentSubscription != undefined && hasProFeatures(currentSubscription);
    }
    const [selectedLocations, setSelectedLocations] = useState(selectLocations());
    const [subscriptionErrorShowing, setSubscriptionErrorShowing] = useState(!canPostAd());
    const [noLocationsErrorShowing, setNoLocationsErrorShowing] = useState(hasNoLocations && !subscriptionErrorShowing)

    const availablePostTypes = ['news', 'service', 'product'] as PostType[];
    const history = useHistory();

    return (
        <React.Fragment>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <PostEditorForm
                            post={post}
                            publisher={myBusinessContext.business}
                            onPostSaved={onPostSaved}
                            availablePostTypes={availablePostTypes}
                            assetUploadUrl={`/organizations/${myBusinessContext.organization.id!}/assets`}
                            locations={availableLocations}
                            selectedLocations={selectedLocations ?? availableLocations}
                            locationsSelected={setSelectedLocations}
                            skipPreviewSave={skipPreviewSave}
                        />
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
            <IonAlert
                isOpen={subscriptionErrorShowing}
                backdropDismiss={false}
                onDidDismiss={() => setSubscriptionErrorShowing(false)}
                message={'You must be in a Pro plan in order to post ads. Do you want to upgrade to a Pro plan now?'}
                buttons={[
                    {
                        text: 'No thanks',
                        handler: () => history.goBack(),
                    },
                    {
                        text: 'Upgrade',
                        handler: () => history.push("/organization/billing/current-subscription"),
                    },
                ]}
            />
            <IonAlert
                isOpen={!subscriptionErrorShowing && noLocationsErrorShowing}
                backdropDismiss={false}
                onDidDismiss={() => setNoLocationsErrorShowing(false)}
                header={'Location required'}
                message={'Only businesses with an active location may post an ad. Create your first location, and then try posting your ad again'}
                buttons={[
                    {
                        text: 'Nevermind',
                        handler: () => history.goBack(),
                    },
                    {
                        text: 'Add Location',
                        handler: () => history.push("/organization/location-creator"),
                    },
                ]}
            />
        </React.Fragment>
)};

export default BusinessPostEditor
