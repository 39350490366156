import React from 'react';
import {IonLabel, IonTextarea} from '@ionic/react';

import BaseLocationEditPage, {BaseLocationEditPagePrimaryProps} from '../BaseLocationEditPage';
import PhoneNumberInput from '../../../components/PhoneNumberInput';

import Location from '../../../models/location/location';
import {LocationEditorContextConsumerState} from '../../../contexts/organization/LocationEditorContext';

interface SimpleInputLocationEditPageProps extends BaseLocationEditPagePrimaryProps<string> {
    inputLabel: string,
    inputPlaceholder: string,
    fieldName: string,
    isMultiLine?: boolean,
    onSetValue?: (value: string ) => string,
}

const SimpleInputLocationEditPage: React.FC<SimpleInputLocationEditPageProps> = ({inputLabel, inputPlaceholder, fieldName, isMultiLine, onSetValue, ...props}) => {

    const setValue = (value: string, location: Location): Location => {
        const locationData = {...location} as any;
        if( onSetValue ) {
            value = onSetValue( value )
        }
        locationData[fieldName] = value;
        return locationData;
    }

    const getDefaultValue = (locationEditorStateContext: LocationEditorContextConsumerState): string => {
        const locationData = {...locationEditorStateContext.dirtyLocation} as any;
        return locationData[fieldName] ?? '';
    }

    return (
        <BaseLocationEditPage createInitialState={getDefaultValue} constructLocation={setValue} {...props}>
            {currentDataContext =>
                <div>
                    <IonLabel>{inputLabel}</IonLabel>
                    {fieldName == 'phone' ? (
                        <PhoneNumberInput
                            name={'phone'}
                            onPhoneNumberChange={(phoneNumber: string) => currentDataContext.setCurrentData(phoneNumber)}
                            value={currentDataContext.currentData}
                            placeholder={inputPlaceholder} />
                    ) : (
                        <IonTextarea
                            rows={1}
                            autoGrow={true}
                            value={currentDataContext.currentData}
                            autocapitalize={'true'}
                            placeholder={inputPlaceholder}
                            onInput={event => currentDataContext.setCurrentData(event.currentTarget.value!)} />
                    )}
                </div>
            }
        </BaseLocationEditPage>
    )
}

export default SimpleInputLocationEditPage
