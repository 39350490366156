import React from 'react';
import './index.scss';
import Message from '../../../models/user/message';
import {IonList} from '@ionic/react';
import MessageItem from '../MessageItem';

interface MessageListProps {
    messages: Message[],
    onReply: (message: Message) => void,
    replyingToMessage?: Message,
}

const MessageList: React.FC<MessageListProps> = ({replyingToMessage, messages, onReply}) => {

    return (
        <div className={'message-list'}>
            <IonList>
                {messages.map(message =>
                    <MessageItem
                        key={message.id}
                        entity={message.from}
                        body={message.data.body}
                        created_at={message.created_at!}
                        onReply={() => onReply(message)}
                        isReplying={replyingToMessage == message}
                    />
                )}
            </IonList>
        </div>
    )
}

export default MessageList;
