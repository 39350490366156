import React, {useState} from 'react'

import './index.scss'
import SignUpPageStep from '../../../../components/WelcomeSection/SignUpPageStep'
import {SignUpContext, SignUpData} from '../../../../contexts/SignIn/SignUpContext';
import VerificationCodeEntry from '../../../../components/WelcomeSection/VerificationCodeEntry';
import VerificationCodeRequests from '../../../../services/requests/VerificationCodeRequests';
import {IonToast, useIonRouter} from '@ionic/react';
import { useHistory } from 'react-router'

const VerificationCodeStep: React.FC = () => {

    const [isErrorShowing, setIsErrorShowing] = useState(false);
    const router = useIonRouter();

    const validateVerificationCode = (signUpData: SignUpData) => {
        VerificationCodeRequests.validateVerificationCode(signUpData.phone, signUpData.verification_code as number).then(() => {
            router.push('/welcome/sign-up/email');
            return true;
        }).catch(() => {
            setIsErrorShowing(true);
            return false;
        })
        return true;
    }

    return (
        <SignUpPageStep
            cancelOnBack
            bypassSubmit
        >
            <SignUpContext.Consumer>
                {signUpContext =>
                    <React.Fragment>
                        <VerificationCodeEntry
                            verificationCodeContext={signUpContext}
                            onSubmit={data => validateVerificationCode(data as SignUpData)}
                            mustExist={false}
                        />
                        <IonToast
                            message={"Invalid verification code"}
                            duration={2000}
                            isOpen={isErrorShowing}
                            onDidDismiss={() => setIsErrorShowing(false)}
                        />
                    </React.Fragment>
                }
            </SignUpContext.Consumer>
        </SignUpPageStep>
    )
}

export default VerificationCodeStep
