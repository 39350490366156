import React from 'react';
import OrganizationDashboardTabbedPage
    from '../../../../components/OrganizationManagement/OrganizationDashboardTabbedPage';
import {IonButton, IonIcon} from '@ionic/react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from "../../../../contexts/organization/MyBusinessContext";

const NoShopListings: React.FC = () => {
    return (
        <div className={'no-shop-listings'}>
            <h4>Coming Soon!</h4>
            <IonIcon src={'/assets/app-icons/storefront.svg'}/>
            <p>
                <strong>A new space to sell your products</strong>
            </p>
            <p>
                The Geddit Local Marketplace will provide an opportunity to showcase your products and get the attention of customers in your area.
            </p>
            <IonButton disabled>Get Started</IonButton>
        </div>
    )
}

const Shop: React.FC = () => {
    return (
        <OrganizationDashboardTabbedPage>
            <MyBusinessContextProvider hideLoadingSpace={true}>
                    <NoShopListings/>
            </MyBusinessContextProvider>
        </OrganizationDashboardTabbedPage>
    );
}

export default Shop


