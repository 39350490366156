import './index.scss';
import React, {useState} from 'react';
import Location from '../../../models/location/location';
import {IonSelect, IonSelectOption, IonItem, IonIcon, IonAlert} from '@ionic/react';
import {number} from 'yup';
import {ellipsisText, grammaticalList} from '../../../util/strings';
import {caretDownOutline} from 'ionicons/icons';

interface Props {
	availableLocations?: Location[],
	selectedLocations?: Location[],
	mode: "single" | 'multiple'
	onLocationsSelected?: (locations: Location[]) => void,
}

const LocationSelect: React.FC<Props> = ({mode, availableLocations, selectedLocations, onLocationsSelected}) => {

	const defaultCheckedLocations = () => {
		if (mode == 'multiple') {
			if (selectedLocations) {
				return selectedLocations
			} else {
				return availableLocations ?? [];
			}
		}

		return [];
	}
	const [checkedLocations, setCheckedLocations] = useState<Location[]>(defaultCheckedLocations());
	const [multiLocationSelectShowing, setMultiLocationSelectShowing] = useState(false);

	const locationsSelected = (value: Location[]) => {
		if (onLocationsSelected) {
			onLocationsSelected(value);
			setCheckedLocations(value);
		}
	}

	return (mode == 'single' ?
		<IonSelect className={'location-select'} value={checkedLocations} onIonChange={event => locationsSelected([event.detail.value])}>
			{availableLocations?.map(location => (
				<IonSelectOption key={location.id} value={location}>{location.name}</IonSelectOption>
			))}
		</IonSelect> :
		<IonItem className={'multi-location-selector'} lines={'none'} onClick={() => setMultiLocationSelectShowing(true)}>
			{checkedLocations.length == availableLocations?.length ? 'All Locations' : ellipsisText(grammaticalList(checkedLocations.map(location => location.name)), 25, '…')}
			<IonIcon slot={'end'} icon={caretDownOutline} color={'medium'}/>
			{availableLocations &&
			<IonAlert
				isOpen={multiLocationSelectShowing}
				onDidDismiss={() => setMultiLocationSelectShowing(false)}
				inputs={availableLocations?.map(location => ({
					type: 'checkbox',
					name: 'locations',
					value: location,
					checked: checkedLocations.map(i => i.id).indexOf(location.id) != -1,
					label: location.name,
				}))}
				buttons={[
					{
						text: 'All',
						handler: () => locationsSelected(availableLocations),
					},
					{
						text: 'Ok',
						handler: (form) => locationsSelected(form),
					},
				]}
			/>}
		</IonItem>
	)
}

export default LocationSelect
