import React, {HTMLAttributes} from 'react';
import {IonIcon} from '@ionic/react';
import './index.scss';
import {initialize} from '../../util/strings';

interface OwnProps extends HTMLAttributes<any> {
    name: string,
    url?: string,
    showPlus?: boolean,
    noBorder?: boolean,
    whiteBorder?: boolean,
    isScrolled?: boolean,
    isEdit?: boolean,
    editIcon?: string,
}

interface ProfileImageProps extends OwnProps {
}

/**
 * @param url
 * @param showPlus
 * @param name
 * @param isEdit
 * @param editIcon
 * @param noBorder
 * @param whiteBorder
 * @param isScrolled
 * @param props
 * @constructor
 */
const ProfileImage: React.FC<ProfileImageProps> = ({url, showPlus, name, isEdit, editIcon, noBorder, whiteBorder, isScrolled, ...props})  => {
    return (
        <div className={'profile-image' + (noBorder ? ' no-border' : '') + (whiteBorder ? ' white-border' : '') + (isScrolled ? ' is-scrolled' : '')} {...props}>
            {url ?
                <div className={'image-viewer'} style={{backgroundImage: 'url("' + url + '")'}}/> :
                isEdit ?
                    <IonIcon class={'image-viewer'} icon={editIcon ? editIcon : undefined} src={editIcon ? undefined : '/assets/app-icons/account.svg'}/> :
                    <div className={'image-viewer gradiant-' + name.charAt(0).toLowerCase()}>
                        {initialize(name)}
                    </div>
            }
            {showPlus && <IonIcon class={'plus-sign'} src={'/assets/app-icons/add.svg'}/>}
        </div>
    )
}

export default ProfileImage;
