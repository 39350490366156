import React, {useState} from 'react';
import SubscriptionEditor, {SubscriptionEditorData} from '../SubscriptionEditor';
import Business from '../../../models/organization/business';
import MembershipPlan from '../../../models/subscription/membership-plan';
import PaymentMethod, {findDefaultPaymentMethod} from '../../../models/payment/payment-method';
import {useHistory} from 'react-router';
import {IonAlert, IonContent, IonSlide, IonSlides} from '@ionic/react';
import PaymentMethodSelector from '../../PaymentMethods/PaymentMethodSelector';
import Organization from '../../../models/organization/organization';
import PendingTransactionInformation from '../PendingTransactionInformation';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import Subscription, {
    needsToPayForMoreLocations,
    locationCountDefault,
    calculateSubscriptionTotal,
    replaceOldSubscription
} from '../../../models/subscription/subscription';
import "./index.scss";
import ConfirmationPageContent from '../../GeneralUIElements/ConfirmationPageContent';
import Moment from 'react-moment';

type SlideDirection = 'next' | 'prev';

interface PendingSubscriptionData {
    membershipPlan: MembershipPlan,
    locationCount: number,
    totalToday: number,
}

interface SubscriptionSelectorSlideProps extends SubscriptionEditorData {
    business: Business,
    onSubscriptionSelected: (pendingSubscriptionData: PendingSubscriptionData) => void,
}

const SubscriptionSelectorSlide: React.FC<SubscriptionSelectorSlideProps> = ({business, maybeSubscription, onSubscriptionSelected, ...props}) => {

    const history = useHistory();
    const [isNeedsToRemoveLocationsAlertShowing, setIsNeedsToRemoveLocationsAlertShowing] = useState(false);

    const mainCategory = business.main_category;

    const checkIfPaymentNeeded = (membershipPlan: MembershipPlan, locationCount: number, totalToday: number) => {

        const isPaymentNeeded = needsToPayForMoreLocations(maybeSubscription, membershipPlan, business, locationCount)
        if ( isPaymentNeeded ) {
            setIsNeedsToRemoveLocationsAlertShowing(true);
        } else {
            onSubscriptionSelected({
                membershipPlan,
                locationCount,
                totalToday,
            })
        }
    }

    return (
        <IonSlide>
            <SubscriptionEditor
                business={business}
                onPlanSelected={checkIfPaymentNeeded}
                businessCategory={mainCategory}
                maybeSubscription={maybeSubscription}
                {...props}
            />
            <IonAlert
                isOpen={isNeedsToRemoveLocationsAlertShowing}
                onDidDismiss={() => setIsNeedsToRemoveLocationsAlertShowing(false)}
                message={`The number of locations in your account may not exceed the number of locations in your plan. You can either increase your plan or delete some of your locations.`}
                buttons={[
                    {
                        text: 'Remove Location',
                        handler: () => history.push('/organization/location-list'),
                    },
                    'Increase Plan',
                ]}
            />
        </IonSlide>
    )
}

interface PaymentMethodSelectorSlideProps {
    isTrial: boolean,
    organization: Organization,
    setOrganization: (organization: Organization) => void,
    selectedPaymentMethod?: PaymentMethod,
    onPaymentMethodSelected: (paymentMethod: PaymentMethod) => void,
}

const PaymentMethodSelectorSlide: React.FC<PaymentMethodSelectorSlideProps> = ({isTrial, organization, setOrganization, selectedPaymentMethod, onPaymentMethodSelected, ...props}) => {

    const paymentMethods = organization.payment_methods ? organization.payment_methods : [];

    return (
        <div className={'payment-method-slide'}>
            <h4>{paymentMethods.length > 0 ? 'Select' : 'Enter'} Your Payment Method</h4>
            {isTrial && <p>You will not be charged until the end of your trial.</p>}
            <PaymentMethodSelector
                entity={organization}
                setEntity={setOrganization as any}
                entityType={'organization'}
                showFormByDefault={true}
                paymentMethods={paymentMethods}
                hideFormCancel={true}
                submitText={'Continue'}
                onPaymentMethodChange={onPaymentMethodSelected}
            />
        </div>
    );
}

interface SubscriptionExpirationNoticeTextProps {
    lastSubscription?: Subscription,
    pendingSubscriptionData: PendingSubscriptionData,
    nextExpirationDate?: string,
}

const SubscriptionExpirationNoticeText: React.FC<SubscriptionExpirationNoticeTextProps> = ({nextExpirationDate, lastSubscription, pendingSubscriptionData}) => {

    const lastMembershipPlan = lastSubscription?.membership_plan_rate.membership_plan;

    return ((nextExpirationDate && lastMembershipPlan && lastMembershipPlan.duration != pendingSubscriptionData.membershipPlan.duration && !lastSubscription?.is_trial) ?
        <p>Your updated billing cycle will begin on <strong><Moment format={'MMMM\u00A0Do,\u00A0YYYY'}>{nextExpirationDate}</Moment>.</strong></p> : <></>
    )
}

interface FreePlanConfirmationSlideProps extends SubscriptionExpirationNoticeTextProps {
    onConfirm: () => void,
}

const FreePlanConfirmationSlide: React.FC<FreePlanConfirmationSlideProps> = ({onConfirm, nextExpirationDate, lastSubscription, pendingSubscriptionData}) => {

    return (
        <div className={'free-plan-confirmation-slide'}>
            <ConfirmationPageContent onConfirm={onConfirm} confirmText={'Confirm your order'}>
                <h4>Geddit Local Free Plan</h4>
                <p><strong>Features</strong></p>
                <p>The <strong>Free</strong> plan includes</p>
                <ul>
                    <li>Customized <strong>business listing</strong></li>
                    <li>Create <strong>unlimited number of locations</strong></li>
                    <li>Searchable by <strong>category</strong></li>
                    <li>Searchable by <strong>keyword</strong></li>
                </ul>
                <hr/>
                <h4>Order Summary</h4>
                <div className={'order-summary'}>
                    <strong>$0 per month</strong> for <strong>unlimited locations</strong>
                    <SubscriptionExpirationNoticeText
                        pendingSubscriptionData={pendingSubscriptionData}
                        nextExpirationDate={nextExpirationDate}
                        lastSubscription={lastSubscription}
                    />
                </div>
            </ConfirmationPageContent>
        </div>
    );
}

interface OrderSummarySlideProps extends SubscriptionExpirationNoticeTextProps {
    isTrial: boolean,
    paymentMethod: PaymentMethod,
    paymentMethodDirection: SlideDirection,
    swiper: any,
    pendingSubscriptionData: PendingSubscriptionData,
    onOrderSubmitted: () => void,
}

const OrderSummarySlide: React.FC<OrderSummarySlideProps> = ({nextExpirationDate, paymentMethod, paymentMethodDirection, swiper, pendingSubscriptionData, lastSubscription, isTrial, onOrderSubmitted}) => {

    const locationCount = pendingSubscriptionData.locationCount;

    // This variable tells us exactly how much the user will be charged when the
    const newSubscriptionRate = calculateSubscriptionTotal(locationCount, pendingSubscriptionData.membershipPlan.current_cost);
    // This variable tells us how much the plan would be,
    // if the user was subscribing to the last rate they had subscribed to
    const oldSubscriptionRate = lastSubscription ?
        calculateSubscriptionTotal(locationCount, lastSubscription.last_renewal_rate.cost) : newSubscriptionRate;

    return (
        <div className={'pending-transaction-information-slide'}>
            <PendingTransactionInformation
                paymentMethod={paymentMethod}
                onChangePaymentMethod={() => {
                    if (paymentMethodDirection == 'prev') {
                        swiper.slidePrev();
                    } else {
                        swiper.slideNext();
                    }
                }}
                canChangePaymentMethod={true}
                onConfirm={onOrderSubmitted}
                confirmText={'Place your order'}
            >
                <p>
                    <strong>${newSubscriptionRate.toFixed()} per {pendingSubscriptionData.membershipPlan.duration}</strong> for up to <strong>{pendingSubscriptionData.locationCount} business locations</strong>
                </p>
                {pendingSubscriptionData.totalToday > 0 && pendingSubscriptionData.totalToday < oldSubscriptionRate &&
                    <p>Your prorated charge will be <strong>${pendingSubscriptionData.totalToday.toFixed(2)} today</strong></p>
                }
                {isTrial && <p>You will not be charged until the end of your trial.</p>}
                <SubscriptionExpirationNoticeText
                    pendingSubscriptionData={pendingSubscriptionData}
                    nextExpirationDate={nextExpirationDate}
                    lastSubscription={lastSubscription}
                />
            </PendingTransactionInformation>
        </div>
    )
}

export interface SubscriptionFormProps {
    membershipPlans: MembershipPlan[],
    maybeSubscription?: Subscription,
    organization: Organization,
    setOrganization: (organization: Organization) => void,
    swiper: any,
    setSwiper: (swiper: any) => void,
    business: Business,
}

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({maybeSubscription, membershipPlans, organization, business, setOrganization, swiper, setSwiper, ...props}) => {

    const defaultLocationCount = locationCountDefault(maybeSubscription, business)
    const [paymentMethod, setPaymentMethod] = useState(
        organization.payment_methods ? findDefaultPaymentMethod(organization.payment_methods) : undefined
    );
    const [startsWithDefaultPaymentMethod, _] = useState(paymentMethod != undefined);

    const isTrial = (maybeSubscription && maybeSubscription.is_trial == 1) as boolean;

    const history = useHistory();

    const [pendingSubscriptionData, setPendingSubscriptionData] = useState({
        membershipPlan: membershipPlans.find(i => i.type == 'pro')!,
        locationCount: defaultLocationCount,
        totalToday: 0,
    })

    const swiperOptions = {
        allowTouchMove: false,
    }

    const init = async function(loadEvent: any) {
        setSwiper(loadEvent.target.swiper);
    };

    const subscriptionSelected = (pendingSubscriptionData: PendingSubscriptionData) => {
        setPendingSubscriptionData(pendingSubscriptionData);

        setTimeout(() => swiper.slideNext(), 50);
    }

    const paymentMethodSelected = (direction: SlideDirection, newPaymentMethod: PaymentMethod) => {
        setPaymentMethod(newPaymentMethod)
        setTimeout(() => {
            if (direction == 'next') {
                swiper.slideNext();
            } else {
                swiper.slidePrev();
            }
        }, 50);
    }

    const submitSubscription = (submitPage: string) => {
        const {membershipPlan, locationCount, totalToday} = pendingSubscriptionData;
        const subscriptionData = {
            recurring: membershipPlan.current_cost > 0,
            location_count: locationCount,
            membership_plan_rate_id: membershipPlan.current_rate_id
        } as any;

        if ( paymentMethod ) {
            subscriptionData.payment_method_id = paymentMethod.id;
        }

        (!maybeSubscription || totalToday > 0 ?
            OrganizationRequests.createSubscription(organization.id!, subscriptionData) :
            OrganizationRequests.updateSubscription(organization.id!, maybeSubscription, subscriptionData)
        ).then(subscription => {

            subscription.membership_plan_rate = {
                membership_plan : pendingSubscriptionData.membershipPlan,
                membership_plan_id: pendingSubscriptionData.membershipPlan.id!,
                cost: membershipPlan.current_cost,
                active: true,
            }
            subscription.last_renewal_rate =
                (!maybeSubscription?.expires_at && maybeSubscription?.last_renewal_rate) ?
                    maybeSubscription?.last_renewal_rate : subscription.membership_plan_rate;
            if (maybeSubscription && subscription.id == maybeSubscription.id) {
                organization.subscriptions = replaceOldSubscription(organization, subscription)
            } else {
                organization.subscriptions = [
                    ...organization.subscriptions ?? [],
                    subscription
                ];
            }
            setOrganization({
                ...organization,
            });

            if (swiper && !swiper.destroyed && swiper.slideTo) {
                try {
                    swiper.slideTo(0, 0);
                } catch (e) {
                    console.error(e);
                }
            }

            history.push('/organization/billing/' + submitPage);
        })
    }

    return (
        <IonContent className={'subscription-form'}>
            <IonSlides options={swiperOptions} onIonSlidesDidLoad={init}>
                <SubscriptionSelectorSlide
                    membershipPlans={membershipPlans}
                    business={business}
                    selectedLocationAmount={defaultLocationCount}
                    onSubscriptionSelected={subscriptionSelected}
                    maybeSubscription={maybeSubscription}
                    {...props}
                />
                <IonSlide>
                    {pendingSubscriptionData.membershipPlan.current_cost > 0 ?
                        ( startsWithDefaultPaymentMethod ?
                            <OrderSummarySlide
                                isTrial={isTrial}
                                nextExpirationDate={maybeSubscription?.expires_at}
                                paymentMethod={paymentMethod!}
                                paymentMethodDirection={'next'}
                                swiper={swiper}
                                lastSubscription={maybeSubscription}
                                pendingSubscriptionData={pendingSubscriptionData}
                                onOrderSubmitted={() => submitSubscription('subscription-upgraded')}
                            /> :
                            <PaymentMethodSelectorSlide
                                isTrial={isTrial}
                                selectedPaymentMethod={paymentMethod}
                                onPaymentMethodSelected={newPaymentMethod => paymentMethodSelected('next', newPaymentMethod)}
                                organization={organization}
                                setOrganization={setOrganization}
                            />
                        ) :
                        <FreePlanConfirmationSlide
                            lastSubscription={maybeSubscription}
                            pendingSubscriptionData={pendingSubscriptionData}
                            nextExpirationDate={maybeSubscription?.expires_at}
                            onConfirm={() => submitSubscription('free-subscription-selected')}
                        />
                    }
                </IonSlide>
                <IonSlide>
                    { startsWithDefaultPaymentMethod ?
                        <PaymentMethodSelectorSlide
                            isTrial={isTrial}
                            selectedPaymentMethod={paymentMethod}
                            onPaymentMethodSelected={newPaymentMethod => paymentMethodSelected('prev', newPaymentMethod)}
                            organization={organization}
                            setOrganization={setOrganization}
                        /> :
                        (paymentMethod &&
                            <OrderSummarySlide
                                isTrial={isTrial}
                                nextExpirationDate={maybeSubscription?.expires_at}
                                paymentMethodDirection={'prev'}
                                paymentMethod={paymentMethod}
                                lastSubscription={maybeSubscription}
                                swiper={swiper}
                                pendingSubscriptionData={pendingSubscriptionData}
                                onOrderSubmitted={() => submitSubscription('subscription-upgraded')}
                            />
                        )
                    }
                </IonSlide>

            </IonSlides>
        </IonContent>
    );
}

export default SubscriptionForm;
