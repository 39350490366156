import {ActionType} from '../../util/types';
import {FeedSettings, TokenState} from './persistent.state';
import {GeoLocationContextState} from '../../components/GeoLocationContextProvider/interfaces';

export const setHasSeenSwipeTutorial = (hasSeenSwipeTutorial: boolean) => ({
    type: 'set-has-seen-swipe-tutorial',
    hasSeenSwipeTutorial
} as const);

export const setHasSeenSavedTutorial = (hasSeenSavedTutorial: boolean) => ({
    type: 'set-has-seen-saved-tutorial',
    hasSeenSavedTutorial
} as const);

export const setHasSeenReferralsTutorial = (hasSeenReferralsTutorial: boolean) => ({
    type: 'set-has-seen-referrals-tutorial',
    hasSeenReferralsTutorial
} as const);

export const setHasSeenIntroductionTutorial = (hasSeenIntroductionTutorial: boolean) => ({
    type: 'set-has-seen-introduction-tutorial',
    hasSeenIntroductionTutorial
} as const);

export const setHasRespondedToLocationPrompt = (hasRespondedToLocationPrompt: boolean) => ({
    type: 'set-has-responded-to-location-prompt',
    hasRespondedToLocationPrompt
} as const);

export const setHasRespondedToNotificationPrompt = (hasRespondedToNotificationPrompt: boolean) => ({
    type: 'set-has-responded-to-notification-prompt',
    hasRespondedToNotificationPrompt
} as const);

export const setFirebaseToken = (firebaseToken: string) => ({
    type: 'set-firebase-token',
    firebaseToken
} as const);

export const setTokenData = (tokenData: TokenState) => ({
    type: 'set-token-data',
    tokenData
} as const);

export const setManagingBusinessId = (managingBusinessId?: number) => ({
    type: 'set-managing-business-id',
    managingBusinessId,
} as const);

export const setLastKnownLocation = (lastKnownLocation: GeoLocationContextState) => ({
    type: 'set-last-known-location',
    lastKnownLocation,
} as const);

export const setFeedSettings = (feedSettings: FeedSettings) => ({
    type: 'set-feed-settings',
    feedSettings,
} as const);

export const logOut = () => ({
    type: 'log-out'
} as const);

export type PersistentActions =
    | ActionType<typeof setHasSeenSwipeTutorial>
    | ActionType<typeof setHasSeenSavedTutorial>
    | ActionType<typeof setHasSeenReferralsTutorial>
    | ActionType<typeof setHasSeenIntroductionTutorial>
    | ActionType<typeof setHasRespondedToLocationPrompt>
    | ActionType<typeof setHasRespondedToNotificationPrompt>
    | ActionType<typeof setFirebaseToken>
    | ActionType<typeof setTokenData>
    | ActionType<typeof setManagingBusinessId>
    | ActionType<typeof setLastKnownLocation>
    | ActionType<typeof setFeedSettings>
    | ActionType<typeof logOut>
