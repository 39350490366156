import React, {useState} from 'react';
import {IonButton, IonContent, IonIcon, IonImg, IonItem, IonList, IonPage} from '@ionic/react';
import './index.scss';
import {chevronBack, closeOutline, closeSharp, settings} from "ionicons/icons";
import MeContextProvider, {MeContext} from "../../../../contexts/MeContext";
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import User from '../../../../models/user/user';
import {
    AcceptedInvitationsContext,
    AcceptedInvitationsContextProvider
} from '../../../../contexts/AcceptedInvitationsContext';
import UserItem from '../../../../components/MembersPages/UserItem';
import moment from 'moment';
import SendInvitationModal from '../../../../components/MembersPages/SendInvitationModal';

interface AcceptedInvitationsContentProps {
    user: User
}

const AcceptedInvitationsContent: React.FC<AcceptedInvitationsContentProps> = ({user}) => {

    const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);

    return (
        <React.Fragment>
            {user.invitation_token &&
                <React.Fragment>
                    <IonList className={'accepted-invites-list'}>
                        <AcceptedInvitationsContextProvider invitationId={user.invitation_token.id!}>
                            <AcceptedInvitationsContext.Consumer>
                                {acceptedInvitationsContext =>
                                    acceptedInvitationsContext.loadedData.map(i =>
                                        <UserItem
                                            key={i.id}
                                            user={i.user}
                                            showFollow
                                            detailLine={i.created_at && 'Accepted invite ' + moment(i.created_at).format('MMMM Do, YYYY')}
                                        />
                                    )
                                }
                            </AcceptedInvitationsContext.Consumer>
                        </AcceptedInvitationsContextProvider>
                    </IonList>
                    <div className={'invite-link'} slot={'fixed'}>
                        <IonButton
                            onClick={() => setIsInvitationModalOpen(true)}
                        >
                            Share link
                        </IonButton>
                    </div>
                    <SendInvitationModal
                        invitation={user.invitation_token}
                        isOpen={isInvitationModalOpen}
                        onDidDismiss={() => setIsInvitationModalOpen(false)}
                    />
                </React.Fragment>
            }
        </React.Fragment>

    )
}

const AcceptedInvitations: React.FC = () => {
    return (
        <IonPage id={'accepted-invitations-page'}>
            <LoggedInHeaderComponent hideBackButton={false} iconType={closeOutline}/>
            <IonContent>
                <h4>Accepted Invites</h4>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <AcceptedInvitationsContent user={meContext.me}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default AcceptedInvitations


