import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import Follower from '../models/user/follower';
import React, {useEffect, useState} from 'react';

/**
 * The state interface for our state
 */
export interface UserFollowsContextState extends BasePaginatedContextState<Follower> {}

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = createDefaultState();

function createDefaultState(): UserFollowsContextState {
    return {
        ...defaultBaseContext(),
        loadAll: true,
        expands: [
            'follows',
            'follows.business',
        ],
        order: {
            'created_at': 'desc',
        },
        limit: 50,
    }
}

/**
 * The actual context component
 */
export const UserFollowsContext = React.createContext<UserFollowsContextState>(createDefaultState());

export interface UserFollowsContextProviderProps extends BasePaginatedContextProviderProps{
    userId: number,
}

export const UserFollowsContextProvider: React.FC<UserFollowsContextProviderProps> = (props => {
    const [userFollowsState, setUserFollowsState] = useState(persistentContext);
    useEffect(() => {
        prepareContextState(setUserFollowsState, userFollowsState, '/users/' + props.userId + '/follows')
    }, [props.userId]);

    const fullContext = {
        ...persistentContext,
        ...createCallbacks(setUserFollowsState, persistentContext, '/users/' + props.userId + '/follows')
    }

    return (
        <UserFollowsContext.Provider value={fullContext}>
            {props.children}
        </UserFollowsContext.Provider>
    )
});
