import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {
    IonButton,
    IonContent,
    IonIcon,
    IonInput,
    IonLabel,
    IonPage,
    IonSlide,
    IonSlides,
    IonAlert,
    IonItem, IonCheckbox
} from '@ionic/react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import './index.scss';

import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import SideBySideComponent from '../../../components/SideBySide';
import UnderlinedInput from '../../../components/GeneralUIElements/UnderlinedInput';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import Business from '../../../models/organization/business';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import {phoneRegExp, stateRegExp, zipRegExp} from '../../../util/regex';
import Subscription, {
    findCurrentActiveSubscription,
    didPayForSubscription
} from '../../../models/subscription/subscription';
import LocationOutOfBoundsAlert from "../../../components/LocationOutOfBoundsAlert";
import {RequestError} from '../../../models/request-error';
import ServerAlert from '../../../components/ServerAlert';
import GooglePlacesSearchField from '../../../components/GooglePlacesSearchField';
import Location from '../../../models/location/location';
import {findAddressComponent} from '../../../components/Providers/GooglePlaces/GlobalGooglePlacesContextProvider';

interface LocationCreatorData {
    name: string,
    address: string,
    address_2?: string,
    city: string,
    state: string,
    zip: string,
    hide_address: boolean,
    place_id?: string,
    phone?: string,
    email?: string,
}

const initialLocationCreatorState: LocationCreatorData = {
    name: '',
    address: '',
    address_2: undefined,
    city: '',
    state: '',
    zip: '',
    hide_address: false,
    place_id: undefined,
    phone: undefined,
    email: undefined,
};

interface LocationCreatorFormStepRootProps {
    icon?: string,
}
const LocationCreatorFormStep: React.FC<LocationCreatorFormStepRootProps> = ({icon, children}) => {


    return (
        <IonSlide>
            <SideBySideComponent>
                <IonIcon src={icon}/>
                <div>
                    {children}
                </div>
            </SideBySideComponent>
        </IonSlide>
    );
}

interface LocationCreatorFormStepProps {
    initialData: LocationCreatorData,
    complete: (locationCreatorData: LocationCreatorData) => void,
    isPrimaryLocation: boolean,
}

const LocationCreatorFormStep1: React.FC<LocationCreatorFormStepProps> = ({complete, initialData, isPrimaryLocation}) => {

    const headline = isPrimaryLocation ? 'Add Primary Business' : 'Add New Business'
    const copy = isPrimaryLocation ? (
        'If your business has multiple locations, only those located within 50 miles of this primary location will be able to participate.'
    ) : (
        'Nearby customers will more easily discover your business, and view your posts.'
    )

    const formatData = (result: google.maps.places.PlaceResult|Location) => {
        const data = result as google.maps.places.PlaceResult;
        complete({
            ...initialData,
            place_id: data.place_id,
            name: data.name,
            address: findAddressComponent('street_number', data.address_components ?? []) + ' '
                + findAddressComponent('route', data.address_components ?? []),
            address_2: findAddressComponent('subpremise', data.address_components ?? []),
            city: findAddressComponent('locality', data.address_components ?? []),
            state: findAddressComponent('administrative_area_level_1', data.address_components ?? [], true),
            zip: findAddressComponent('postal_code', data.address_components ?? []),
            phone: data.formatted_phone_number?.replace(/\D/g,""),
        })
    }

    return (
        <IonSlide>
            <div>
                <h4>{headline}</h4>
                <p>{copy}</p>
                <GooglePlacesSearchField
                    privacyPolicyURL={''}
                    mode={'input'}
                    onComplete={formatData}
                    help={'Business Name'}
                    placeholder={'Type your business name'}
                    format={'simple'}
                />

                <div className={'manual-entry-helper'}>
                    <p>Can't find your business?</p>
                    <p><a onClick={() => complete(initialLocationCreatorState)}>Add your location manually</a></p>
                </div>
            </div>
        </IonSlide>
    );
}

const LocationCreatorFormStep2: React.FC<LocationCreatorFormStepProps> = ({initialData, complete, isPrimaryLocation}) => {

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('Business Name is required'),
        address: Yup.string().trim().required('Street Address is required'),
        city: Yup.string().trim().required('City is required'),
        state: Yup.string().trim().length(2)
            .matches(stateRegExp, 'Invalid State').required('State is required'),
        zip: Yup.string().trim().required('Zip is required')
            .length(5, 'ZIP code must be 5 digits')
            .matches(zipRegExp, 'Invalid ZIP'),
        hide_address: Yup.boolean(),
    });

    const form = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: (values) => complete(values)
    });

    const getCurrentError = () => {
        if (form.errors.name && form.touched.name) {
            return form.errors.name;
        }
        if (form.errors.address && form.touched.address) {
            return form.errors.address;
        }
        if (form.errors.city && form.touched.city) {
            return form.errors.city;
        }
        if (form.errors.state && form.touched.state) {
            return form.errors.state;
        }
        if (form.errors.zip && form.touched.zip) {
            return form.errors.zip;
        }

        return "";
    }

    useEffect(() => {
        form.setValues(initialData as any)
    }, [initialData]);

    const setState = (state: string) => {
        form.setFieldValue('state', state.replace( /[^a-zA-Z]/g, ''))
    }

    const setZipCode = (zip: string) => {
        form.setFieldValue('zip', zip.replace( /[^0-9]/g, ''))
    }


    return (
        <LocationCreatorFormStep
            icon={'/assets/app-icons/location.svg'}>
            <UnderlinedInput>
                <IonInput
                    type='text'
                    name='name'
                    value={form.values.name}
                    autocapitalize={'word'}
                    onIonChange={form.handleChange}
                />
                <IonLabel>Business Name</IonLabel>
            </UnderlinedInput>
            <UnderlinedInput>
                <IonInput
                    type='text'
                    name='address'
                    value={form.values.address}
                    autocapitalize={'word'}
                    onIonChange={form.handleChange}
                />
                <IonLabel>Street Address</IonLabel>
            </UnderlinedInput>
            <UnderlinedInput>
                <IonInput
                    type='text'
                    name='address_2'
                    value={form.values.address_2}
                    autocapitalize={'word'}
                    onIonChange={form.handleChange}
                />
                <IonLabel>Street Address Line 2</IonLabel>
            </UnderlinedInput>
            <UnderlinedInput>
                <IonInput
                    type='text'
                    name='city'
                    value={form.values.city}
                    autocapitalize={'word'}
                    onIonChange={form.handleChange}
                />
                <IonLabel>City</IonLabel>
            </UnderlinedInput>
            <SideBySideComponent>
                <UnderlinedInput>
                    <IonInput
                        type='text'
                        name='state'
                        maxlength={2}
                        autocapitalize={'characters'}
                        onIonChange={event => setState(event.detail.value as string)}
                        value={form.values.state}
                    />
                    <IonLabel>State</IonLabel>
                </UnderlinedInput>
                <UnderlinedInput>
                    <IonInput
                        type='text'
                        name='zip'
                        maxlength={5}
                        onIonChange={event => setZipCode(event.detail.value as string)}
                        value={form.values.zip}
                    />
                    <IonLabel>Zip</IonLabel>
                </UnderlinedInput>
            </SideBySideComponent>
            <div className={'hide-address-line'}>
                <IonItem lines={'none'}>
                    <IonCheckbox
                        name={'hide_address'}
                        mode={'md'}
                        onIonChange={event => form.setFieldValue('hide_address', event.detail.checked)}
                    />
                    <IonLabel>Hide address</IonLabel>
                </IonItem>
                <p><i>Check this box if your business is run online, and you want to hide your address from the public</i></p>
            </div>
            <p className={'error'}>
                {getCurrentError()}
            </p>
            <IonButton onClick={() => form.handleSubmit()}>
                Next
            </IonButton>
        </LocationCreatorFormStep>
    )
}

const LocationCreatorFormStep3: React.FC<LocationCreatorFormStepProps> = ({initialData, complete}) => {

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid Email'),
        phone: Yup.string()
            .length(10, 'Phone Number must be 10 digits')
            .matches(phoneRegExp, 'Invalid Phone'),
    });

    const getCurrentError = () => {
        if (form.errors.email && form.touched.email) {
            return form.errors.email;
        }
        if (form.errors.phone && form.touched.phone) {
            return form.errors.phone;
        }

        return "";
    }

    const form = useFormik({
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: (values) => complete({
            ...initialData,
            email: values.email,
            phone: values.phone,
        })
    });

    useEffect(() => {
        form.setValues(initialData as any)
    }, [initialData]);

    return (
        <LocationCreatorFormStep>
            <h4>Add Location phone number, and email</h4>
            <p>This allows customers to connect with you more directly.</p>
            <UnderlinedInput>
                <PhoneNumberInput
                    name={'phone'}
                    onPhoneNumberChange={(phoneNumber: string) => form.setFieldValue( 'phone', phoneNumber )}
                    placeholder={'10-digits'}
                    value={form.values.phone ?? ''}
                />
                <IonLabel>Phone <small>(optional)</small></IonLabel>
            </UnderlinedInput>
            <UnderlinedInput>
                <IonInput
                    type='email'
                    name='email'
                    onIonChange={form.handleChange}
                />
                <IonLabel>Email <small>(optional)</small></IonLabel>
            </UnderlinedInput>
            <p className={'error'}>
                {getCurrentError()}
            </p>
            <IonButton onClick={() => form.handleSubmit()}>
                Submit
            </IonButton>
        </LocationCreatorFormStep>
    );
}

interface LocationCreatorFormProps {
    currentSubscription?: Subscription
    business: Business,
    setBusiness: (business: Business) => void,
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const LocationCreatorForm: React.FC<LocationCreatorFormProps> = ({currentSubscription, business, setBusiness, setHeaderProps}) => {

    const canAddLocation = () => {
        if (currentSubscription) {
            const hasLocationSpace = currentSubscription.location_count > business.locations?.length!;

            if (currentSubscription.is_trial) {
                return !currentSubscription.recurring || hasLocationSpace;
            }

            return !didPayForSubscription(currentSubscription) || hasLocationSpace;
        }
        return true;
    }

    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);
    const [locationCreatorData, setLocationCreatorData] = useState(initialLocationCreatorState);
    const [swiper, setSwiper] = useState<any>({});
    const [currentStep, setCurrentStep] = useState(1);
    const [isNotEnoughLocationsAlertShowing, setIsNotEnoughLocationsAlertShowing] = useState(!canAddLocation());
    const [isOutOfRangeAlertShowing, setOutOfRangeAlertShowing] = useState(false);
    const history = useHistory();

    const init = async function(loadEvent: any) {
        setSwiper(loadEvent.target.swiper);
    };

    useEffect(() => {
        if (history.location.pathname.indexOf("location-creator") != -1) {
            setIsNotEnoughLocationsAlertShowing(!canAddLocation())
        }
    }, [history.location.pathname]);

    const simpleStepComplete = (nextStep: number, updatedData: LocationCreatorData) => {
        setLocationCreatorData({
            ...locationCreatorData,
            ...updatedData,
        });
        setCurrentStep(nextStep);
        swiper.slideNext();
    }

    const isFirstLocation = !business.locations || business.locations.length == 0

    const step3Complete = (updatedData: LocationCreatorData) => {
        const locationData : any = {
            ...locationCreatorData,
            ...updatedData,
        };

        if (business.website && business.website.length > 0) {
            locationData.website = business.website;
        }

        OrganizationRequests.createLocation(business.id!, locationData).then(async (location) => {
            const locations = business.locations ? business.locations : []
            locations.push(location);
            setBusiness({
                ...business,
                total_locations: business.total_locations + 1,
                locations: [...locations]
            });
            history.replace('/organization/location-editor/' + location.id)
        }).catch((error: RequestError) => {
            if( error.data.errors && error.data.errors.address.indexOf('All locations must be within the same community as each other.') != -1 ) {
                setOutOfRangeAlertShowing(true)
            }
            else {
                setRequestError(error)
            }
        })
    }

    const swiperOptions = {
        allowTouchMove: false,
    }

    const backClicked = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
            swiper.slidePrev()
        } else if(isFirstLocation) {
            history.push( '/organization-creator/business-header-setup')
        } else {
            history.goBack()
        }
    }

    useEffect(() => {
        setHeaderProps({
            children: 'Add Location',
            backClicked: backClicked
        });
    }, [currentStep, swiper, isFirstLocation])

    return (
        <React.Fragment>
            <IonContent>
                <IonSlides options={swiperOptions} onIonSlidesDidLoad={init}>
                    <LocationCreatorFormStep1 initialData={locationCreatorData} complete={data => simpleStepComplete(2, data)} isPrimaryLocation={isFirstLocation}/>
                    <LocationCreatorFormStep2 initialData={locationCreatorData} complete={data => simpleStepComplete(3, data)} isPrimaryLocation={isFirstLocation}/>
                    <LocationCreatorFormStep3 initialData={locationCreatorData} complete={step3Complete} isPrimaryLocation={isFirstLocation}/>
                </IonSlides>
            </IonContent>
            <IonAlert
                isOpen={isNotEnoughLocationsAlertShowing}
                onDidDismiss={() => setIsNotEnoughLocationsAlertShowing(false)}
                message={'You must update your subscription to allow for more locations before you create a new location.'}
                buttons={[
                    {
                        text: 'Never mind',
                        handler: () => history.goBack(),
                    },
                    {
                        text: 'OK!',
                        handler: () => history.push('/organization/billing/current-subscription')
                    }
                ]}
            />
            <LocationOutOfBoundsAlert
                open={isOutOfRangeAlertShowing}
                onCloseAlert={() => setOutOfRangeAlertShowing(false)}
                main_category={business.main_category}
                swiper={swiper}
            />
            {requestError &&
                <ServerAlert
                    requestError={requestError}
                    onCloseAlert={() => setRequestError(undefined)}
                />
            }
        </React.Fragment>
    );
}

const LocationCreator: React.FC = () => {

    return (
        <IonPage className={'location-creator-page'}>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <OrganizationHeaderContext.Consumer>
                            {organizationHeaderContext =>
                                <LocationCreatorForm
                                    currentSubscription={findCurrentActiveSubscription(myBusinessContext.organization.subscriptions ?? [])}
                                    business={myBusinessContext.business}
                                    setBusiness={myBusinessContext.setBusiness}
                                    setHeaderProps={organizationHeaderContext.setSharedProps}
                                />
                            }
                        </OrganizationHeaderContext.Consumer>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </IonPage>
    );
}

export default LocationCreator;
