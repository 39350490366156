import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import Follower from '../models/user/follower';
import React, {useEffect, useState} from 'react';

/**
 * The state interface for our state
 */
export interface UserFollowersContextState extends BasePaginatedContextState<Follower> {}

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = {
    ...defaultBaseContext(),
    expands: [
        'user',
    ],
    order: {
        'created_at': 'desc',
    },
    limit: 50,
} as UserFollowersContextState;

function createDefaultState(): UserFollowersContextState {
    return {
        ...defaultBaseContext(),
        expands: [
            'user',
        ],
        order: {
            'created_at': 'desc',
        },
        limit: 50,
    }
}

/**
 * The actual context component
 */
export const UserFollowersContext = React.createContext<UserFollowersContextState>(createDefaultState());

export interface UserFollowersContextProviderProps extends BasePaginatedContextProviderProps{
    userId: number,
}

export const UserFollowersContextProvider: React.FC<UserFollowersContextProviderProps> = (props => {
    const [userFollowsState, setUserFollowsState] = useState(persistentContext);
    useEffect(() => {
        prepareContextState(setUserFollowsState, userFollowsState, '/users/' + props.userId + '/followers')
    }, [props.userId]);

    const fullContext = {
        ...persistentContext,
        ...createCallbacks(setUserFollowsState, persistentContext, '/users/' + props.userId + '/followers')
    }

    return (
        <UserFollowersContext.Provider value={fullContext}>
            <UserFollowersContext.Consumer>
                {context => {
                    if (context.hasAnotherPage) {
                        // We always want to load and cache all of this data
                        context.loadNext();
                    }
                    return props.children;
                }}
            </UserFollowersContext.Consumer>
        </UserFollowersContext.Provider>
    )
});
