import React from 'react';
import SimpleInputLocationEditPage from '../../../../components/OrganizationManagement/SimpleInputLocationEditPage';
import {phoneRegExp, validateRegexMatch} from "../../../../util/regex";

const Phone: React.FC = () => {
    const validatePhone = (value: string ) => {
        if(value.length != 10) {
            return 'Phone number must be 10 digits'
        }

        if( !validateRegexMatch(phoneRegExp, value) ) {
            return 'Invalid phone number'
        }
        return ''
    }
    return (
        <SimpleInputLocationEditPage
            iconUrl={'phone'}
            inputLabel={'Phone Number'}
            inputPlaceholder={'xxx-xxx-xxxx'}
            fieldName={'phone'}
            title={'Location Phone Number'}
            onCurrentDataSave={validatePhone}
        />
    );
}

export default Phone;
