import React, {useEffect} from 'react';
import {TokenState} from '../../data/persistent/persistent.state';
import AuthRequests from '../../services/requests/AuthRequests';
import UserPostRequests from '../../services/requests/UserPostRequests';
import {updateBadgeNumber} from '../../util/badge';
import {connect} from '../../data/connect';
import {Badge} from '@capawesome/capacitor-badge';
import {Capacitor} from '@capacitor/core';

interface StateProps {
    tokenData?: TokenState
}

interface NotificationsResponderProps extends StateProps {
}

/**
 * This component is responsible for listening to notification interaction, and dispatching various actions that we need from a notification
 * @param tokenData
 * @param children
 * @constructor
 */
const BadgeUpdater: React.FC<NotificationsResponderProps> = ({tokenData, children}) => {

    useEffect(() => {

        Badge.isSupported().then(() => {
            Badge.checkPermissions().then(result => {
                if (result.display == 'granted') {
                    if (Capacitor.isNativePlatform()) {

                        if (tokenData) {
                            AuthRequests.getMe().then(me => {
                                UserPostRequests.getUserFollowingUnseenPosts(me.id!)
                                    .then(page => updateBadgeNumber(page.total));
                            })
                        } else {
                            Badge.clear();
                        }
                    }
                }
            })
        })
    }, [tokenData])

    return null;
}

export default connect<{}, StateProps, {}>({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    component: BadgeUpdater
});

