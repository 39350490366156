import './index.scss';
import React, {ReactElement, useEffect, useState} from 'react';
import {IonButton, IonContent, IonMenu, IonPage, IonSplitPane} from '@ionic/react';
import OrganizationMenu from '../OrganizationMenu';
import OrganizationHeader from '../OrganizationHeader';


interface OrganizationTemplateProps {
	hideMenu?: boolean,
}

const OrganizationTemplate: React.FC<OrganizationTemplateProps> = ({ children}) => {

	const currentlySplitPane = () => window.outerWidth > 980;
	const [isSplitPane, setIsSplitPane] = useState(currentlySplitPane());
	const resetSplitPane = () => setIsSplitPane(currentlySplitPane());

	useEffect(resetSplitPane, [window.location.href])
	window.addEventListener('resize', resetSplitPane);

	return (
		<div className={'organization-template'}>
			<OrganizationHeader/>
			<div className={'split-pane-wrapper'}>

				<IonSplitPane contentId="organization" when={isSplitPane}>
					<IonMenu menuId={'organization'} contentId={'organization'}>
						<IonContent>
							<OrganizationMenu/>
						</IonContent>
					</IonMenu>
					<IonContent id={'organization'} scrollY={false}>{children}</IonContent>
				</IonSplitPane>
			</div>
		</div>
	);
}

export default OrganizationTemplate
