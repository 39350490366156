import BaseModel from '../base-model';

export default interface PaymentMethod extends BaseModel {

    /**
     * The last 4 digits of the credit card
     */
    identifier: string;

    /**
     * The type of payment method this is. Always stripe for the time being
     */
    payment_method_type: string;

    /**
     * Whether or not this is the default payment method
     */
    default: boolean;

    /**
     * The brand of the credit card
     */
    brand: string;

    /**
     * The month the card expires
     */
    exp_month: string;

    /**
     * The year the card expires
     */
    exp_year: string;

    /**
     * The owner of the credit card, either an organization id or user id
     */
    owner_id: string;
}

/**
 * This function takes in an array of payments and returns what is most likely the default
 * @param availableMethods
 */
export function findDefaultPaymentMethod(availableMethods: PaymentMethod[]): PaymentMethod|undefined {
    const defaultMethod = availableMethods.find(i => i.default)

    if (defaultMethod) {
        return defaultMethod;
    }

    return availableMethods[0] ?? undefined;
}
