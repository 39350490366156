import React from 'react';
import Post from '../../../models/post/post';
import {Helmet} from 'react-helmet';

interface PostMetaInformationProps {
    post: Post,
}

const PostMetaInformation : React.FC<PostMetaInformationProps> = ({post}) => {
    return (
        <Helmet>
            <meta property="og:url" content={"https://app.gedditlocal.com/posts/" + post.id} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={post.title} />
            <meta property="og:description" content={post.article_content} />
            <meta property="og:image" content={post.main_image_url} />
        </Helmet>
    )
}

export default PostMetaInformation;
