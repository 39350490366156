import {IonPage} from '@ionic/react';
import React from 'react';
import TermsOfUseText from '../../../components/TermsOfUseText';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import './index.scss';

const TermsOfUse: React.FC = () => {

    return (
        <IonPage className={'terms-page'}>
            <LoggedOutHeaderComponent>
                Terms of Use
            </LoggedOutHeaderComponent>
            <TermsOfUseText/>
        </IonPage>
    );
}

export default TermsOfUse;
