import React from 'react';
import {IonButton, IonContent, IonIcon, IonImg, IonItem, IonModal, IonPage} from '@ionic/react';
import './index.scss';
import LoggedInHeaderComponent from "../../../components/LoggedInHeader";
import {chevronBack, closeOutline, closeSharp, settings} from "ionicons/icons";
import Invitation from '../../../models/invitation';
import {Share} from '@capacitor/share';

interface SendInvitationModalProps extends React.ComponentProps<typeof IonModal> {
    invitation: Invitation
}

const SendInvitationModal: React.FC<SendInvitationModalProps> = ({onDidDismiss, invitation, ...props}) => {

    const shareLink = () => {
        const url = "https://app.gedditlocal.com/invite/" + invitation.token;
        console.log('url', url);
        Share.share({
            title: '',
            text: 'Join me on Geddit Local to discover and share recommendations of things to do and places to go. ' + url,
        })
    }

    return (
        <IonModal className={'send-invite-modal'} {...props}>
            <LoggedInHeaderComponent iconType={closeOutline} onBackPressed={() => onDidDismiss ? (onDidDismiss as any)() : {}}>
                Invite Friends
            </LoggedInHeaderComponent>
            <IonContent scrollEvents>
                <div className={'invite-friends'}>
                    <img src={'/assets/splash.png'}/>
                    <h4>Invite friends, support your local community</h4>
                    <p>
                        We rely on locals inviting fellow locals into the app to discover and share recommendations of great local businesses in your area.
                    </p>
                </div>
                <div className={'invite-link'} slot={'fixed'}>
                    <IonButton onClick={shareLink}>Share link</IonButton>
                </div>
            </IonContent>
        </IonModal>
    );
}

export default SendInvitationModal


