import React from 'react';
import SimpleInputLocationEditPage from '../../../../components/OrganizationManagement/SimpleInputLocationEditPage';

const LocationNameEditor: React.FC = () => {

    return (
        <SimpleInputLocationEditPage
            iconUrl={'name'}
            inputLabel={'Name'}
            inputPlaceholder={'Location Name'}
            fieldName={'name'}
            title={'Location Name'}
        />
    );
}

export default LocationNameEditor;
