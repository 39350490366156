import React, {ChangeEventHandler, useRef, useState, ChangeEvent} from 'react';
import Asset from '../../models/asset';
import AssetRequests from '../../services/requests/AssetRequests';
import {IonAlert} from '@ionic/react';
import {OpenNativeSettings} from '@ionic-native/open-native-settings';
import {connect} from '../../data/connect';
import {decrementLoadingCount, incrementLoadingCount} from '../../data/session/session.actions';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import "./index.scss";

type AssetType = 'image' | 'multimedia';

interface OwnProps {
    uploadEndpoint: string
    assetUploaded: (asset: Asset) => void
    type: AssetType,
    children?: React.ReactNode
    ref?: React.Ref<HTMLDivElement>
}

interface DispatchProps {
    incrementLoadingCount: typeof incrementLoadingCount
    decrementLoadingCount: typeof decrementLoadingCount
}

interface AssetUploaderProps extends OwnProps, DispatchProps {
}

const AssetUploader: React.FC<AssetUploaderProps> = React.forwardRef(({children, uploadEndpoint, incrementLoadingCount, decrementLoadingCount, assetUploaded, type}, ref: React.Ref<HTMLDivElement>) => {

    const [isErrorShowing, setIsErrorShowing] = useState(false);
    const [isFileOver, setIsFileOver] = useState(false);
    const inputRef = useRef< HTMLInputElement>(null);

    const uploadFile = (fileContentsBase64: string) => {
        incrementLoadingCount()
        AssetRequests.uploadAsset(uploadEndpoint, fileContentsBase64).then(asset => {
            decrementLoadingCount()
            assetUploaded(asset)
        });
    }

    /**
     * Gets the factor that we should resize to in order to make the shortest size of an image at max 1080 pixels
     * @param image
     */
    const calculateImageScaleFactor = (image: HTMLImageElement) => {
        const shortestSide = image.naturalWidth > image.naturalHeight ?
            image.naturalHeight : image.naturalWidth;

        return shortestSide > 1080 ? shortestSide / 1080 : 1;
    }

    /**
     * This is responsible for asking the user how they want to select an asset
     */
    const promptUploadMethod = () => {
        if (Capacitor.isNativePlatform()) {
            Camera.getPhoto({
                quality: 75,
                allowEditing: false,
                width: 1080,
                height: 1080,
                resultType: CameraResultType.Base64
            }).then(data => {
                const fileContents = data.base64String as string;
                uploadFile(fileContents)
            }).catch(error => {
                decrementLoadingCount()
                setIsErrorShowing(error.message == 'User denied access to photos');
                const ignoredMessages = [
                    'User cancelled photos app',
                    'No image picked',
                ];
                if (ignoredMessages.indexOf(error.message) == -1) {
                    alert('Image error - ' + JSON.stringify(error));
                }
            });
        } else {
            inputRef.current!.click()
        }
    }

    const handleFileBlob = (file: Blob) => {
        const reader = new FileReader();
        reader.onload = function () {
            const img = new Image();
            img.onload = function (event) {
                // Dynamically create a canvas element
                const canvas = document.createElement("canvas");

                // var canvas = document.getElementById("canvas");
                const ctx = canvas.getContext("2d");

                const factor = calculateImageScaleFactor(img);

                canvas.width = img.naturalWidth / factor;
                canvas.height = img.naturalHeight / factor;

                // Actual resizing
                ctx?.drawImage(img, 0, 0, img.naturalWidth / factor, img.naturalHeight / factor);

                const fullContents = canvas.toDataURL(file.type);
                const fileContentsBase64 = fullContents.split('base64,')[1];
                uploadFile(fileContentsBase64);
            }
            img.src = reader.result as string;
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        reader.readAsDataURL(file);
    }

    const handleFileSelected = (event: ChangeEvent) => {
        handleFileBlob((event?.target as any).files[0])
    }

    let mimeTypes = [
        'image/jpeg',
        'image/png',
    ]

    const grabDraggedFile = (ev: DragEvent) => {

        if (ev.dataTransfer) {
            if (ev.dataTransfer.items) {
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        const file = ev.dataTransfer.items[i].getAsFile();
                        if (file && mimeTypes.indexOf(file.type) != -1) {
                            return file;
                        }
                    }
                }
            } else {
                for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                    const file = ev.dataTransfer.files[i];
                    if (mimeTypes.indexOf(file.type) != -1) {
                        return file;
                    }
                }
            }
        }
    }

    const  dropHandler = (ev: DragEvent) => {
        ev.preventDefault();

        const file = grabDraggedFile(ev);
        if (file) {
            handleFileBlob(file);
        }
        setIsFileOver(false);
    }

    const dragOverHandler = (ev: DragEvent) => {
        ev.preventDefault();
        setIsFileOver(true);
    }

    const dragLeaveHandle = () => {
        setIsFileOver(false);
    }

    return (
        <div
            ref={ref}
            className={'asset-uploader'}
            onClick={() => promptUploadMethod()}
            onDrop={ev => dropHandler(ev as any)}
            onDragOver={ev => dragOverHandler(ev as any)}
            onDragLeave={dragLeaveHandle}
        >
            <input
                type={'file'}
                className={'desktop-file-upload'}
                ref={inputRef}
                accept={mimeTypes.join(',')}
                onChange={handleFileSelected}
            />
            {children}
            <div className={'drop-overlay ' + (isFileOver ? 'visible' : '')}>
                Drop Image{/*{type == 'multimedia' && ' or Video'}*/}
            </div>
            <IonAlert
                isOpen={isErrorShowing}
                onDidDismiss={() => setIsErrorShowing(false)}
                message={'You need to grant Geddit Local permission to access your photos before you can upload a photo.'}
                header={'Oh No!'}
                buttons={[
                    'Not Now',
                    {
                        text: 'OK',
                        handler: () => OpenNativeSettings.open('application_details').catch(console.error),
                    }
                ]}
            />
        </div>
    );
})

export default connect<OwnProps, { }, DispatchProps>({
    mapDispatchToProps: ({
        incrementLoadingCount,
        decrementLoadingCount,
    }),
    component: AssetUploader
});

