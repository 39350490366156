import {IonContent, IonPage} from '@ionic/react';
import React from 'react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import {TokenState} from '../../../../../data/persistent/persistent.state';
import MeContextProvider, {MeContext} from '../../../../../contexts/MeContext';
import './index.scss';
import ContactUsForm from '../../../../../components/ContactUsForm';
import {chevronBack} from "ionicons/icons";

interface StateProps {
    tokenData?: TokenState;
}

interface ContactUsProps extends StateProps {
}

const ContactUs: React.FC<ContactUsProps> = ({tokenData}) => {

    return (
        <IonPage className={'contact-us-page'}>
        	<LoggedInHeaderComponent iconType={chevronBack}>
                Contact Us
        	</LoggedInHeaderComponent>
        	<IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext =>
                            <ContactUsForm
                                loggedInUser={meContext.me}
                            />
                        }
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default ContactUs
