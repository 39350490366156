import React from 'react';
import {
    IonButton,
    IonContent,
    IonModal,
} from '@ionic/react';
import './index.scss'

interface NewsFeedOptionsModalProps  extends React.ComponentProps<typeof IonModal> {
    onAccepted: () => void,
}

const ReferralTutorialModal: React.FC<NewsFeedOptionsModalProps> = ({onDidDismiss, onAccepted, ...rest}) => {

    const dismiss = () => onDidDismiss ? (onDidDismiss as any)() : {};

    return (
        <IonModal
            initialBreakpoint={0.85}
            breakpoints={[0.85, 0.95]}
            onDidDismiss={onDidDismiss}
            className={'referral-tutorial-modal'}
            {...rest}
        >
            <IonContent>
                <h4>What would you recommend?</h4>
                <p>Share your local dining, shopping, and event recommendations with the Geddit Local community.</p>
                <ol>
                    <li>Select a location</li>
                    <li>Make your recommendation</li>
                    <li>Post it!</li>
                </ol>
                <p>We just ask that you follow these two simple rules:</p>
                <h3>Keep it positive<br/>Keep it local</h3>
                <p className={'disclaimer'}><i>Negative posts or posts recommending national chains may get flagged and removed</i></p>
                <IonButton onClick={onAccepted}>I Agree!</IonButton>
                <IonButton onClick={dismiss} fill={'clear'} color={'medium'}>No thanks</IonButton>
            </IonContent>
        </IonModal>
    )
}

export default ReferralTutorialModal;

