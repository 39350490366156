import React, {useEffect, useState} from 'react';
import GlobalGooglePlacesContextProvider, {
    GlobalGooglePlacesContext,
    GoogleGlobalPlacesProviderContextConsumerState, neededFields
} from '../GlobalGooglePlacesContextProvider';

let cachedResults: {[key: string]: google.maps.places.PlaceResult[]} = {}

export interface SearchGooglePlacesContextProviderConsumerState extends GoogleGlobalPlacesProviderContextConsumerState {
    result: google.maps.places.PlaceResult[],
    loaded: boolean,
}

const defaultState = {
    result: [],
    loaded: false,
    service: null,
};

export const SearchGooglePlacesContext = React.createContext<SearchGooglePlacesContextProviderConsumerState>(defaultState);

interface SearchGooglePlacesQueryManagerProps extends SearchGooglePlacesContextProviderProps {
    globalGooglePlacesContext: GoogleGlobalPlacesProviderContextConsumerState
}

const SearchGooglePlacesQueryManager: React.FC<SearchGooglePlacesQueryManagerProps> = ({query, location, globalGooglePlacesContext, children}) => {

    const [currentResults, setCurrentResults] = useState<SearchGooglePlacesContextProviderConsumerState>(defaultState);

    const handleSearchResult = (results: any, status: any) => {
        if (status == 'OK') {
            cachedResults[query] = results;
            setCurrentResults({
                result: results,
                loaded: true,
                service: globalGooglePlacesContext.service,
            });
        }
    };

    useEffect(() => {
        if (globalGooglePlacesContext.service) {
            if (cachedResults[query]) {
                setCurrentResults({
                    result: cachedResults[query],
                    loaded: true,
                    service: globalGooglePlacesContext.service,
                })
            } else {
                setCurrentResults({
                    result: [],
                    loaded: false,
                    service: globalGooglePlacesContext.service,
                });
                if (query.length > 0) {
                    globalGooglePlacesContext.service.textSearch({
                        location: location,
                        query: query,
                    }, handleSearchResult);
                } else {
                    globalGooglePlacesContext.service.nearbySearch({
                        location: location,
                        radius: 10,
                    }, handleSearchResult)
                }
            }

        }
    }, [query, globalGooglePlacesContext.service])

    return (
        <SearchGooglePlacesContext.Provider value={currentResults}>
            {children}
        </SearchGooglePlacesContext.Provider>
    )
}


interface SearchGooglePlacesContextProviderProps {
    query: string,
    location: google.maps.LatLng,
}

const SearchGooglePlacesContextProvider: React.FC<SearchGooglePlacesContextProviderProps> = ({ children, ...rest}) => {

    return (
        <GlobalGooglePlacesContextProvider>
            <GlobalGooglePlacesContext.Consumer>
                {globalGooglePlacesContext =>
                    <SearchGooglePlacesQueryManager globalGooglePlacesContext={globalGooglePlacesContext} {...rest}>
                        {children}
                    </SearchGooglePlacesQueryManager>
                }
            </GlobalGooglePlacesContext.Consumer>
        </GlobalGooglePlacesContextProvider>
    )
}

export default SearchGooglePlacesContextProvider;
