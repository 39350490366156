import React from 'react';
import {useHistory} from 'react-router';
import {IonButton, IonContent, IonPage, IonRouterLink} from '@ionic/react';
import OrganizationHeader from '../../../components/OrganizationManagement/OrganizationHeader';
import './index.scss';
import FeaturedImages from '../../../components/FeaturedImages';
import BottomStickySection from '../../../components/BottomStickySection';
import BusinessEditorContextProvider, {
    BusinessEditorContext,
    BusinessEditorContextConsumerState
} from '../../../contexts/organization/BusinessEditorContext';
import Business from '../../../models/organization/business';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import {closeOutline} from "ionicons/icons";

interface BusinessInformationLinksProps {
    businessEditorContext: BusinessEditorContextConsumerState
}

const BusinessHeaderImages: React.FC<BusinessInformationLinksProps> = ({businessEditorContext}) => {

    const business = businessEditorContext.dirtyBusiness;
    return (
        <div className={'header-images'}>
            <FeaturedImages
                images={business.featured_images ? business.featured_images : []}
                editEnabled={{setBusiness: businessEditorContext.setBusiness, business: businessEditorContext.business}}
            />
        </div>
    );
}

interface BusinessHeaderSetupProps  {
}

const BusinessHeaderSetup: React.FC<BusinessHeaderSetupProps> = () => {

    const history = useHistory();
    const backHandler = () => window.location.replace( '/home');

    const saveData = (business: Business, updateCompleted: (business: Business) => void) => {
        OrganizationRequests.updateBusiness(business).then(updatedBusiness => {
            updateCompleted(updatedBusiness)
            if (!business.locations || business.locations.length < 1) {
                window.location.replace('/organization/location-creator');
            } else {
                window.location.replace('/organization/dashboard');
            }
        }).catch(error => {
            alert('business update error - ' + JSON.stringify(error));
        })
    }

    return (
        <IonPage className={'business-header-setup'}>
            <BusinessEditorContextProvider noLongerEditingRedirectLink={'/organization/location-creator'}>
                <BusinessEditorContext.Consumer>
                    {businessEditorContext =>
                        <React.Fragment>
                            <OrganizationHeader backClicked={backHandler} closeButtonIcon={closeOutline}>
                                Business Header
                            </OrganizationHeader>
                            <IonContent>
                                <h4>Use 3 photos to create your business header</h4>
                                <BusinessHeaderImages businessEditorContext={businessEditorContext}/>
                                <p>You can always change these later</p>
                                <p className={'skip-line'}>
                                    <IonRouterLink href={'/organization/location-creator'}>
                                        {"Skip For Now >"}
                                    </IonRouterLink>
                                </p>
                            </IonContent>
                            <BottomStickySection>
                                <IonButton
                                    disabled={businessEditorContext.business.featured_images ? (
                                        businessEditorContext.business.featured_images.length !== 3
                                    ) : true}
                                    onClick={() => saveData(
                                        businessEditorContext.dirtyBusiness,
                                        businessEditorContext.updateCompleted)}>
                                    Next
                                </IonButton>
                            </BottomStickySection>
                        </React.Fragment>
                    }
                </BusinessEditorContext.Consumer>
            </BusinessEditorContextProvider>
        </IonPage>
    );
}

export default BusinessHeaderSetup
