import React, {useState} from 'react';
import {IonAlert, IonIcon} from '@ionic/react';
import Subscription from '../../../models/subscription/subscription';
import './index.scss';
import Category from '../../../models/category';
import PaidMembershipDetails from '../PaidMembershipDetails';
import MembershipPlan, {MembershipPlanTypes} from '../../../models/subscription/membership-plan';
import Business from '../../../models/organization/business';


interface LocationCountSelectorProps {
    amount: number,
    setAmount: (amount: number) => void,
}

const LocationCountSelector: React.FC<LocationCountSelectorProps> = ({amount, setAmount}) => {

    const [locationInputShowing, setLocationInputShowing] = useState(false);

    return (
        <div className={'location-count-selector'}>
            <span onClick={() => setAmount(1)} className={amount == 1 ? 'selected' : ''}><span>1</span></span>
            <span onClick={() => setAmount(2)} className={amount == 2 ? 'selected' : ''}><span>2</span></span>
            <span onClick={() => setAmount(3)} className={amount == 3 ? 'selected' : ''}><span>3</span></span>
            <span onClick={() => setAmount(4)} className={amount == 4 ? 'selected' : ''}><span>4</span></span>
            <span onClick={() => setLocationInputShowing(true)} className={amount >= 5 ? 'selected' : ''}><span>Custom</span></span>
            <IonAlert
                isOpen={locationInputShowing}
                onDidDismiss={() => setLocationInputShowing(false)}
                message={'Enter Location Amount'}
                inputs={[
                    {
                        name: 'location_count',
                        type: 'number',
                        min: 5,
                        value: amount >= 5 ? amount : 5,
                    },
                ]}
                buttons={[
                    {
                        text: 'Ok',
                        handler: (value) => {
                            setAmount(value.location_count);
                        }
                    }
                ]}
            />
        </div>
    )
}

export interface SubscriptionEditorData {
    selectedLocationAmount: number,
    membershipPlans: MembershipPlan[]
    business: Business,
    businessCategory?: Category,
    maybeSubscription?: Subscription,
}

interface SubscriptionEditorProps extends SubscriptionEditorData {
    onPlanSelected: (membershipPlan: MembershipPlan, locationCount: number, totalToday: number) => void
    previewMode?: boolean,
}

const SubscriptionEditor: React.FC<SubscriptionEditorProps> = ({previewMode, business, businessCategory, onPlanSelected, selectedLocationAmount, maybeSubscription, membershipPlans}) => {
    const [selectedLocationCount, setSelectedLocationCount] = useState(selectedLocationAmount);

    const [selectedTab, setSelectedTab] = useState( (maybeSubscription ? maybeSubscription.membership_plan_rate.membership_plan.type : 'pro') as MembershipPlanTypes);

    const headline = previewMode ? 'Preview plans' : 'Pick your plan'

    const planSelected = (membershipPlan: MembershipPlan, totalToday: number) => {
        onPlanSelected(membershipPlan, selectedLocationCount, totalToday);
    }

    const monthlyProMembershipPlan = membershipPlans.find(i => i.type === 'pro' && i.duration === 'month' && !i.default);
    const yearlyProMembershipPlan = membershipPlans.find(i => i.type === 'pro' && i.duration === 'year' && !i.default);
    const monthlyBasicMembershipPlan = membershipPlans.find(i => i.type === 'basic' && i.duration === 'month' && !i.default);
    const yearlyBasicMembershipPlan = membershipPlans.find(i => i.type === 'basic' && i.duration === 'year' && !i.default);

    return (
        <div className={'subscription-editor'}>
            <div className={'subscription-intro'}>
                <h3>{headline}</h3>
                <h5>Confirm your number of locations</h5>
                <p>
                    <i>All location must be within {businessCategory?.location_fence_radius ? businessCategory.location_fence_radius : 50} miles of each other</i>
                </p>
            </div>
            <LocationCountSelector
                amount={selectedLocationCount}
                setAmount={setSelectedLocationCount}
            />
            <div className={'tab-bar'}>
                <div className={selectedTab === 'basic' ? 'selected' : ''} onClick={() => setSelectedTab('basic')}>
                    Basic
                </div>
                <div className={selectedTab === 'pro' ? 'selected' : ''} onClick={() => setSelectedTab('pro')}>
                    <IonIcon src={'/assets/main-logo.svg'}/>Pro
                </div>
            </div>
            <div className={'tab-content'}>
                {selectedTab === 'basic' ?
                    <PaidMembershipDetails
                        previewMode={previewMode}
                        business={business}
                        onSubscribe={planSelected}
                        monthlyMembershipPlan={monthlyBasicMembershipPlan}
                        yearlyMembershipPlan={yearlyBasicMembershipPlan}
                        maybeSubscription={maybeSubscription}
                        selectedLocationAmount={selectedLocationCount}
                        tierName={'Basic'}
                        tierDescription={'Keep your business in front of Geddit Local members and get discovered more easily'}
                        hasLogo={false}
                    >
                        <p>The <strong>Basic</strong> plan includes:</p>
                        <ul>
                            <li>Customized <strong>business listing</strong></li>
                            <li>Searchable by <strong>category</strong></li>
                            <li>Searchable by <strong>keyword</strong></li>
                        </ul>
                    </PaidMembershipDetails>
                    :
                    <PaidMembershipDetails
                            previewMode={previewMode}
                            business={business}
                            onSubscribe={planSelected}
                            monthlyMembershipPlan={monthlyProMembershipPlan}
                            yearlyMembershipPlan={yearlyProMembershipPlan}
                            maybeSubscription={maybeSubscription}
                            selectedLocationAmount={selectedLocationCount}
                            tierName={'Pro'}
                            tierDescription={'Connect to the community around you with unlimited access to premium tools'}
                            hasLogo
                    >
                        <p>Everything <strong>Basic</strong> has, plus:</p>
                        <ul>
                            <li>Post <strong>unlimited ads</strong></li>
                            <li>Use <strong>call-to-action links</strong> in posts</li>
                            <li>Add <strong>unlimited events</strong> (COMING SOON)</li>
                        </ul>
                    </PaidMembershipDetails>
                }
            </div>
        </div>
    )
}

export default SubscriptionEditor;
