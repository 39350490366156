import Business, {placeholderBusiness} from '../../models/organization/business';
import Location, {placeholderLocation} from '../../models/location/location';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import MyBusinessContextProvider, {MyBusinessContext, MyBusinessContextState} from './MyBusinessContext';

interface BusinessEditorContextState {
    business: Business,
    dirtyBusiness: Business,
    isBusinessDirty: boolean,
}

export interface BusinessEditorContextConsumerState extends BusinessEditorContextState {
    setBusiness: (business: Business) => void,
    updateCompleted: (business: Business) => void,
}

let persistedState = {
    business: placeholderBusiness(),
    dirtyBusiness: placeholderBusiness(),
} as BusinessEditorContextState;

function createDefaultState(): BusinessEditorContextConsumerState {
    return {
        ...persistedState,
        setBusiness: (business: Business) => {},
        updateCompleted: (business: Business) => {},
    }
}

export const BusinessEditorContext = React.createContext<BusinessEditorContextConsumerState>(createDefaultState());

function createSetBusinessCallback(BusinessEditorContext: BusinessEditorContextState, setBusinessEditorContext: Dispatch<SetStateAction<BusinessEditorContextState>>) {
    return (business: Business) => {

        persistedState = {
            ...BusinessEditorContext,
            isBusinessDirty: true,
            dirtyBusiness: {...business},
        };
        setBusinessEditorContext({...persistedState});
    }
}

/**
 * The callback produced from this will need to be called whenever the pieces of data are updated
 * in order to clear out our temporary data, and also to update the main persistent context
 * @param BusinessEditorContext
 * @param setBusinessEditorContext
 * @param setMyBusiness
 */
function createUpdateCompletedCallback(
    BusinessEditorContext: BusinessEditorContextState,
    setBusinessEditorContext: Dispatch<SetStateAction<BusinessEditorContextState>>,
    setMyBusiness: (business: Business) => void)
{
    return (business: Business) => {

        const constructedBusiness = {
            ...business,
            categories: BusinessEditorContext.dirtyBusiness.categories,
            featured_images: BusinessEditorContext.dirtyBusiness.featured_images,
            locations: BusinessEditorContext.dirtyBusiness.locations
        }

        persistedState = {
            ...BusinessEditorContext,
            business: {...constructedBusiness},
            dirtyBusiness: {...constructedBusiness},
        };

        setMyBusiness({...constructedBusiness});
        setBusinessEditorContext({...persistedState});
    }
}

interface BusinessEditorContextWithBusinessReadyProps {
    myBusinessContext: MyBusinessContextState,
}

const BusinessEditorContextWithBusinessReady: React.FC<BusinessEditorContextWithBusinessReadyProps> = ({myBusinessContext, ...props}) => {

    const [businessEditorContext, setBusinessEditorContext] = useState({
        business: {...myBusinessContext.business},
        dirtyBusiness: {...myBusinessContext.business},
        isBusinessDirty: false,
    });
    /**
     * Updates our local instances to the editing business whenever
     */
    useEffect(() => {
        if (myBusinessContext.business.id != persistedState.business.id) {
            persistedState = {
                business: {...myBusinessContext.business},
                dirtyBusiness: {...myBusinessContext.business},
                isBusinessDirty: false,
            };
            setBusinessEditorContext(persistedState);
        }
    }, [myBusinessContext.business.id]);

    const fullContext = {
        ...persistedState,
        setBusiness: createSetBusinessCallback(persistedState, setBusinessEditorContext),
        updateCompleted: createUpdateCompletedCallback(persistedState, setBusinessEditorContext, myBusinessContext.setBusiness),
    } as BusinessEditorContextConsumerState;

    return (
        <BusinessEditorContext.Provider value={fullContext}>
            {props.children}
        </BusinessEditorContext.Provider>
    )
}

interface BusinessEditorContextProviderProps {
    noLongerEditingRedirectLink?: string
}

/**
 * Allows child components the ability to easily use the information of the current business and location the user is editing
 */
const BusinessEditorContextProvider: React.FC<BusinessEditorContextProviderProps> = ({noLongerEditingRedirectLink, ...props}) => {

    return (
        <MyBusinessContextProvider doneManagingRedirectLink={noLongerEditingRedirectLink}>
            <MyBusinessContext.Consumer>
                {myBusinessContext =>
                    <BusinessEditorContextWithBusinessReady myBusinessContext={myBusinessContext} {...props}/>
                }
            </MyBusinessContext.Consumer>
        </MyBusinessContextProvider>
    )
};

export default BusinessEditorContextProvider;
