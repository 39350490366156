import Post from '../../models/post/post';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

/**
 * The state for this will only be an array of posts
 */
export interface SeenPostsContextConsumerState {
    posts: Post[],
    setPost: (post: Post) => void,
    removePost: (post: Post) => void,
}

const defaultContext : SeenPostsContextConsumerState = {
    posts: [],
    setPost: () => {},
    removePost: () => {}
};

export const SeenPostsContext = React.createContext<SeenPostsContextConsumerState>(defaultContext);

let cachedPosts: Post[] = [];

let seenPostsSubscriptions: {[key: string]: Dispatch<SetStateAction<Post[]>>} = {};

const broadcastPostsUpdate = (posts: Post[]) => {
    cachedPosts = posts;
    Object.keys(seenPostsSubscriptions).forEach(key => {
        seenPostsSubscriptions[key](posts);
    })
}

const SeenPostsContextProvider: React.FC = (props) => {
    const [posts, setPosts] = useState(cachedPosts);
    const [instanceKey, _] = useState(Math.random() + "-" + Date.now());


    useEffect(() => {
        seenPostsSubscriptions[instanceKey] = setPosts;

        return () => {
            delete seenPostsSubscriptions[instanceKey];
        }
    }, []);

    const setPost = (post: Post) => {
        let alreadyExists = false;
        const updated = cachedPosts.map((i, index) => {

            if (i.id == post.id) {
                alreadyExists = true;
            }

            return i.id == post.id ? post : i
        });

        if (!alreadyExists) {
            updated.push(post);
        }

        broadcastPostsUpdate(updated);
    }

    const removePost = (post: Post) => {
        const filteredPosts = cachedPosts.filter(i => i.id !== post.id);
        broadcastPostsUpdate(filteredPosts);
    }

    return (
        <SeenPostsContext.Provider value={{
            setPost,
            removePost,
            posts,
        }}>
            {props.children}
        </SeenPostsContext.Provider>
    )
}

export default SeenPostsContextProvider
