import React from 'react';
import OrganizationCreationResultsPage from '../../../components/OrganizationCreationResultsPage';

interface BusinessRejectionProps {
}

const BusinessRejection: React.FC<BusinessRejectionProps> = () => {

    return (
        <OrganizationCreationResultsPage id={'business-rejection-page'} closeLink={'/home/dashboard/account'} linkText={'Contact Us'} linkURL={'/organization-creator/contact-us'}>
            <h4>We're sorry...</h4>
            <p>Based on the information you've provided, your business does not meet the requirements for participation on Geddit Local. We work exclusively with locally owned and operated businesses with no affiliation to a national chain or a controlling franchise.</p>
            <p>If you want to appeal this decision, please reach out to us directly by following the link to our contact form below.</p>
        </OrganizationCreationResultsPage>
    );
}

export default BusinessRejection
