import React, {PropsWithChildren, useState} from 'react';
import {IonContent, IonIcon, IonItem, IonMenu, useIonRouter} from '@ionic/react';
import './index.scss';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import AccountSwitcher from '../../AccountSwitcher';
import {arrowUndo, search, settings} from 'ionicons/icons';
import { logOut } from '../../../data/persistent/persistent.actions';
import { clearLoadingCount } from '../../../data/session/session.actions';
import {useHistory, useLocation} from 'react-router';
import {connect} from '../../../data/connect';

interface HomeMenuLinkProps {
    url: string,
}

const HomeMenuLink: React.FC<HomeMenuLinkProps> = ({url, children}) => {

    const location = useLocation();

    return (
        <IonItem lines={'none'} routerLink={url} className={location.pathname == url ? 'active' : ''}>
            {children}
        </IonItem>
    )
}

interface DispatchProps {
    logOut: typeof logOut,
    clearLoadingCount: typeof clearLoadingCount,
}

interface HomeDesktopMenuProps extends DispatchProps {}

const HomeDesktopMenu: React.FC<HomeDesktopMenuProps> = ({logOut, clearLoadingCount}) => {

    const navigate = useHistory();

    /**
     * Logs out our user from the app properly
     */
    const handleLogOut = () => {
        logOut();
        clearLoadingCount();
        window.location.pathname = '/welcome/splash'
    }

    return (
        <IonMenu className={'home-desktop-menu'} contentId={'home'} swipeGesture={false}>
            <IonContent>

                <MeContextProvider hideLoadingSpace={true}>
                    <MeContext.Consumer>
                        {meContext => (
                            <AccountSwitcher selectedText={meContext.me.full_name}/>
                        )}
                    </MeContext.Consumer>

                </MeContextProvider>
                <hr/>
                <HomeMenuLink url={'/home/dashboard/feed/nearby'}>
                    <IonIcon src={'/assets/app-icons/browse.svg'}/> Nearby
                </HomeMenuLink>
                <HomeMenuLink url={'/home/dashboard/feed/following'}>
                    <IonIcon src={'/assets/app-icons/star.svg'}/> Following
                </HomeMenuLink>
                <hr/>
                <HomeMenuLink url={'/home/dashboard/explore'}>
                    <IonIcon icon={search}/> Explore
                </HomeMenuLink>
                <HomeMenuLink url={'/home/dashboard/post-creator'}>
                    <IonIcon src={'/assets/app-icons/add.svg'}/> Post
                </HomeMenuLink>
                <HomeMenuLink url={'/home/dashboard/account'}>
                    <IonIcon src={'/assets/app-icons/account.svg'}/> Profile
                </HomeMenuLink>
                <hr/>
                <HomeMenuLink url={'/home/dashboard/account/settings'}>
                    <IonIcon icon={settings}/> Settings
                </HomeMenuLink>
                <IonItem routerLink={"#"} lines={'none'} onClick={handleLogOut}>
                    <IonIcon icon={arrowUndo}/> Log Out
                </IonItem>
            </IonContent>
        </IonMenu>
    )
}

const ConnectedHomeDesktopMenu = connect<{  }, { }, DispatchProps>({
    mapDispatchToProps: ({
        logOut,
        clearLoadingCount,
    }),
    component: HomeDesktopMenu,
});

export default ConnectedHomeDesktopMenu;
