import React from 'react';
import ProfileImage from '../../ProfileImage';
import './index.scss';
import {
    UserFollowsContext,
    UserFollowsContextProvider,
} from '../../../contexts/UserFollowsContext';
import FollowButton from '../FollowButton';
import User from "../../../models/user/user";
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import CanParticipateInForum from '../../../models/can-participate-in-forum';

interface EntityDetailsProps {
    entity?: CanParticipateInForum,
    showFollow?: boolean,
    detailLine?: string,
}

const EntityDetails: React.FC<EntityDetailsProps> = ({entity, showFollow, detailLine}) => {

    return (
        <div className={'entity-details-wrapper'}>
            <div className={'entity-info'}>
                <ProfileImage url={entity && entity.profile_image_url ? entity.profile_image_url : ''} name={entity && entity.full_name ? entity.full_name : ''}/>
                <div className={'entity-details-text'}>
                    <h6>{entity ? entity.full_name : 'Unknown User'}</h6>
                    {detailLine && <p>{detailLine}</p>}
                </div>
            </div>
            {(entity && showFollow && !Object.hasOwn(entity, 'locations')) &&
                <MeContextProvider optional hideLoadingSpace>
                    <MeContext.Consumer>
                        {meContext =>
                            <UserFollowsContextProvider userId={meContext.me.id!}>
                                <UserFollowsContext.Consumer>
                                    {userFollowsContext =>
                                        <FollowButton
                                            relatedId={entity.id!}
                                            relatedType={'user'}
                                            related={entity as User}
                                            userFollowsContext={userFollowsContext}
                                            hideBell
                                        />
                                    }
                                </UserFollowsContext.Consumer>
                            </UserFollowsContextProvider>
                        }
                    </MeContext.Consumer>
                </MeContextProvider>
            }
        </div>
    )
}

export default EntityDetails;
