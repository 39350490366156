import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {IonButton, IonContent, IonLabel, IonPage} from '@ionic/react';
import OrganizationHeader from '../../../components/OrganizationManagement/OrganizationHeader';
import './index.scss';
import BottomStickySection from '../../../components/BottomStickySection';
import BusinessEditorContextProvider, {
    BusinessEditorContext,
} from '../../../contexts/organization/BusinessEditorContext';
import Business from '../../../models/organization/business';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import {closeOutline} from "ionicons/icons";
import { setManagingBusinessId } from '../../../data/persistent/persistent.actions';
import {connect} from "../../../data/connect";
import IndustrySelector from '../../../components/IndustrySelector';
import Category from '../../../models/category';

interface Props {
}

interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessHeaderSetupProps extends Props, DispatchProps  {
}


const MainCategorySelector: React.FC<BusinessHeaderSetupProps> = ({setManagingBusinessId}) => {
    const history = useHistory()

    const backHandler = () => setManagingBusinessId()
    const [industry, setIndustry] = useState<Category|undefined>(undefined);

    const saveData = (business: Business, updateCompleted: (business: Business) => void) => {
        OrganizationRequests.updateBusiness(business, {main_category_id: industry!.id}).then(updatedBusiness => {
            updateCompleted(updatedBusiness)
            history.push('/organization-creator/business-created/' + ( business.approved_at ? 'beta' : 'pending' ))
        }).catch(error => {
            alert('business update error - ' + JSON.stringify(error));
        })
    }

    return (
        <BusinessEditorContextProvider noLongerEditingRedirectLink={'/home/dashboard/account'}>
            <BusinessEditorContext.Consumer>
                {businessEditorContext =>
                    <IonPage className={'main-category-selector'}>
                        <OrganizationHeader backClicked={backHandler} closeButtonIcon={closeOutline}>
                            Business Industry
                        </OrganizationHeader>
                        <IonContent>
                            <h4>What is your primary industry?</h4>
                            <IndustrySelector
                                name={'main_category_id'}
                                placeholder={'Choose Industry'}
                                onChange={category => setIndustry(category)}
                            />
                        </IonContent>
                        <BottomStickySection>
                            <IonButton
                                disabled={industry == undefined}
                                onClick={() => saveData(
                                    businessEditorContext.dirtyBusiness,
                                    businessEditorContext.updateCompleted)}>
                                Save
                            </IonButton>
                        </BottomStickySection>
                    </IonPage>
                }
            </BusinessEditorContext.Consumer>
        </BusinessEditorContextProvider>
    );
}


export default connect<{}, {}, DispatchProps >({
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: MainCategorySelector
});
