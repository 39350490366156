import React from 'react';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../../contexts/organization/MyBusinessContext';
import BusinessInformation from '../../../../components/OrganizationManagement/BusinessInformation';
import './index.scss';
import OrganizationDashboardTabbedPage
    from '../../../../components/OrganizationManagement/OrganizationDashboardTabbedPage';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
import {canFillRole} from '../../../../models/user/user';
import {AvailableRoles} from '../../../../models/role';

const Profile: React.FC = () => {
    return (
        <OrganizationDashboardTabbedPage className={'business-profile'} >
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <MeContextProvider>
                            <MeContext.Consumer>
                                {meContext => (
                                    <BusinessInformation
                                        business={myBusinessContext.business}
                                        displayLocations={true}
                                        canAdd={canFillRole(meContext.me, myBusinessContext.organization, [AvailableRoles.Administrator])}
                                    />
                                )}
                            </MeContext.Consumer>
                        </MeContextProvider>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </OrganizationDashboardTabbedPage>
    );
}

export default Profile
