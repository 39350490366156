import React from 'react';
import {IonContent} from '@ionic/react';
import "./index.scss"

interface ResponsiveContentProps extends React.ComponentProps<typeof IonContent> {
    maxWidth?: string,
}

const ResponsiveContent: React.FC<ResponsiveContentProps> = ({children, className, maxWidth, ...rest}) => {

    return (
        <IonContent className={className + ' responsive-content'}>
            <div className={'responsive-content-wrapper'} style={maxWidth ? {maxWidth} : {}}>
                {children}
            </div>
        </IonContent>
    );
}

export default ResponsiveContent;
