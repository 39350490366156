import React from 'react';
import SimpleInputLocationEditPage from '../../../../components/OrganizationManagement/SimpleInputLocationEditPage';
import {emailRegExp, validateRegexMatch} from "../../../../util/regex";

const Email: React.FC = () => {
    const validateEmail = (value: string ) => {

        if( !validateRegexMatch(emailRegExp, value) ) {
            return 'Invalid email'
        }
        return ''
    }

    return (
        <SimpleInputLocationEditPage
            iconUrl={'email'}
            inputLabel={'Email'}
            inputPlaceholder={'info@yoursite.com'}
            fieldName={'email'}
            title={'Location Email'}
            onCurrentDataSave={validateEmail}
        />
    );
}

export default Email;
