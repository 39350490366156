import React, {useEffect} from 'react';
import './index.scss';
import {IonContent, IonPage} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import {useParams} from 'react-router';

type PaymentReceiptRouteParams = {
    paymentId: string
}

interface PaymentReceiptContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const PaymentReceiptContent: React.FC<PaymentReceiptContentProps> = ({setHeaderProps}) => {

    const {paymentId} = useParams<PaymentReceiptRouteParams>()

    useEffect(() => {
        setHeaderProps({
            children: 'Payment Receipt',
        });
    }, [])

    return (
        <IonContent>
        </IonContent>
    );
}

const PaymentReceipt: React.FC = () => {

    return (
        <IonPage>
            <OrganizationHeaderContext.Consumer>
                {organizationHeaderContext =>
                    <PaymentReceiptContent
                        setHeaderProps={organizationHeaderContext.setSharedProps}
                    />
                }
            </OrganizationHeaderContext.Consumer>
        </IonPage>
    )
}

export default PaymentReceipt;
