import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, FilterProps,
    prepareContextState, SearchProps
} from './BasePaginatedContext';
import Post, {PublisherEndpointType} from '../models/post/post';
import React, {useContext, useEffect, useState} from 'react';
import {number, string} from 'yup';

/**
 * The state interface for our state
 */
export interface TaggedPostsContextState extends BasePaginatedContextState<Post> {}

const defaultState = (): BasePaginatedContextState<Post> => {

    return {
        ...defaultBaseContext(),
        expands: [
            'locations',
            'postLocations',
            'publisher',
        ],
        filter: {
            'postLocations.reference_type': 'location',
            'postLocations.tagged': 1,
        },
        order: {
            "updated_at": 'desc',
        }
    }
}

const cachedStates: { [key: string]: TaggedPostsContextState; } = {};

/**
 * The actual context component
 */
export const TaggedPostsContext = React.createContext<TaggedPostsContextState>(defaultState());

/**
 * The props for the provider with optional locations
 */
export interface TaggedPostsContextProviderProps extends BasePaginatedContextProviderProps {
    taggedId: number,
    includeRemoved?: boolean,
    refresh?: boolean,
    onRefreshComplete?: () => void,
}

// Shares our state setter in order to run updates across all consumers
let setters: any = {};

export const TaggedPostsContextProvider: React.FC<TaggedPostsContextProviderProps> = ({includeRemoved, taggedId, refresh, onRefreshComplete, children}) => {

    const cacheKey = taggedId;
    if (!cachedStates[cacheKey]) {
        cachedStates[cacheKey] = {...defaultState()};
    }

    const [taggedPostsState, setTaggedPostsState] = useState<BasePaginatedContextState<any>>(cachedStates[cacheKey] );

    useEffect(() => {
        if (!setters[cacheKey]) {
            setters[cacheKey] = [];
        }
        setters[cacheKey].push(setTaggedPostsState)
        let params = {} as any
        params["filter[postLocations.reference_id]"] = taggedId
        if (!includeRemoved) {
            params["filter[removed_at]"] = "null"
        }
        prepareContextState(setTaggedPostsState, cachedStates[cacheKey],'/posts', params)
    }, [taggedId]);

    useEffect(() => {
        if (refresh && onRefreshComplete) {
            taggedPostsState.refreshData(false).then(() => onRefreshComplete());
        }
    }, [refresh])

    useEffect(() => {
        cachedStates[cacheKey] = taggedPostsState;
        setters[cacheKey].forEach((setter: any) => {
            setter(taggedPostsState);
        })
    }, [taggedPostsState])

    return (
        <TaggedPostsContext.Provider value={taggedPostsState}>
            {children}
        </TaggedPostsContext.Provider>
    )
}
