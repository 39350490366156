import React from 'react'
import './index.scss';
import {IonAlert} from "@ionic/react";
import Category from "../../models/category";
import {useHistory} from "react-router";

interface LocationOutOfBoundsAlertProps {
	open: boolean,
	onCloseAlert: () => void,
	main_category?: Category,
	swiper?: any
}

const LocationOutOfBoundsAlert: React.FC<LocationOutOfBoundsAlertProps> = ({ main_category, open, onCloseAlert, swiper }) => {
	const history = useHistory();
	const maxRadius = main_category?.location_fence_radius ? main_category.location_fence_radius * 2 : 100

	return (
		<IonAlert
			cssClass={'outOfBoundsAlert'}
			isOpen={open}
			onDidDismiss={() => onCloseAlert()}
			message={'All of the locations in your business must be within ' + maxRadius + ' miles of each other. If you feel that this is in error please get in touch with us.'}
			buttons={[
				{
					text: 'Cancel',
					handler: () => history.goBack()
				},
				{
					text: 'Edit Address',
					handler: () => {
						onCloseAlert()
						if( swiper ) {
							swiper.slidePrev()
						}
					}
				}
			]}
		/>
	)
}

export default LocationOutOfBoundsAlert
