import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from '../../data/connect';

interface StateProps {
    managingBusinessId?: number;
}

interface HomeRouteRedirectProps extends StateProps {
}

const HomeRouteRedirect: React.FC<HomeRouteRedirectProps> = ({ managingBusinessId }) => {
    return (
        <Redirect to={managingBusinessId ? '/organization' : '/home/dashboard'}/>
    )
}

export default connect<{}, StateProps, { }>({
    mapStateToProps: (state) => ({
        managingBusinessId: state.persistent.managingBusinessId
    }),
    component: HomeRouteRedirect
});
