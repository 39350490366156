import React, {useEffect, useState} from 'react';
import Business, {placeholderBusiness} from '../models/organization/business';
import Location, {placeholderLocation} from '../models/location/location';
import OrganizationRequests from '../services/requests/OrganizationRequests';
import LoadingScreen from '../components/LoadingScreen';

let cachedBusinesses = {} as {[key: number]: Business};

/**
 * The structure of the consumer
 */
export interface BusinessLocationContextConsumerState {
    hasLoaded: boolean,
    notFound: boolean,
    business: Business,
    location: Location,
}

let defaultContext = {
    hasLoaded: false,
    notFound: false,
    business: placeholderBusiness(),
    location: placeholderLocation(),
};

export const BusinessLocationContext = React.createContext<BusinessLocationContextConsumerState>(defaultContext);

export interface BusinessLocationContextProviderProps {
    businessId: number,
    locationId: number,
    hideLoadingSpace?: boolean,
}

export const BusinessLocationContextProvider: React.FC<BusinessLocationContextProviderProps> = ({businessId, locationId, hideLoadingSpace, children}) => {
    const [businessLocationState, setBusinessLocationState] = useState(defaultContext);

    const determineStatus = (business: Business) => {
        const location = business.locations?.find(i => i.id == locationId);
        if (location) {
            setBusinessLocationState({
                hasLoaded: true,
                notFound: false,
                business: business,
                location: location,
            });
        } else {
            setBusinessLocationState({
                ...businessLocationState,
                hasLoaded: true,
                notFound: true,
            });
        }
    }

    useEffect(() => {

        let business = cachedBusinesses[businessId] ?? undefined;
        if (business) {
            determineStatus(business)
        } else {
            setBusinessLocationState({
                ...businessLocationState,
                hasLoaded: false,
            });
            OrganizationRequests.getPublicBusiness(businessId).then(business => {
                cachedBusinesses[businessId] = {...business};
                determineStatus(business);
            }).catch(console.error)
        }
    }, [businessId, locationId]);

    return (
        <BusinessLocationContext.Provider value={businessLocationState}>
            <BusinessLocationContext.Consumer>
                {context => {
                    return context.hasLoaded && !context.notFound ? children : (!hideLoadingSpace && <LoadingScreen/>);
                }}
            </BusinessLocationContext.Consumer>
        </BusinessLocationContext.Provider>
    )
}
