import React, {PropsWithChildren, useEffect, useState} from 'react';
import './index.scss';
import Post, {isPostExpired, PublisherEndpointType} from '../../models/post/post';
import {
    IonIcon,
    IonItem,
    IonList,
    IonActionSheet,
    IonToast,
    IonAlert,
    IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent
} from '@ionic/react';
import {chevronDownCircleOutline, ellipsisHorizontal} from 'ionicons/icons';
import PostManagementRequests from '../../services/requests/PostManagementRequests';
import {
    PublishedPostsContextState
} from '../../contexts/PublishedPostsContext';
import PostComments from '../Posts/PostComments';
import CanParticipateInForum from '../../models/can-participate-in-forum';
import Thread from '../../models/user/thread';
import Message from '../../models/user/message';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router';
import LoadingScreen from '../LoadingScreen';
import moment from 'moment';
import PostAgreeLink from "../Posts/PostAgreeLink";
import GooglePlaceLocationDisplay from '../Posts/GooglePlaceLocationDisplay';
import {ellipsisText} from '../../util/strings';


interface UserRecommendationItemProps {
    post: Post,
    setPost: (post: Post) => void,
    onDeletePost: (post: Post) => void,
    postBaseUrl: string,
    editUrl: string,
    commentingEntity: CanParticipateInForum,
    createPostThread: (post: Post, topic: string) => Promise<Thread>,
    createMessage: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
    isMe: boolean,
}

const UserRecommendationItem: React.FC<UserRecommendationItemProps> = ({post, postBaseUrl, editUrl, setPost, onDeletePost, commentingEntity, createPostThread, createMessage, isMe}) => {

    const [optionsShowing, setOptionsShowing] = useState(false);
    // const [copiedToastShowing, setCopiedToastShowing] = useState(false);
    const [confirmDeletionShowing, setConfirmDeletionShowing] = useState(false);

    const postLink = `${postBaseUrl}${post.id}`!;

    const navigate = useHistory();

    const onMenuExpand = (event: any) => {
        event.preventDefault()
        event.stopPropagation();
        setOptionsShowing(true)
    }

    const post_excerpt_length = 160
    const post_content = ellipsisText( post.article_content ?? '', post_excerpt_length, '…');

    const maybeGooglePostLocation = post.post_locations?.find(i => i.reference_type == 'google_place');
    const maybeLocation = post.locations ? post.locations.find(() => true) : null;

    return (
        <IonItem className={'user-recommendation-item'} routerLink={postLink} detail={false}>
            <div className={'user-recommendation-item-content'}>

                <div className={"post-link-header"}>
                    {post.main_image_url &&
                        <div className={'post-preview-thumbnail'}  style={{backgroundImage: `url(${post.main_image_url})`}}/>
                    }
                    <div className={'post-header-details'}>
                        {maybeGooglePostLocation ?
                            <GooglePlaceLocationDisplay postLocation={maybeGooglePostLocation}/> :

                            <div className={"post-location-details"}>
                                <p>
                                    <strong>{maybeLocation?.name}</strong>
                                    <br/>
                                    <small>{maybeLocation?.city}, {maybeLocation?.state}</small>
                                </p>
                            </div>
                        }
                        {post.published_at ? <p className={'posted-time'}>{moment(post.published_at).fromNow()}</p> : null}
                    </div>
                    <div className={'post-preview-content'}>
                        <div className={'post-status-line'}>
                            {isMe ? (
                                <IonIcon onClick={onMenuExpand} icon={ellipsisHorizontal}/>
                            ) : null}
                        </div>
                    </div>
                </div>
                <p className={'post-content'} dangerouslySetInnerHTML={{__html: post_content!}}/>
                <PostAgreeLink post={post}/>
            </div>
            <IonActionSheet
                cssClass={'post-list-action-sheet-options'}
                onDidDismiss={() => setOptionsShowing(false)}
                buttons={[{
                    text: 'Delete',
                    role: 'delete',
                    handler: () => setConfirmDeletionShowing(true),
                // }, {
                //     text: 'Edit',
                //     handler: () => navigate.push(editLink),
                // }, {
                //     text: 'Copy Link',
                //     handler: () => {
                //         Clipboard.write({
                //             string: postUrl,
                //         }).catch(console.error);
                //         setCopiedToastShowing(true);
                //     }
                // },{
                //     text: 'Share to…',
                //     handler: () => {
                //         Share.share({
                //             title: post.title,
                //             text: post.article_content,
                //             url: postUrl,
                //             dialogTitle: 'Share post'
                //         });
                //     }
                },{
                    text: 'Cancel',
                    role: 'cancel',
                }]}
                isOpen={optionsShowing}
            />
            {/*<IonToast*/}
            {/*    isOpen={copiedToastShowing}*/}
            {/*    onDidDismiss={() => setCopiedToastShowing(false)}*/}
            {/*    duration={2000}*/}
            {/*    position={"top"}*/}
            {/*    message={"Link copied to clipboard"}*/}
            {/*/>*/}
            <IonAlert
                isOpen={confirmDeletionShowing}
                onDidDismiss={() => setConfirmDeletionShowing(false)}
                header={"Are you sure?"}
                message={"Deleting this post will immediately remove it from all feeds, and any saved versions in someone's inbox."}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Delete',
                        role: 'delete',
                        handler: () => onDeletePost(post)
                    },
                ]}
            />
        </IonItem>
    )
}


interface UserRecommendationRendererProps {
    postsContext: PublishedPostsContextState
    publisherType: PublisherEndpointType,
    publisherId: number,
    postBaseUrl: string,
    editUrl: string,
    commentingEntity: CanParticipateInForum,
    createPostThread: (post: Post, topic: string) => Promise<Thread>,
    createMessage: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
    isMe: boolean,
}

const UserRecommendationRenderer: React.FC<UserRecommendationRendererProps> = ({postsContext, editUrl, publisherId, publisherType, ...rest}) => {
    const [isDeleteErrorShowing, setIsDeleteErrorShowing] = useState(false);
    const deletePost = (removePost: (model: Post) => void, post: Post) => {
        PostManagementRequests.deletePost(publisherType, publisherId, post).catch(error => {
            setIsDeleteErrorShowing(true)
        });
        removePost(post);
    }

    const posts = postsContext.loadedData
        .filter((post, index, self) => self.findIndex(i => i.id == post.id) === index)
        .sort((a: Post, b: Post) => Date.parse(b.created_at!)- Date.parse(a.created_at!))

    return (
        <React.Fragment>
            <IonList className={'user-recommendation-list'}>
                {posts.map(post =>
                    <UserRecommendationItem
                        key={post.id}
                        post={post}
                        editUrl={editUrl}
                        onDeletePost={post => deletePost(postsContext.removeModel, post)}
                        setPost={postsContext.addModel}
                        {...rest}
                    />
                )}
            </IonList>
            <IonInfiniteScroll
                onIonInfinite={(event: any) => {
                    postsContext.loadNext().then(() => {
                        event.target.complete();
                    })
                }}
                disabled={!postsContext.hasAnotherPage}
            >
                <IonInfiniteScrollContent title={'Loading more'}/>
            </IonInfiniteScroll>
            <IonToast
                isOpen={isDeleteErrorShowing}
                onDidDismiss={() => setIsDeleteErrorShowing(false)}
                duration={2000}
                message={'Error deleting post'}
            />
        </React.Fragment>
    )
}

interface UserRecommendationListProps extends UserRecommendationRendererProps {
    refreshing: boolean,
    onRefresh: () => void,
    isMe: boolean,
}

const UserRecommendationList: React.FC<PropsWithChildren<UserRecommendationListProps>> = ({postsContext, refreshing,  onRefresh, publisherType, publisherId, children, ...rest}) => {

    const [eventTarget, setEventTarget] = useState<any>(undefined);

    const doRefresh = (event: any) => {
        onRefresh();
        setEventTarget(event.detail);
    }

    useEffect(() => {
        if (!refreshing && eventTarget) {
            eventTarget.complete();
            setEventTarget(undefined);
        }
    }, [refreshing])

    return (
        <>
            <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
                <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Pull to refresh"
                    refreshingSpinner="circles"
                    refreshingText="Refreshing..."
                />
            </IonRefresher>
            {postsContext.initialLoadComplete ?
                (postsContext.noResults ? (
                        <>{children}</>
                    ) : (
                        <UserRecommendationRenderer
                            postsContext={postsContext}
                            publisherType={publisherType}
                            publisherId={publisherId}
                            {...rest}
                        />
                    )
                ) : <LoadingScreen/>
            }
        </>
    )
}

export default UserRecommendationList
