import React from 'react';
import BaseLocationEditPage from '../../../../components/OrganizationManagement/BaseLocationEditPage';
import {IonLabel, IonInput, IonItem, IonCheckbox} from '@ionic/react';
import SideBySideComponent from '../../../../components/SideBySide';
import {LocationEditorContextConsumerState} from '../../../../contexts/organization/LocationEditorContext';
import Location from '../../../../models/location/location';
import {stateRegExp, validateRegexMatch, zipRegExp} from "../../../../util/regex";
import './index.scss'

interface AddressFormState {
    address: string,
    city: string,
    state: string,
    zip: string,
    hide_address: boolean,
}

interface AddressFormProps {
    currentData: AddressFormState,
    setCurrentData: (data: AddressFormState) => void
}

const AddressForm: React.FC<AddressFormProps> = ({currentData, setCurrentData}) => {

    const setAddress = (address: string) => {
        setCurrentData({
            ...currentData,
            address: address,
        })
    }

    const setCity = (city: string) => {
        setCurrentData({
            ...currentData,
            city: city,
        })
    }

    const setState = (state: string) => {
        setCurrentData({
            ...currentData,
            state: state.replace( /[^a-zA-Z]/g, ''),
        })
    }

    const setZipCode = (zip: string) => {
        setCurrentData({
            ...currentData,
            zip: zip.replace( /[^\d]/g, ''),
        })
    }

    const setHideAddress = (hideAddress: boolean) => {
        setCurrentData({
            ...currentData,
            hide_address: hideAddress,
        })
    }

    return (
        <div className={'address-form'}>
            <IonLabel>Street Address</IonLabel>
            <IonInput
                value={currentData.address}
                onInput={event => setAddress(event.currentTarget.value as string)}
            />
            <IonLabel>City</IonLabel>
            <IonInput
                value={currentData.city}
                onInput={event => setCity(event.currentTarget.value as string)}
            />
            <SideBySideComponent>
                <div>
                    <IonLabel>State</IonLabel>
                    <IonInput
                        maxlength={2}
                        autocapitalize={'characters'}
                        value={currentData.state}
                        onIonChange={event => setState(event.detail.value as string)}
                    />
                </div>
                <div>
                    <IonLabel>Zip Code</IonLabel>
                    <IonInput
                        maxlength={5}
                        value={currentData.zip}
                        onIonChange={event => setZipCode(event.detail.value as string)}
                    />
                </div>
            </SideBySideComponent>
            <div className={'hide-address-line'}>
                <IonItem lines={'none'}>
                    <IonCheckbox
                        name={'hide_address'}
                        mode={'md'}
                        checked={currentData.hide_address}
                        onIonChange={event => setHideAddress(event.detail.checked)}
                    />
                    <IonLabel>Hide address</IonLabel>
                </IonItem>
                <p><i>Check this box if your business is run online, and you want to hide your address from the public</i></p>
            </div>
        </div>
    );
}

const Address: React.FC = () => {

    function createInitialAddressFormState(locationEditorContext: LocationEditorContextConsumerState): AddressFormState {
        return {
            address: locationEditorContext.dirtyLocation.address ? locationEditorContext.dirtyLocation.address : '',
            city: locationEditorContext.dirtyLocation.city ? locationEditorContext.dirtyLocation.city : '',
            state: locationEditorContext.dirtyLocation.state ? locationEditorContext.dirtyLocation.state : '',
            zip: locationEditorContext.dirtyLocation.zip ? locationEditorContext.dirtyLocation.zip : '',
            hide_address: locationEditorContext.dirtyLocation.hide_address,
        }
    }

    function constructLocation(data: AddressFormState, location: Location): Location {
        return {
            ...location,
            ...data,
        }
    }

    const validateAddress = (value: AddressFormState) => {
        const invalidFields = []

        if( value.address.length === 0 ) {
            invalidFields.push('Street Address')
        }

        if( value.city.length === 0 ) {
            invalidFields.push('City')
        }

        if( !validateRegexMatch(stateRegExp, value.state, true) || value.state.length < 2 ) {
            invalidFields.push('State')
        }

        if( !validateRegexMatch(zipRegExp, value.zip) ) {
            invalidFields.push('Zip Code')
        }

        if( invalidFields.length ) {
            const fieldString = invalidFields.length < 3 ? (
                invalidFields.join(' and ')
            ) : (
                invalidFields.slice(0, invalidFields.length-1).join( ', ' ) + ', and ' + invalidFields.slice(invalidFields.length-1)
            )

            return fieldString + ' ' + (invalidFields.length === 1 ? 'is' : 'are') + ' invalid'
        }

        return ''
    }

    return (
        <BaseLocationEditPage createInitialState={createInitialAddressFormState} constructLocation={constructLocation} title={'Edit Address'} iconUrl={'location'} onCurrentDataSave={validateAddress} required>
            {currentDataContext =>
                <AddressForm
                    currentData={currentDataContext.currentData}
                    setCurrentData={data => currentDataContext.setCurrentData(data)}
                />
            }
        </BaseLocationEditPage>
    );
}

export default Address;
