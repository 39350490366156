import React, {useEffect, useState} from 'react';
import OrganizationDashboardTabbedPage
    from '../../../../../components/OrganizationManagement/OrganizationDashboardTabbedPage';
import {IonButton, useIonViewWillEnter} from '@ionic/react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../../../contexts/organization/MyBusinessContext';
import PostsList from '../../../../../components/PostManagement/PostsList';
import MessagingRequests from '../../../../../services/requests/MessagingRequests';
import {PendingPostsContext, PendingPostsContextProvider} from '../../../../../contexts/PendingPostsContext';
import moment from 'moment';

const NoPosts: React.FC = () => {

    return (
        <div className={'no-posts'}>
            <h4>There are no posts waiting in queue at this time.</h4>
        </div>
    )
}

const Upcoming: React.FC = () => {

    const [refresh, setRefresh] = useState(false);
    const [lastLoad, setLoadLoad] = useState(moment())
    useIonViewWillEnter(() => {
        const fiveMinutesBefore = moment();
        fiveMinutesBefore.set({
            minute: fiveMinutesBefore.minutes() - 5,
        });
        if (lastLoad.isBefore(fiveMinutesBefore)) {
            setRefresh(true);
        }
    })

    useEffect(() => {
        if (refresh) {
            setLoadLoad(moment());
        }
    }, [refresh])

    return (
        <OrganizationDashboardTabbedPage className={'upcoming-activity'}>
            <MyBusinessContextProvider hideLoadingSpace={true}>
                <MyBusinessContext.Consumer>
                    {myBusinessContext =>
                        <PendingPostsContextProvider
                            publisherType={'businesses'}
                            publisherId={myBusinessContext.business.id!}
                            refresh={refresh}
                            onRefreshComplete={() => setRefresh(false)}
                        >
                            <PendingPostsContext.Consumer>
                                {postsContext =>
                                    <PostsList
                                        refreshing={refresh}
                                        onRefresh={() => setRefresh(true)}
                                        postsContext={postsContext}
                                        postBaseUrl={'/organization/dashboard/activity/post/'}
                                        commentingEntity={myBusinessContext.business}
                                        createPostThread={(post, message) =>
                                            MessagingRequests.createBusinessPostThread(myBusinessContext.business, post, message)
                                        }
                                        createMessage={(thread, replyingToId) =>
                                            MessagingRequests.createBusinessMessage(myBusinessContext.business, thread, replyingToId)
                                        }
                                        editUrl={'/organization/post-editor/'}
                                        postInteractionsPageBaseUrl={'/organization/dashboard/activity/post/'}
                                        publisherId={myBusinessContext.business.id!}
                                        publisherType={'businesses'}
                                        onBuildPostStatistics={post => [
                                            {
                                                title: 'Views',
                                                amount: post.view_count ?? 0,
                                            },
                                            {
                                                title: 'Likes',
                                                amount: post.like_count ?? 0,
                                            },
                                            {
                                                title: 'Clicks',
                                                amount: post.click_count ?? 0,
                                            },
                                        ]}
                                    >
                                        <NoPosts/>
                                    </PostsList>
                                }
                            </PendingPostsContext.Consumer>
                        </PendingPostsContextProvider>
                    }
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </OrganizationDashboardTabbedPage>
    );
}

export default Upcoming
