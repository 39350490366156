import React, {useEffect, useState} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {
    IonButton, IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel, IonPage,
    IonRadio,
    IonRadioGroup
} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import OrganizationManager from '../../../models/organization/organization-manager';
import {AvailableRoles, getRoleDescription} from '../../../models/role';
import {
    OrganizationManagerContext,
    OrganizationManagerContextProvider
} from '../../../contexts/organization/OrganizationManagersContext';
import {useHistory} from 'react-router';
import {closeOutline} from 'ionicons/icons';
import UnderlinedInput from '../../../components/GeneralUIElements/UnderlinedInput';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import {emailRegExp, validateRegexMatch} from '../../../util/regex';
import BottomStickySection from '../../../components/BottomStickySection';
import PhoneNumberInput from '../../../components/PhoneNumberInput';


interface OrganizationManagerEditorProps {
    organizationId: number,
    businessName: string,
    organizationManagers: OrganizationManager[],
    addOrganizationManager: (organizationManager: OrganizationManager) => void,
    removeOrganizationManager: (organizationManager: OrganizationManager) => void,
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const UserEditorForm: React.FC<OrganizationManagerEditorProps> = ({organizationId, setHeaderProps, businessName, organizationManagers, addOrganizationManager, removeOrganizationManager}) => {

    type InvitationMethod = 'sms' | 'email';

    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserPhone, setNewUserPhone] = useState('');
    const [selectedRole, setSelectedRole] = useState(0);
    const [invitationMethod, setInvitationMethod] = useState('sms' as InvitationMethod);

    const history = useHistory();

    const createOrganizationManager = () => {
        if (invitationMethod == 'email' && validateRegexMatch(emailRegExp, newUserEmail) ||
            invitationMethod == 'sms' && newUserPhone.length >= 10) {
            OrganizationRequests.createOrganizationManager(
                organizationId,
                selectedRole,
                newUserEmail.length > 0 ? newUserEmail : null,
                newUserPhone.length > 0 ? newUserPhone : null,
            ).then(organizationManager => {
                setNewUserEmail('');
                setSelectedRole(0);
                addOrganizationManager(organizationManager);
                history.goBack();
            })
        } else {
            // TODO show error toast
        }
    }

    useEffect(() => {
        setHeaderProps({
            closeButtonIcon: closeOutline,
        });
    }, [])

    return (
        <IonContent>
            <div>
                <IonRadioGroup
                    className={'via-radio-group'}
                    value={invitationMethod}
                    onIonChange={event => setInvitationMethod(event.detail.value)}
                >
                    <IonCol>
                        <IonLabel>Invite via...</IonLabel>
                    </IonCol>

                    <IonCol>
                        <IonLabel onClick={() => setInvitationMethod('sms')}>SMS</IonLabel>
                        <IonRadio value={'sms'}/>
                    </IonCol>

                    <IonCol>
                        <IonLabel onClick={() => setInvitationMethod('email')}>Email</IonLabel>
                        <IonRadio value={'email'}/>
                    </IonCol>
                </IonRadioGroup>
            </div>
            {invitationMethod == 'sms' ?
                <div>
                    <UnderlinedInput>
                        <PhoneNumberInput
                            name={'phone'}
                            value={newUserPhone}
                            onPhoneNumberChange={value => setNewUserPhone(value)}
                        />
                    </UnderlinedInput>
                    <p>
                        <small>Enter the phone number of the member you would like to add</small>
                    </p>
                </div> :
                <div>
                    <UnderlinedInput>
                        <IonInput
                            placeholder={'Email Address'}
                            type={'email'}
                            onIonChange={e => setNewUserEmail(e.detail.value!)}
                        />
                    </UnderlinedInput>
                    <p>
                        <small>Enter the email address of the member you would like to add</small>
                    </p>
                </div>
            }
            <IonLabel>
                Assign a role
            </IonLabel>
            <IonRadioGroup>
                <IonItem>
                    <IonRadio onClick={() => setSelectedRole(AvailableRoles.Administrator)}/>
                    <IonLabel>
                        Owner
                        <br/>
                        <p><i>{getRoleDescription(AvailableRoles.Administrator)}</i></p>
                    </IonLabel>
                </IonItem>
                <IonItem>
                    <IonRadio onClick={() => setSelectedRole(AvailableRoles.Manager)}/>
                    <IonLabel>
                        Manager
                        <br/>
                        <p><i>{getRoleDescription(AvailableRoles.Manager)}</i></p>
                    </IonLabel>
                </IonItem>
            </IonRadioGroup>
            <BottomStickySection>
                <IonButton
                    disabled={selectedRole == 0}
                    onClick={createOrganizationManager}
                >
                    Submit
                </IonButton>
            </BottomStickySection>
        </IonContent>
    )
}

export const UserEditor: React.FC = () => {

    return (
        <IonPage className={'user-editor-page'}>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <OrganizationManagerContextProvider organizationId={myBusinessContext.organization.id!}>
                            <OrganizationManagerContext.Consumer>
                                {organizationManagerContext => (
                                    <OrganizationHeaderContext.Consumer>
                                        {organizationHeaderContext =>
                                            <UserEditorForm
                                                organizationId={myBusinessContext.business.organization_id}
                                                businessName={myBusinessContext.business.name}
                                                organizationManagers={organizationManagerContext.loadedData}
                                                addOrganizationManager={organizationManagerContext.addModel}
                                                removeOrganizationManager={organizationManagerContext.removeModel}
                                                setHeaderProps={organizationHeaderContext.setSharedProps}
                                            />
                                        }
                                    </OrganizationHeaderContext.Consumer>
                                )}
                            </OrganizationManagerContext.Consumer>
                        </OrganizationManagerContextProvider>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </IonPage>
    );
}
