import {PropsWithChildren} from 'react';
import {setHasRespondedToLocationPrompt, setLastKnownLocation} from '../../data/persistent/persistent.actions';
import {Position} from '@capacitor/geolocation';

/**
 * All of our possible
 */
type LocationStatus
    = 'pending'
    | 'initializing'
    | 'in-settings'
    | 'success'
    | 'failed'
    | 'denied'
    | 'requesting'

export interface GeoLocationContextState {
    location: Position,
    receivedAt: number,
    status: LocationStatus,
}

export const defaultState = {
    location: {} as Position,
    receivedAt: 0,
    status: 'initializing',
} as GeoLocationContextState;

export interface GeoLocationContextProviderOwnProps {
    privacyPolicyURL?: string,
    forceRefresh?: boolean,
    onLocationReceived?: () => void,
    promptPermission?: boolean,
    locationRequired?: boolean,
    onResponseGiven?: (actionTaken: boolean) => void,
}

export interface StateProps {
    hasRespondedToLocationPrompt: boolean,
    lastKnownLocation?: GeoLocationContextState
}

export interface DispatchProps {
    setHasRespondedToLocationPrompt: typeof setHasRespondedToLocationPrompt,
    setLastKnownLocation: typeof setLastKnownLocation,
}

export interface GeoLocationContextProviderProps extends GeoLocationContextProviderOwnProps, StateProps, DispatchProps {}
