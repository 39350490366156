import React, {useState} from 'react';
import {IonList} from '@ionic/react';
import './index.scss';
import Post from "../../models/post/post";
import PostItem from "../MembersPages/PostItem";

export interface BusinessLocationPostListProps {
    posts: Post[],
    baseUrl: string,
}

const BusinessLocationPostList: React.FC<BusinessLocationPostListProps> = ({posts, baseUrl}) => {

    return (
        <IonList className={'location-posts'}>
            {posts
                .filter(i => i.published_at)
                .sort((a, b) => Date.parse(b.published_at!) - Date.parse(a.published_at!))
                .map(post =>
                    <PostItem post={post} key={post.id!} baseUrl={baseUrl}/>
                )
            }
        </IonList>
    )
}

export default BusinessLocationPostList;
