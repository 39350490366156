import React, {useState} from 'react'
import {IonContent, IonPage, IonButton} from '@ionic/react';
import './index.scss';
import BusinessLocationContent, {BusinessLocationContentProps} from '../../../components/BusinessLocationContent';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import ResponsiveContent from '../../../components/ResponsiveContent';
import DummyTabsContent from '../../../components/WelcomeSection/DummyTabsContent';
import NotLoggedInModal from '../../../components/WelcomeSection/NotLoggedInModal';

interface BusinessLocationPageProps extends BusinessLocationContentProps {}

const BusinessLocationPage: React.FC<BusinessLocationPageProps> = ({...props}) => {

	const [notLoggedInModalShowing, setNotLoggedInModalShowing] = useState(false);

	const handleAction = () => {
		setNotLoggedInModalShowing(true)
	}

	return (
		<IonPage className={'business-location-splash-page'}>
			<LoggedOutHeaderComponent hideBackButton={true} className={'hide-in-desktop'}/>
			<DummyTabsContent onLinkClicked={handleAction}>

				<ResponsiveContent>
					<BusinessLocationContent {...props}/>
					<IonButton className={'see-more-button'} onClick={handleAction}>
						See More!
					</IonButton>
				</ResponsiveContent>
				<NotLoggedInModal
					abilityMessage={'follow locations, or like and save posts'}
					isOpen={notLoggedInModalShowing}
					onDidDismiss={() => setNotLoggedInModalShowing(false)}
				/>
			</DummyTabsContent>
		</IonPage>
	)
}

export default BusinessLocationPage
