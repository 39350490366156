import {IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import React from 'react';
import PrivacyPolicyText from '../../../../../components/PrivacyPolicyText';
import {chevronBack} from "ionicons/icons";

const PrivacyPolicy: React.FC = () => {

    return (
        <IonPage>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Privacy Policy
            </LoggedInHeaderComponent>
            <PrivacyPolicyText contactUsUrl={'/home/dashboard/contact-us'}/>
        </IonPage>
    );
}

export default PrivacyPolicy;
