import React, {useEffect, useState} from 'react'
import { Route} from 'react-router';
import {
    createGesture,
    Gesture,
    IonButton,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from '@ionic/react';
import {people, pulse} from 'ionicons/icons';
import './index.scss';
import Profile from './Profile';
import Activity from './Activity';
import Followers from './Followers';
import Shop from './Shop';
import PostInteractions from '../PostInteractions';
import Post from '../Post';
import {Redirect} from 'react-router-dom';
import {
    OrganizationHeaderContext, OrganizationHeaderProps,
} from '../../../components/OrganizationManagement/OrganizationHeader';

interface DashboardContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const DashboardContent: React.FC<DashboardContentProps> = ({setHeaderProps}) => {

    const [gesture, setGesture] = useState<Gesture|undefined>(undefined);

    const homePages = [
        '/organization/dashboard/activity',
        '/organization/dashboard/activity/published',
        '/organization/dashboard/activity/upcoming',
        '/organization/dashboard/followers',
        '/organization/dashboard/profile',
        '/organization/dashboard/shop',
    ];
    const isHome = homePages.indexOf(window.location.pathname) != -1;

    useEffect(() => {
        const tabs = document.getElementsByClassName('organization-tabs-content');
        let gestureInstance = gesture;
        if (!gesture && tabs.length) {
            gestureInstance = createGesture({
                el: tabs[0] as Node,
                threshold: 0,
                gestureName: 'my-gesture',
                gesturePriority: 40.5, // priority of swipe to go back is 40
            });
            setGesture(gestureInstance);
        }

        if (gestureInstance) {
            gestureInstance.enable(isHome);
        }

    }, [window.location.pathname])

    useEffect(() => {
        if (isHome) {
            setHeaderProps({
                isHome: true,
            })
        }
    }, [window.location.pathname, isHome])

    return (
        <IonTabs className={'organization-tabs-content'} >
            <IonRouterOutlet>
                <Route path={`/organization/dashboard/:tab(profile)`} render={() => <Profile/>}/>
                <Route exact path={`/organization/dashboard/:tab(activity)`} render={() => <Activity/>}/>
                <Route path={`/organization/dashboard/:tab(activity)/*`} render={() => <Activity/>}/>
                <Route path={`/organization/dashboard/:tab(followers)`} render={() => <Followers/>}/>
                <Route path={`/organization/dashboard/:tab(shop)`} render={() => <Shop/>}/>
                <Redirect exact path="/organization/dashboard" to="/organization/dashboard/profile" />
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
                <IonTabButton tab='profile' href={`/organization/dashboard/profile`}>
                    <IonIcon src={'/assets/app-icons/feed.svg'} />
                    <IonLabel>Home</IonLabel>
                </IonTabButton>
                <IonTabButton tab='activity' href={`/organization/dashboard/activity`}>
                    <IonIcon icon={pulse} />
                    <IonLabel>Activity</IonLabel>
                </IonTabButton>
                <IonTabButton tab='followers' href={`/organization/dashboard/followers`}>
                    <IonIcon icon={people} />
                    <IonLabel>Followers</IonLabel>
                </IonTabButton>
                {/*<IonTabButton tab='shop' href={`/organization/dashboard/shop`}>*/}
                {/*    <IonIcon src={'/assets/app-icons/storefront.svg'} />*/}
                {/*    <IonLabel>Shop</IonLabel>*/}
                {/*</IonTabButton>*/}
            </IonTabBar>
        </IonTabs>
    )
}

const Dashboard: React.FC = () => {

    return (
        <OrganizationHeaderContext.Consumer>
            {context =>
                <DashboardContent setHeaderProps={context.setSharedProps}/>
            }
        </OrganizationHeaderContext.Consumer>
    )
}

export default Dashboard
