import React from 'react';
import {IonHeader} from '@ionic/react';
import Subscription, {
    calculateDaysToExpiration,
    findCurrentActiveSubscription,
    mostRecentActiveSubscription
} from '../../../models/subscription/subscription';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import Moment from 'react-moment';
import './index.scss';
import moment from 'moment';

interface TrialInformationBannerRendererProps {
    subscriptions: Subscription[],
}

const TrialInformationBannerRenderer: React.FC<TrialInformationBannerRendererProps> = ({subscriptions}) => {

    const currentSubscription = findCurrentActiveSubscription(subscriptions);
    const mostRecentSubscription = mostRecentActiveSubscription(subscriptions);

    if (currentSubscription && currentSubscription.is_trial) {
        const days = calculateDaysToExpiration(currentSubscription)

        if (days < 1) {
            return (
                <IonHeader className={'trial-info-banner'}>
                    Your free trial ends today
                </IonHeader>
            )
        }

        return (
            <IonHeader className={'trial-info-banner'}>
                Your free trial ends in {days} day{days > 1 ? 's' : ''}
            </IonHeader>
        )
    }
    if (!currentSubscription && mostRecentSubscription && mostRecentSubscription.is_trial) {
        return (
            <IonHeader className={'trial-info-banner'}>
                Your free trial has ended
            </IonHeader>
        )
    }

    return (<></>);
}

const TrialInformationBannerInformative: React.FC = ({children}) => {
    return (
        <MyBusinessContextProvider hideLoadingSpace={true}>
            <MyBusinessContext.Consumer>
                {myBusinessContext => (
                    <TrialInformationBannerRenderer
                        subscriptions={myBusinessContext.organization.subscriptions ? myBusinessContext.organization.subscriptions : []}
                    />
                )}
            </MyBusinessContext.Consumer>
        </MyBusinessContextProvider>
    )
}

const TrialInformationBanner: React.FC = ({children}) => {
    return (
        <IonHeader className={'trial-info-banner'}>
            in Beta
        </IonHeader>
    )
}

export default TrialInformationBanner
