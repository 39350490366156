import Post from '../../../../models/post/post';
import React from 'react';
// @ts-ignore
import Location from '../../../models/location/location';
import {MeContextStateConsumer} from '../../../../contexts/MeContext';
import MessagingRequests from '../../../../services/requests/MessagingRequests';
import InteractivePost from '../../../Posts/InteractivePost';
import PostResponse from '../../../../models/post/post-response';
import "./index.scss"
import {
    PublishedPostsContext,
    PublishedPostsContextProvider,
    PublishedPostsContextState
} from '../../../../contexts/PublishedPostsContext';
import {UserFollowsContextState} from '../../../../contexts/UserFollowsContext';

interface PostSlideProps {
    post: Post,
    onPostMetaChanged: (post: Post) => void,
    inFocus: boolean,
    meContext: MeContextStateConsumer,
    userFollowsContext: UserFollowsContextState,
    onRemovePost: () => void,
    onUnfollow: () => void,
    postLocation?: Location
    locationPageUrlRoot: string,
    userPageUrlRoot?: string,
}

const PostSlide: React.FC<PostSlideProps> = ({onRemovePost, postLocation, meContext, userFollowsContext, post, ...rest}) => {

    const onPostResponseUpdated = (postResponse: PostResponse) => {
        if (postResponse.reported) {
            onRemovePost();
        }
    }

    const postRemoved = (publishedPostsContext: PublishedPostsContextState) => {
        onRemovePost();
        publishedPostsContext.removeModel(post);
    }

    return (
        <PublishedPostsContextProvider publisherType={'users'} publisherId={meContext.me.id!}>
            <PublishedPostsContext.Consumer>
                {publishedPostsContext =>
                    <InteractivePost
                        post={post}
                        loggedInUser={meContext.me}
                        participant={meContext.me}
                        createPostThread={MessagingRequests.createPostThread}
                        createMessage={MessagingRequests.createMessage}
                        location={postLocation}
                        onPostResponseChanged={onPostResponseUpdated}
                        userFollowsContext={userFollowsContext}
                        onPostRemoved={() => postRemoved(publishedPostsContext)}
                        {...rest}
                    />
                }
            </PublishedPostsContext.Consumer>
        </PublishedPostsContextProvider>
    )
}

export default PostSlide;
