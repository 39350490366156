import React, {useCallback, useEffect, useRef, useState} from 'react';
import './index.scss';
import {IonButton, IonButtons, IonContent, IonDatetime, IonIcon, IonItem, IonLabel, IonModal} from '@ionic/react';
import Moment from 'react-moment';
import {caretDown} from 'ionicons/icons';
import {format, utcToZonedTime} from 'date-fns-tz';

interface DateDisplayProps {
    date?: string;
}

const DateDisplay: React.FC<DateDisplayProps> = ({date}) => {

    return (
        <React.Fragment>
            <span className={'date'}>
                {date ?
                    <span>
                        <Moment format={'MMM DD, YYYY'}>{date}</Moment> at <Moment format={'h:mm A'}>{date}</Moment>
                    </span> :
                    <span>Now</span>
                }
            </span>
            <IonIcon icon={caretDown}/>
        </React.Fragment>
    )
}

interface ScheduleSectionProps {
    publishedAt?: string,
    onPublishedAtSet: (publishedAt: string) => void,
    expiresAt?: string,
    onExpiresAtSet: (publishedAt: string) => void,
}

const ScheduleSection: React.FC<ScheduleSectionProps> = ({publishedAt, onPublishedAtSet, expiresAt, onExpiresAtSet}) => {

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const now = utcToZonedTime(new Date(), userTimeZone);
    const [isPublishedAtModalOpen, setIsPublishedAtModalOpen] = useState(false);
    const [isExpiresAtModalOpen, setIsExpiresAtModalOpen] = useState(false);

    const styleDateTime = (ref: any) => {
        if (ref) {
            const style = document.createElement("style");
            style.innerText = `
            .calendar-day {
              color: var(--ion-color-primary) !important;
              font-size: 4.375vw !important;
            }
            .calendar-day.calendar-day-active {
              color: var(--ion-color-contrast) !important;
            }
            .calendar-day.calendar-day-active:after {
              opacity: 1;
            }
            
            @media screen and (min-width: 548px) {
              .calendar-day {
                font-size: 24px !important;
              }
            }
            `;
            ref.shadowRoot.appendChild(style)
        }
    }

    const publishedDateTimeRef = useCallback(styleDateTime, [])
    const expiresDateTimeRef = useCallback(styleDateTime, [])

    const getExpiresAt = (): string => {
        if (expiresAt) {
            return expiresAt;
        }

        const defaultExpires = new Date();
        defaultExpires.setDate(defaultExpires.getDate() + 7)

        return defaultExpires.toISOString()
    }

    return (
        <div className={'schedule-section'}>
            <div className={'schedule-title'}>
                <hr/>
                <span>Schedule Your Ad</span>
            </div>
            <IonItem
                lines={'none'}
                onClick={() => setIsPublishedAtModalOpen(true)}
            >
                Start
                <span slot={'end'}>
                    <DateDisplay date={publishedAt}/>
                </span>
            </IonItem>
            <IonItem
                lines={'none'}
                onClick={() => setIsExpiresAtModalOpen(true)}
            >
                End
                <span slot={'end'}>
                    <DateDisplay date={getExpiresAt()}/>
                </span>
            </IonItem>
            <IonModal
                className={'schedule-date-modal'}
                isOpen={isPublishedAtModalOpen}
                onDidDismiss={() => setIsPublishedAtModalOpen(false)}
                initialBreakpoint={0.8}
                breakpoints={[0, 0.8, 1]}
            >
                <IonDatetime
                    ref={publishedDateTimeRef}
                    min={format(now, "yyyy-MM-dd HH:mm:ssXXX", { timeZone: userTimeZone }).replace(" ", "T")}
                    value={format(publishedAt ? new Date(publishedAt) : new Date(), "yyyy-MM-dd HH:mm:ssXXX", { timeZone: userTimeZone }).replace(" ", "T")}
                    onIonChange={event => onPublishedAtSet(event.detail.value! as string)}
                    size={'cover'}
                    minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                />
                <IonButton onClick={() => setIsPublishedAtModalOpen(false)}>
                    Save
                </IonButton>
            </IonModal>
            <IonModal
                className={'schedule-date-modal'}
                isOpen={isExpiresAtModalOpen}
                onDidDismiss={() => setIsExpiresAtModalOpen(false)}
                initialBreakpoint={0.8}
                breakpoints={[0, 0.8, 1]}
            >
                <IonDatetime
                    ref={expiresDateTimeRef}
                    min={format(now, "yyyy-MM-dd HH:mm:ssXXX", { timeZone: userTimeZone }).replace(" ", "T")}
                    value={expiresAt ? format(new Date(expiresAt), "yyyy-MM-dd HH:mm:ssXXX", { timeZone: userTimeZone }).replace(" ", "T") : undefined}
                    onIonChange={event => onExpiresAtSet(event.detail.value! as string)}
                    size={'cover'}
                    minuteValues={"0,5,10,15,20,25,30,35,40,45,50,55"}
                />
                <IonButton onClick={() => setIsExpiresAtModalOpen(false)}>
                    Save
                </IonButton>
            </IonModal>
        </div>
    )
}

export default ScheduleSection;
