import {IonContent, IonItem, IonLabel, IonList, IonPage, IonToggle} from '@ionic/react';
import React, {useState} from 'react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import NotificationToggle from '../../../../../components/Notifications/NotificationToggle';
import {chevronBack} from "ionicons/icons";
import NotificationEnabledStatusContextProvider, {NotificationEnabledStatusContext} from '../../../../../components/Notifications/NotificationEnabledStatusContext';
import MeContextProvider, {MeContext} from '../../../../../contexts/MeContext';
import NotificationsPermissionProvider from '../../../../../components/Notifications/NotificationsPermissionProvider';

const NotificationSettings: React.FC = () => {

    const [refreshStatus, setRefreshStatus] = useState(false);

    return (
        <IonPage>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Notification Settings
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <div>
                        <h3>Push Notifications</h3>
                        <NotificationEnabledStatusContextProvider refresh={refreshStatus} onCheckComplete={() => setRefreshStatus(false)}>
                            <NotificationEnabledStatusContext.Consumer>
                                {statusContext =>
                                    statusContext.enabled ?
                                        <>
                                            <h5>From Geddit Local</h5>
                                            <IonList>
                                                <NotificationToggle label={"Account Updates"} notificationName={"push_notifications_receive_breaking_news"}/>
                                                <MeContext.Consumer>
                                                    {meContext => (
                                                        (meContext.me.organization_managers && meContext.me.organization_managers?.length > 0) &&
                                                            <NotificationToggle label={"New Followers"}
                                                                            notificationName={"push_notifications_receive_new_followers"}/>
                                                    )}
                                                </MeContext.Consumer>
                                                <NotificationToggle label={"New Businesses"} notificationName={"push_notifications_receive_new_locations"}/>
                                            </IonList>
                                            <h5>From Accounts You Follow</h5>
                                            <IonList>
                                                <NotificationToggle label={"New Offers"} notificationName={"push_notifications_receive_business_offers"}/>
                                                <NotificationToggle label={"New Events"} notificationName={"push_notifications_receive_business_events"}/>
                                                <NotificationToggle label={"New Updates"} notificationName={"push_notifications_receive_business_updates"}/>
                                            </IonList>
                                        </> :
                                        <MeContext.Consumer>
                                            {meContext =>
                                                <NotificationsPermissionProvider
                                                    userId={meContext.me.id!}
                                                    onResponseGiven={() => setRefreshStatus(true)}
                                                    forceAsk
                                                    simpleMode
                                                />
                                            }
                                        </MeContext.Consumer>
                                }
                            </NotificationEnabledStatusContext.Consumer>
                        </NotificationEnabledStatusContextProvider>
                    </div>
                    <div>
                        <h3>Email Notifications</h3>
                        <h5>From Geddit Local</h5>
                        <IonList>
                            <NotificationToggle label={"New Businesses"} notificationName={"email_notifications_receive_new_locations"}/>
                            <NotificationToggle label={"Recent Posts"} notificationName={"email_notifications_receive_recent_posts"}/>
                            <MeContext.Consumer>
                                {meContext => (
                                    (meContext.me.organization_managers && meContext.me.organization_managers?.length > 0) &&
                                        <NotificationToggle label={"New Followers"}
                                                        notificationName={"email_notifications_receive_new_followers"}/>
                                )}
                            </MeContext.Consumer>
                        </IonList>
                    </div>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default NotificationSettings;
