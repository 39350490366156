import React from 'react';
import SimpleInputLocationEditPage from '../../../../components/OrganizationManagement/SimpleInputLocationEditPage';
import {urlRegExp, validateRegexMatch} from "../../../../util/regex";
import {addHttpPrefix} from "../../../../util/strings";

const Website: React.FC = () => {
    const validateWebsite = (value: string ) => {
        if(value.indexOf('.') == -1 || value[value.length - 1] == '.') {
            return 'Website address must have a valid domain extension'
        }

        if(!validateRegexMatch(urlRegExp, value)) {
            return 'URL is invalid'
        }
        return ''
    }
    const decorateWebsite = (value: string) => {
        return addHttpPrefix(value)
    }
    return (
        <SimpleInputLocationEditPage
            iconUrl={'website'}
            inputLabel={'Website'}
            inputPlaceholder={'https://'}
            fieldName={'website'}
            title={'Location Website'}
            onSetValue={decorateWebsite}
            onCurrentDataSave={validateWebsite}
        />
    );
}

export default Website;
