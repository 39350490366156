import React, {useEffect, useState} from 'react';
import './index.scss';
import EntityDetails from '../../MembersPages/EntityDetails';
import Thread from '../../../models/user/thread';
import {
    ThreadMessagesContext,
    ThreadMessagesContextProvider,
    ThreadMessagesContextState
} from '../../../contexts/messaging/ThreadMessagesContext';
import MessageList from '../MessageList';
import {IonButton} from '@ionic/react';
import MessageItem from '../MessageItem';
import Message from '../../../models/user/message';

interface DisplayedThreadRepliesProps extends ThreadItemProps {
    contextState: ThreadMessagesContextState,
}

const DisplayedThreadReplies: React.FC<DisplayedThreadRepliesProps> = ({contextState, newMessage, thread, replyingToMessage, onReply}) => {

    const [amountShowing, setAmountShowing] = useState(6);

    useEffect(() => {
        if (newMessage) {
            contextState.addModel(newMessage)
            setAmountShowing(amountShowing + 1)
        }
    }, [newMessage]);


    /**
     * Gets all unique messages
     * @param thread
     * @param loadedMessages
     */
    const allMessages = (thread: Thread, loadedMessages: Message[]): Message[] => {

        const merged = thread.first_message ? [thread.first_message, ...loadedMessages] : [...loadedMessages];

        const unique = merged
            .filter((e, i) => merged.findIndex(a => a.id === e.id) === i)
            .sort((a,b) => Date.parse(a.created_at!) - Date.parse(b.created_at!))

        return unique.slice(0, amountShowing)
    }

    const showMore = () => {
        const newAmount = amountShowing + 5 > contextState.total! ? contextState.total! : amountShowing + 5;
        setAmountShowing(newAmount);
        if (contextState.loadedData.length - newAmount <= 5 && contextState.hasAnotherPage) {
            contextState.loadNext();
        }
    }

    return (
        <React.Fragment>
            <MessageList
                messages={allMessages(thread, contextState.loadedData)}
                onReply={onReply}
                replyingToMessage={replyingToMessage}
            />
            {contextState.total! > amountShowing &&
                <IonButton className={'more-link'} onClick={showMore}>
                    Show More
                </IonButton>
            }
        </React.Fragment>
    )
}

interface ThreadRepliesProps extends ThreadItemProps {
    onHide: () => void,
    replyingToMessage?: Message,
}

const ThreadReplies: React.FC<ThreadRepliesProps> = ({thread, onReply, onHide, newMessage, replyingToMessage}) => {

    return (
        <div className={'thread-replies'}>
            <ThreadMessagesContextProvider threadId={thread.id!}>
                <ThreadMessagesContext.Consumer>
                    {threadMessagesContext =>
                        <DisplayedThreadReplies
                            contextState={threadMessagesContext}
                            thread={thread}
                            onReply={onReply}
                            newMessage={newMessage}
                            replyingToMessage={replyingToMessage}
                        />
                    }
                </ThreadMessagesContext.Consumer>
                <IonButton className={'more-link orange'} onClick={onHide}>
                    Hide Replies
                </IonButton>
            </ThreadMessagesContextProvider>
        </div>
    )
}

interface ThreadItemProps {
    thread: Thread,
    onReply: (replyingTo?: Message) => void,
    newMessage?: Message,
    replyingToThread?: Thread,
    replyingToMessage?: Message,
}

const ThreadItem: React.FC<ThreadItemProps> = ({thread, onReply, newMessage, replyingToMessage, replyingToThread}) => {

    const [replyCount, setReplyCount] = useState(thread.reply_count ?? 0);
    const [viewingReplies, setViewingReplies] = useState(false);

    useEffect(() => {
        if (newMessage) {
            setReplyCount(replyCount + 1)
            setViewingReplies(true)
        }
    }, [newMessage]);
    return (
        <div className={'thread-item ion-padding'}>
            <MessageItem entity={thread.created_by} body={thread.topic} created_at={thread.created_at!} onReply={onReply} isReplying={!replyingToMessage && replyingToThread == thread}>
                <span className={'replies'} onClick={() => setViewingReplies(true)}>
                    &nbsp;&nbsp;&nbsp;{(replyCount) + ( replyCount == 1 ? ' Reply' : ' Replies')}
                </span>
            </MessageItem>
            {viewingReplies &&
                <ThreadReplies
                    onHide={() => setViewingReplies(false)}
                    thread={thread}
                    onReply={onReply}
                    newMessage={newMessage}
                    replyingToMessage={replyingToMessage}
                />
            }
        </div>
    )
}

export default ThreadItem;
