import * as H from 'history';
import {TokenState} from '../data/persistent/persistent.state';

export function navigateOutsideUrl(history: H.History, url: string, tokenData?: TokenState) {
    const slug = url.split('.com').pop();
    if (slug) {
        if (slug.indexOf('/post') === 0 || slug.indexOf('/business') === 0) {
            history.push((tokenData ? '/home/dashboard' : '/welcome') + slug);
        } else if (slug.indexOf('/home/user') === 0) {
            history.push(slug.replace('home', 'home/dashboard'));
        } else if (slug.indexOf('/invite') === 0) {
            history.push(slug.replace('invite', 'welcome/sign-up/invitation-code'));
        } else {
            history.push(slug);
        }
    }
}
