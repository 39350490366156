import React from 'react';
import {IonIcon} from '@ionic/react';
import "./index.scss"
import {arrowForward} from 'ionicons/icons';

interface ResponsiveContentProps extends React.ComponentProps<typeof IonIcon> {
    isValid: boolean,
}

const NextArrow: React.FC<ResponsiveContentProps> = ({children, isValid, ...rest}) => {

    return (
        <IonIcon
            slot={'end'}
            className={'next-arrow' + (isValid ? ' valid' : '')}
            icon={arrowForward}
            {...rest}
        />
    );
}

export default NextArrow;
