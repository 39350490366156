import {IonButton, IonContent, IonIcon, IonItem, IonList, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import React, {useEffect, useState} from 'react';
import './index.scss';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
import User from '../../../../models/user/user';
import Follower from '../../../../models/user/follower';
import {chevronBack, personAdd} from "ionicons/icons";
import {
    UserFollowersContext,
    UserFollowersContextProvider,
    UserFollowersContextState
} from '../../../../contexts/UserFollowersContext';
import EntityDetails from '../../../../components/MembersPages/EntityDetails';


interface FollowersListProps {
    followers: Follower[],
}

const FollowersList: React.FC<FollowersListProps> = ({followers}) => {

    return (
        <IonList>
            {followers
                .filter((follower, index, self) => {
                    return !follower.hidden && follower.user
                        && self.findIndex(i => i.user_id == follower.user_id) === index
                })
                .sort((a,b) => Date.parse(b.created_at!) - Date.parse(a.created_at!))
                .map(follower => (
                <IonItem detail={false} lines={'none'} routerLink={'/home/dashboard/user/' + follower.user_id}>
                    <EntityDetails
                        entity={follower.user!}
                        key={follower.id}
                    />
                </IonItem>
            ))}
        </IonList>
    )
}

const NoFollowers: React.FC = () => (
    <IonContent id={'no-followers'}>
        <h4>You don't have any followers yet!</h4>
        <IonIcon src={'/assets/app-icons/followers.svg'}/>
        <p>
            Invite friends to follow you on Geddit Local so they don't miss out on when you share your local knowledge!
        </p>
        {false && <IonButton>Invite</IonButton>}
    </IonContent>
)

interface FollowersDataProcessorProps {
    userFollowersContext: UserFollowersContextState
}

/**
 * This little guy processes a set of data, and makes sure to sort everything out properly
 * @param userFollowedBusinessesContext
 * @constructor
 */
const FollowersDataProcessor: React.FC<FollowersDataProcessorProps> = ({userFollowersContext}) => {

    useEffect(() => {
        const active = window.location.pathname.indexOf('followers') != -1;

        if (active) {
            userFollowersContext.refreshData(false);
        }

    }, [window.location])

    return (!userFollowersContext.noResults ?
        <FollowersList
            followers={userFollowersContext.loadedData}
        /> : <NoFollowers/>
    )
}

interface FollowersLoaderProps {
    me: User
}

/**
 * This handles loading all follows businesses for a user,
 * and then hands off rendering of said businesses to another functional component
 * @param me
 * @constructor
 */
const FollowersLoader: React.FC<FollowersLoaderProps> = ({me}) => {

    return (
        <UserFollowersContextProvider userId={me.id!}>
            <UserFollowersContext.Consumer>
                {userFollowedBusinessesContext => (
                    <FollowersDataProcessor
                        userFollowersContext={userFollowedBusinessesContext}
                    />
                )}
            </UserFollowersContext.Consumer>
        </UserFollowersContextProvider>
    )
}

/**
 * This is our base component for this page. It simply handles loading the logged in user,
 * and then hands off proper displays to our previously defined elements.
 * @constructor
 */
const Followers: React.FC = () => {

    return (
        <IonPage id={'followers-page'}>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Followers
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <FollowersLoader me={meContext.me}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default Followers;
