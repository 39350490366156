import React, {useEffect, useState} from 'react';
import {PushNotifications} from '@capacitor/push-notifications';
import {Capacitor} from '@capacitor/core';

interface NotificationEnabledStatusContextProviderProps {
    refresh: boolean,
    onCheckComplete: () => void
}

interface NotificationEnabledStatusState {
    enabled: boolean,
}

export const NotificationEnabledStatusContext = React.createContext<NotificationEnabledStatusState>({
    enabled: false,
});

const NotificationEnabledStatusContextProvider: React.FC<NotificationEnabledStatusContextProviderProps> = ({refresh, onCheckComplete, children}) => {

    const [checked, setChecked] = useState(!Capacitor.isNativePlatform());
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            PushNotifications.checkPermissions().then(permissions => {
                setEnabled(permissions.receive == 'granted');
                setChecked(true);
                onCheckComplete();
            })
        }
    }, [refresh]);

    return (
        <NotificationEnabledStatusContext.Provider value={{enabled}}>
            {checked && children}
        </NotificationEnabledStatusContext.Provider>
    )
}

export default NotificationEnabledStatusContextProvider;
