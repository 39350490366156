import React, {ReactNode} from 'react';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from '@ionic/react';
import './index.scss';
import LoadingIndicatorComponent from '../LoadingIndicator';
import {useHistory} from 'react-router';


interface Props {
	className?: string,
	hideBackButton?: boolean
	onBackPressed?: () => void,
	children?: ReactNode
	iconType?: any
	orangeHeader?: boolean
	hideLoading?: boolean,
	rightIcon?: {
		icon: string,
		handler: () => void,
		className?: string,
		mobileOnly?: boolean,
		desktopOnly?: boolean,
	}
}

const LoggedInHeaderComponent: React.FC<Props> = ({ className, iconType, onBackPressed, orangeHeader, hideLoading, hideBackButton, rightIcon, children }) => {
	return (
		<IonHeader className={"logged-in-header ion-no-border " + (orangeHeader ? "orange-header " : '') + className}>
			<IonToolbar>
				<IonButtons slot="start">
					{!hideBackButton && ( onBackPressed ?
						<IonButton onClick={onBackPressed} fill={'clear'}>
							<IonIcon icon={iconType}/>
						</IonButton> :
						<IonBackButton defaultHref={'/home'} icon={iconType} text='' />
					)}
				</IonButtons>
				<IonTitle>{children}</IonTitle>
				<IonButtons slot={'end'}>
					{rightIcon ?
						<IonIcon
							icon={rightIcon.icon}
							className={rightIcon.className + (rightIcon.mobileOnly ? ' hide-in-desktop' : '') + (rightIcon.desktopOnly ? ' hide-in-mobile' : '')}
							onClick={rightIcon.handler}
						/>
						: !hideLoading && <LoadingIndicatorComponent/>
					}
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	)
}

export default LoggedInHeaderComponent
