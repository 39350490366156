import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import OrganizationManager from '../../models/organization/organization-manager';
import LoadingScreen from '../../components/LoadingScreen';

/**
 * The state interface for our state
 */
export interface OrganizationManagersContextState extends BasePaginatedContextState<OrganizationManager> {}

const defaultContext = {
    ...defaultBaseContext(),
    expands: [
        'invitation',
        'user',
    ],
} as OrganizationManagersContextState;

/**
 * The actual context component
 */
export const OrganizationManagerContext = React.createContext<OrganizationManagersContextState>(defaultContext);

export interface OrganizationManagerContextProviderProps extends BasePaginatedContextProviderProps {
    organizationId: number,
    clearCache?: boolean,
    onCacheCleared?: () => void
}

export const OrganizationManagerContextProvider: React.FC<OrganizationManagerContextProviderProps> = ({organizationId, clearCache, onCacheCleared, children}) => {
    const [organizationManagersState, setOrganizationManagersState] = useState(defaultContext);

    useEffect(() => {
        const newContext = prepareContextState(setOrganizationManagersState, {...organizationManagersState},'/organizations/' + organizationId + '/organization-managers');
        if (clearCache && onCacheCleared) {
            newContext.refreshData(false);
            onCacheCleared();
        }
        setOrganizationManagersState({...newContext});
    }, [organizationId, clearCache]);

    return (
        <OrganizationManagerContext.Provider value={organizationManagersState}>
            <OrganizationManagerContext.Consumer>
                {context => context.initialLoadComplete ? children : <LoadingScreen/>}
            </OrganizationManagerContext.Consumer>
        </OrganizationManagerContext.Provider>
    );
};
