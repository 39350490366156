import React from 'react';
import './index.scss';
import {IonIcon} from '@ionic/react';
import {sadOutline} from 'ionicons/icons';


const NetworkError: React.FC = () => {
	return (
		<div className={'network-error'}>
			<h2>Unable to Connect</h2>
			<IonIcon icon={sadOutline}/>
			<p>It looks like there was an issue connecting to the server. Please check your connection and try again.</p>
		</div>
	)
}

export default NetworkError
