import {IonContent, IonPage} from '@ionic/react';
import React from 'react';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import {TokenState} from '../../../data/persistent/persistent.state';
import {connect} from '../../../data/connect';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import './index.scss';
import ContactUsForm from '../../../components/ContactUsForm';
import OrganizationHeader from '../../../components/OrganizationManagement/OrganizationHeader';
import BusinessCreationHeader from '../../../components/BusinessCreationHeader';

interface StateProps {
    tokenData?: TokenState;
}

interface ContactUsProps extends StateProps {
}

const ContactUs: React.FC<ContactUsProps> = ({tokenData}) => {

    return (
        <IonPage className={'contact-us-page'}>
        	<BusinessCreationHeader>
        		Contact Us
        	</BusinessCreationHeader>
        	<IonContent>
                {tokenData ?
                    <MeContextProvider>
                        <MeContext.Consumer>
                            {meContext =>
                                <ContactUsForm
                                    loggedInUser={meContext.me}
                                />
                            }
                        </MeContext.Consumer>
                    </MeContextProvider> :
                    <ContactUsForm/>
                }
            </IonContent>
        </IonPage>
    );
}

export default connect<{ }, StateProps, { }>({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    component: ContactUs
});

