import React, {PropsWithChildren, useState} from 'react';
import './index.scss';
import GoogleLatLngDetailsContextProvider, {GoogleLatLngDetailsContext} from '../Providers/GooglePlaces/GoogleLatLngDetailsContextProvider';
import {findAddressComponent} from '../Providers/GooglePlaces/GlobalGooglePlacesContextProvider';

interface GoogleLocalityDisplayProps {
    latitude: number,
    longitude: number,
}

const GoogleLocalityDisplay: React.FC<GoogleLocalityDisplayProps> = ({ latitude, longitude }) => {

    return (
        <GoogleLatLngDetailsContextProvider latitude={latitude} longitude={longitude} >
            <GoogleLatLngDetailsContext.Consumer>
                {googleLatLngDetails =>
                    findAddressComponent('locality', googleLatLngDetails.result?.address_components ?? [])
                    + ', ' +
                    findAddressComponent('administrative_area_level_1', googleLatLngDetails.result?.address_components ?? [], true)
                }
            </GoogleLatLngDetailsContext.Consumer>
        </GoogleLatLngDetailsContextProvider>
    )
}

export default GoogleLocalityDisplay
