import {IonButton, IonContent, IonIcon, IonInput, IonItem, IonPage, IonRouterLink, useIonRouter} from '@ionic/react';
import React, {KeyboardEvent, useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import LoggedOutHeaderComponent from '../../../../components/WelcomeSection/LoggedOutHeader';
import './index.scss';
import {phoneRegExp} from '../../../../util/regex';
import SignInContextProvider, {
	SignInContext,
	SignInContextStateConsumer,
	SignInData
} from '../../../../contexts/SignIn/SignInContext';
import PhoneNumberInput from '../../../../components/PhoneNumberInput';
import { Link } from 'react-router-dom';
import {useHistory} from 'react-router';
import GeoLocationContextProvider from '../../../../components/GeoLocationContextProvider';
import ResponsiveContent from '../../../../components/ResponsiveContent';
import WelcomeTemplate from '../../../../components/WelcomeSection/WelcomeTemplate';

interface SignInPhoneContentProps {
	signInContext: SignInContextStateConsumer,
}

const SignInPhoneContent : React.FC<SignInPhoneContentProps> = ({signInContext}) => {

	const router = useIonRouter();

	const SignupSchema = Yup.object().shape({
		phone: Yup.string().trim().required('Phone Number is required')
			.matches(phoneRegExp, 'Invalid Phone')
	})

	const submit = (values: SignInData) => {
		signInContext.setData(values);
		router.push('/welcome/sign-in/verification-code');
	}

	const form = useFormik({
		initialValues: {
			phone: signInContext.data.phone,
			phoneInvalid: signInContext.data.phoneInvalid,
		},
		validationSchema: SignupSchema,
		onSubmit: (values) => submit(values)
	})

	const enterPressed = (event: KeyboardEvent) => {
		if (event.keyCode == 13) {
			form.handleSubmit();
		}
	}

	return (
		<div>
			<h4>Sign in with your phone number</h4>
			<IonItem lines={"none"}>
				<IonIcon slot={'start'} src={'/assets/app-icons/phone.svg'} />
				<PhoneNumberInput
					name='phone'
					placeholder='Phone Number'
					value={form.values.phone}
					onPhoneNumberChange={phoneNumber => form.setFieldValue('phone', phoneNumber)}
					onKeyDown={enterPressed}
				/>
			</IonItem>
			{(form.submitCount > 0 && form.errors.phone) ? <p className={'error'}>{form.errors.phone}</p> : null}
			{signInContext.data.phoneInvalid ? <p className={'error'}>phone number not found</p> : null}
			<h5>We'll send you a text with a verification code to sign in.</h5>
			<IonButton disabled={!form.isValid || !form.dirty} onClick={() => form.handleSubmit()}>
				Next{'>>'}
			</IonButton>
		</div>
	)
}

interface SignInPhoneProps { }

const SignInPhone: React.FC<SignInPhoneProps> = () => {

	const cancel = () => {
		window.location.replace('/welcome/splash')
	}

	return (
		<IonPage className={'sign-in-phone-page'}>
			<LoggedOutHeaderComponent backText='Cancel' onBackClick={cancel}/>
			<ResponsiveContent maxWidth={'480px'}>
				<SignInContextProvider>
					<SignInContext.Consumer>
						{signInContext =>
							<SignInPhoneContent signInContext={signInContext}/>
						}
					</SignInContext.Consumer>
				</SignInContextProvider>
			</ResponsiveContent>
		</IonPage>
	)
}

export default SignInPhone
