import {api} from '../api';
import PaymentMethod from '../../models/payment/payment-method';
import {AvailableEntityTypes} from '../../models/entities';

export default class PaymentMethodRequests {

    /**
     * Creates a payment method for us properly
     * @param entityType
     * @param entityId
     * @param paymentMethodData
     */
    static async createPaymentMethod(entityType: AvailableEntityTypes, entityId: number, paymentMethodData: any): Promise<PaymentMethod> {
        const { data } = await api.post('/' + entityType + 's/' + entityId + '/payment-methods ', paymentMethodData);
        return data as PaymentMethod;
    }

    /**
     * Updates a payment method for us
     * @param entityType
     * @param paymentMethod
     * @param updateData
     */
    static async updatePaymentMethod(entityType: AvailableEntityTypes, paymentMethod: PaymentMethod, updateData: any): Promise<PaymentMethod> {
        const { data } = await api.put('/' + entityType + 's/' + paymentMethod.owner_id + '/payment-methods/' + paymentMethod.id, updateData);
        return data as PaymentMethod;
    }

    /**
     * Deletes a payment method!
     * @param entityType
     * @param paymentMethod
     */
    static async deletePaymentMethod(entityType: AvailableEntityTypes, paymentMethod: PaymentMethod) : Promise<any> {
        return api.delete('/' + entityType + 's/' + paymentMethod.owner_id +  '/payment-methods/' + paymentMethod.id)
    }
}
