import React from 'react'
import {IonButton} from '@ionic/react';
import './index.scss';
import UserProfileImageUploader from "../UserProfileImageUploader";
import {MeContextStateConsumer} from "../../contexts/MeContext";
import {useHistory} from "react-router-dom";

interface ProfileIncompleteProps{
	meContext: MeContextStateConsumer,
	onProfileComplete: (value: boolean) => void
}

const ProfileIncomplete: React.FC<ProfileIncompleteProps> = ({meContext, onProfileComplete}) => {
	const imageUploader = React.useRef<HTMLDivElement>(null);

	const onButtonClick = () => {
		if(meContext.me.profile_image_url){
			onProfileComplete(true)
		}
		else {
			imageUploader.current?.click()
		}
	}

	return (
		<div id={'profile-incomplete'}>
			<h4>You need a profile<br/>picture in order to post</h4>
			<UserProfileImageUploader ref={imageUploader} meContext={meContext} showPlus={true} />
			<p>Before you can post, add a friendly picture of yourself to complete your profile. It takes no time at all!</p>
			<IonButton disabled={!meContext || !meContext.me.profile_image_url} onClick={onButtonClick}>Complete</IonButton>
		</div>
	)
}

export default ProfileIncomplete
