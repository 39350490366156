import React, {KeyboardEvent, useEffect, useState} from 'react'

import './index.scss'
import SignUpPageStep from '../../../../components/WelcomeSection/SignUpPageStep'
import {SignUpContext, SignUpContextStateConsumer, SignUpData} from '../../../../contexts/SignIn/SignUpContext';
import {IonIcon, IonInput, IonToast, useIonRouter} from '@ionic/react';
import InvitationTokenRequests from '../../../../services/requests/InvitationTokenRequests';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {arrowForward} from 'ionicons/icons';
import Footnote from "../../../../components/Footnote";
import {Link} from "react-router-dom";
import NextArrow from '../../../../components/WelcomeSection/NextArrow';
import {useParams} from 'react-router';
import LoadingScreen from '../../../../components/LoadingScreen';

interface InvitationCodeFormProps {
    signUpContext: SignUpContextStateConsumer
    routeToken?: string
}

const InvitationCodeForm: React.FC<InvitationCodeFormProps> = ({signUpContext, routeToken}) => {

    const [isErrorShowing, setIsErrorShowing] = useState(false);
    const router = useIonRouter();
    const [checkingRouteToken, setCheckingRouteToken] = useState(!!routeToken)

    const SignUpSchema = Yup.object().shape({
        invitation_token: Yup.string().test(
            'len',
            'Invitation code must be 6 numbers',
            val => val?.length === 6
        ).required('Please enter your verification code'),
    })

    const validateInvitationCode = (signUpData: SignUpData) => {
        InvitationTokenRequests.validateInvite("" + signUpData.invitation_token).then(() => {
            signUpContext.setData({
                ...signUpData,
                invitation_token: "" + signUpData.invitation_token,
            })
            router.push('/welcome/sign-up/name-phone');
        }).catch(error => {
            setIsErrorShowing(true);
            setCheckingRouteToken(false)
        })
    }

    const form = useFormik({
        initialValues: signUpContext.data,
        initialErrors: {invitation_token: ''},
        validationSchema: SignUpSchema,
        onSubmit: validateInvitationCode,
    });

    const enterPressed = (event: KeyboardEvent) => {
        if (event.keyCode == 13) {
            form.handleSubmit();
        }
    }

    useEffect(() => {
        if (routeToken) {
            validateInvitationCode({
                ...signUpContext.data,
                invitation_token: routeToken,
            })
        }
    }, [routeToken])

    return (checkingRouteToken ?
        <LoadingScreen text={'Verifying Invite'}/> :
        <React.Fragment>
            <div className={'verification-code-entry'}>
                <h4>Enter your invitation code</h4>
                <IonInput
                    name={"invitation_token"}
                    type={"text"}
                    inputmode={'numeric'}
                    value={form.values.invitation_token}
                    onIonChange={form.handleChange}
                    maxlength={6}
                    autocomplete={'one-time-code'}
                    onKeyDown={enterPressed}
                />
                <div className={'submit-line'}>
                    {/*This is here for balance*/}
                    <span/>
                    <NextArrow
                        onClick={() => form.handleSubmit()}
                        isValid={form ? form.isValid : false}
                    />
                </div>
                <p className={'error'}>{form.submitCount > 0 && form.errors.invitation_token ? form.errors.invitation_token : ''}</p>
                <IonToast
                    message={"Invalid invitation code"}
                    duration={2000}
                    isOpen={isErrorShowing}
                    onDidDismiss={() => setIsErrorShowing(false)}
                />
            </div>
            <Footnote>
                <Link to='/welcome/contact-us'>Access to Geddit Local is by invitation only at this stage. If you received an invite link, and you are seeing this page, try clicking the invite link again. If you have questions, or if you're interested to get on our wait list, <strong>Contact Us</strong></Link>
            </Footnote>
        </React.Fragment>
    )
}

interface RouteParams {
    token?: string,
}

const InvitationCodeStep: React.FC = () => {

    let {token} = useParams<RouteParams>();

    return (
        <SignUpPageStep className={'invitation-code-step'} bypassSubmit cancelOnBack={false} showArrow={true}>
            <SignUpContext.Consumer>
                {signUpContext =>
                    <InvitationCodeForm signUpContext={signUpContext} routeToken={token}/>
                }
            </SignUpContext.Consumer>
        </SignUpPageStep>
    )
}

export default InvitationCodeStep
