import PostResponse from '../../../models/post/post-response';
import React, {useEffect, useState} from 'react';
import PostSlide from './PostSlide';
import {IonicSlides} from '@ionic/react';
import {NewsFeedSharedProps} from '../interfaces';
import NoPostsSlide from './NoPostsSlide';
import './index.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Swiper as SwiperClass} from 'swiper/types';
import {SeenPostsContextConsumerState} from '../../../contexts/feed/SeenPostsContext';
import Post from '../../../models/post/post';
import {hasMouse, isTouchDevice} from '../../../util/platform';
import {Navigation} from 'swiper';
import {
    UserFollowsContext,
    UserFollowsContextProvider,
    UserFollowsContextState
} from '../../../contexts/UserFollowsContext';

interface NewsFeedSlidesProps extends NewsFeedSharedProps {
    setCanShowSavedTutorial: (canShow: boolean) => void,
    addPostResponse: (postResponse: PostResponse) => void,
    currentPosts: Post[]
    seenPostsContext: SeenPostsContextConsumerState,
    onRemovePost: (post: Post) => void,
    resetIndex: boolean,
    onIndexReset: () => void,
}

const NewsFeedSlides: React.FC<NewsFeedSlidesProps> = ({currentPosts, selectLocation, onRemovePost, seenPostsContext, onPostSeen, skipPost, loadMorePosts, setCanShowSavedTutorial, addPostResponse, inFocus, noPostsSlide, meContext, resetIndex, onIndexReset, ...rest}) => {

    const [swiper, setSwiper] = useState<SwiperClass|undefined>(undefined);
    const [lastIndex, setLastIndex] = useState(0);
    const [currentViewingPost, setCurrentViewingPost] = useState<Post|undefined>(undefined);
    const [unfollowedPosts, setUnfollowedPosts] = useState<Post[]>([])

    const viewingPosts = currentPosts
        .filter(i => {
            if (skipPost) {
                return !skipPost(i)
            }
            return !unfollowedPosts.find(unfollowedPost => i.id == unfollowedPost.id);
        })
        .map(post => {
                const seenPost = seenPostsContext.posts.find(i => i.id == post.id);

                return seenPost ? seenPost : post;
            }
        )

    useEffect(() => {
        if (currentPosts.length && swiper && !swiper.animating) {
            let newIndex = currentViewingPost ? currentPosts.findIndex(i => i.id == currentViewingPost.id) : 0;
            if (newIndex === -1) {
                newIndex = lastIndex;
            }
            postPresented(newIndex);
            swiper.slideTo(newIndex, 0);
        }
    }, [currentPosts.length, swiper])

    useEffect(() => {
        if (resetIndex && swiper) {
            swiper.slideTo(0, 0)
            postPresented(0);
        }
        onIndexReset();
    }, [resetIndex])

    const onSlideChangeTransitionEnd = (swiper: SwiperClass) => {
        postPresented(swiper.activeIndex);
    }

    const removePost = (post: Post) => {
        seenPostsContext.removePost(post)
        onRemovePost(post);
    }

    const postPresented = (activeIndex: number) => {
        if (activeIndex < viewingPosts.length) {
            setLastIndex(activeIndex);
            const post = viewingPosts[activeIndex];
            onPostSeen(post);
            setCurrentViewingPost(post);
        }
        if (viewingPosts.length - activeIndex < 6) {
            loadMorePosts();
        }
    }

    const needsDesktopStyle = hasMouse || !isTouchDevice;

    return (
        <UserFollowsContextProvider userId={meContext.me.id!}>
            <UserFollowsContext.Consumer>
                {userFollowsContext =>
                    <Swiper
                        modules={[...(needsDesktopStyle ? [Navigation] : []),...[IonicSlides]]}
                        onSwiper={setSwiper}
                        onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
                        navigation={needsDesktopStyle}
                        observer
                        observeParents
                        dir={'rtl'}
                    >
                        {viewingPosts.length == 0 &&
                            <SwiperSlide dir={'ltr'}>
                                <NoPostsSlide {...noPostsSlide}/>
                            </SwiperSlide>
                        }
                        {viewingPosts.map((post, index) =>
                            <SwiperSlide
                                key={post.id}
                                dir={'ltr'}
                            >
                                <PostSlide
                                    post={post}
                                    onPostMetaChanged={seenPostsContext.setPost}
                                    inFocus={inFocus && !!currentViewingPost && currentViewingPost.id === post.id}
                                    postLocation={selectLocation(post.locations ?? [])}
                                    onRemovePost={() => removePost(post)}
                                    onUnfollow={() => setUnfollowedPosts([...unfollowedPosts, post])}
                                    meContext={meContext}
                                    userFollowsContext={userFollowsContext}
                                    {...rest}
                                />
                            </SwiperSlide>
                        )}
                    </Swiper>
                }
            </UserFollowsContext.Consumer>
        </UserFollowsContextProvider>
    )
}

export default NewsFeedSlides;
