import React from 'react';
import User from '../../../models/user/user';
import {IonItem} from '@ionic/react';
import EntityDetails from '../EntityDetails';

interface UserItemProps {
    user: User,
    showFollow?: boolean,
    detailLine?: string,
}

const UserItem: React.FC<UserItemProps> = ({user, showFollow, detailLine}) => {

    return (
        <IonItem
            lines={'none'}
            detail={false}
            routerLink={`/home/dashboard/user/${user.id!}`}
            className={'user-item'}
        >
            <EntityDetails entity={user} showFollow={showFollow} detailLine={detailLine}/>
        </IonItem>
    )
}

export default UserItem;
