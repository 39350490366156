import {IonAlert} from '@ionic/react';
import React from 'react';

interface DeletePostConfirmationModalProps extends React.ComponentProps<typeof IonAlert>  {
    onDelete: () => void,
}

const DeletePostConfirmationAlert: React.FC<DeletePostConfirmationModalProps> = ({onDelete, ...props}) => {

    return (
        <IonAlert
            {...props}
            header={"Are you sure?"}
            message={"Deleting this post will immediately remove it from all feeds, and any saved versions in someone's inbox."}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Delete',
                    role: 'delete',
                    handler: onDelete
                },
            ]}
        />
    )
}

export default DeletePostConfirmationAlert;
