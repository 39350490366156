import React, {useEffect, useState} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {IonAlert, IonButton, IonContent, IonItem, IonPage} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import Business from '../../../models/organization/business';
import ProfileImage from '../../../components/ProfileImage';
import {connect} from '../../../data/connect';
import {setManagingBusinessId} from '../../../data/persistent/persistent.actions';
import {useHistory} from 'react-router';
import Organization from '../../../models/organization/organization';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import User from '../../../models/user/user';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';

interface BusinessItemProps {
    managingBusinessId?: number,
    setManagingBusinessId: typeof setManagingBusinessId,
    business: Business,
    me: User,
    setMe: (user: User) => void,
    organization: Organization,
    setOrganization: (organization: Organization) => void,
}

const BusinessItem: React.FC<BusinessItemProps> = ({setManagingBusinessId, business, me, setMe, organization, setOrganization}) => {
    const history = useHistory();
    const [deletePromptShowing, setDeletePromptShowing] = useState(false);

    const businessClicked = () => {
        setManagingBusinessId(business.id);
        history.push('/organization/location-list');
    }

    const runDelete = () => {
        OrganizationRequests.deleteBusiness(business).then(() => {
            const businesses = organization.businesses ?? [];
            const remainingBusiness = businesses.filter(i => i.id != business.id);
            const organizationManagers = me.organization_managers ?? [];
            if (remainingBusiness.length <= 0) {
                setManagingBusinessId(undefined);
                (window.location as any) = '/home';
                setMe({
                    ...me,
                    organization_managers: organizationManagers
                        .filter(organizationManager => organizationManager.organization_id != business.organization_id),
                })
            } else {
                setOrganization({
                    ...organization,
                    businesses: remainingBusiness,
                });
                setMe({
                    ...me,
                    organization_managers: organizationManagers.map(organizationManager => {
                        if (organizationManager.organization_id == business.organization_id) {
                            organizationManager.organization.businesses = remainingBusiness;
                        }

                        return organizationManager;
                    }),
                })
                setManagingBusinessId(remainingBusiness[0].id);
            }
        });
    }

    return (
        <IonItem key={business.id!} lines={'none'}>
            <ProfileImage url={business.logo_url} slot={'start'} onClick={businessClicked} name={business.name}/>
            <div className={'item-content'}  onClick={businessClicked}>
                <h6>
                    {business.name}
                </h6>
                <p>
                    {business.total_locations} Location{business.total_locations != 1 ? 's': ''}
                </p>
            </div>
            <IonButton onClick={() => setDeletePromptShowing(true)} slot={'end'}>
                Delete
            </IonButton>
            <IonAlert
                isOpen={deletePromptShowing}
                onDidDismiss={() => setDeletePromptShowing(false)}
                header={'Are you sure?'}
                subHeader={'By deleting this Business all information pertaining to the business and its locations will be purged from Geddit Local.'}
                buttons={[
                    'Cancel',
                    {
                        text: 'Delete',
                        handler: runDelete,
                    },
                ]}
                />
        </IonItem>
    );
}

interface BusinessesListContentProps extends BusinessesListProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const BusinessesListContent: React.FC<BusinessesListContentProps> = ({setHeaderProps, managingBusinessId, setManagingBusinessId}) => {

    useEffect(() => {
        setHeaderProps({
            children: "Manage Business",
        })
    }, [])

    return (
        <MeContextProvider>
            <MeContext.Consumer>
                {meContext => (
                    <MyBusinessContextProvider>
                        <MyBusinessContext.Consumer>
                            {myBusinessContext => (
                                <React.Fragment>
                                    <h5>Business</h5>
                                    {myBusinessContext.organization.businesses?.map(business => (
                                        <BusinessItem
                                            key={business.id}
                                            managingBusinessId={managingBusinessId}
                                            setManagingBusinessId={setManagingBusinessId}
                                            business={business}
                                            organization={myBusinessContext.organization}
                                            setOrganization={myBusinessContext.setOrganization}
                                            me={meContext.me}
                                            setMe={meContext.setMe}
                                        />
                                    ))}
                                </React.Fragment>
                            )}
                        </MyBusinessContext.Consumer>
                    </MyBusinessContextProvider>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    )
}

interface StateProps {
    managingBusinessId?: number,
}

interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessesListProps extends StateProps, DispatchProps {
}

const BusinessesList: React.FC<BusinessesListProps> = (props) => {

    return (
        <IonPage className={'business-list-page'}>
            <IonContent>
                <OrganizationHeaderContext.Consumer>
                    {organizationHeaderContext =>
                        <BusinessesListContent
                            setHeaderProps={organizationHeaderContext.setSharedProps}
                            {...props}
                        />
                    }
                </OrganizationHeaderContext.Consumer>
            </IonContent>
        </IonPage>
    );
}

export default connect<{ }, StateProps, DispatchProps >({
    mapStateToProps: (state) => ({
        managingBusinessId: state.persistent.managingBusinessId
    }),
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: BusinessesList
});

