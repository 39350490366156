import React, {useState} from 'react';
import ProfileImage from '../../ProfileImage';
import './index.scss';
import User from "../../../models/user/user";
import {
    IonAlert,
    IonButton,
    IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent,
    IonItem,
    IonList,
    useIonViewWillEnter
} from '@ionic/react';
import PostResponse from '../../../models/post/post-response';
import {ellipsisText} from '../../../util/strings';
import {formatTimeAgo} from '../../../util/time';
import Location from '../../../models/location/location';
import UserPostRequests from '../../../services/requests/UserPostRequests';
import {
    UserPostResponsesContext,
    UserPostResponsesContextProvider,
    UserPostResponsesContextState
} from '../../../contexts/UserPostResponsesContext';
import { trashOutline } from 'ionicons/icons';


const NoSavedPosts: React.FC = () => {

    return (
        <div className={'no-saved-posts'}>
            <p>Your inbox is empty</p>
            <IonIcon src={'/assets/app-icons/inbox-empty.svg'} color={'medium'}/>
        </div>
    );
}

const savedPostEllipsisPosition = 75;

interface SavedPostItemProps {
    postResponse: PostResponse,
    onPostResponseArchived: () => void,
}

const SavedPostItem: React.FC<SavedPostItemProps> = ({postResponse, onPostResponseArchived}) => {

    const [archiveConfirmationShowing, setArchiveConfirmationShowing] = useState(false);
    const followed = postResponse.follows ? postResponse.follows.follows :
        (postResponse.post.locations?.length ? postResponse.post.locations[0] : postResponse.post.publisher);

    const description = postResponse.post.article_content ?
        ellipsisText(postResponse.post.article_content, savedPostEllipsisPosition) : '';

    const promptArchive = (event: Event) => {
        event.stopPropagation();
        event.preventDefault();
        setArchiveConfirmationShowing(true)
    }

    return (
        <IonItem
            className={'saved-post-item-content'}
            lines={'none'}
            routerLink={`/home/dashboard/post/${postResponse.post_id}`}
            detail={false}
        >
            <ProfileImage
                url={postResponse.post.main_image_url}
                slot={'start'}
                name={postResponse.post.title ?? ''}
                noBorder
            />
            <div className={'saved-post-item-text'}>
                <div className={'saved-post-item-headline'}>
                    <p>
                        {formatTimeAgo(postResponse.post.published_at!)}
                        <IonButton
                            fill={'clear'}
                            className={'remove-btn hide-in-mobile'}
                            onClick={event => promptArchive(event as any)}
                        >
                            <IonIcon
                                color={'dark'}
                                icon={trashOutline}
                            />
                        </IonButton>
                    </p>
                    {followed && <h2>{followed?.hasOwnProperty('name') ? (followed as Location)?.name : (followed as User).full_name}</h2>}
                </div>
                <h3>{postResponse.post.title}</h3>
                <p>{description}{description.length < savedPostEllipsisPosition ? '' : '…'}</p>
            </div>
            <IonIcon
                slot={'end'}
                icon={trashOutline}
                onClick={event => promptArchive(event as any)}
            />
            <IonAlert
                onDidDismiss={() => setArchiveConfirmationShowing(false)}
                isOpen={archiveConfirmationShowing}
                header={"Delete Post?"}
                buttons={[
                    {
                        text: "Cancel",
                        role: "dismiss",
                    },
                    {
                        text: "Yes",
                        handler: onPostResponseArchived,
                    }
                ]}
            />
        </IonItem>
    )
}

interface SavedPostsProcessor {
    userPostResponseContext: UserPostResponsesContextState,
}

const SavedPostsProcessor: React.FC<SavedPostsProcessor> = ({userPostResponseContext}) => {

    const archivePostResponse = (postResponse: PostResponse, removePostResponse: (postResponse: PostResponse) => void) => {
        UserPostRequests.archivePostResponse(postResponse).catch(console.error);
        removePostResponse(postResponse);
    }

    const filterPostResponses = (data: PostResponse[]): PostResponse[] => {
        return data
            .filter((postResponse, index, self) => {
                return postResponse.saved && !postResponse.archived &&
                    self.findIndex(i => i.post_id == postResponse.post_id) === index;
            })
            .sort((a, b) => {
                return b.updated_at && a.updated_at ? Date.parse(b.updated_at!)- Date.parse(a.updated_at!) : 0;
            })
    }
    useIonViewWillEnter(() => {
        userPostResponseContext.refreshData(false).catch(console.error)
    });

    return (userPostResponseContext.noResults ?
        <NoSavedPosts/> :
        <div className={'saved-posts-content-list'}>
            <IonList>
                {filterPostResponses(userPostResponseContext.loadedData).map(postResponse =>
                    <SavedPostItem
                        key={postResponse.id}
                        postResponse={postResponse}
                        onPostResponseArchived={
                            () => archivePostResponse(postResponse, userPostResponseContext.removeModel)
                        }
                    />
                )}
            </IonList>
            <IonInfiniteScroll
                threshold="100px"
                disabled={!userPostResponseContext.hasAnotherPage}
                onIonInfinite={(event:any) => {
                    userPostResponseContext.loadNext().then(() => {
                        event.target.complete();
                    })
                }}
            >
                <IonInfiniteScrollContent
                    loadingText="Loading...">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>
        </div>
    )
}

interface SavedPostsContentProps {
    userId: number,
    searchTerm?: string,
}

const SavedPosts: React.FC<SavedPostsContentProps> = ({userId, searchTerm}) => {

    return (
        <UserPostResponsesContextProvider
            searchText={searchTerm}
            userId={userId}
        >
            <UserPostResponsesContext.Consumer>
                {userPostResponseContext => <SavedPostsProcessor userPostResponseContext={userPostResponseContext}/>}
            </UserPostResponsesContext.Consumer>
        </UserPostResponsesContextProvider>
    )
}

export default SavedPosts
