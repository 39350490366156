import React, {useEffect, useState} from 'react';
import { useFormik } from 'formik';
import {IonButton, IonContent, IonIcon, IonInput, IonPage, useIonRouter} from '@ionic/react';
import * as Yup from 'yup';

import LoggedOutHeaderComponent from '../../components/WelcomeSection/LoggedOutHeader';
import './index.scss';
import {connect} from '../../data/connect';
import OrganizationRequests from '../../services/requests/OrganizationRequests';
import OrganizationManager from '../../models/organization/organization-manager';
import SideBySideComponent from '../../components/SideBySide';
import {AvailableRoles, getRoleDescription} from '../../models/role';
import {TokenState} from '../../data/persistent/persistent.state';
import ResponsiveContent from '../../components/ResponsiveContent';
import NextArrow from '../../components/WelcomeSection/NextArrow';
import Organization from '../../models/organization/organization';
import {Capacitor} from '@capacitor/core';
import {setManagingBusinessId} from '../../data/persistent/persistent.actions';
import {isAndroid, isIOS} from '../../util/platform';
import {APP_STORE_URL, PLAY_STORE_URL} from '../../util/const';
import {useHistory, useParams} from 'react-router';

interface StateProps {
	tokenData?: TokenState;
	hasRespondedToNotificationPrompt?: boolean;
}

type RouteParams = {
	token: string
}

interface DispatchProps {
	setManagingBusinessId: typeof setManagingBusinessId,
}

interface AcceptInvitationProps extends StateProps, DispatchProps {
}

interface AcceptInvitationData {
	verification_code: string,
}

const AcceptInvitation: React.FC<AcceptInvitationProps> = ({tokenData, hasRespondedToNotificationPrompt, setManagingBusinessId}) => {

	const {token} = useParams<RouteParams>();

	let [acceptedOrganizationManager, setAcceptedOrganizationManager] = useState<OrganizationManager|undefined>(undefined)
	const navigate = useHistory();

	const VerificationCodeSchema = Yup.object().shape({
		verification_code: Yup.number().test(
			'len',
			'Must be exactly 6 characters',
			val => val?.toString().length === 6
		).required('Please enter your verification code'),
	})

	const form = useFormik({
		initialValues: {verification_code: ''},
		initialErrors: {verification_code: ''},
		validationSchema: VerificationCodeSchema,
		onSubmit: (values) => submit(values)
	});

	const goToLogin = (url: string) => {
		localStorage.setItem('login_redirect', window.location.pathname);
		navigate.push(url);
	}

	const submit = async (values: AcceptInvitationData) => {
		const verificationCode = ('' + values.verification_code!).trim();
		OrganizationRequests.acceptInvitation((token!).trim(), verificationCode).then(organizationManager => {

			OrganizationRequests.getMyOrganization(organizationManager.organization_id).then(organzation => {
				if (organzation.businesses && organzation.businesses.length) {
					setManagingBusinessId(organzation.businesses[0].id);
				}
				setAcceptedOrganizationManager(organizationManager);
			})
		}).catch((error) => {
			form.setErrors({
				verification_code: 'Please double check your verification code.',
			});
		});
	}

	const goToMyAccount = () => {
		window.location = ( hasRespondedToNotificationPrompt || !Capacitor.isNativePlatform() ? '/home/dashboard/account' : '/welcome/notifications') as any;
	}

	const goToOrganization = () => {
		if (!Capacitor.isNativePlatform()) {
			if (isIOS) {
				window.location = APP_STORE_URL as any;
				return;
			} else if (isAndroid) {
				window.location = PLAY_STORE_URL as any;
				return;
			}
		}
		window.location = '/organization' as any;
	}

	const getRoleDetails = (role: AvailableRoles): string => {
		if (role == AvailableRoles.Administrator) {
			return 'Billing, the ability to Edit/Delete Users, access to Activity Results, the option to Edit Location Details, and the ability to Post Ads.';
		}

		return 'Activity Results, the option to Edit Location Details, and the Ability to Post Ads.';
	}

	return (
		<IonPage className={'accept-invitation-page'}>
			{acceptedOrganizationManager ?
				<ResponsiveContent>
					<div className={'invitation-accepted'}>
						<h1>Congratulations!</h1>
						<p>You now have {acceptedOrganizationManager.role_id == AvailableRoles.Administrator ? 'admin' : 'manager'} privileges for {acceptedOrganizationManager.organization.name}. This gives you access to {getRoleDetails(acceptedOrganizationManager.role_id)}</p>
						<SideBySideComponent>
							<img src={'/assets/invitation-accepted.png'}/>
							<p>
								<i>Tap the drop down arrow next to your name on your Account page to switch between your personal account and your business account.</i>
							</p>
						</SideBySideComponent>
						{Capacitor.isNativePlatform() ?
							<IonButton onClick={goToMyAccount}>
								Go To My Account
							</IonButton> :
							<div>
								<h2>What's next?</h2>
								<p>Geddit Local is optimized for use as a mobile application. Download the app on your phone or tablet to get the best experience.</p>
								<IonButton onClick={goToOrganization}>
									Okay, Got it!
								</IonButton>
							</div>
						}
					</div>
				</ResponsiveContent> :
				<React.Fragment>
					<LoggedOutHeaderComponent backText='Cancel' onBackClick={() => window.location.href = '/'} />
					<ResponsiveContent maxWidth={'480px'}>
						{tokenData ?
							<div>
								<h1>Accept Your Invitation</h1>
								<p>
									Please enter the verification code that was included with your invitation
								</p>
								<IonInput
									name={"verification_code"}
									type={"number"}
									inputmode={'numeric'}
									value={form.values.verification_code}
									onIonChange={form.handleChange}
									maxlength={6}
									autocomplete={'one-time-code'}
								/>
								{form.touched.verification_code && form.errors.verification_code  && <p className={'error'}>{form.errors.verification_code}</p>}
								<div className={'submit-line'}>
									<NextArrow
										onClick={() => form.handleSubmit()}
										isValid={form ? form.isValid : false}
									/>
								</div>
							</div> :
							<div className={'not-logged-in'}>
								<h2>Welcome to Geddit Local!</h2>
								<p>Before you can accept your invitation, you must be signed into an existing account.</p>
								<IonButton
									onClick={() => goToLogin('/welcome/sign-up/name-phone')}
								>
									Create Account
								</IonButton>
								<IonButton
									onClick={() => goToLogin('/welcome/sign-in/phone')}
								>
									Sign In
								</IonButton>
							</div>
						}
					</ResponsiveContent>
				</React.Fragment>
			}
		</IonPage>
	)
}

export default connect<{ }, StateProps, DispatchProps >({
	mapDispatchToProps: ({
		setManagingBusinessId,
	}),
	mapStateToProps: (state) => ({
		tokenData: state.persistent.tokenData,
		hasRespondedToNotificationPrompt: state.persistent.hasRespondedToNotificationPrompt,
	}),
	component: AcceptInvitation
});
