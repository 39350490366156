import React, {useContext, useState} from 'react';
import ReactDOM from "react-dom";
import User, {canUserCreateBusiness} from '../../models/user/user';
import MeContextProvider, {MeContext} from '../../contexts/MeContext';
import {IonActionSheet, IonIcon, IonItem, IonList, NavContext} from '@ionic/react';
import ProfileImage from '../ProfileImage';
import './index.scss';
import {add, chevronDownOutline} from 'ionicons/icons';
import {connect} from '../../data/connect';
import Business from '../../models/organization/business';
import { setManagingBusinessId } from '../../data/persistent/persistent.actions';
import {useHistory} from 'react-router';
import {Capacitor} from '@capacitor/core';

interface OptionsProps {
    me: User,
    changeManagingBusinessId: (businessId?: number) => void,
    managingBusinessId?: number,
    newBusiness: () => void,
    dismiss: () => void,
}

/**
 * This puts together the options for the account switcher
 * @param history
 * @param me
 * @param managingBusinessId
 * @constructor
 */
const Options: React.FC<OptionsProps> = ({me, changeManagingBusinessId, managingBusinessId, dismiss, newBusiness}) => {

    const {navigate} = useContext(NavContext);

    const businesses: Business[] = [];
    me.organization_managers?.forEach(organizationManager => {
        organizationManager.organization.businesses?.forEach(business => {
            businesses.push(business);
        });
    });
    const onBusinessClick = (business: Business) => {
        (business.approved_at || canUserCreateBusiness(me)) ?
            changeManagingBusinessId(business.id) : navigate('/organization-creator/business-created/pending')
    }

    const inOrganizationSection = () => {
        return window.location.href.indexOf('/organization/') != -1
    }

    return (
        <IonList class={'account-switcher-options'} lines={'full'}>
            <IonItem onClick={inOrganizationSection() ? () => changeManagingBusinessId() : dismiss} key={'me'}>
                <ProfileImage name={me.full_name} slot={'start'} url={me.profile_image_url}/>
                {me.full_name}
                {(managingBusinessId && inOrganizationSection()) ? '' : <IonIcon class={'checkmark'} src={'/assets/app-icons/rewards.svg'} slot={'end'}/>}
            </IonItem>
            {businesses?.map(business => (
                <IonItem onClick={() => onBusinessClick(business)} key={business.id}>
                    <ProfileImage name={business.name} slot={'start'} url={business.logo_url}/>
                    {business.name}
                    {(managingBusinessId == business.id && inOrganizationSection()) ? <IonIcon class={'checkmark'} src={'/assets/app-icons/rewards.svg'} slot={'end'}/> : ''}
                </IonItem>
            ))}
            <IonItem class={'add-business-button'} key={'add'} onClick={newBusiness} detail={false}>
                <IonIcon icon={add} slot={'start'}/>
                Request a Business Account
            </IonItem>
        </IonList>
    );
}

/**
 * This is a major hack in order to inject react components into the action sheet.
 * If this https://github.com/ionic-team/ionic-framework/issues/18067 is closed, then this can be removed,
 * and replaced with the standard action sheet props
 * @param changeManagingBusinessId
 * @param me
 * @param managingBusinessId
 * @param newBusiness
 */
function buildOptions(changeManagingBusinessId: (organizationId?: number) => void, newBusiness: () => void,  dismiss: () => void, me: User, managingBusinessId?: number) {
    const actionSheetGroup = document.getElementById('user-selector-action-sheet')?.querySelector('.action-sheet-group') as HTMLElement;

    ReactDOM.render(
        <Options
            me={me}
            changeManagingBusinessId={changeManagingBusinessId}
            managingBusinessId={managingBusinessId}
            newBusiness={newBusiness}
            dismiss={dismiss}
        />
    , actionSheetGroup);
}

interface OwnProps {
    selectedText: string,
}

interface StateProps {
    managingBusinessId?: number;
}

interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface AccountSwitcherProps extends OwnProps, StateProps, DispatchProps {
}

/**
 * Lets the user change their
 * @param children
 * @param managingBusinessId
 * @param setManagingBusinessId
 * @constructor
 */
const AccountSwitcher: React.FC<AccountSwitcherProps> = ({selectedText, managingBusinessId, setManagingBusinessId}) => {
    const [accountSwitchingDrawerOpen, setAccountSwitchingDrawerOpen] = useState(false);
    const history = useHistory();

    let isLongText = false;
    if (selectedText.length > 18) {
        isLongText = true;
        selectedText = selectedText.substr(0, 18).trim();
    }

    const changeManagingBusinessId = (businessId?: number) => {
        setAccountSwitchingDrawerOpen(false);
        setManagingBusinessId(businessId);
        // We need to interact directly with the window here in order to essentially reload the page to start with a fresh navigation stack
        window.location.replace(businessId == null ? '/home/dashboard/account' : '/organization');
    }


    const newBusiness = () => {
        setAccountSwitchingDrawerOpen(false);
        history.push('/organization-creator/qualifying-guidelines')
    }

    return (
        <MeContextProvider hideLoadingSpace={true}>
            <MeContext.Consumer>
                {meContext => (
                    <span className={'account-switcher'} onClick={() => setAccountSwitchingDrawerOpen(true)}>
                        {selectedText}{isLongText ? '…' : ''} <IonIcon icon={chevronDownOutline}/>
                        <IonActionSheet
                            id={'user-selector-action-sheet'}
                            isOpen={accountSwitchingDrawerOpen}
                            onWillPresent={() => buildOptions(changeManagingBusinessId, newBusiness, () => setAccountSwitchingDrawerOpen(false), meContext.me, managingBusinessId)}
                            onDidDismiss={() => setAccountSwitchingDrawerOpen(false)}
                        />
                    </span>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    );
}

export default connect<OwnProps, StateProps, DispatchProps >({
    mapStateToProps: (state) => ({
        managingBusinessId: state.persistent.managingBusinessId
    }),
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: AccountSwitcher
});
