import React from 'react';
import './index.scss';

interface ColorSquareProps {
    active: boolean,
    color: string,
    onClick: () => void
}

const ColorSquare: React.FC<ColorSquareProps> = ({active, color, onClick}) => {
    console.log('color', color)
    const classes = 'color-square' + (
        color == '#FFFFFF' ? ' white' : ''
    ) + (
        active ? ' active' : ''
    )
    return <div className={classes} onClick={onClick} style={{background: color}}/>
}

interface ColorSelectorProps {
    label: string,
    activeColor?: string,
    colorOptions: string[],
    onColorSet: (color: string) => void
}

const ColorSelector: React.FC<ColorSelectorProps> = ({label, activeColor, colorOptions, onColorSet}) => {
    return (
        <div className={'color-selector'}>
            <p>{label}</p>
            <div className={'color-squares'}>
                {colorOptions.map((color) => (
                    <ColorSquare
                        key={color}
                        active={activeColor == color}
                        color={color}
                        onClick={() => onColorSet(color)}
                    />
                ))}
            </div>
        </div>
    )
}

export default ColorSelector;
