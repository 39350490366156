import {IonContent, IonList, IonPage} from '@ionic/react';
import React from 'react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import NotificationToggle from '../../../../../components/Notifications/NotificationToggle';
import {chevronBack} from "ionicons/icons";

const PrivacySettings: React.FC = () => {

    return (
        <IonPage>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Privacy Settings
            </LoggedInHeaderComponent>
            <IonContent>
                <h5>My Account</h5>
                <IonList>
                    <NotificationToggle label={"Allow others to find me"} notificationName={"allow_users_to_find_me"}/>
                    <NotificationToggle label={"Allow others to follow me"} notificationName={"allow_users_to_add_me"}/>
                </IonList>
            </IonContent>
        </IonPage>
    );
}

export default PrivacySettings;
