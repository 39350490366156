import React, {useEffect, useState} from 'react';
import './index.scss';
import MyBusinessContextProvider, {
    MyBusinessContext,
    MyBusinessContextState
} from '../../../contexts/organization/MyBusinessContext';
import {IonAlert, IonButton, IonContent, IonItem, IonPage, useIonRouter} from '@ionic/react';
import {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import Location from '../../../models/location/location';
import ProfileImage from '../../../components/ProfileImage';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import Business from '../../../models/organization/business';
import Organization from '../../../models/organization/organization';

interface LocationItemProps {
    location: Location,
    business: Business,
    setBusiness: (business: Business) => void,
    organization: Organization,
    setOrganization: (organization: Organization) => void,
}

const LocationItem: React.FC<LocationItemProps> = ({location, business, setBusiness, organization, setOrganization}) => {
    const router = useIonRouter();
    const [deletePromptShowing, setDeletePromptShowing] = useState(false);

    const businessClicked = () => {
        router.push('/organization/location-editor/' + location.id);
    }

    const runDelete = () => {
        OrganizationRequests.deleteLocation(location).then(() => {
            const updatedBusiness = {
                ...business,
                locations: business.locations?.filter(i => i.id !== location.id),
                total_locations: business.total_locations - 1,
            }
            setBusiness({...updatedBusiness});
            setOrganization({
                ...organization,
                businesses: organization.businesses?.map(i => i.id == updatedBusiness.id ? updatedBusiness : i),
            })
        });
    }

    return (
        <IonItem lines={'none'}>
            <ProfileImage url={location.logo} slot={'start'} onClick={businessClicked} name={location.name}/>
            <div className={'item-content'} onClick={businessClicked}>
                <h6>
                    {location.name}
                </h6>
                <p>
                    {location.address} {location.city} {location.state} {location.zip}
                </p>
            </div>
            <IonButton onClick={() => setDeletePromptShowing(true)} slot={'end'}>Delete</IonButton>
            <IonAlert
                isOpen={deletePromptShowing}
                onDidDismiss={() => setDeletePromptShowing(false)}
                header={'Are you sure?'}
                subHeader={'By deleting this location all information pertaining to the location will be purged from Geddit Local.'}
                buttons={[
                    'Cancel',
                    {
                        text: 'Delete',
                        handler: runDelete,
                    },
                ]}
            />
        </IonItem>
    );
}

interface LocationsListContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
    myBusinessContext: MyBusinessContextState,
}

const LocationsListContent: React.FC<LocationsListContentProps> = ({setHeaderProps, myBusinessContext}) => {

    useEffect(() => {
        setHeaderProps({
            children: "Manage Business",
        });
    }, [])

    return (
        <React.Fragment>
            {myBusinessContext.business.locations?.map(location => (
                <LocationItem
                    key={location.id}
                    location={location}
                    business={myBusinessContext.business}
                    setBusiness={myBusinessContext.setBusiness}
                    organization={myBusinessContext.organization}
                    setOrganization={myBusinessContext.setOrganization}
                />
            ))}
        </React.Fragment>
    );
}

const LocationsList: React.FC = () => {

    return (
        <IonPage className={'location-list-page'}>
            <IonContent>
                <MyBusinessContextProvider>
                    <MyBusinessContext.Consumer>
                        {myBusinessContext =>
                            <OrganizationHeaderContext.Consumer>
                                {organizationHeaderContext =>
                                    <LocationsListContent
                                        setHeaderProps={organizationHeaderContext.setSharedProps}
                                        myBusinessContext={myBusinessContext}
                                    />
                                }
                            </OrganizationHeaderContext.Consumer>
                        }
                    </MyBusinessContext.Consumer>
                </MyBusinessContextProvider>
            </IonContent>
        </IonPage>

    )
}

export default LocationsList;
