export default interface SessionState {
    loadingCount: number;
    sharedPostInit: boolean,
    notificationPostInit: boolean,
}

export const initialSessionState: SessionState = {
    loadingCount: 0,
    sharedPostInit: false,
    notificationPostInit: false,
}
