import React, {useEffect, useState} from 'react';

let googlePlacesService: google.maps.places.PlacesService|null = null;

export interface GoogleGlobalPlacesProviderContextConsumerState {
    service: google.maps.places.PlacesService|null,
}

export const findAddressComponent = (type: string, components: google.maps.GeocoderAddressComponent[], short = false): string => {
    const foundComponent = components.find(component => component.types.indexOf(type) != -1 );

    return foundComponent ? (short ? foundComponent.short_name : foundComponent.long_name) : '';
}

export const GlobalGooglePlacesContext = React.createContext<GoogleGlobalPlacesProviderContextConsumerState>({
    service: googlePlacesService,
});

export const neededFields: string[] = [
    'place_id',
    'name',
    'formatted_address',
    'opening_hours',
    'geometry',
];

const GlobalGooglePlacesContextProvider: React.FC = ({children}) => {

    const [service, setService] = useState(googlePlacesService);

    useEffect(() => {
        if (!googlePlacesService && typeof google != "undefined") {
            googlePlacesService = new google.maps.places.PlacesService(document.createElement('div'));
            setService(googlePlacesService);
        }
    }, [])

    return (
        <GlobalGooglePlacesContext.Provider value={{service}}>
            {children}
        </GlobalGooglePlacesContext.Provider>
    )
}

export default GlobalGooglePlacesContextProvider;
