import React from 'react';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import './index.scss';
import Subscription, {
    findCurrentActiveSubscription,
    mostRecentActiveSubscription
} from '../../../models/subscription/subscription';
import {IonItem, IonPage, IonRouterLink} from '@ionic/react';

const NoSubscriptionWrapper: React.FC = ({children}) => {

    return (
        <div className={'no-subscription-wrapper'}>
            <div className={'explanation-wrapper'}>
                {children}
                <IonItem lines={'none'} routerLink={'/organization/billing/current-subscription'} className={'pick-plan-link'}>
                    <div>
                        <h6>Pick a plan</h6>
                        <p>
                            To continue with Geddit Local you need to pick a plan.
                        </p>
                    </div>
                </IonItem>
            </div>
            <p className={'help-line'}>Need help? <IonRouterLink routerLink={'/organization/contact-us'}>Contact Geddit Local Support</IonRouterLink></p>
        </div>
    );
}

interface SubscriptionErrorProps {
    businessName: string,
}

const NeverSubscribedError: React.FC<SubscriptionErrorProps> = () => {

    return (
        <NoSubscriptionWrapper>
            <h5>You can't access this business yet because you have not started your trial.</h5>
            <p>To start using Geddit Local, you need to pick a plan.</p>
        </NoSubscriptionWrapper>
    )
}

const SubscriptionExpiredError: React.FC<SubscriptionErrorProps> = ({businessName}) => {

    return (
        <NoSubscriptionWrapper>
            <h5>You can't access this business because your subscription has expired.</h5>
            <p>Your Geddit Local subscription for {businessName} has expired. To continue using Geddit Local, you need to renew your membership.</p>
        </NoSubscriptionWrapper>
    )
}

const TrialExpiredError: React.FC<SubscriptionErrorProps> = ({businessName}) => {

    return (
        <NoSubscriptionWrapper>
            <h5>You can't access this business because your trial ended.</h5>
            <p>Your Geddit Local trial for {businessName} has expired. To continue using this business account, you need to pick a plan.</p>
        </NoSubscriptionWrapper>
    )
}

interface SubscriptionWrapperProps extends SubscriptionErrorProps{
    subscriptions: Subscription[],
    hideError: boolean
}

const SubscriptionErrorSelector: React.FC<SubscriptionWrapperProps> = ({subscriptions, businessName, hideError}) => {

    switch (true) {
        case hideError:
            return <></>;

        case subscriptions.length == 0:
            return <NeverSubscribedError businessName={businessName}/>

        case mostRecentActiveSubscription(subscriptions)?.is_trial === 1:
            return <TrialExpiredError businessName={businessName}/>

        default:
            return <SubscriptionExpiredError businessName={businessName}/>

    }
}

const SubscriptionVerificationWrapper: React.FC<SubscriptionWrapperProps> = ({subscriptions, businessName, hideError, children}) => {

    const currentSubscription = findCurrentActiveSubscription(subscriptions);

    return (currentSubscription ?
        <>{children}</> :
        <SubscriptionErrorSelector businessName={businessName} subscriptions={subscriptions} hideError={hideError}/>
    )
}

interface SubscriptionRequiredProps {
    hideLoadingSpace?: boolean,
    hideError?: boolean,
}

const SubscriptionRequired: React.FC<SubscriptionRequiredProps> = ({hideLoadingSpace, hideError,children}) => {
    return (
        <>
            <MyBusinessContextProvider hideLoadingSpace={hideLoadingSpace}>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <SubscriptionVerificationWrapper
                            hideError={hideError ?? false}
                            businessName={myBusinessContext.organization.name}
                            subscriptions={myBusinessContext.organization.subscriptions ? myBusinessContext.organization.subscriptions : []}>
                            {children}
                        </SubscriptionVerificationWrapper>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </>
    );
}

export default SubscriptionRequired;
