import React from 'react'

import './index.scss'
import {IonContent, IonPage} from '@ionic/react';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import MeContextProvider, { MeContext } from '../../../contexts/MeContext';
import NotificationsPermissionProvider from '../../../components/Notifications/NotificationsPermissionProvider';

const NotificationsPrompt: React.FC = () => {

    const handleNotificationResponse = () => {
        window.location.replace('/home/dashboard')
    }

    return (
        <IonPage className={'notifications-prompt-page'}>
            <LoggedOutHeaderComponent hideBackButton/>
            <IonContent>
                <MeContextProvider reset>
                    <MeContext.Consumer>
                        {meContext =>
                            <NotificationsPermissionProvider
                                userId={meContext.me.id!}
                                onResponseGiven={handleNotificationResponse}
                            />
                        }
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    )
}

export default NotificationsPrompt;
