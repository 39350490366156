import React, {useState} from 'react'

import {IonContent, IonItem, IonList, IonPage, IonButton} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
import './index.scss';

import {logOut} from '../../../../data/persistent/persistent.actions';
import {connect} from '../../../../data/connect';
import ServerAlert from '../../../../components/ServerAlert';
import {RequestError} from '../../../../models/request-error';
import {clearLoadingCount} from '../../../../data/session/session.actions';
import {chevronBack} from 'ionicons/icons';
import BottomStickySection from '../../../../components/BottomStickySection';


interface DispatchProps {
    logOut: typeof logOut,
    clearLoadingCount: typeof clearLoadingCount,
}

interface AccountProps extends DispatchProps {}

const AccountSettings: React.FC<AccountProps> = ({logOut, clearLoadingCount}) => {
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);

    /**
     * Logs out our user from the app properly
     */
    const handleLogOut = () => {
        logOut();
        clearLoadingCount();
        window.location.pathname = '/welcome/splash'
    }

    return (
        <IonPage id={'settings-page'}>
            <LoggedInHeaderComponent hideBackButton={false} iconType={chevronBack}>
                Account Settings
            </LoggedInHeaderComponent>
            <IonContent class={'ion-no-padding settings-menu'}>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <React.Fragment>
                                <h4 className={'hide-in-desktop'}>My Settings</h4>
                                <IonList lines={'none'}>
                                    <IonItem routerLink={`/home/dashboard/account/settings/profile`}>
                                        Profile
                                    </IonItem>
                                    <IonItem routerLink={`/home/dashboard/account/settings/notification-settings`}>
                                        Notifications
                                    </IonItem>
                                    {/*<IonItem routerLink={`/home/dashboard/account/settings/privacy-settings`}>*/}
                                    {/*    Privacy Settings*/}
                                    {/*</IonItem>*/}
                                    {meContext.me.has_password ? (
                                        <IonItem routerLink={`/home/dashboard/account/settings/change-password`}>
                                            Change Password
                                        </IonItem>
                                    ) : null }
                                    <IonItem routerLink={`/home/dashboard/account/settings/terms`}>
                                        Terms of Use
                                    </IonItem>
                                    <IonItem routerLink={`/home/dashboard/account/settings/privacy-policy`}>
                                        Privacy Policy
                                    </IonItem>
                                    <IonItem routerLink={`/home/dashboard/account/settings/contact-us`}>
                                        Contact Geddit Local
                                    </IonItem>
                                    <IonItem  routerLink = {`/home/dashboard/account/settings/delete-account`}>
                                        Delete Account
                                    </IonItem>
                                </IonList>
                            </React.Fragment>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
                <BottomStickySection className={'hide-in-desktop'}>
                    <IonButton onClick={() => handleLogOut()} >
                        Sign Out
                    </IonButton>
                </BottomStickySection>
            </IonContent>
            {requestError &&
            <ServerAlert
                requestError={requestError}
                onCloseAlert={() => setRequestError(undefined)}
            />
            }
        </IonPage>
    );
}

const ConnectedAccountSettings = connect<{  }, { }, DispatchProps>({
    mapDispatchToProps: ({
        logOut,
        clearLoadingCount,
    }),
    component: AccountSettings,
});

export default ConnectedAccountSettings;
