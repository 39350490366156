import React from 'react';
import {MeContextStateConsumer} from '../../contexts/MeContext';
import ProfileImage from '../ProfileImage';
import AssetUploader from '../AssetUploader';
import Asset from '../../models/asset';

interface UserProfileImageUploaderProps {
    meContext: MeContextStateConsumer,
    showPlus?: boolean,
    whiteBorder?: boolean,
    isScrolled?: boolean,
    ref?: React.Ref<HTMLDivElement>,
}

/**
 * Handles the profile image uploaded response by updating our stored user with the new url
 * @param meContext
 * @param asset
 */
function profileImageUploaded(meContext: MeContextStateConsumer, asset: Asset) {
    const me = meContext.me;
    me.profile_image_url = asset.url;
    meContext.setMe(me);
}

const UserProfileImageUploader: React.FC<UserProfileImageUploaderProps> = React.forwardRef(({meContext, showPlus, whiteBorder, isScrolled}, ref: React.Ref<HTMLDivElement>) => {
    return (
        <AssetUploader type={'image'} ref={ref} uploadEndpoint={'/users/' + meContext.me.id + '/profile-images'}
                       assetUploaded={asset => profileImageUploaded(meContext, asset)}>
            <ProfileImage url={meContext.me.profile_image_url} showPlus={showPlus} isEdit whiteBorder={whiteBorder} isScrolled={isScrolled} name={meContext.me.full_name}/>
        </AssetUploader>
    );
})

export default UserProfileImageUploader;
