import React, {useEffect, useState} from 'react';
import {BusinessLocationContext, BusinessLocationContextProvider} from '../../contexts/BusinessLocationContext';
import Business from '../../models/organization/business';
import Location from '../../models/location/location';
import {
    ActionSheetButton,
    IonActionSheet,
    IonIcon,
    useIonRouter,
    IonButton,
    IonList,
    useIonViewDidEnter
} from '@ionic/react';
import {checkmarkCircleOutline} from 'ionicons/icons';
import LocationDetails from '../MembersPages/LocationDetails';
import {ellipsisText} from '../../util/strings';
import './index.scss';
import {LaunchNavigator} from "@ionic-native/launch-navigator";
import {PublishedPostsContext, PublishedPostsContextProvider} from "../../contexts/PublishedPostsContext";
import BusinessLocationPostList from "../BusinessLocationPostList";
import {useLocation, useParams} from 'react-router';
import {RouteComponentProps} from 'react-router-dom';
import PostSwiper from '../PostSwiper';
import {SwiperSlide} from 'swiper/react';
import {TaggedPostsContext, TaggedPostsContextProvider} from '../../contexts/TaggedPostsContext';

interface BusinessLocationDetailsProps {
    allowActions?: boolean,
    currentRoute: string,
    business: Business,
    locationModel: Location,
}

const BusinessLocationDetails: React.FC<BusinessLocationDetailsProps> = ({business, locationModel, currentRoute}) => {
    const locationDescription = locationModel.description ?? '';

    const [isDescriptionEllipsis, setIsDescriptionEllipsis] = useState(locationDescription.length > 100);
    const [isLocationSelectorShowing, setIsLocationSelectorShowing] = useState(false);

    const router = useIonRouter();

const locationSelectorButtons: ActionSheetButton[] = business.locations ?
    business.locations.filter(i => i.ready_at)
        .map(i => ({
            text: i.name + ' - ' + i.address,
            icon: locationModel.id == i.id ? checkmarkCircleOutline : undefined,
            cssClass: locationModel.id == i.id ? 'selected' : '',
            handler: () => {
                router.push(currentRoute.replace('/location/' + locationModel.id!, '/location/' + i.id!));
            }
        }
    )) : [];

    const buttonClicked = (url?: string) => {
        if (url) {
            window.location.href = ((url.startsWith('http') || url.startsWith('tel:') || url.startsWith('mailto:') ? '' : 'http://') + url);
        }
    }

    const navigateToLocation = () => {
        if (!locationModel.hide_address) {
            const destination = locationModel.address + " " + locationModel.city + ", " + locationModel.state + " " + locationModel.zip
            LaunchNavigator.navigate(destination).then(result => console.log('result', result)).catch(console.error)
        }
    }

    return (
        <div className={'business-location-content-details'}>
            <LocationDetails location={locationModel} business={business}/>
            {isDescriptionEllipsis ?
                <p className={'location-description'}>{ellipsisText(locationDescription, 100)} <span className={'more-link'} onClick={() => setIsDescriptionEllipsis(false)}>…more</span></p> :
                <p className={'location-description'}>{locationDescription}</p>
            }
            <h6>Location</h6>
            <div className={'location-address-info'}>
                <div>
                    <p onClick={navigateToLocation}>{!locationModel.hide_address && locationModel.address + ', '}{locationModel.city} {locationModel.state} {locationModel.zip}</p>
                </div>
                {locationSelectorButtons.length > 1 &&
                    <IonButton
                        onClick={() => setIsLocationSelectorShowing(true)} fill={"clear"}>
                        <IonIcon
                            src={'/assets/app-icons/additional-locations.svg'}
                            color={'secondary'}
                        />
                    </IonButton>

                }
            </div>
            <div className={'location-ctas'}>
                {locationModel.website ? (
                    <IonButton onClick={() => buttonClicked(locationModel.website)}>Website</IonButton>
                ) : null }
                {locationModel.phone ? (
                    <IonButton onClick={() => buttonClicked("tel:+1-" + locationModel.phone)}>Call</IonButton>
                ) : null }
                {locationModel.email ? (
                    <IonButton onClick={() => buttonClicked("mailto:" + locationModel.email)}>Email</IonButton>
                ) : null }
                {locationModel.custom_links ? locationModel.custom_links.map( link => (
                    <IonButton key={link.id} onClick={() => buttonClicked(link.url)}>{link.label}</IonButton>
                )) : null }
            </div>
            <PostSwiper firstLabel={'Posts'} secondLabel={'Recommendations'}>
                <SwiperSlide>
                    <PublishedPostsContextProvider publisherType={'businesses'} publisherId={business.id!} locations={locationModel.id ? [locationModel.id] : undefined}>
                        <PublishedPostsContext.Consumer>
                            {publishedPostsContext =>
                                <BusinessLocationPostList
                                    posts={publishedPostsContext.loadedData}
                                    baseUrl={currentRoute.replace(`/business/${business.id}/location/${locationModel.id}` , '')}
                                />
                            }
                        </PublishedPostsContext.Consumer>
                    </PublishedPostsContextProvider>
                </SwiperSlide>
                <SwiperSlide>
                    <TaggedPostsContextProvider  taggedId={locationModel.id!}>
                        <TaggedPostsContext.Consumer>
                            {taggedPostsContext =>
                                taggedPostsContext.noResults ?
                                    <h4 className={'no-results-message'}>There are no recommendations to show yet</h4> :
                                <BusinessLocationPostList
                                    posts={taggedPostsContext.loadedData}
                                    baseUrl={currentRoute.replace(`/business/${business.id}/location/${locationModel.id}` , '')}
                                />
                            }
                        </TaggedPostsContext.Consumer>
                    </TaggedPostsContextProvider>
                </SwiperSlide>
            </PostSwiper>
            <IonActionSheet
                isOpen={isLocationSelectorShowing}
                cssClass={'location-action-sheet'}
                onDidDismiss={() => setIsLocationSelectorShowing(false)}
                header={'Choose a Location'}
                buttons={locationSelectorButtons}
            />
        </div>
    )
}

type RouteParams = {
    businessId: string,
    locationId: string,
}

export interface BusinessLocationContentProps {
    allowActions?: boolean,
}

const BusinessLocationContent: React.FC<BusinessLocationContentProps> = ({allowActions}) => {

    let {businessId, locationId} = useParams<RouteParams>();
    const location = useLocation()

    if (!locationId || !businessId) {
        const parts = location.pathname.match(/\d+/g);
        if (parts) {
            businessId = parts.shift()!;
            locationId = parts.shift()!;
        }
    }

    return ((businessId && locationId) ?
        <BusinessLocationContextProvider businessId={parseInt(businessId!)} locationId={parseInt(locationId!)}>
            <BusinessLocationContext.Consumer>
                {context => (
                    <BusinessLocationDetails
                        allowActions={allowActions}
                        currentRoute={location.pathname}
                        business={context.business}
                        locationModel={context.location}
                    />
                )}
            </BusinessLocationContext.Consumer>
        </BusinessLocationContextProvider> : <></>
    )
}

export default BusinessLocationContent;
