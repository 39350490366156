import React, {useContext, useEffect, useState} from 'react';
import {IonAlert, IonButton, IonContent, IonIcon, IonList, IonPage, IonToast, NavContext} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import GreyHeaderContentArea from '../../../components/GreyHeaderContentArea';
import './index.scss';
import {camera, closeOutline, pencilOutline} from 'ionicons/icons';
import BottomStickySection from '../../../components/BottomStickySection';
import LocationEditorContextProvider, {
    LocationEditorContext,
    LocationEditorContextConsumerState
} from '../../../contexts/organization/LocationEditorContext';
import Location from '../../../models/location/location';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import ProfileEditorLink from '../../../components/OrganizationManagement/ProfileEditorLink';
import Asset from '../../../models/asset';
import AssetUploader from '../../../components/AssetUploader';
import ProfileImage from '../../../components/ProfileImage';
import {decoratePhoneNumber} from '../../../components/PhoneNumberInput';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import LocationOutOfBoundsAlert from "../../../components/LocationOutOfBoundsAlert";
import ServerAlert from "../../../components/ServerAlert";
import {RequestError} from '../../../models/request-error';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import Business from "../../../models/organization/business";
import {useLocation, useHistory, useParams} from 'react-router';

interface ProfileImageEditorProps {
    organizationId: number
    availableLocations: Location[],
    setLocation: (location: Location) => void,
    location: Location,
}

const ProfileImageEditor: React.FC<ProfileImageEditorProps> = ({organizationId, setLocation, availableLocations, location}) => {

    const profileImageUploaded = (asset: Asset) => {
        setLocation({
            ...location,
            logo_asset_id: asset.id,
            logo: asset.url,
        })
    }

    useEffect(() => {
        if (!location.logo_asset_id) {
            const defaultLogo = availableLocations
                .map(i => i.logo_asset).find(i => i)

            if (defaultLogo) {
                profileImageUploaded(defaultLogo);
            }
        }
    }, [availableLocations]);


    return (
        <div className={'profile-image-editor'}>
            <p>
                <strong>Logo Image</strong>
                <br/>
                (Required)
            </p>
            <AssetUploader type={'image'} uploadEndpoint={'organizations/' + organizationId + '/assets'} assetUploaded={profileImageUploaded}>
                <ProfileImage url={location.logo} isEdit name={location.name} editIcon={camera}/>
                <IonIcon icon={pencilOutline} className={'profile-image-icon'}/>
            </AssetUploader>
            {/*This is here for flex balance*/}
            <p/>
        </div>
    );
}

interface LocationInformationLinksProps extends SharedLocationEditorProps {
}


const LocationInformationLinks: React.FC<LocationInformationLinksProps> = ({locationEditorContext, business}) => {

    const locationModel = locationEditorContext.dirtyLocation;
    const location = useLocation();

    return (
        <div>
            <GreyHeaderContentArea title={locationModel.name} editLink={`/organization/location-editor/${locationModel.id}/name`}>
                <IonList className={'location-edit-links'}>
                    <ProfileImageEditor
                        organizationId={business.organization_id}
                        location={locationModel}
                        setLocation={locationEditorContext.setLocation}
                        availableLocations={business.locations ?? []}
                    />
                    <ProfileEditorLink addMessage={'an address'} iconType={'location'} routerLink={`/organization/location-editor/${locationModel.id}/address`}>
                        {locationModel.address}
                    </ProfileEditorLink>
                    <ProfileEditorLink addMessage={'a description'} iconType={'business-description'} routerLink={`/organization/location-editor/${locationModel.id}/description`}>
                        {locationModel.description && locationModel.description.length > 50 ?
                            locationModel.description.substr(0 , 50) + '...'
                            : locationModel.description
                        }
                    </ProfileEditorLink>
                    <ProfileEditorLink addMessage={'a website'} iconType={'website'} routerLink={`/organization/location-editor/${locationModel.id}/website`}>
                        {locationModel.website}
                    </ProfileEditorLink>
                    <ProfileEditorLink addMessage={'an email address'} iconType={'email'} routerLink={`/organization/location-editor/${locationModel.id}/email`}>
                        {locationModel.email}
                    </ProfileEditorLink>
                    <ProfileEditorLink addMessage={'a phone number'} iconType={'phone'} routerLink={`/organization/location-editor/${locationModel.id}/phone`}>
                        {decoratePhoneNumber(locationModel.phone ?? '')}
                    </ProfileEditorLink>
                    <ProfileEditorLink addMessage={'a custom link'} iconType={'link'} routerLink={`/organization/location-editor/${locationModel.id}/custom-links`}>
                        {locationModel.custom_links && locationModel.custom_links.length > 0 ?
                            locationModel.custom_links[0].label ? locationModel.custom_links[0].label : locationModel.custom_links[0].url
                            : ""
                        }
                    </ProfileEditorLink>
                </IonList>
            </GreyHeaderContentArea>
        </div>
    );
}

type RouteParams = {
    locationId: string,
    firstLocation?: string,
}

interface SharedLocationEditorProps {
    business: Business,
    onUpdateManagedBusiness: (business: Business) => void,
    locationEditorContext: LocationEditorContextConsumerState
}

interface LocationEditorContentProps extends SharedLocationEditorProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
    locationId: string,
}

const LocationEditorContent: React.FC<LocationEditorContentProps> = ({setHeaderProps, locationId, ...props}) => {

    const [alertShowing, setAlertShowing] = useState(false);
    const [isProfileImageToastShowing, setIsProfileImageToastShowing] = useState(false);
    const [isOutOfRangeAlertShowing, setOutOfRangeAlertShowing] = useState(false);
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);
    const {goBack} = useContext(NavContext);
    const {onUpdateManagedBusiness, business, locationEditorContext} = props
    const navigate = useHistory();

    const navigateBackToDashboard = () => {
        goBack('/organization');
    }

    const saveData = (locationModel: Location, updateCompleted: (location: Location) => void) => {
        if (!locationModel.logo) {
            setIsProfileImageToastShowing(true);
        } else {
            OrganizationRequests.updateLocation(locationModel).then(updatedLocation => {
                const locationIndex = business.locations?.findIndex(loc => loc.id === locationModel.id)
                if(locationModel.business && locationIndex === 0) {
                    locationModel.business.logo_url = locationModel.logo
                    onUpdateManagedBusiness(locationModel.business)
                }
                updateCompleted(updatedLocation);
                navigateBackToDashboard();
            }).catch((error: RequestError) => {
                if ( error.data.errors && error.data.errors.address.indexOf('All locations must be within the same community as each other.') != -1 ) {
                    setOutOfRangeAlertShowing(true)
                } else {
                    setRequestError(error);
                }
            });
        }
    }

    const backClicked = () => {
        if (!locationEditorContext.location.logo && business.locations?.length == 1) {
            window.location.pathname = '/home';
        } else if (locationEditorContext.isLocationDirty) {
            setAlertShowing(true);
        } else {
            navigateBackToDashboard();
        }
    }

    useEffect(() => {
        setHeaderProps({
            children: 'Edit Page',
            backClicked: backClicked,
            closeButtonIcon: closeOutline
        });
    }, [isProfileImageToastShowing, locationEditorContext.isLocationDirty])

    return (
        <React.Fragment>
            <IonContent>
                <LocationInformationLinks {...props} locationEditorContext={locationEditorContext}/>
            </IonContent>
            <BottomStickySection>
                <IonButton
                    disabled={!locationEditorContext.isLocationDirty}
                    expand={'full'}
                    onClick={() => saveData(
                        locationEditorContext.dirtyLocation,
                        locationEditorContext.updateCompleted)}>
                    Submit
                </IonButton>
            </BottomStickySection>
            <IonAlert
                isOpen={alertShowing}
                onDidDismiss={() => setAlertShowing(false)}
                header={'Discard Changes?'}
                message={'Would you like to save your changes, or discard them before going back?'}
                buttons={[
                    {
                        text: 'Discard',
                        handler: navigateBackToDashboard,
                    },
                    {
                        text: 'Save',
                        handler: () => saveData(
                            locationEditorContext.dirtyLocation,
                            locationEditorContext.updateCompleted
                        ),
                    },
                ]}
            />
            <LocationOutOfBoundsAlert
                open={isOutOfRangeAlertShowing}
                onCloseAlert={() => setOutOfRangeAlertShowing(false)}
                main_category={locationEditorContext.location.business?.main_category}
            />
            {requestError &&
                <ServerAlert
                    requestError={requestError}
                    onCloseAlert={(fix: boolean, field?: string) => {
                        const relatedFields = [
                            'address',
                            'custom-links',
                            'description',
                            'email',
                            'phone',
                            'website',
                        ];

                        if (fix && field && relatedFields.indexOf(field) != -1) {
                            navigate.push( `/organization/location-editor/${locationId}/${field}`)
                        }
                        setRequestError(undefined);
                    }}
                />
            }
            <IonToast
                isOpen={isProfileImageToastShowing}
                onDidDismiss={() => setIsProfileImageToastShowing(false)}
                duration={2000}
                message={"Please submit a logo image for your location."}
            />
        </React.Fragment>
    );
}

const LocationEditor:React.FC = () => {

    const {locationId} = useParams<RouteParams>()

    return (
        <IonPage className={'location-editor'}>
            <MeContextProvider>
                <MeContext.Consumer>
                    {meContext => (
                        <MyBusinessContextProvider>
                            <MyBusinessContext.Consumer>
                                {myBusinessContext =>
                                    <LocationEditorContextProvider locationId={parseInt(locationId!)}>
                                        <LocationEditorContext.Consumer>
                                            {locationEditorContext =>
                                                <OrganizationHeaderContext.Consumer>
                                                    {organizationHeaderContext =>
                                                        <LocationEditorContent
                                                            locationId={locationId}
                                                            onUpdateManagedBusiness={meContext.updateManagedBusiness}
                                                            business={myBusinessContext.business}
                                                            locationEditorContext={locationEditorContext}
                                                            setHeaderProps={organizationHeaderContext.setSharedProps}
                                                        />
                                                    }
                                                </OrganizationHeaderContext.Consumer>
                                            }
                                        </LocationEditorContext.Consumer>
                                    </LocationEditorContextProvider>
                                }
                            </MyBusinessContext.Consumer>
                        </MyBusinessContextProvider>
                    )}
                </MeContext.Consumer>
            </MeContextProvider>
        </IonPage>
    )
}

export default LocationEditor;
