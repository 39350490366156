import React from 'react';
import './index.scss';
import EntityDetails from '../../MembersPages/EntityDetails';
import {IonIcon, IonItem} from '@ionic/react';
import {formatTimeAgo} from '../../../util/time';
import {arrowUndo, arrowUndoOutline} from 'ionicons/icons';
import CanParticipateInForum from '../../../models/can-participate-in-forum';

interface MessageItemProps {
    body: string,
    entity?: CanParticipateInForum
    created_at: string;
    isReplying: boolean,
    onReply: () => void,
}

const MessageItem: React.FC<MessageItemProps> = ({body, entity, created_at, isReplying, onReply, children}) => {

    return (
        <div className={'message-item'}>
            <EntityDetails entity={entity}/>
            <IonItem className={'message'}>
                <div>
                    <p>{body}</p>
                    <p><i><small>{formatTimeAgo(created_at, true)}{children}</small></i></p>
                </div>
                {/*<IonIcon slot={'end'} color={isReplying ? 'primary': 'medium'} icon={isReplying ? arrowUndo : arrowUndoOutline} onClick={() => onReply()}/>*/}
            </IonItem>
        </div>
    )
}

export default MessageItem;
