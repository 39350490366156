import React, { useEffect } from 'react';
import { App } from '@capacitor/app';
import {useHistory} from 'react-router';
import {TokenState} from '../data/persistent/persistent.state';
import {navigateOutsideUrl} from '../util/routing';
import {URLOpenListenerEvent} from '@capacitor/app/dist/esm/definitions';
import {connect} from '../data/connect';
import {setSharedPostInit} from '../data/session/session.actions';

interface StateProps {
    tokenData?: TokenState
}

interface DispatchProps {
    setSharedPostInit: typeof setSharedPostInit,
}

interface DeepLinksListenerProps extends StateProps, DispatchProps {
}

const DeepLinksListener: React.FC<DeepLinksListenerProps> = ({tokenData, setSharedPostInit}) => {
    let history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
            if (data.url.indexOf('post') !== -1) {
                setSharedPostInit(true);
            }
            navigateOutsideUrl(history, data.url, tokenData);
        });

    }, [tokenData]);

    return null;
};

export default connect<{}, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    mapDispatchToProps: ({
        setSharedPostInit,
    }),
    component: DeepLinksListener
});
