import React, {useState, useEffect} from 'react';
import './index.scss';
import PaymentMethod from '../../../models/payment/payment-method';
import {IonButton, IonList, IonRadioGroup} from '@ionic/react';
import PaymentMethodItem from '../PaymentMethodItem';
import PaymentMethodForm from '../PaymentMethodForm';
import PaymentMethodRequests from '../../../services/requests/PaymentMethodRequests';
import {AvailableEntityTypes, Entity} from '../../../models/entities';

interface PaymentMethodProps {
    paymentMethods: PaymentMethod[],
    showFormByDefault?: boolean,
    activeMethod?: PaymentMethod,
    entityType: AvailableEntityTypes,
    entity: Entity
    setDefault?: boolean,
    hideFormCancel?: boolean,
    hideFormSubmit?: boolean,
    submitText?: string,
    submitNewMethod?: boolean,
    setEntity: (entity: Entity) => void,
    cancelSelection?: () => void,
    onPaymentMethodChange?: (paymentMethod: PaymentMethod) => void,
    onUpdateDefaultMethod?: (paymentMethod: PaymentMethod|undefined) => void,

}

const PaymentMethodSelector: React.FC<PaymentMethodProps> = ( {...props} ) => {
    const {paymentMethods, activeMethod, entity, setEntity, entityType, showFormByDefault, cancelSelection, onPaymentMethodChange, onUpdateDefaultMethod, setDefault, hideFormSubmit, hideFormCancel, submitText, submitNewMethod } = props

    const [showingPaymentMethodForm, setShowingPaymentMethodForm] = useState(showFormByDefault ? paymentMethods.length == 0 : false);

    let defaultPaymentMethod: PaymentMethod|undefined = undefined;
    if (paymentMethods.length > 0) {
        defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.default);
        if (!defaultPaymentMethod) {
            defaultPaymentMethod = paymentMethods[0];
        }
    }

    const removePaymentMethod = (paymentMethod: PaymentMethod) => {
        PaymentMethodRequests.deletePaymentMethod('organization', paymentMethod).then(() => {
            setEntity({
                ...entity,
                payment_methods: paymentMethods.filter(i => i.id != paymentMethod.id)
            });
        });
    }

    const paymentMethodCreated = (paymentMethod: PaymentMethod) => {
        setShowingPaymentMethodForm(false);

        if (onPaymentMethodChange) {
            onPaymentMethodChange( paymentMethod )
        }
        const existingPaymentMethods =
            [...paymentMethods].map(i => ({...i, default: false}));

        existingPaymentMethods.push(paymentMethod);
        setEntity({
            ...entity,
            payment_methods: existingPaymentMethods
        });
    }

    const paymentMethodChanged = (paymentMethod: PaymentMethod) => {
        if (!paymentMethod.default) {
            PaymentMethodRequests.updatePaymentMethod(entityType, paymentMethod, {
                default: true,
            }).catch(console.error);
            paymentMethod.default = true;

            const existingPaymentMethods =
                [...paymentMethods].map(i => ({...i, default: i.id == paymentMethod.id}));

            setEntity({
                ...entity,
                payment_methods: existingPaymentMethods
            });
        }
        if (onPaymentMethodChange) {
            onPaymentMethodChange( paymentMethod )  
        }
        setShowingPaymentMethodForm( false )
    }

    const showPaymentMethodForm = () => {
        setShowingPaymentMethodForm( true )
    }

    const cancel = () => {
        if (cancelSelection) {
            cancelSelection();
        } else {
            setShowingPaymentMethodForm(false)
        }
    }

    return (
        <div className={'payment-method-selector'}>
            <IonList
                className={'payment-methods-list'}
            >
                {paymentMethods.map(paymentMethod => (
                    <PaymentMethodItem
                        key={paymentMethod.id}
                        onClick={() => paymentMethodChanged(paymentMethod)}
                        onRemovePayment={() => removePaymentMethod(paymentMethod)}
                        paymentMethod={paymentMethod}
                        defaultMethod={defaultPaymentMethod == paymentMethod}
                    />
                ))}
            </IonList>
            {showingPaymentMethodForm ?
                <PaymentMethodForm
                    entityId={entity.id ? entity.id : 0}
                    entityType={entityType}
                    onCancel={cancel}
                    submitNewMethod={submitNewMethod}
                    hideCancel={hideFormCancel}
                    hideSubmit={hideFormSubmit}
                    submitText={submitText}
                    onPaymentMethodCreated={paymentMethodCreated}/> :
                <IonButton color={'medium'} onClick={showPaymentMethodForm}>
                    Add Payment Method
                </IonButton>
            }
        </div>
    );
}

export default PaymentMethodSelector;
