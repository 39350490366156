import React, {KeyboardEvent, useEffect, useState} from 'react';
import './index.scss';
import {
    PhoneVerificationContextData,
    PhoneVerificationContextStateConsumer
} from '../../../contexts/SignIn/PhoneVerificationContext';
import {IonIcon, IonInput, IonItem, IonToast, useIonRouter} from '@ionic/react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import VerificationCodeRequests from '../../../services/requests/VerificationCodeRequests';
import {useHistory} from 'react-router-dom';
import NextArrow from '../NextArrow';

interface VerificationCodeEntryProps {
    verificationCodeContext: PhoneVerificationContextStateConsumer<any>,
    onSubmit: (data: PhoneVerificationContextData) => boolean,
    mustExist: boolean,
    name?: string,
}

const VerificationCodeEntry: React.FC<VerificationCodeEntryProps> = ({ verificationCodeContext, mustExist, onSubmit }) => {

    const [toastMessage, setToastMessage] = useState('')
    const history = useHistory();

    const VerificationCodeSchema = Yup.object().shape({
        verification_code: Yup.number().test(
            'len',
            'Verification code must be exactly 6 characters',
            val => val?.toString().length === 6
        ).required('Please enter your verification code'),
    })

    const submit = (data: PhoneVerificationContextData) => {
        const validCode = onSubmit(data);
        if (validCode) {
            verificationCodeContext.setData(data);
        }
    }

    const form = useFormik({
        initialValues: verificationCodeContext.data,
        initialErrors: {verification_code: ''},
        validationSchema: VerificationCodeSchema,
        onSubmit: submit,
    });

    useEffect(() => {
        if (!form.isValid) {

            if (form.errors.verification_code && form.touched.verification_code) {
                setToastMessage(form.errors.verification_code);
            }
        }
    }, [form.isSubmitting, form.isValid])

    const formatPhoneNumber = (): string => {
        if (verificationCodeContext.data.phone) {
            const phone = verificationCodeContext.data.phone;
            return phone.substr(0,3) + " "
                + phone.substr(3,3) + " "
                + phone.substr(6);
        }

        return "";
    }

    const requestValidationCode = () => {
        if (verificationCodeContext.data.phone) {
            VerificationCodeRequests.requestVerificationCode(verificationCodeContext.data.phone, mustExist).then((result) => {
                setToastMessage('Verification code sent')
            }).catch((error) => {
                if(error.data && error.data.errors && error.data.errors.must_exist){
                    setToastMessage(error.data.errors.must_exist)
                    verificationCodeContext.setData({
                        ...verificationCodeContext.data,
                        phoneInvalid: true,
                    })
                    setTimeout(() => history.goBack(), 1000);
                }
            })
        }
    }
    useEffect(requestValidationCode, [verificationCodeContext.data.phone]);

    const enterPressed = (event: KeyboardEvent) => {
        if (event.keyCode == 13) {
            form.handleSubmit();
        }
    }

    return (form &&
        <div className={'verification-code-entry'}>
            <h4>Enter your verification code</h4>
            <p className={'phone-number-display'}>
                Sent to {formatPhoneNumber()} <a onClick={() => history.goBack()}>Edit</a>
            </p>
            <IonInput
                name={"verification_code"}
                type={"number"}
                inputmode={'numeric'}
                value={form.values.verification_code}
                onIonChange={form.handleChange}
                maxlength={6}
                autocomplete={'one-time-code'}
                onKeyDown={enterPressed}
            />
            <div className={'submit-line'}>
                <a className={'resend'} onClick={requestValidationCode}>
                    Didn't get a code?
                    <br/>
                    click here to resend.
                </a>
                <NextArrow
                    onClick={() => form.handleSubmit()}
                    isValid={form ? form.isValid : false}
                />
            </div>
            {(form.submitCount > 0 && form.errors.verification_code) ? <p className={'error'}>{form.errors.verification_code}</p> : null}
            <IonToast
                isOpen={toastMessage ? toastMessage.length > 0 : false}
                duration={2000}
                onDidDismiss={() => setToastMessage('')}
                message={toastMessage}
            />
        </div>
    )
}


export default VerificationCodeEntry
