import moment from 'moment';


/**
 * This formats a published at string to show how long ago a post was published
 * @param from
 * @param compact
 */
export function formatTimeAgo(from: string, compact = false) {

    const now = moment();
    const publishedDate = moment(from);
    const secondsAgo = now.diff(from, 'seconds');

    if (secondsAgo < 0) {
        return 'Scheduled';
    }

    const minutesAgo = now.diff(from, 'minutes');
    const hoursAgo = now.diff(from, 'hours');
    const daysAgo = now.diff(from, 'days');
    const monthsAgo = now.diff(from, 'months');

    if (secondsAgo < 60) {
        return 'Just now';
    } else if (minutesAgo < 60) {
        return minutesAgo + (compact ? 'm' : " minute" + (minutesAgo == 1 ? "" : "s") + " ago");
    } else if (hoursAgo < 24) {
        return hoursAgo + (compact ? 'h' : " hour" + (hoursAgo == 1 ? "" : "s") + " ago");
    } else if (daysAgo < 31) {
        return daysAgo + (compact ? 'd' : " day" + (daysAgo == 1 ? "" : "s") + " ago");
    } else if (monthsAgo < 31) {
        return monthsAgo + (compact ? 'mo' : " month" + (monthsAgo == 1 ? "" : "s") + " ago");
    } else {
        return publishedDate.fromNow()
    }
}
