import {IonPage} from '@ionic/react';
import React from 'react';
import PrivacyPolicyText from '../../../components/PrivacyPolicyText';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import './index.scss';
import BusinessCreationHeader from '../../../components/BusinessCreationHeader';

const PrivacyPolicy: React.FC = () => {

    return (
        <IonPage className={'privacy-page'}>
            <BusinessCreationHeader/>
            <PrivacyPolicyText
                contactUsUrl={'/organization-creator/contact-us'}
            />
        </IonPage>
    );
}

export default PrivacyPolicy;
