import React, {PropsWithChildren, useEffect, useState} from 'react';
import {Capacitor} from '@capacitor/core';
import { PushNotifications} from '@capacitor/push-notifications';
import {ActionPerformed} from '@capacitor/push-notifications/dist/esm/definitions';
import {navigateOutsideUrl} from '../../../util/routing';
import {useHistory} from 'react-router';
import {TokenState} from '../../../data/persistent/persistent.state';
import {connect} from '../../../data/connect';
import {setNotificationPostInit} from '../../../data/session/session.actions';

interface StateProps {
    tokenData?: TokenState
}

interface DispatchProps {
    setNotificationPostInit: typeof setNotificationPostInit,
}

interface NotificationsResponderProps extends DispatchProps, StateProps {
}

/**
 * This component is responsible for listening to notification interaction, and dispatching various actions that we need from a notification
 * @param tokenData
 * @param children
 * @constructor
 */
const NotificationsResponder: React.FC<NotificationsResponderProps> = ({tokenData, setNotificationPostInit, children}) => {

    let history = useHistory();

    useEffect(() => {

        if (Capacitor.isNativePlatform()) {
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
                const url = action.notification?.data?.aps?.category;

                // notification.click_action
                if (url) {
                    if (url.indexOf('post') !== -1) {
                        setNotificationPostInit(true);
                    }
                    navigateOutsideUrl(history, url, tokenData);
                }
            });
        }
    }, [])

    return null;
}

export default connect<{}, StateProps, DispatchProps >({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    mapDispatchToProps: ({
        setNotificationPostInit,
    }),
    component: NotificationsResponder
});

