import React, {HTMLAttributes} from 'react';
import './index.scss';


interface AppStoreLinksProps {
	onlyIos?: boolean,
	onlyAndroid?: boolean,
}

const AppStoreLinks: React.FC<AppStoreLinksProps> = ({onlyAndroid, onlyIos})  => (
	<div className="side-by-side app-store-links">
		{!onlyIos &&
			<a href="https://play.google.com/store/apps/details?id=com.gedditlocal.mobile" target="_blank" className="app-store-logo">
				<img src="/assets/android.png" />
			</a>
		}
		{!onlyAndroid &&
			<a href="https://apps.apple.com/us/app/ssu/id1560583285" target="_blank" className="app-store-logo">
				<img src="/assets/ios.png" />
			</a>
		}
	</div>
)

export default AppStoreLinks;
