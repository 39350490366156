import React from 'react';
import BaseLocationEditPage from '../../../../components/OrganizationManagement/BaseLocationEditPage';
import CustomLink from '../../../../models/custom-link';
import {IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList} from '@ionic/react';
import Location from '../../../../models/location/location';
import './index.scss';
import {LocationEditorContextConsumerState} from '../../../../contexts/organization/LocationEditorContext';
import Category from '../../../../models/category';
import {addHttpPrefix, grammaticalList} from "../../../../util/strings";
import {urlRegExp, validateRegexMatch} from "../../../../util/regex";

interface CustomLinkEditorProps {
    customLink: CustomLink,
    setCustomLink: (customLink: CustomLink) => void,
}

const CustomLinkEditor: React.FC<CustomLinkEditorProps> = ({customLink, setCustomLink}) => {

    const setLabel = (label: string) => {
        setCustomLink({
            ...customLink,
            label: label,
        })
    }

    const setUrl = (url: string) => {
        setCustomLink({
            ...customLink,
            url: url,
        })
    }

    return (
        <div className={'custom-link-editor'}>
            <IonLabel>Label</IonLabel>
            <IonInput
                value={customLink.label}
                placeholder={"e.g. Donate, Menu, etc."}
                onInput={event => setLabel(event.currentTarget.value as string)}
            />
            <IonLabel>Browser URL</IonLabel>
            <IonInput
                value={customLink.url}
                placeholder={"https://"}
                onInput={event => setUrl(event.currentTarget.value as string)}
            />
        </div>
    )
}


interface CustomLinkListState {
    customLinks: CustomLink[],
}

interface CustomLinksListProps {
    currentData: CustomLinkListState,
    setCurrentData: (data: CustomLinkListState) => void
}

const CustomLinksList: React.FC<CustomLinksListProps> = ({currentData, setCurrentData}) => {

    const replaceCustomLink = (index: number, customLink: CustomLink) => {
        const updatedCustomLinks = [...currentData.customLinks];

        updatedCustomLinks[index] = customLink

        setCurrentData({
            ...currentData,
            customLinks: updatedCustomLinks,
        });
    }

    const removeCustomLink = (index: number) => {

        const updatedCustomLinks = [...currentData.customLinks];

        delete updatedCustomLinks[index];

        setCurrentData({
            ...currentData,
            // filter the array to reset our indexes
            customLinks: updatedCustomLinks.filter(i => i),
        });
    }

    const addCustomLink = () => {

        const updatedCustomLinks = [...currentData.customLinks];

        updatedCustomLinks.push({
            url: "",
            label: "",
        })

        setCurrentData({
            ...currentData,
            customLinks: updatedCustomLinks,
        });
    }

    if (currentData.customLinks.length == 0) {
        currentData.customLinks.push({
            url: "",
            label: "",
        });
    }

    return (
        <div className={'custom-link-list'}>
            <IonList>
                {currentData.customLinks.map((customLink, index) =>
                    <IonItem lines={'none'} key={index}>
                        <CustomLinkEditor
                            customLink={customLink}
                            setCustomLink={updated => replaceCustomLink(index, updated)}/>
                        {currentData.customLinks.length <= 1 ?
                            '' : <IonIcon slot={'end'} src={'/assets/app-icons/trash.svg'} onClick={() => removeCustomLink(index)} />
                        }
                    </IonItem>
                )}
            </IonList>
            <IonButton color={'light'} onClick={() => addCustomLink()}>
                Add Another
            </IonButton>
        </div>
    )
}


const CustomLinks: React.FC = () => {

    const createInitialState = (locationEditorContext: LocationEditorContextConsumerState): CustomLinkListState => {
        const locationModel = locationEditorContext.dirtyLocation;
        return {
            customLinks: locationModel.custom_links ? [...locationModel.custom_links] : [],
        }
    }

    const decorateCustomLinks = (value: CustomLinkListState) : CustomLink[] => {

        return value.customLinks
            .filter(i => i.label.length > 0 && i.url.length > 0)
            .map( customLink => ({
                ...customLink,
                url: addHttpPrefix(customLink.url)
            }));
    }

    const constructLocation = (currentData: CustomLinkListState, locationModel: Location): Location => {
        return {
            ...locationModel,
            custom_links: decorateCustomLinks(currentData)
        };
    }

    const validateCustomLinks = (value: CustomLinkListState) => {

        const invalidLabels = value.customLinks
            .filter(i => i.label.length == 0 && i.url.length > 0);

        if (invalidLabels.length) {
            const urls = invalidLabels.map(i => i.url);
            return grammaticalList(urls) + (urls.length > 1 ? ' must have labels.' : ' needs a label.');
        }

        const invalidLinks = value.customLinks.filter(customLink => {
            return customLink.label.length > 0 &&
                (!customLink.url || customLink.url.indexOf('.') == -1 || customLink.url[customLink.url.length -1] == '.' || !validateRegexMatch(urlRegExp, customLink.url))
        })

        if (invalidLinks.length) {
            const labels = invalidLinks.map((link) => link.label)

            return grammaticalList( labels ) + (labels.length > 1 ? ' do not have valid URLs' : ' does not have a valid URL')
        }

        return ''
    }

    return (
        <BaseLocationEditPage
            createInitialState={createInitialState}
            title={'Custom Links'}
            iconUrl={'link'}
            constructLocation={constructLocation}
            onCurrentDataSave={validateCustomLinks}
            required={true}
        >
            {currentDataContext =>
                <CustomLinksList
                    currentData={currentDataContext.currentData}
                    setCurrentData={data => currentDataContext.setCurrentData(data)}
                />
            }
        </BaseLocationEditPage>
    );
}

export default CustomLinks;
