import React, {HTMLAttributes, PropsWithChildren} from 'react';
import './index.scss';

interface ComingSoonPlaceholderProps {
	imageUrl?: string
}

/**
 * @param url
 * @param showPlus
 * @param props
 * @constructor
 */
const ComingSoonPlaceholder: React.FC<PropsWithChildren<ComingSoonPlaceholderProps>> = ({imageUrl, children})  => {

	const css = imageUrl ? {backgroundImage: `url(${imageUrl})`} : {};

	return (
		<div className={'coming-soon-image'} style={css}>
			<div className={'coming-soon-copy'}>
				{children}
			</div>
		</div>
	)
}

export default ComingSoonPlaceholder
