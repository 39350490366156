import React  from 'react';
import {IonRouterOutlet} from '@ionic/react';
import {Route, useLocation} from 'react-router';
import BusinessInformationForm from './BusinessInformationForm';
import QualifyingGuidelines from './QualifyingGuidelines';
import BusinessRejection from './BusinessRejection';
import BusinessCreatedResult from './BusinessCreatedResult';
import BusinessHeaderSetup from './BusinessHeaderSetup';
import PlanPreview from './PlanPreview';
import ContactUs from './ContactUs';
import MainCategorySelector from './MainCategorySelector';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import {Redirect} from 'react-router-dom';
import './index.scss';


const OrganizationCreator: React.FC = () => {

    const location = useLocation()

    return (
        <IonRouterOutlet className={'organization-creator-section'}>
            <Redirect path={'/organization-creator'} to={`/organization-creator/qualifying-guidelines`}/>
            <Route path={`/organization-creator/business-information`} render={() => <BusinessInformationForm/>}/>
            <Route path={`/organization-creator/organization-information`} render={() => <BusinessInformationForm/>}/>
            <Route path={`/organization-creator/contact-us`} render={() => <ContactUs/>}/>
            <Route path={`/organization-creator/qualifying-guidelines`} render={() => <QualifyingGuidelines/>}/>
            <Route path={`/organization-creator/business-rejection`} render={() => <BusinessRejection/>}/>
            <Route path={`/organization-creator/business-created/:result`} render={() => <BusinessCreatedResult/>}/>
            <Route path={`/organization-creator/organization-created/:result`} render={() => <BusinessCreatedResult/>}/>
            <Route path={`/organization-creator/business-header-setup`} render={() => <BusinessHeaderSetup/>}/>
            <Route path={`/organization-creator/main-category-selector`} render={() => <MainCategorySelector/>}/>
            <Route path={`/organization-creator/plan-preview`} render={() => <PlanPreview/>}/>
            <Route path={`/organization-creator/privacy-policy`} render={() => <PrivacyPolicy/>} />
            <Route path={`/organization-creator/terms`} render={() => <TermsOfUse/>} />
        </IonRouterOutlet>
    );
};

export default OrganizationCreator;
