import Asset from '../../models/asset';
import React from 'react';
import './index.scss';
import AssetUploader from '../AssetUploader';
import {IonIcon} from '@ionic/react';
import {pencilOutline} from 'ionicons/icons';
import Business from '../../models/organization/business';

interface FeaturedImageProps {
    images: Asset[],
    index: number,
}

interface EditDetails {
    business: Business,
    setBusiness: (business: Business) => void,
}

const FeaturedImage: React.FC<FeaturedImageProps> = ({images, index}) => {
    return (images.length > index ?
        <div className={'image-entry'} style={{backgroundImage: 'url("' + images[index].url + '")'}}/> : <div className={'placeholder'}/>
    );
}

interface EditableFeaturedImagesProps extends FeaturedImageProps, EditDetails {}



const EditableFeaturedImage: React.FC<EditableFeaturedImagesProps> = ({images, index, business, setBusiness}) => {

    const assetUploaded = (asset: Asset) => {

        const newImages = images;
        if (index < images.length) {
            newImages[index] = asset;
        } else {
            newImages.push(asset);
        }

        setBusiness({
            ...business,
            featured_images: newImages,
        });
    }

    return (
        <AssetUploader type={'image'} uploadEndpoint={'organizations/' + business.organization_id + '/assets'} assetUploaded={assetUploaded}>
            <FeaturedImage images={images} index={index}/>
            <IonIcon icon={pencilOutline}/>
        </AssetUploader>
    )
}

interface FeaturedImagesProps {
    images: Asset[],
    editEnabled?: EditDetails,
}

const FeaturedImages: React.FC<FeaturedImagesProps> = ({ images, editEnabled }) => {

    return (editEnabled ?
        <div className={'featured-images'}>
            <EditableFeaturedImage images={images} index={0} business={editEnabled.business} setBusiness={editEnabled.setBusiness}/>
            <EditableFeaturedImage images={images} index={1} business={editEnabled.business} setBusiness={editEnabled.setBusiness}/>
            <EditableFeaturedImage images={images} index={2} business={editEnabled.business} setBusiness={editEnabled.setBusiness}/>
        </div>:
        <div className={'featured-images'}>
            <FeaturedImage images={images} index={0}/>
            <FeaturedImage images={images} index={1}/>
            <FeaturedImage images={images} index={2}/>
        </div>
    );
};

export default FeaturedImages;
