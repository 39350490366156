import React, {useEffect, useRef, useState} from 'react';
import {
	IonButton,
	IonButtons,
	IonContent, IonFooter,
	IonHeader, IonIcon, IonInput,
	IonItem,
	IonPage,
	IonTitle,
	IonToolbar, useIonViewDidEnter, useIonViewWillEnter
} from '@ionic/react';
import './index.scss';
import MeContextProvider, {MeContext, MeContextStateConsumer} from "../../../contexts/MeContext";
import ProfileIncomplete from "../../../components/ProfileIncomplete";
import {setHasSeenReferralsTutorial} from "../../../data/persistent/persistent.actions";
import {connect} from '../../../data/connect';
import {
	PublishedPostsContextProvider,
	PublishedPostsContext,
	PublishedPostsContextState
} from '../../../contexts/PublishedPostsContext';
import Post, {isPostPublished} from "../../../models/post/post";
import {useHistory} from "react-router-dom";
import GeoLocationContextProvider, {GeoLocationContext} from '../../../components/GeoLocationContextProvider';
import NearbyPostsContextProvider, {NearbyPostsContext} from '../../../contexts/feed/NearbyPostsContext';
import {
	PendingPostsContext,
	PendingPostsContextProvider,
	PendingPostsContextState
} from '../../../contexts/PendingPostsContext';
import GooglePlacesSearchField from '../../../components/GooglePlacesSearchField';
import Location from '../../../models/location/location';
import ProfileImage from '../../../components/ProfileImage';
import PostLocation from '../../../models/post/post-location';
import PostManagementRequests from '../../../services/requests/PostManagementRequests';
import User from '../../../models/user/user';
import BottomStickySection from '../../../components/BottomStickySection';
import SideBySideComponent from '../../../components/SideBySide';
import {Editor} from "react-draft-wysiwyg";
import Draft, {
	EditorState,
	ContentState,
	SelectionState,
	Modifier,
	convertToRaw, RichUtils
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Asset from '../../../models/asset';
import AssetUploader from '../../../components/AssetUploader';
import {cameraOutline} from 'ionicons/icons';
import ReferralTutorialModal from '../../../components/MembersPages/ReferralTutorialModal';

interface PostCreationData {
	has_content: boolean,
	article_content: string,
	main_image_id: undefined|number,
	post_locations: PostLocation[],
}

const defaultPostCreationData: PostCreationData = {
	has_content: false,
	article_content : '',
	main_image_id: undefined,
	post_locations: [],
}

interface MemberInformationHeaderProps {
}

const MemberInformationHeader: React.FC<MemberInformationHeaderProps> = () => {

	return (
		<MeContextProvider>
			<MeContext.Consumer>
				{meContext =>
					<IonItem className={'member-information-header'} lines={'none'}>
						<ProfileImage
							url={meContext.me.profile_image_url}
							slot={'start'}
							name={meContext.me.full_name}
						/>
						<div className={'member-information'}>
							<div className={'member-information-bubble'}>
								{meContext.me.full_name}
							</div>
						</div>
					</IonItem>
				}
			</MeContext.Consumer>
		</MeContextProvider>
	)
}

interface LocationData {
	postLocation: PostLocation,
	locationData?: Location,
	name: string,
	images: Asset[],
	address?: string,
}

interface MemberPostCreatorContentEditorProps {
	user: User
	selectedLocation: LocationData,
	onPostCreationDataChanged: (data: PostCreationData) => void,
}

const MemberPostCreatorContentEditor : React.FC<MemberPostCreatorContentEditorProps> = ({selectedLocation, user, onPostCreationDataChanged}) => {

	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [isItalic, setIsItalic] = useState(false);
	const [selectedImage, setSelectedImage] = useState<Asset|undefined>(undefined)

	const editorRef = useRef<Editor>(null)
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		setSelectedImage(selectedLocation.images.length ? selectedLocation.images[0] : undefined);
		setEditorState(EditorState.createEmpty());
	}, [selectedLocation])

	useEffect(() => {
		if (editorRef && editorRef.current) {
			editorRef.current.focusEditor();
		}
	}, [])

	useEffect(() => {

		const userLocation = {
			...selectedLocation.postLocation,
			reference_id: user.id,
			reference_type: 'user',
		} as PostLocation
		const message = editorState.getCurrentContent().hasText() ?
			draftToHtml(convertToRaw(editorState.getCurrentContent())) : '';
		onPostCreationDataChanged({
			has_content: editorState.getCurrentContent()?.hasText(),
			article_content: message,
			main_image_id: selectedImage?.id,
			post_locations: [
				{
					...selectedLocation.postLocation,
					tagged: true,
				},
				userLocation,
			],
		});

	}, [selectedImage, editorState.getCurrentContent()])

	const addSpecialText = (text: string) => {

		let currentContent = editorState.getCurrentContent();
		let currentSelection = editorState.getSelection().merge({
			focusOffset: 0,
			anchorOffset: 0
		});

		const italicStyle = editorState.getCurrentInlineStyle().add( 'ITALIC');

		let newContent = Modifier.insertText(
			currentContent,
			currentSelection,
			text,
			italicStyle as any
		);

		let newEditorState = EditorState.push(editorState, newContent, 'insert-characters')

		currentContent = newEditorState.getCurrentContent();
		currentSelection = newEditorState.getSelection();
		const baseStyle = newEditorState.getCurrentInlineStyle().remove( 'ITALIC');
		newContent = Modifier.insertText(
			currentContent,
			currentSelection,
			"\n",
			baseStyle as any
		);
		newEditorState = EditorState.push(newEditorState, newContent, 'insert-characters')
		newEditorState = EditorState.forceSelection(
			newEditorState,
			newContent.getSelectionAfter()
		);
		updateEditorState(newEditorState)
	}

	const toggleItalic = (event: any) => {
		event.preventDefault();
		const currentSelection = editorState.getSelection();
		const endSelection = currentSelection.getFocusOffset();
		const startSelection = currentSelection.getAnchorOffset();
		let newEditorState = editorState;
		if (endSelection != startSelection) {
			newEditorState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
		} else {
			const selectionState = editorState.getSelection();
			const currentKey = selectionState.getAnchorKey();
			const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);
			const paragraphStart = getParagraphStart(selectionState.getFocusOffset(), currentBlock.getText());
			const paragraphEnd = getParagraphEnd(selectionState.getFocusOffset(), currentBlock.getText());
			if (paragraphStart == paragraphEnd) {
				newEditorState = RichUtils.toggleInlineStyle(editorState, "ITALIC");
			} else {
				const entireBlockSelectionState = selectionState.merge({
					anchorKey: currentKey,
					anchorOffset: paragraphStart,
					focusKey: currentKey,
					focusOffset: paragraphEnd
				});
				const selectedState = EditorState.forceSelection(
					editorState,
					entireBlockSelectionState
				);
				newEditorState = RichUtils.toggleInlineStyle(selectedState, "ITALIC");
			}
		}
		updateEditorState(newEditorState);
	}

	const getParagraphStart = (cursorPosition: number, text: string): number|undefined =>  {
		if (cursorPosition == 0) {
			return 0;
		}
		let paragraphStart = 0;

		const paragraphs = text.split('\n');

		return paragraphs.map(paragraph => {
			if (paragraphStart + paragraph.length >= cursorPosition) {
				return paragraphStart;
			} else {
				paragraphStart+= paragraph.length;
				return undefined;
			}
		}).find(i => i !== undefined)
	}

	const getParagraphEnd = (cursorPosition: number, text: string): number|undefined =>  {
		if (cursorPosition == 0) {
			return 0;
		}
		let paragraphEnd = 0;

		const paragraphs = text.split('\n');

		return paragraphs.map(i => i.length).map(paragraphLength => {
			paragraphEnd+= paragraphLength
			if (paragraphEnd >= cursorPosition) {
				return paragraphEnd;
			} else {
				return undefined;
			}
		}).find(i => i !== undefined)
	}

	const updateEditorState = (newEditorState: EditorState) => {
		const editorStyle = newEditorState.getCurrentInlineStyle();

		setIsItalic(editorStyle.contains('ITALIC'))

		setEditorState(newEditorState)
	}

	const imagedClicked = (event: any, image: Asset) => {
		event.preventDefault();
		setSelectedImage(image)
	}

	const handleReturn = () => {
		setTimeout(() => inputRef.current!.focus(), 1);
		setTimeout(() => editorRef.current!.focusEditor(), 1);
	  	return false;
	}

	const customImageSelected = selectedImage && selectedLocation && !selectedLocation.images.find(i => i == selectedImage );

	return (
		<React.Fragment>

			<IonContent className={'post-form-content ion-no-padding'}>
				<div className={'post-content-header'}>
					<MemberInformationHeader />
					<p
						className={'italic-toggle' + (isItalic ? ' italic' : '')}
						onMouseDown={toggleItalic}
					>
						Aa
					</p>
				</div>
				<Editor
					toolbarHidden
					stripPastedStyles
					placeholder={'What do you want people to know?'}
					onEditorStateChange={updateEditorState}
					editorState={editorState}
					handleReturn={handleReturn}
					ref={editorRef}
				/>
				<input className={'dummy-input'} ref={inputRef}/>
				<h4>{selectedLocation.name}</h4>
				{selectedLocation.address && <h5>{selectedLocation.address}</h5>}
				<div className={'image-selector total-images-' + selectedLocation.images.length}>
					<div
						className={customImageSelected ? 'selected' : 'image-uploader-placeholder'}
						style={customImageSelected ? {backgroundImage: `url(${selectedImage?.url})`} : {}}
					>
						<AssetUploader
							uploadEndpoint={`/users/${user.id}/assets`}
							assetUploaded={setSelectedImage}
							type={'image'}
						>
							{!customImageSelected && <IonIcon icon={'/assets/app-icons/profile-image.svg'}/>}
						</AssetUploader>
					</div>
					{selectedLocation.images.map(i =>
						<div
							key={i.id}
							onMouseDown={(event) => imagedClicked(event, i)}
							className={i == selectedImage ? 'selected' : ''}
							style={{backgroundImage: `url(${i.url})`}}
						/>
					)}
				</div>
			</IonContent>

			<BottomStickySection>
				<SideBySideComponent>
					<p onClick={() => addSpecialText('I love this place!')}>
						I love this place!
					</p>
					<p onClick={() => addSpecialText('Highly recommend!')}>
						Highly recommend!
					</p>
				</SideBySideComponent>
			</BottomStickySection>
		</React.Fragment>
	)
}

interface MemberPostCreatorFormProps {
	user: User,
	onPostCreated: (post: Post) => void,
}

const MemberPostCreatorForm: React.FC<MemberPostCreatorFormProps> = ({user, onPostCreated}) => {

	const history = useHistory();
	const [selectedLocation, setSelectedLocation] = useState<LocationData|undefined>(undefined);
	const [postCreationData, setPostCreationData] = useState<PostCreationData>({...defaultPostCreationData});

	const handleLocationSelection = (result: google.maps.places.PlaceResult|Location) => {
		// check if google place
		if ((result as google.maps.places.PlaceResult).address_components) {
			const googlePlace = (result as google.maps.places.PlaceResult);
			const longitude = googlePlace.geometry?.location.lng();
			const latitude = googlePlace.geometry?.location.lat();
			const placeId = googlePlace.place_id
			if (placeId && latitude && longitude) {
				setSelectedLocation({
					name: googlePlace.name,
					address: googlePlace.formatted_address,
					images: [],
					postLocation: {
						latitude,
						longitude,
						reference_id: placeId,
						reference_type: 'google_place',
					}
				})
			}
		} else if ((result as Location).latitude) {
			// This is a geddit local location
			const gedditLocalLocation = result as Location;
			const latitude = gedditLocalLocation.latitude;
			const longitude = gedditLocalLocation.longitude;
			const locationId = gedditLocalLocation.id;
			if (locationId && latitude && longitude) {
				const featuredImages = gedditLocalLocation.business?.featured_images ?? [];
				setSelectedLocation({
					name: gedditLocalLocation.name,
					locationData: gedditLocalLocation,
					address: gedditLocalLocation.address + ', ' + gedditLocalLocation.city + ", " + gedditLocalLocation.state + " " + gedditLocalLocation.zip,
					images: featuredImages,
					postLocation: {
						latitude,
						longitude,
						reference_id: locationId,
						reference_type: 'location',
					}
				})
			}
		}
	}

	const reset = () => {
		setSelectedLocation(undefined);
		setPostCreationData({...defaultPostCreationData})
	}

	const submit = () => {

		const postData = {
			post_type : 'referral',
			publish: true,
			article_content: postCreationData.article_content,
			post_locations: postCreationData.post_locations,
		} as any

		if (postCreationData.main_image_id) {
			postData.main_image_id = postCreationData.main_image_id;
		}

		PostManagementRequests
			.createPost('users', user, postData)
			.then(createdPost => {
				createdPost.post_locations = postCreationData.post_locations;
				createdPost.locations = selectedLocation?.locationData ? [selectedLocation.locationData] : [];
				onPostCreated(createdPost);
			})
			.catch(console.error)
	}

	const cancel = () => {
		history.goBack()
		reset();
	}

	useEffect(() => {
		if (!history.location.pathname.includes('post-creator')) {
			reset();
		}
	}, [history.location.pathname])

	return (
		<div className={'member-post-creator-form ' + (selectedLocation ? 'scroll-locked' : '')}>
			<IonHeader className={"ion-no-border"}>
				<IonToolbar>
					<IonButtons slot={'start'}>
						<IonButton
							fill={'clear'}
							color={'medium'}
							onClick={cancel}
							class={'close-button'}
						>
							X
						</IonButton>
					</IonButtons>
					<IonTitle>
						{!selectedLocation && 'Search Location'}
					</IonTitle>
					<IonButtons slot={'end'}>
						{selectedLocation &&
							<IonButton
								disabled={!postCreationData.has_content}
								onClick={submit}
								color={'success'}
								fill={'solid'}
								className={'submit-button'}
							>
								Post
							</IonButton>
						}
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			{!selectedLocation ?
				<GooglePlacesSearchField
					privacyPolicyURL={''}
					onComplete={handleLocationSelection}
					includeGedditLocalResults
					placeholder={'Search'}
					mode={'page'}
					format={'detailed'}
					gedditLocalAmount={20}
				/> :
				<MemberPostCreatorContentEditor
					user={user}
					onPostCreationDataChanged={setPostCreationData}
					selectedLocation={selectedLocation}
				/>
			}
		</div>
	)
}

interface MemberPostCreatorContentProps {
	meContext: MeContextStateConsumer,
}

const MemberPostCreatorContent: React.FC<MemberPostCreatorContentProps> = ({ meContext}) => {
	const [profileComplete, setProfileComplete] = useState(!!meContext.me.profile_image_url)
	const navigate = useHistory();

	const postSaved = (post: Post, publishedPostsContext: PublishedPostsContextState, pendingPostsContext: PendingPostsContextState, addPostToFeed: (post: Post) => void) => {

		post.publisher = meContext.me;
		if (isPostPublished(post)) {
			addPostToFeed(post);
			publishedPostsContext.addModel(post);
			pendingPostsContext.removeModel(post);
		} else {
			pendingPostsContext.addModel(post);
		}

		navigate.replace('/home/dashboard/feed/nearby');
	}

	return profileComplete ? (
		<GeoLocationContextProvider privacyPolicyURL={'/home/dashboard/account/settings/help/privacy-policy'} locationRequired>
			<GeoLocationContext.Consumer>
				{getLocationContext =>
					<PublishedPostsContextProvider publisherType={'users'} publisherId={meContext.me.id!}>
						<PublishedPostsContext.Consumer>
							{publishedPostsContext =>
								<PendingPostsContextProvider publisherType={'users'} publisherId={meContext.me.id!}>
									<PendingPostsContext.Consumer>
										{pendingPostsContext =>
											<NearbyPostsContextProvider latitude={getLocationContext.location.coords.latitude}
																		longitude={getLocationContext.location.coords.longitude}>
												<NearbyPostsContext.Consumer>
													{nearByPostsContext =>
														<MemberPostCreatorForm
															onPostCreated={post => postSaved(post, publishedPostsContext, pendingPostsContext, nearByPostsContext.addModel)}
															user={meContext.me}
														/>
													}
												</NearbyPostsContext.Consumer>
											</NearbyPostsContextProvider>
										}
									</PendingPostsContext.Consumer>
								</PendingPostsContextProvider>

							}
						</PublishedPostsContext.Consumer>
					</PublishedPostsContextProvider>
				}
			</GeoLocationContext.Consumer>
		</GeoLocationContextProvider>
	) : (
		<ProfileIncomplete meContext={meContext} onProfileComplete={(value: boolean) => setProfileComplete(value)}  />
	)
}

interface StateProps {
	hasSeenReferralsTutorial: boolean
}

interface DispatchProps {
	setHasSeenReferralsTutorial: typeof setHasSeenReferralsTutorial,
}

interface MemberPostCreatorProps extends StateProps, DispatchProps {}

const MemberPostCreator: React.FC<MemberPostCreatorProps> = ({hasSeenReferralsTutorial, setHasSeenReferralsTutorial})  => {

	const history = useHistory();
	const [isTutorialOpen, setIsTutorialOpen] = useState(!hasSeenReferralsTutorial)

	const tutorialAccepted = () => {
		setIsTutorialOpen(false);
		setHasSeenReferralsTutorial(true)
	}

	const tutorialDeclined = () => {
		if (!hasSeenReferralsTutorial && isTutorialOpen) {
			history.goBack();
		}
		setIsTutorialOpen(false);
	}

	useIonViewDidEnter(() => {
		setIsTutorialOpen(!hasSeenReferralsTutorial)
	})

	return (
		<IonPage id={'post-editor-page'} className={'member-post-creator-page'}>
			<IonContent>
				<MeContextProvider>
					<MeContext.Consumer>
						{ meContext =>
							<MemberPostCreatorContent
								meContext={meContext}
							/>
						}
					</MeContext.Consumer>
				</MeContextProvider>
				<ReferralTutorialModal
					isOpen={!hasSeenReferralsTutorial && isTutorialOpen}
					onDidDismiss={tutorialDeclined}
					onAccepted={tutorialAccepted}
				/>
			</IonContent>
		</IonPage>
	)
}

export default connect<{}, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		hasSeenReferralsTutorial: state.persistent.hasSeenReferralsTutorial
	}),
	mapDispatchToProps: ({
		setHasSeenReferralsTutorial,
	}),
	component: MemberPostCreator
});

