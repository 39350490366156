import './index.scss';
import React, {useEffect, useRef, useState} from 'react';
import Post, {
	postPlaceholder,
	PostType, toPostServerObject
} from '../../../models/post/post';
import Location from '../../../models/location/location';
import {
	createGesture, Gesture,
	IonAlert,
	IonButton,
	IonContent,
	IonIcon, IonItem, IonLabel,
	IonSegment,
	IonSegmentButton, IonToast, IonToggle,
} from '@ionic/react';
import BottomStickySection from '../../BottomStickySection';
import SideBySideComponent from '../../SideBySide';
import AssetUploader from '../../AssetUploader';
import Asset from '../../../models/asset';
import {
	imagesOutline,
} from 'ionicons/icons';
import TextInputWithLabelInBorder from '../../GeneralUIElements/TextInputWithLabelInBorder';
import PostManagementRequests from '../../../services/requests/PostManagementRequests';
import GreyBackgroundSection from '../../GreyBackgroundSection';
import {useLocation} from 'react-router-dom';
import {urlRegExp, validateRegexMatch} from '../../../util/regex';
import LocationSelect from '../../OrganizationManagement/LocationSelect';
import PostLocation from '../../../models/post/post-location';
import Business from '../../../models/organization/business';
import LabelInBorder from "../../GeneralUIElements/LabelInBorder";
import ColorSelector from './ColorSelector';
import ScheduleSection from './ScheduleSection';

interface PostEditorProps {
	publisher: Business,
	assetUploadUrl: string,
	onBeforePostSaved?: (postContent: any) => any,
	onPostSaved: (preview: boolean, post: Post) => void,
	availablePostTypes: PostType[],
	post?: Post,
	locations?: Location[],
	selectedLocations: Location[],
	locationsSelected?: (locations: Location[]) => void ,
	skipPreviewSave?: boolean,
}

const PostEditorForm: React.FC<PostEditorProps> = ({assetUploadUrl, availablePostTypes, publisher, onBeforePostSaved, onPostSaved, locations, selectedLocations, locationsSelected, post, skipPreviewSave, ...rest}) => {

	const location = useLocation();

	const content = useRef<HTMLIonContentElement|null>(null);

	const [gesture, setGesture] = useState<Gesture|undefined>(undefined);

	useEffect(() => {
		const postEditorPage = document.getElementById('post-editor-page');
		let gestureInstance = gesture;
		if (!gesture && postEditorPage) {
			gestureInstance = createGesture({
				el: postEditorPage as Node,
				threshold: 0,
				gestureName: 'my-gesture',
				gesturePriority: 40.5, // priority of swipe to go back is 40
				onMove: ev => console.log(ev)
			});
			setGesture(gestureInstance);
		}

		if (gestureInstance) {
			gestureInstance.enable(postEditorPage !== undefined);
		}

	}, [window.location])

	const [followersHelpOpen, setFollowersHelpOpen] = useState(false);
	const [editingPost, setEditingPost] = useState(post ?? postPlaceholder(availablePostTypes));
	const [hasButton, setHasButton] = useState<boolean>(editingPost.url?.length ? editingPost.url?.length > 0 : false);
	const [hasPriceRange, setHasPriceRange] = useState<boolean>(editingPost.max_price ? editingPost.max_price.length > 0 : false);
	const [priceText, setPriceText] = useState(editingPost.price ?? '$');
	const [maxPriceText, setMaxPriceText] = useState(editingPost.max_price ?? '$');
	const [submissionError, setSubmissionError] = useState('');
	const [renderingPostId, setRenderingPostId] = useState<number|undefined>(undefined);
	const [postImage, setPostImage] = useState({
		id: editingPost.main_image_id,
		url: editingPost.main_image_url
	} as Asset);

	const resetState = () => {
		setFollowersHelpOpen(false);
		setEditingPost(post ?? postPlaceholder(availablePostTypes));
		setHasButton(post && post.url ? post.url.length > 0 : false);
		setPriceText(post && post.price ? post.price : '$');
		setMaxPriceText(post && post.max_price ? post.max_price : '');
		content.current?.scrollToTop(0);
		setPostImage({
			id: editingPost.main_image_id,
			url: editingPost.main_image_url
		} as Asset)
	}

	useEffect(() => {
		if (!post || post.id != renderingPostId) {
			resetState();
			setRenderingPostId(post?.id);
		}
	}, [post]);

	useEffect(() => {
		if (location.pathname.indexOf('post') != -1) {
			resetState();
		}
	}, [location.pathname, publisher])

	let titleLabel = 'Headline (optional)';
	let titlePlaceholder = 'Capture people\'s attention...';
	let descriptionLabel = 'Caption (optional)';
	if (editingPost.post_type == 'product') {
		titleLabel = 'Product Name';
		titlePlaceholder = '';
		descriptionLabel = 'Product Description'
	} else if (editingPost.post_type == 'service') {
		titleLabel = 'Service Name';
		descriptionLabel = 'Service Description'
	}

	const selectedPostType = (type: PostType) => {
		setEditingPost({
			...editingPost,
			post_type: type,
		});
	}

	const titleSet = (title: string) => {
		setEditingPost({
			...editingPost,
			title: title,
		});
	}

	const priceSet = (price: string|undefined) => {
		setPriceText(price ?? '$');
		setEditingPost({
			...editingPost,
			price: price,
		})
	}

	const maxPriceSet = (maxPrice: string|undefined) => {
		setMaxPriceText(maxPrice ?? '$');
		setEditingPost({
			...editingPost,
			max_price: maxPrice,
		})
	}

	const toggleHasPriceRange = () => {
		setHasPriceRange(!hasPriceRange);
	}

	const setEditingPostField = (key: string, value: any) => {
		setEditingPost({
			...editingPost,
			[key]: value,
		});
	}

	const articleContentSet = (articleContent: string) => {
		setEditingPost({
			...editingPost,
			article_content: articleContent,
		});
	}

	const buttonLabelSet = (buttonLabel: string) => {
		setEditingPost({
			...editingPost,
			button_label: buttonLabel,
		});
	}

	const urlSet = (url: string) => {
		setEditingPost({
			...editingPost,
			url: url,
		});
	}

	const buttonColorSet = (buttonColor: string) => {
		setEditingPost({
			...editingPost,
			button_color: buttonColor,
		});
	}

	const validatePost = () : boolean => {

		if (!postImage.id) {
			setSubmissionError('Please upload an image.')
			return false;
		}

		if (hasButton) {
			if (!editingPost.url) {
				setSubmissionError('If you add a button to your post, you must set the url to open when the button is clicked.')
				return false;
			}
			if (!editingPost.button_label) {
				setSubmissionError('If you add a button to your post, you must set the label that will show on your button.')
				return false;
			}
			if (editingPost.button_label.length > 15) {
				setSubmissionError('Your button label cannot be more than 15 characters');
				return false;
			}
			if (!validateRegexMatch(urlRegExp, editingPost.url)) {
				setSubmissionError('Please enter a valid URL for your button action.')
				return false;
			}
		}

		if (editingPost.published_at && editingPost.expires_at) {
			const publishedAt = Date.parse(editingPost.published_at);
			const expiresAt = Date.parse(editingPost.expires_at);

			if (publishedAt > expiresAt) {
				setSubmissionError('Your post must start before it ends.')
				return false;
			}
		}

		return true;
	}

	const postLocations = (selectedLocations ?? []).map(location => ({
			reference_id: location.id!,
			reference_type: 'location',
			latitude: location.latitude!,
			longitude: location.longitude!,
		}))

	const postContent = toPostServerObject(editingPost, postLocations as PostLocation[], postImage);

	const savePost = (preview: boolean) => {

		let saveData = {...postContent};

		if (!preview && !editingPost.published_at) {
			saveData.publish = true;
		}

		if (onBeforePostSaved) {
			saveData = onBeforePostSaved(saveData);
		}

		if (preview && skipPreviewSave) {
			saveData.main_image_id = postImage.id;
			saveData.main_image_url = postImage.url;
			saveData.locations = saveData.post_locations.map((postLocation: PostLocation) => {
				return postLocation.reference_type == 'location' ?
					selectedLocations?.find(i => i.id == postLocation.reference_id) : undefined
			}).filter((i: Location|undefined) => i)
			onPostSaved(preview, saveData);
		} else {
			(editingPost.id ?
					PostManagementRequests.updatePost('businesses', publisher, editingPost, saveData) :
					PostManagementRequests.createPost('businesses', publisher, saveData)
			).then(post => {
				setEditingPost(post);
				onPostSaved(preview, post);
				if (!preview) {
					resetState();
				}
			});
		}
	}

	const submitPost = (preview: boolean) => {
		if (validatePost()) {
			savePost(preview);
		}
	}

	const colorOptions = [
		'#000000',
		'#FF0000',
		'#3298b3',
		'#008037',
		'#004aac',
		'#e77818',
		'#ffde58',
		'#8b52ff',
		'#FFFFFF'
	]

	useEffect(() => {
		if (!hasPriceRange) {
			maxPriceSet(undefined);
		}

	}, [hasPriceRange])

	return (
		<React.Fragment>
			<IonContent ref={content} className={'post-editor-form'}>
				{(availablePostTypes.length > 1) &&
					<IonSegment onIonChange={(event) => selectedPostType(event.detail.value as PostType)} value={editingPost.post_type}>
						<IonSegmentButton value={'news'} layout={'icon-start'}>
							<IonIcon src={'/assets/app-icons/news.svg'}/>News
						</IonSegmentButton>
						<IonSegmentButton value={'product'} layout={'icon-start'}>
							<IonIcon src={'/assets/app-icons/product.svg'}/>Product
						</IonSegmentButton>
						<IonSegmentButton value={'service'} layout={'icon-start'}>
							<IonIcon src={'/assets/app-icons/service.svg'}/>Service
						</IonSegmentButton>
					</IonSegment>
				}
				{(locations && locations.length > 1) &&
					<LocationSelect
						availableLocations={locations}
						selectedLocations={selectedLocations}
						mode={'multiple'}
						onLocationsSelected={locationsSelected}
					/>
				}
				<AssetUploader
					type={'multimedia'}
					uploadEndpoint={assetUploadUrl} assetUploaded={setPostImage}>
					{postImage.url ?
						<div className={'main-image-preview'} style={{backgroundImage: `url('${postImage.url}')`}}/> :
						<React.Fragment>
							<IonIcon icon={imagesOutline}/>
							<p>Add photo</p>
						</React.Fragment>
					}
				</AssetUploader>
				<TextInputWithLabelInBorder
					label={titleLabel}
					placeholder={titlePlaceholder}
					value={editingPost.title ?? ''}
					onInputChange={titleSet}
					autoCapitalize={"sentences"}
					className={'title-input'}
				/>
				{['product', 'service'].indexOf(editingPost.post_type) != -1 &&
					<React.Fragment>
						<IonItem lines={'none'} className={'toggle-line'}>
							<IonLabel>+ Add a price range</IonLabel>
							<IonToggle
								slot={'end'}
								onIonChange={() => toggleHasPriceRange()}
								checked={hasPriceRange}
							/>
						</IonItem>
						<TextInputWithLabelInBorder
							label={'Enter Price ' + (hasPriceRange ? 'Minimum' : '(Optional)')}
							value={priceText}
							type={'text'}
							onInputChange={priceSet}
						/>
						{hasPriceRange &&
							<TextInputWithLabelInBorder
								label={'Enter Price Max'}
								value={maxPriceText}
								type={'text'}
								onInputChange={maxPriceSet}
							/>
						}
					</React.Fragment>
				}
				<TextInputWithLabelInBorder
					label={descriptionLabel}
					placeholder={`Tell people about your ${editingPost.post_type}...`}
					value={editingPost.article_content ?? ''}
					onInputChange={articleContentSet}
					autoCapitalize={"sentences"}
					multiline={true}
				/>
				<LabelInBorder label={""} className={'button-section'}>
					<IonItem lines={'none'} className={'toggle-line'}>
						<IonLabel onClick={() => setHasButton(!hasButton)}>+ Add a button (optional)</IonLabel>
						<IonToggle
							mode={'ios'}
							slot={'end'}
							onIonChange={event => setHasButton(event.detail.checked)}
							checked={hasButton}
						/>
					</IonItem>
					{hasButton &&
						<React.Fragment>
							<TextInputWithLabelInBorder
								label={'Add button label'}
								value={editingPost.button_label ?? ''}
								onInputChange={buttonLabelSet}
								charCount={15}
							/>
							<TextInputWithLabelInBorder
								label={'Enter a website'}
								value={editingPost.url ?? ''}
								onInputChange={urlSet}
							/>
							<ColorSelector
								label={'Choose button color'}
								activeColor={editingPost.button_color}
								colorOptions={colorOptions}
								onColorSet={buttonColorSet}/>
						</React.Fragment>
					}
				</LabelInBorder>
				{/*<LabelInBorder label={''}>*/}
				{/*	<IonItem lines={'none'} className={'toggle-line'}>*/}
				{/*		<IonLabel onClick={() => setEditingPostField('comments_allowed', !editingPost.comments_allowed)}>Allow Comments on Post?</IonLabel>*/}
				{/*		<IonToggle*/}
				{/*			mode={'ios'}*/}
				{/*			slot={'end'}*/}
				{/*			onIonChange={event => setEditingPostField('comments_allowed', event.detail.checked)}*/}
				{/*			checked={editingPost.comments_allowed}*/}
				{/*		/>*/}
				{/*	</IonItem>*/}
				{/*</LabelInBorder>*/}
				<ScheduleSection
					publishedAt={editingPost.published_at}
					onPublishedAtSet={value => setEditingPostField('published_at', new Date(value).toUTCString())}
					expiresAt={editingPost.expires_at}
					onExpiresAtSet={value => setEditingPostField('expires_at', new Date(value).toUTCString())}
				/>
				<GreyBackgroundSection>
					<SideBySideComponent>
						<IonIcon color={'primary'} src={'/assets/app-icons/notify-followers.svg'}/>
						<p>
							We'll notify your followers at the time this ad is posted.
						</p>
					</SideBySideComponent>
				</GreyBackgroundSection>
				{(publisher as Business).social_media_connections.find(i => i.type == 'instagram') &&
					<IonItem lines={'none'} className={'toggle-line'}>
						<img className={'social-logo'} src={'/assets/instagram-logo.svg'} slot={'start'}/>
						<IonLabel>Share to Instagram?</IonLabel>
						<IonToggle
							slot={'end'}
							onIonChange={() => setEditingPostField('publish_instagram', !editingPost.publish_instagram)}
							checked={editingPost.publish_instagram}
						/>
					</IonItem>
				}
				{(publisher as Business).social_media_connections.find(i => i.type == 'facebook') &&
					<IonItem lines={'none'} className={'toggle-line'}>
						<img className={'social-logo'} src={'/assets/facebook-logo.svg'} slot={'start'}/>
						<IonLabel>Share to Facebook?</IonLabel>
						<IonToggle
							slot={'end'}
							onIonChange={() => setEditingPostField('publish_facebook', !editingPost.publish_facebook)}
							checked={editingPost.publish_facebook}
						/>
					</IonItem>
				}
			</IonContent>
			<BottomStickySection className={'submit-post-sticky-section'}>
				{editingPost.published_at && new Date(editingPost.published_at) < new Date() ?
					<IonButton onClick={() => submitPost(false)}>Save</IonButton> :
					<SideBySideComponent>
						<IonButton onClick={() => submitPost(true)} className={'secondary-button-outlined'}>
							Preview
						</IonButton>
						<IonButton onClick={() => submitPost(false)} className={'bold'}>
							{editingPost.published_at ?
								(editingPost.id ? 'Save' : 'Schedule')
								: 'Publish'
							}
						</IonButton>
					</SideBySideComponent>
				}
			</BottomStickySection>
			<IonAlert
				isOpen={followersHelpOpen}
				message={'Selecting this option will route your ad exclusively to the people that Follow your business.'}
				buttons={['OK!']}
				onDidDismiss={() => setFollowersHelpOpen(false)}
			/>
			<IonToast
				isOpen={submissionError.length > 0}
				message={submissionError}
				duration={2000}
				onDidDismiss={() => setSubmissionError('')}
			/>
		</React.Fragment>
	);
};

export default PostEditorForm
