import React from 'react';
import {
    IonButton,
    IonContent,
    IonIcon,
    IonLabel,
    IonPage,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from '@ionic/react';
import './index.scss';
import {Redirect} from 'react-router-dom';
import {Route} from 'react-router';
import Published from './Published';
import Upcoming from './Upcoming';
import Post from '../../Post';
import PostInteractions from '../../PostInteractions';
import OrganizationTemplate from '../../../../components/OrganizationManagement/OrganizationTemplate';

const ActivityTabs: React.FC = () => {
    return (
        <IonTabs className={'activity-tabs-content'}>
            <IonRouterOutlet>
                <Redirect exact path="/organization/dashboard/activity" to="/organization/dashboard/activity/published"/>
                <Route exact path={`/organization/dashboard/activity/published`} render={() => <Published/>}/>
                <Route exact path={`/organization/dashboard/activity/upcoming`} render={() => <Upcoming/>}/>
            </IonRouterOutlet>
            <IonTabBar slot="top">
                <IonTabButton tab='published' href={`/organization/dashboard/activity/published`}>
                    <IonLabel>Published</IonLabel>
                </IonTabButton>
                <IonTabButton tab='upcoming' href={`/organization/dashboard/activity/upcoming`}>
                    <IonLabel>In Queue</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    )
}

const Activity: React.FC = () => {
    return (
        <IonPage>
            <IonContent>

                <IonRouterOutlet>
                    <Redirect exact path="/organization/dashboard/activity" to="/organization/dashboard/activity/published"/>
                    <Route path={`/organization/dashboard/activity/post/:postId`} render={() => <Post/>}/>
                    <Route path={`/organization/dashboard/activity/post/:postId/:interaction`} render={() => <PostInteractions/>}/>
                    <Route exact path={"/organization/dashboard/activity/published"} render={() => <ActivityTabs/>}/>
                    <Route exact path={"/organization/dashboard/activity/upcoming"} render={() => <ActivityTabs/>}/>
                </IonRouterOutlet>
            </IonContent>

        </IonPage>
    )
}

export default Activity
