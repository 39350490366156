import {IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonModal, IonTitle, IonToolbar} from '@ionic/react';
import React from 'react';
import Post from '../../../models/post/post';
import './index.scss';
import ProfileImage from '../../ProfileImage';
import User from '../../../models/user/user';
import Location from '../../../models/location/location';
import {formatTimeAgo} from '../../../util/time';
import moment from "moment";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import {
    UserFollowsContext,
    UserFollowsContextProvider,
    UserFollowsContextState
} from "../../../contexts/UserFollowsContext";
import FollowButton from "../../MembersPages/FollowButton";

interface PostPublishedDetailsProps {
    post: Post,
    locationPageUrlRoot: string,
    onFollow?: (id: number) => void,
    onUnfollow?: () => void,
    isPreview?: boolean,
    location?: Location,
    userPageUrlRoot?: string,
    userFollowsContext?: UserFollowsContextState,
}

const PostPublishedDetails: React.FC<PostPublishedDetailsProps> = ({post, location, isPreview, onFollow, onUnfollow, userPageUrlRoot, locationPageUrlRoot, userFollowsContext}) => {

    const hasMember = post.publisher_type === 'user' && post.publisher;

    const publisherName = hasMember ? (post.publisher as User).full_name : location?.name;
    const profileImageUrl = hasMember ? (post.publisher as User).profile_image_url : location?.logo;
    let publisherLink = undefined;

    if (!isPreview) {
        if (hasMember) {
            publisherLink = userPageUrlRoot + '/user/' + post.publisher_id!;
        } else if (location) {
            publisherLink = locationPageUrlRoot + '/business/' + location.business_id + '/location/' + location.id!;
        }
    }

    const date = post.published_at ? post.published_at : null

    return (
        <IonItem
            className={'post-published-details'}
            routerLink={publisherLink}
            detail={false}
            lines={'none'}
        >
            <ProfileImage
                name={publisherName ?? ''}
                url={profileImageUrl}
                slot={'start'}
            />
            <p>
                <strong>{publisherName}</strong>
                <br/>
                {(!isPreview && date) &&
                    <small>{formatTimeAgo(date)}</small>
                }
            </p>
            {userFollowsContext ?
                <FollowButton
                    relatedId={(post.publisher as User).id!}
                    relatedType={'user'}
                    related={(post.publisher as User)}
                    userFollowsContext={userFollowsContext}
                    onFollow={(follower) => {
                        if (onFollow && follower.id! > 0) {
                             onFollow(follower.id!)
                        }
                    }}
                    onUnfollow={onUnfollow}
                    whiteButton
                    hideBell
                /> : null
            }
        </IonItem>
    )
}

export default PostPublishedDetails;
