import React, {useEffect, useState} from 'react';
import Post, {
    isPostExpired, isPostPublished,
} from '../../../models/post/post';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {
    PublishedPostsContext,
    PublishedPostsContextProvider, PublishedPostsContextState
} from '../../../contexts/PublishedPostsContext';
import {PostContext, PostContextProvider} from '../../../contexts/PostContext';
import SubscriptionRequired from '../../OrganizationManagement/SubscriptionRequired';
import BusinessPostEditor from '../BusinessPostEditor';
import {useHistory} from 'react-router';
import {
    PendingPostsContext,
    PendingPostsContextProvider,
    PendingPostsContextState
} from '../../../contexts/PendingPostsContext';

interface BusinessPostEditorWrapperProps {
    postId?: string,
    onPostPreview : (post: Post) => void,
    onPostPublished? : (post: Post) => void,
    skipPreviewSave?: boolean,
}

const BusinessPostEditorWrapper: React.FC<BusinessPostEditorWrapperProps> = ({ postId, onPostPreview, onPostPublished, skipPreviewSave, ...rest}) => {

    const navigate = useHistory();
    const [savedPost, setSavedPost] = useState<Post|undefined>(undefined);

    useEffect(() => {
        if (window.location.pathname.indexOf('post-creator') == -1) {
            setSavedPost(undefined);
        }
    }, [window.location.pathname])

    const postSaved = (preview: boolean, post: Post, publishedPostsContext: PublishedPostsContextState, pendingPostsContext: PendingPostsContextState, setPost?: (post: Post) => void) => {
        setSavedPost(post);

        if (setPost) {
            setPost(post)
        }

        if (!preview) {
            if (isPostPublished(post)) {
                publishedPostsContext.addModel(post);
                pendingPostsContext.removeModel(post);
            } else {
                pendingPostsContext.addModel(post);
            }

            if (onPostPublished) {
                onPostPublished(post);
            } else {
                navigate.push(`/organization/post-creator/published`);
            }
        } else {
            onPostPreview(post);
        }
    }

    return (
        <SubscriptionRequired>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <PendingPostsContextProvider publisherType={'businesses'} publisherId={myBusinessContext.business.id!}>
                            <PendingPostsContext.Consumer>
                                {pendingPostsContext =>
                                    <PublishedPostsContextProvider publisherType={'businesses'}
                                                                   publisherId={myBusinessContext.business.id!}>
                                        <PublishedPostsContext.Consumer>
                                            {publishedPostsContext => (postId ?
                                                    <PostContextProvider postId={parseInt(postId)} skipCache>
                                                        <PostContext.Consumer>
                                                            {postContext =>
                                                                <BusinessPostEditor
                                                                    organization={myBusinessContext.organization}
                                                                    locations={myBusinessContext.business.locations ?? []}
                                                                    post={{
                                                                        ...postContext.post,
                                                                        id: isPostExpired(postContext.post) ? undefined : postContext.post.id,
                                                                        published_at: isPostExpired(postContext.post) ? undefined : postContext.post.published_at,
                                                                    }}
                                                                    business={myBusinessContext.business}
                                                                    onPostSaved={(preview, post) => postSaved(preview, post, publishedPostsContext, pendingPostsContext, postContext.setPost)}
                                                                    setSavedPost={setSavedPost}
                                                                    savedPost={savedPost}
                                                                    skipPreviewSave={skipPreviewSave}
                                                                />
                                                            }
                                                        </PostContext.Consumer>
                                                    </PostContextProvider>
                                                    : <BusinessPostEditor
                                                        business={myBusinessContext.business}
                                                        locations={myBusinessContext.business.locations ?? []}
                                                        organization={myBusinessContext.organization}
                                                        post={savedPost}
                                                        onPostSaved={(preview, post) => postSaved(preview, post, publishedPostsContext, pendingPostsContext)}
                                                        setSavedPost={setSavedPost}
                                                        savedPost={savedPost}
                                                        skipPreviewSave={skipPreviewSave}
                                                    >
                                                    </BusinessPostEditor>

                                            )}
                                        </PublishedPostsContext.Consumer>
                                    </PublishedPostsContextProvider>
                                }
                            </PendingPostsContext.Consumer>
                        </PendingPostsContextProvider>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </SubscriptionRequired>
    )
};

export default BusinessPostEditorWrapper
