import React, {PropsWithChildren, useEffect, useState} from 'react';
import {IonButton, IonContent, IonIcon, IonItem, IonMenu, useIonRouter} from '@ionic/react';
import './index.scss';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import AccountSwitcher from '../../AccountSwitcher';
import {arrowUndo, search, settings} from 'ionicons/icons';
import { logOut } from '../../../data/persistent/persistent.actions';
import { clearLoadingCount } from '../../../data/session/session.actions';
import {useHistory, useLocation} from 'react-router';
import {connect} from '../../../data/connect';

interface WelcomeMenuLinkProps {
    url: string,
    onClick?: () => void,
}

const WelcomeMenuLink: React.FC<WelcomeMenuLinkProps> = ({url, onClick, children}) => {

    return (
        <IonItem lines={'none'} routerLink={onClick ? undefined : url} onClick={onClick} className={window.location.pathname == url ? 'active' : ''}>
            {children}
        </IonItem>
    )
}
interface WelcomeMenuProps {
    disabled: boolean,
}

const WelcomeMenu: React.FC<WelcomeMenuProps> = ({disabled}) => {

    const location = useLocation();
    const history = useHistory();

    const [splashUrl, setSplashUrl] = useState('/welcome/splash/home');

    const replaceUrl = (url: string) => {
        if (window.location.pathname.indexOf('/welcome/splash') !== -1) {

            window.history.replaceState(null, '', url);
            window.dispatchEvent(new Event('splash-navigated'));
        } else {
            history.push(url);
        }
        setSplashUrl(url);
    }

    useEffect(() => {
        window.addEventListener('splash-navigated', () => {
            setSplashUrl(window.location.pathname);
        });
    }, [])

    return (
        <IonMenu className={'welcome-desktop-menu'} contentId={'welcome'} disabled={disabled}>
            <IonContent>
                {location.pathname.indexOf('post') !== -1 ?
                    <React.Fragment>
                        <IonItem className={'active'} lines={'none'}>
                            <IonIcon src={'/assets/app-icons/browse.svg'}/> Nearby
                        </IonItem>
                        <IonItem lines={'none'}>
                            <IonIcon src={'/assets/app-icons/star.svg'}/> Following
                        </IonItem>
                    </React.Fragment> :
                    <React.Fragment>
                        <WelcomeMenuLink url={ '/welcome/splash/home'} onClick={() => replaceUrl('/welcome/splash/home')}>
                            Home
                        </WelcomeMenuLink>
                        <WelcomeMenuLink url={'/welcome/splash/about'} onClick={() => replaceUrl('/welcome/splash/about')} >
                            How It Works
                        </WelcomeMenuLink>
                    </React.Fragment>
                }
                <hr/>
                <p>Login to follow vendors, like posts and view comments.</p>
                <IonButton routerLink={'/welcome/sign-in/phone'}>
                    Sign In
                </IonButton>
                <hr/>
                <WelcomeMenuLink url={'/welcome/terms'}>
                    Terms
                </WelcomeMenuLink>
                <WelcomeMenuLink url={'/welcome/privacy-policy'}>
                    Privacy
                </WelcomeMenuLink>
                <WelcomeMenuLink url={'/welcome/contact-us'}>
                    Contact
                </WelcomeMenuLink>
            </IonContent>
        </IonMenu>
    )
}

export default WelcomeMenu;
