import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import PostResponse from '../models/post/post-response';

/**
 * The state interface for our state
 */
export interface UserPostResponsesContextState extends BasePaginatedContextState<PostResponse> {}

const defaultContext = {
    ...defaultBaseContext(),
    limit: 20,
    order: {
        'created_at' : 'desc'
    },
    filter: {
        archived: 0,
        saved: 1,
        "post.removed_at": "null",
    },
    expands: [
        'follows',
        'follows.follows',
        'post',
        'post.locations',
        'post.publisher',
    ],
} as UserPostResponsesContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const UserPostResponsesContext = React.createContext<UserPostResponsesContextState>(defaultContext);

export interface UserPostResponsesContextProviderProps extends BasePaginatedContextProviderProps{
    userId: number,
    minimal?: boolean,
    searchText?: string,
}

export const UserPostResponsesContextProvider: React.FC<UserPostResponsesContextProviderProps> = (({searchText, userId, ...props}) => {
    const [userPostResponsesState, setUserPostResponsesState] = useState(persistentContext);

    useEffect(() => {
        prepareContextState(setUserPostResponsesState, userPostResponsesState,'/users/' + userId + '/post-responses')
    }, []);

    useEffect(() => {
        userPostResponsesState.setSearch('post.resource.content', searchText);
    }, [searchText])

    persistentContext = {
        ...createCallbacks(setUserPostResponsesState as any, userPostResponsesState, '/users/' + userId + '/post-responses'),
    };
    return (
        <UserPostResponsesContext.Provider value={persistentContext}>
            {props.children}
        </UserPostResponsesContext.Provider>
    )
});
