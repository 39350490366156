import {IonButton, IonContent, IonInput, IonPage} from '@ionic/react';
import React, {useState} from 'react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import MeContextProvider, {MeContext} from '../../../../../contexts/MeContext';
import User from '../../../../../models/user/user';
import ProfileImage from '../../../../../components/ProfileImage';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import InputWrapper from '../../../../../components/InputWrapper';
import AuthRequests, {LoginReq} from '../../../../../services/requests/AuthRequests';
import {useHistory} from 'react-router';
import GreyBackgroundSection from '../../../../../components/GreyBackgroundSection';
import './index.scss';
import UnderlinedInput from '../../../../../components/GeneralUIElements/UnderlinedInput';
import {chevronBack} from "ionicons/icons";

interface ChangePasswordFormProps {
    me: User,
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({me}) => {

    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const history = useHistory();

    const UpdatePasswordSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Please enter your current password'),
        newPassword: Yup.string().required('Please enter your new password').min(6, 'New Password must be at least 6 characters')
    })
	const form = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: ''
        },
        validationSchema: UpdatePasswordSchema,
        onSubmit: (values) => submit(values)
    });

    const submit = (values: { currentPassword: string; newPassword: string }) => {
        let userReq: LoginReq = {
            password: values.currentPassword,
            email: me.email,
        }

        AuthRequests.signIn(userReq).then(async result => {
            AuthRequests.updateMe(me.id!, {
                password: values.newPassword,
            }).then(() => {
                history.goBack();
            });
        }).catch(error => {
            if (error.status == 401) {
                setCurrentPasswordError(true);
            }
        })
    }

    return (
        <div className={'form-wrapper'}>
            <ProfileImage url={me.profile_image_url} name={me.full_name}/>
            <GreyBackgroundSection>
                <h4>New Password</h4>
                {currentPasswordError &&
                <p className={'error'}>Your current password does not seem right</p>
                }
                <UnderlinedInput>
                    <InputWrapper
                        label={'Current Password'}
                        error={form.errors.currentPassword && form.touched.currentPassword ? form.errors.currentPassword : undefined}
                    >
                        <IonInput
                            type={'password'}
                            name='currentPassword'
                            onIonChange={form.handleChange}
                            value={form.values.currentPassword}
                        />
                    </InputWrapper>
                </UnderlinedInput>
                <UnderlinedInput>
                    <InputWrapper
                        label={'New Password'}
                        error={form.errors.newPassword && form.touched.newPassword ? form.errors.newPassword : undefined}
                    >
                        <IonInput
                            type={'password'}
                            name='newPassword'
                            onIonChange={form.handleChange}
                            value={form.values.newPassword}
                        />
                    </InputWrapper>
                </UnderlinedInput>
            </GreyBackgroundSection>
            <IonButton onClick={() => form.handleSubmit()}>Save</IonButton>
        </div>
    )
}

/**
 * Main component for the page
 * @constructor
 */
const ChangePassword: React.FC = () => {

    return (
        <IonPage id={'change-password-page'}>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Change Password
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <ChangePasswordForm me={meContext.me}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default ChangePassword;
