import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Follower from '../../models/organization/follower';

/**
 * The state interface for our state
 */
export interface BusinessFollowersContextState extends BasePaginatedContextState<Follower> {}

const defaultContext = {
    ...defaultBaseContext(),
    order: {
        created_at: 'desc',
    }
} as BusinessFollowersContextState;

interface BusinessFollowersLoadedContextStates {
    initiatedStates: {[key: number]: BusinessFollowersContextState},
}

const persistentContext = {
    initiatedStates: {},
} as BusinessFollowersLoadedContextStates

/**
 * The actual context component
 */
export const BusinessFollowersContext = React.createContext<BusinessFollowersContextState>(defaultContext);

export interface BusinessFollowerContextProviderProps extends BasePaginatedContextProviderProps {
    businessId: number,
}

export const BusinessFollowersContextProvider: React.FC<BusinessFollowerContextProviderProps> = ({businessId, children}) => {
    const [businessFollowersLoadedState, setBusinessFollowersLoadedState] = useState(persistentContext);

    const businessFollowersContextState = businessFollowersLoadedState.initiatedStates[businessId] ?
        businessFollowersLoadedState.initiatedStates[businessId] : defaultContext;

    useEffect(() => {
        if (!businessFollowersContextState.initiated) {
            const setOrganizationPaymentsContextState = (updatedContext : any) => {
                persistentContext.initiatedStates[businessId] = updatedContext;
                setBusinessFollowersLoadedState({...persistentContext});
            }
            prepareContextState(setOrganizationPaymentsContextState, businessFollowersContextState,'/businesses/' + businessId + '/followers')
        }
    }, [businessId]);

    return (
        <BusinessFollowersContext.Provider value={businessFollowersContextState}>
            <BusinessFollowersContext.Consumer>
                {() => children}
            </BusinessFollowersContext.Consumer>
        </BusinessFollowersContext.Provider>
    );
};
