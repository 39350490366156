import React, {PropsWithChildren} from 'react';
import './index.scss';
import {IonItem} from '@ionic/react';

interface Props {
	className?: string
	routerLink?: string
	onClick?: () => void,
}

const DropShadowRouterLink: React.FC<PropsWithChildren<Props>> = ({ children, routerLink, onClick, className }) => {
	return (
		<IonItem onClick={onClick} routerLink={routerLink} className={'drop-shadow-router-link ' + className} lines={'none'} detail={false} >
			{children}
		</IonItem>
	)
}

export default DropShadowRouterLink
