import {ActionType} from '../../util/types';

export const setSharedPostInit = (sharedPostInit: boolean) => ({
    type: 'set-shared-post-init',
    sharedPostInit
} as const);

export const setNotificationPostInit = (notificationPostInit: boolean) => ({
    type: 'set-notification-post-init',
    notificationPostInit
} as const);

export const incrementLoadingCount = () => ({
    type: 'increment-loading-count',
} as const);

export const decrementLoadingCount = () => ({
    type: 'decrement-loading-count',
} as const);

export const clearLoadingCount = () => ({
    type: 'clear-loading-count',
} as const);

export type SessionActions =
    | ActionType<typeof setSharedPostInit>
    | ActionType<typeof setNotificationPostInit>
    | ActionType<typeof incrementLoadingCount>
    | ActionType<typeof decrementLoadingCount>
    | ActionType<typeof clearLoadingCount>
