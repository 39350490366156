import React from 'react';
import BaseBusinessEditPage from '../../../../components/OrganizationManagement/BaseBusinessEditPage';
import {IonInput, IonLabel} from '@ionic/react';
import './index.scss';
import {BusinessEditorContextConsumerState} from '../../../../contexts/organization/BusinessEditorContext';
import Business from '../../../../models/organization/business';

const BusinessNameEditor: React.FC = () => {

    function createInitialState(businessEditorContext: BusinessEditorContextConsumerState): string {
        const business = businessEditorContext.dirtyBusiness;
        return business.name;
    }

    function constructBusiness(currentData: string, business: Business): Business {
        return {
            ...business,
            name: currentData
        }
    }

    return (
        <BaseBusinessEditPage title={'Business Name'} iconUrl={'storefront'} createInitialState={createInitialState} constructBusiness={constructBusiness}>
            {currentDataContext =>
                <div>
                    <IonLabel>Name</IonLabel>
                    <IonInput
                        value={currentDataContext.currentData}
                        placeholder={'Business Name'}
                        onInput={event => currentDataContext.setCurrentData(event.currentTarget.value!)}
                    />
                </div>
            }
        </BaseBusinessEditPage>
    );
}

export default BusinessNameEditor;
