import React, {useRef, useState} from 'react'
import * as Yup from 'yup'

import './index.scss'
import { phoneRegExp } from '../../../../util/regex'
import SignUpPageStep, {FormInformation} from '../../../../components/WelcomeSection/SignUpPageStep'
import SideBySideComponent from '../../../../components/SideBySide';
import {IonAlert, IonCheckbox, IonContent, IonIcon, IonInput, IonItem} from '@ionic/react';
import {person} from 'ionicons/icons';
import PhoneNumberInput from '../../../../components/PhoneNumberInput';
import {useHistory} from 'react-router-dom';

const NameAndPhoneStep: React.FC = () => {

    const [form, setForm] = useState<any>(undefined);
    const [phoneInput, setPhoneInput] = useState<HTMLIonInputElement|undefined>(undefined)
    const firstNameInputRef = useRef<HTMLIonInputElement>(null)
    const lastNameInputRef = useRef<HTMLIonInputElement>(null)
    const history = useHistory();

    const SignUpSchema = Yup.object().shape({
        first_name: Yup.string().trim().required('First Name is required'),
        last_name: Yup.string().trim().required('Last Name is required'),
        phone: Yup.string().trim().required('Phone Number is required')
            .matches(phoneRegExp, 'Invalid Phone'),
        age: Yup.boolean().equals([true], 'You must be at least 17 years old to use Geddit Local')
    })

    const validateForm = (submission: any) : string|undefined => {
        if (submission.errors.first_name && submission.touched.first_name) {
            return submission.errors.first_name;
        } else if (submission.errors.last_name && submission.touched.last_name) {
            return submission.errors.last_name;
        } else if (submission.errors.phone && submission.touched.phone) {
            return submission.errors.phone;
        } else if (submission.errors.age) {
            return submission.errors.age;
        }
        return undefined;
    }

    const formInformation: FormInformation = {
        onValidate: validateForm,
        validationSchema: SignUpSchema,
        setForm,
    }

    const onNextStep = () => {
        history.push('/welcome/sign-up/verification-code');
    }

    return (
        <SignUpPageStep
            cancelOnBack
            header={'Create your account'}
            formInformation={formInformation}
            onNextStep={onNextStep}
            className={'name-phone-signup'}
            disclaimer={'Geddit Local will never share your personal contact information with third parties. It is used exclusively for security and account identification purposes.'}
            inputs={[
                firstNameInputRef?.current,
                lastNameInputRef?.current,
                phoneInput,
            ].filter(i => i)as HTMLIonInputElement[]}
        >
            {form &&
                <div className={'name-email-form-content'}>
                    <SideBySideComponent>
                        <IonItem>
                            <IonIcon icon={person} />
                            <IonInput
                                autocapitalize={'word'}
                                name='first_name'
                                placeholder={'First Name'}
                                type='text'
                                onIonChange={form.handleChange}
                                ref={firstNameInputRef}
                            />
                        </IonItem>
                        <IonItem>
                            <IonInput
                                autocapitalize={'word'}
                                name='last_name'
                                placeholder={'Last Name'}
                                type='text'
                                onIonChange={form.handleChange}
                                ref={lastNameInputRef}
                            />
                        </IonItem>
                    </SideBySideComponent>
                    <IonItem>
                        <IonIcon slot={'start'} src={'/assets/app-icons/phone.svg'} />
                        <PhoneNumberInput
                            name='phone'
                            placeholder='Phone'
                            value={form.values.phone}
                            onPhoneNumberChange={phoneNumber => form.setFieldValue('phone', phoneNumber)}
                            onInputSet={setPhoneInput}
                        />
                    </IonItem>
                    <p className={'disclaimer'}>
                        Geddit Local will send you a text with a verification code. Message and data rates may apply.
                    </p>
                    <IonItem lines={'none'} className={'age-checkbox'}>
                        <IonCheckbox
                            slot={'start'}
                            name={'age'}
                            mode={'md'}
                            onIonChange={event => form.setFieldValue('age', event.detail.checked)}
                        />
                        <p>I acknowledge that I am 17 years of age or older</p>
                    </IonItem>
                </div>
            }
        </SignUpPageStep>
    )
}

export default NameAndPhoneStep
