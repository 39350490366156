import React from 'react'
import {IonContent, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import './index.scss';
import ComingSoonPlaceholder from "../../../components/ComingSoonPlaceholder";
import {arrowBackOutline, chevronBack} from "ionicons/icons";

const Events: React.FC = () => {
	return (
		<IonPage id={'events-page'}>
			<LoggedInHeaderComponent hideBackButton={true} iconType={chevronBack}>
				Events
			</LoggedInHeaderComponent>
			<IonContent>
				<ComingSoonPlaceholder imageUrl={'/assets/coming-soon-events-placeholder.jpg'}>
					<h2>Coming Soon!</h2>
					<p>Calendar of Events</p>
				</ComingSoonPlaceholder>
			</IonContent>
		</IonPage>
	)
}

export default Events
