import {PublishedPostsContextState} from "../../../contexts/PublishedPostsContext";
import Post, {
    isPostPublished,
    PublisherEndpointModel,
    PublisherEndpointType,
    toPostServerObject
} from "../../../models/post/post";
import React, {useContext} from "react";
import {IonButton, IonContent, NavContext} from "@ionic/react";
import PostManagementRequests from "../../../services/requests/PostManagementRequests";
import PostDetails from "../../Posts/PostDetails";
import BottomStickySection from "../../BottomStickySection";
import SideBySideComponent from "../../SideBySide";
import {useLocation, useHistory} from 'react-router'
import {PendingPostsContextState} from '../../../contexts/PendingPostsContext';

interface PostPreviewContentProps {
    postId?: string,
    post: Post,
    publishedPostsContext: PublishedPostsContextState,
    pendingPostsContext: PendingPostsContextState,
    publisher: PublisherEndpointModel,
    publisherType: PublisherEndpointType,
    publishedUrl?: string,
    onPostPublished?: (post: Post) => void,
    onBack?: () => void,
}

const PostPreviewContent: React.FC<PostPreviewContentProps>  = ({post, publishedUrl, publishedPostsContext, pendingPostsContext, publisher, publisherType, postId, onPostPublished, onBack}) => {
    const navigate = useHistory()

    const location = useLocation();

    const possibleLocation = post.locations && post.locations.length ? post.locations[0]  : undefined

    const publish = (publishedPostsContext: PublishedPostsContextState, pendingPostsContext: PendingPostsContextState, publisherEndpoint: PublisherEndpointModel, post: Post) => {

        const saveData = toPostServerObject(post);

        saveData.publish = true;

        (post.id ?
            PostManagementRequests.updatePost(publisherType, publisherEndpoint, post, saveData) :
            PostManagementRequests.createPost(publisherType, publisherEndpoint, saveData)
        ).then(post => {
            if (isPostPublished(post)) {
                publishedPostsContext.addModel(post);
                pendingPostsContext.removeModel(post);
            } else {
                pendingPostsContext.addModel(post);
            }

            if (publishedUrl) {
                navigate.push(publishedUrl);
            } else if (onPostPublished) {
                onPostPublished(post);
            }
        })
    }

    const goBack = () => {
        if (onBack) {
            onBack();
        } else {
            navigate.goBack();
        }
    }

    return (
        <React.Fragment>
            <IonContent className={'post-details-content-wrapper ion-no-padding'}>
                <PostDetails
                    post={post}
                    location={possibleLocation}
                    locationPageUrlRoot={location.pathname.replace('/posts/' + postId, '')}
                    isPreview={true}
                />
            </IonContent>
            <BottomStickySection>
                <SideBySideComponent>
                    <IonButton onClick={() => goBack()} className={'secondary-button-outlined'}>
                        Back
                    </IonButton>
                    <IonButton onClick={() => publish(publishedPostsContext, pendingPostsContext, publisher, post)}>
                        Publish
                    </IonButton>
                </SideBySideComponent>
            </BottomStickySection>
        </React.Fragment>
    )
}

export default PostPreviewContent
