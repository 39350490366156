import React from 'react'
import {IonContent, IonPage} from '@ionic/react';
import './index.scss';
import {PostContext, PostContextProvider} from '../../../../contexts/PostContext';
import {
    PublishedPostsContext,
    PublishedPostsContextProvider,
} from '../../../../contexts/PublishedPostsContext';
import PostPreviewContent from '../../../../components/PostManagement/PostPreviewContent';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
import LoggedInHeaderComponent from "../../../../components/LoggedInHeader";
import {chevronBack} from "ionicons/icons";
import {useParams} from 'react-router';
import {PendingPostsContext, PendingPostsContextProvider} from '../../../../contexts/PendingPostsContext';

type RouteParams = {
    postId: string,
}

const MemberPostPreviewPage: React.FC = () => {

    const {postId} = useParams<RouteParams>();

    return (
        <IonPage className={'post-preview-page'}>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Preview
            </LoggedInHeaderComponent>
            <IonContent className={'post-preview-main-content'}>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext =>
                            <PostContextProvider postId={parseInt(postId!)} skipCache>
                                <PostContext.Consumer>
                                    {postContext =>
                                        <PublishedPostsContextProvider publisherType={'users'} publisherId={meContext.me.id!}>
                                            <PublishedPostsContext.Consumer>
                                                {publishedPostsContext =>
                                                    <PendingPostsContextProvider publisherType={'users'} publisherId={meContext.me.id!}>
                                                        <PendingPostsContext.Consumer>
                                                            {pendingPostsContext =>
                                                                <PostPreviewContent
                                                                    publishedUrl={'/home/member-post-creator/published'}
                                                                    publishedPostsContext={publishedPostsContext}
                                                                    pendingPostsContext={pendingPostsContext}
                                                                    postId={postId!}
                                                                    publisherType={'users'}
                                                                    post={postContext.post}
                                                                    publisher={meContext.me}
                                                                />
                                                            }
                                                        </PendingPostsContext.Consumer>
                                                    </PendingPostsContextProvider>
                                                }
                                            </PublishedPostsContext.Consumer>
                                        </PublishedPostsContextProvider>
                                    }
                                </PostContext.Consumer>
                            </PostContextProvider>
                        }
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    )
}

export default MemberPostPreviewPage
