import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext,
    prepareContextState
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import AcceptedInvitation from '../models/user/accepted-invitation';

/**
 * The state interface for our state
 */
export interface AcceptedInvitationsContextState extends BasePaginatedContextState<AcceptedInvitation> {}

const defaultContext = {
    ...defaultBaseContext(),
    loadAll: true,
    expands: ['user'],
} as AcceptedInvitationsContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const AcceptedInvitationsContext = React.createContext<AcceptedInvitationsContextState>(defaultContext);

export interface AcceptedInvitationsContextProviderProps extends BasePaginatedContextProviderProps{
    invitationId: number,
}

export const AcceptedInvitationsContextProvider: React.FC<AcceptedInvitationsContextProviderProps> = ({invitationId, children}) => {
    const [acceptedInvitationsState, setAcceptedInvitationsState] = useState(persistentContext);

    useEffect(() => {
        prepareContextState(setAcceptedInvitationsState, acceptedInvitationsState, `/invitations/${invitationId}/accepted`)
    }, [invitationId]);

    return (
        <AcceptedInvitationsContext.Provider value={acceptedInvitationsState}>
            {children}
        </AcceptedInvitationsContext.Provider>
    )
};
