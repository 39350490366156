import { Badge } from '@capawesome/capacitor-badge';
import Page from '../models/page';

export function decrementBadgeCount() {
    Badge.isSupported().then(() => {
        Badge.decrease().catch(console.error);
    })
}

/**
 * Updates the app icon bade number based on the passed in page
 * 
 * @param count
 */
export function updateBadgeNumber(count: number) {
    Badge.isSupported().then(() => {
        if (count) {
            Badge.set({count})
                .catch(e => console.error('error updating badge number', e));
        } else {
            Badge.clear()
                .catch(e => console.error('error clearing badge number', e));
        }
    })
}
