import React, {useEffect, useState} from 'react'
import {IonContent, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import './index.scss';
import {chevronBack} from "ionicons/icons";
import {UserContext, UserContextProvider} from '../../../contexts/UserContext';
import User from '../../../models/user/user';
import ProfileImage from '../../../components/ProfileImage';
import {ellipsisText} from '../../../util/strings';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import {useParams} from 'react-router';
import {UserFollowsContextProvider, UserFollowsContext} from "../../../contexts/UserFollowsContext";
import FollowButton from "../../../components/MembersPages/FollowButton";
import PostSwiper from "../../../components/PostSwiper";
import {SwiperSlide} from 'swiper/react';
import UserRecommendations from '../../../components/UserRecommendations';
import SavedPosts from '../../../components/MembersPages/SavedPosts';

interface UserContentProps {
	user: User,
	setUser: (user: User) => void,
	hasScrolled: boolean,
}

const UserContent: React.FC<UserContentProps> = ({user, setUser, hasScrolled}) => {

	const [fullBioShowing, setFullBioShowing] = useState(false);

	useEffect(() => {
		setFullBioShowing(false)
	}, [user])

	const followersCount = user.followers_count ?? 0;
	const incrementFollowers = () => {
		setUser({
			...user,
			followers_count: followersCount + 1,
		})
	}
	const decrementFollowers = () => {
		setUser({
			...user,
			followers_count: followersCount > 0 ? followersCount - 1 : 0
		})
	}

	return (
		<React.Fragment>
			<div className={"user-header"} slot={"fixed"}>
				<div className={"user-stripe"}/>
				<div className={'user-profile-image-line'}>
					<ProfileImage url={user.profile_image_url} whiteBorder isScrolled={hasScrolled} name={user.full_name}/>
				</div>
			</div>
			<MeContextProvider>
				<MeContext.Consumer>
					{meContext =>
						<div className={'user-content'}>
							<UserFollowsContextProvider userId={meContext.me.id!}>
								<UserFollowsContext.Consumer>
									{userFollowsContext =>
										<FollowButton
											relatedId={user.id!}
											relatedType={'user'}
											related={user}
											onFollow={incrementFollowers}
											onUnfollow={decrementFollowers}
											userFollowsContext={userFollowsContext}
										/>
									}
								</UserFollowsContext.Consumer>
							</UserFollowsContextProvider>
							<div className={'user-info'}>
								<p><strong>{user.full_name}</strong></p>
								{(user.about_me && user.about_me.length > 0) &&
								user.about_me.length > 120 && !fullBioShowing ?
									<p>{ellipsisText(user.about_me, 120, '...')} <strong onClick={() => setFullBioShowing(true)}>More</strong></p>
									: <p>{user.about_me}</p>
								}
								<div className={'followers-statistics'}>
									<div className={'followers-statistic'}>
										<p><strong>{user.follows_count ?? 0}</strong> Following</p>
									</div>
									<div className={'followers-statistic'}>
										<p><strong>{followersCount ?? 0}</strong> Followers</p>
									</div>
								</div>
							</div>
							<PostSwiper firstLabel={'Recommendations'} >

								<SwiperSlide>
									<UserRecommendations
										user={user}
										me={meContext.me}
										noPostsMessage={'This member has not made any recommendations yet'}
									/>
								</SwiperSlide>
							</PostSwiper>
						</div>
					}
				</MeContext.Consumer>
			</MeContextProvider>
		</React.Fragment>
	)
}

type RouteParams = {
	userId: string,
}

const UserPage: React.FC = () => {

	const {userId} = useParams<RouteParams>();
	const [hasScrolled, setHasScrolled] = useState(false)

	const onContentScroll = (event: any) => {
		setHasScrolled(event.detail.scrollTop > 0)
	}

	return (
		<IonPage className={'user-page'}>
			<LoggedInHeaderComponent iconType={chevronBack} orangeHeader/>
			<IonContent scrollEvents onIonScroll={onContentScroll}>
				<UserContextProvider userId={parseInt(userId!)}>
					<UserContext.Consumer>
						{userContext =>
							<UserContent
								user={userContext.user}
								setUser={userContext.setUser}
								hasScrolled={hasScrolled}
							/>
						}
					</UserContext.Consumer>
				</UserContextProvider>
			</IonContent>
		</IonPage>
	)
}

export default UserPage
