import React, {useEffect, useState} from 'react'
import {
	IonTabButton,
	IonRouterOutlet,
	IonTabBar,
	IonTabs,
	IonContent,
	IonModal,
	IonButton,
	IonTitle, IonHeader, IonPage, IonToolbar, IonButtons, IonIcon
} from '@ionic/react';
import './index.scss';
import {connect} from '../../../data/connect';
import {Redirect, useHistory} from 'react-router-dom';
import TutorialNewsFeed from "../../../components/Tutorials/TutorialNewsFeed";
import {Route} from 'react-router';
import Following from './Following';
import Nearby from './Nearby';
import {setHasSeenIntroductionTutorial} from '../../../data/persistent/persistent.actions';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import LoggedInHeader from '../../../components/LoggedInHeader';
import {optionsOutline, search} from 'ionicons/icons';
import NewsFeedOptionsModal from '../../../components/NewsFeedOptionsModal';


interface StateProps {
	hasSeenIntroductionTutorial: boolean;
}

interface DispatchProps {
	setHasSeenIntroductionTutorial: typeof setHasSeenIntroductionTutorial,
}

interface FeedTabsProps extends FeedProps {
	feedSettingsModalShowing: boolean,
	setFeedSettingsModalShowing: (showing: boolean) => void,
}

const FeedTabs: React.FC<FeedTabsProps> = ({hasSeenIntroductionTutorial, setHasSeenIntroductionTutorial, feedSettingsModalShowing, setFeedSettingsModalShowing}) => {

	const history = useHistory();

	const [introductionModalShowing, setIntroductionModalShowing] = useState(!hasSeenIntroductionTutorial);

	const introductionModalDismissed = () => {
		setHasSeenIntroductionTutorial(true);
		setIntroductionModalShowing(false);
	}

	const goToSearch = () => {
		history.push('/home/dashboard/explore');
	}

	return (
		<IonTabs className={'feed-tabs'}>
			<IonRouterOutlet>
				<Route exact path={`/home/dashboard/feed/nearby`} render={() => <Nearby/>}/>
				<Route exact path={`/home/dashboard/feed/following`} render={() => <Following/>}/>
				<Redirect exact path={'/home/dashboard/feed'} to={`/home/dashboard/feed/nearby`} />
			</IonRouterOutlet>
			<IonTabBar slot="top" className={'feed-tabs-bar hide-in-desktop'} >
				<IonTabButton>
					<IonIcon
						onClick={goToSearch}
						icon={search}
					/>
				</IonTabButton>
				<IonTabButton tab='nearby' href={`/home/dashboard/feed/nearby`}>
					Nearby
				</IonTabButton>
				<IonTabButton tab='following' href={`/home/dashboard/feed/following`}>
					Following
					<IonModal
						className={'introduction-modal'}
						onDidDismiss={introductionModalDismissed}
						isOpen={introductionModalShowing}
					>
						<div className={'introduction-modal-content'}>
							<div className={'profile-name'}>
								<MeContextProvider hideLoadingSpace>
									<MeContext.Consumer>
										{meContext =>
											meContext.me.first_name.slice(0, 1) + " " + meContext.me.last_name.slice(0, 1)
										}
									</MeContext.Consumer>
								</MeContextProvider>
							</div>
							<h4>Time to find things to do</h4>
							<p>Get new ideas from local businesses and guides in the community around you!</p>
							<IonButton onClick={introductionModalDismissed}>Start swiping</IonButton>
						</div>
					</IonModal>
				</IonTabButton>
				<IonTabButton
				>
					<IonIcon
						className={'options-button'}
						icon={optionsOutline}
						onClick={() => setFeedSettingsModalShowing(true)}
					/>
					<NewsFeedOptionsModal
						isOpen={feedSettingsModalShowing}
						onDidDismiss={() => setFeedSettingsModalShowing(false)}
					/>
				</IonTabButton>
			</IonTabBar>
		</IonTabs>
	)
}

interface FeedProps extends StateProps, DispatchProps {}

const FeedPage: React.FC<FeedProps> = (props) => {

	const [feedSettingsModalShowing, setFeedSettingsModalShowing] = useState(false);

	return (
		<IonPage className={'feed-page'}>
			<LoggedInHeader
				hideBackButton
				hideLoading
				rightIcon={{
					icon: optionsOutline,
					handler: () => setFeedSettingsModalShowing(true),
					className: 'options-button',
					desktopOnly: true,
				}}
			>
				<h2 className={'gagalin'}>Geddit Local</h2>
			</LoggedInHeader>
			<IonContent className={'home-content'} id={'home'} scrollY={false}>
				<IonRouterOutlet>
					<Route path={`/home/dashboard/feed`} render={() =>
						<FeedTabs feedSettingsModalShowing={feedSettingsModalShowing} setFeedSettingsModalShowing={setFeedSettingsModalShowing} {...props}/>
					}/>
					<Route path={`/home/dashboard/feed/*`} render={() =>
						<FeedTabs feedSettingsModalShowing={feedSettingsModalShowing} setFeedSettingsModalShowing={setFeedSettingsModalShowing} {...props}/>
					}/>
				</IonRouterOutlet>
			</IonContent>
		</IonPage>
	);
}

export default connect<{ }, StateProps, {  }>({
	mapStateToProps: (state) => ({
		hasSeenIntroductionTutorial: state.persistent.hasSeenIntroductionTutorial,
	}),
	mapDispatchToProps: ({
		setHasSeenIntroductionTutorial,
	}),
	component: FeedPage
});
