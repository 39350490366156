import React, {ConsumerProps, useEffect, useState} from 'react';
import './index.scss';
import BusinessEditorContextProvider , {BusinessEditorContext, BusinessEditorContextConsumerState} from '../../../contexts/organization/BusinessEditorContext';
import Business from '../../../models/organization/business';
import BaseEditPageTemplate from '../BaseEditPageTemplate';

interface BaseBusinessEditorFormConsumerProps<PageData>  {
    currentData: PageData,
    setCurrentData: (data: PageData) => void,
}

export interface BaseBusinessEditPagePrimaryProps<PageData> {
    title: string
    iconUrl?: string
    customLayout?: boolean,
    constructBusiness?: (data: PageData, business: Business) => Business,
}

interface BaseLocationEditPageWrapperProps<PageData> extends BaseBusinessEditPagePrimaryProps<PageData>, ConsumerProps<BaseBusinessEditorFormConsumerProps<PageData>> {
    createInitialState: (businessEditorContextState: BusinessEditorContextConsumerState) => PageData,
    businessEditorContextState: BusinessEditorContextConsumerState,
}

const BaseBusinessEditPageWrapper: React.FC<BaseLocationEditPageWrapperProps<any>> = ({title, createInitialState, businessEditorContextState, constructBusiness, iconUrl, customLayout, children, ...rest}) => {

    const [initialFormState, setInitialFormState] = useState(undefined);
    const [shouldSave, setShouldSave] = useState(false);
    const [currentData, setCurrentData] = useState(initialFormState);


    useEffect(() => {
        if (shouldSave) {
            storeChanges();
        }
    }, [currentData]);

    useEffect(() => {
        const initialState = createInitialState(businessEditorContextState);
        setCurrentData(initialState)
        setInitialFormState(initialState);
    }, []);

    if (initialFormState === undefined) {
        return (
            <span/>
        );
    }

    const storeChanges = () => {
        if (constructBusiness) {
            businessEditorContextState.setBusiness({
                ...constructBusiness(currentData, businessEditorContextState.dirtyBusiness),
            });
        }
    }

    const consumerState = {
        currentData: currentData,
        setCurrentData: setCurrentData,
    };

    const isDirty = currentData != initialFormState;

    const onSave = () => {
        setShouldSave(true);
        storeChanges();
        setTimeout(() => setShouldSave(false), 2000);
        return Promise.resolve();
    }

    return (
        <BaseEditPageTemplate
            title={title}
            onSave={onSave}
            isDirty={isDirty}
            iconUrl={iconUrl}
            customLayout={customLayout}
            editingData={currentData}
        >
            {children(consumerState)}
        </BaseEditPageTemplate>
    );
}

interface BaseBusinessEditPageProps<PageData> extends BaseBusinessEditPagePrimaryProps<PageData>, ConsumerProps<BaseBusinessEditorFormConsumerProps<PageData>> {
    createInitialState: (businessEditorContextState: BusinessEditorContextConsumerState) => PageData,
}

const BaseBusinessEditPage: React.FC<BaseBusinessEditPageProps<any>> = (props) => {
    return (
        <BusinessEditorContextProvider>
            <BusinessEditorContext.Consumer>
                {businessEditorContextState =>
                    <BaseBusinessEditPageWrapper
                        businessEditorContextState={businessEditorContextState}
                        {...props}
                    />
                }
            </BusinessEditorContext.Consumer>
        </BusinessEditorContextProvider>
    )
}

export default BaseBusinessEditPage
