import React from 'react';
import './index.scss';
import PostPublishedContent from '../../../../components/PostManagement/PostPublishedContent';

const PostPublished: React.FC = () => {

    return (
        <PostPublishedContent backUrl={'/home/dashboard/account'}/>
    );
}

export default PostPublished;
