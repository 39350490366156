import React from 'react';
import PaymentMethod from '../../../models/payment/payment-method';
import { IonButton } from '@ionic/react';
import Moment from 'react-moment';
import './index.scss';
import BottomStickySection from '../../BottomStickySection';
import ConfirmationPageContent, {ConfirmationPageContentProps} from '../../GeneralUIElements/ConfirmationPageContent';

interface PendingTransactionInformationProps extends ConfirmationPageContentProps {
    paymentMethod: PaymentMethod,
    onChangePaymentMethod: () => void,
    canChangePaymentMethod: boolean,
}

const PendingTransactionInformation: React.FC<PendingTransactionInformationProps> = ({paymentMethod, onChangePaymentMethod, canChangePaymentMethod, children, ...props}) => {

    return (
        <ConfirmationPageContent {...props}>
            <h5>Payment Method</h5>
            <div className={'payment-method-info'}>
                <p className={'card-number'}>•••• •••• •••• <span className={'last-4'}>{paymentMethod.identifier}</span></p>
                <p className={'card-expiration'}>Expires <Moment parse={'YYYY-M'} format={'MMMM YYYY'}>{paymentMethod.exp_year + '-' + paymentMethod.exp_month}</Moment></p>
                <IonButton color={'medium'} disabled={!canChangePaymentMethod} onClick={onChangePaymentMethod}>
                    Change
                </IonButton>
            </div>
            {canChangePaymentMethod && <p>To remove your payment option, downgrade to the Free plan or delete your account</p>}
            <hr/>
            <h5>Order Summary</h5>
            <div className={'order-summary'}>{children}</div>
        </ConfirmationPageContent>
    );
}

export default PendingTransactionInformation;
