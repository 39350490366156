import React from 'react';
import {IonIcon, IonItem, IonList, useIonRouter} from '@ionic/react';
import './index.scss';
import Location from '../../../models/location/location';
import Business from '../../../models/organization/business';
import LocationItem from '../LocationItem';

interface LocationsListProps {
    business: Business,
    addEnabled?: boolean,
    locationSelected: (location: Location) => void,
}

/**
 * This puts together the options for the account switcher
 * @constructor
 */
const LocationsList: React.FC<LocationsListProps> = ({business, addEnabled, locationSelected}) => {

    const router = useIonRouter();
    const locations = business.locations ? business.locations : [];

    const addLocation = () => {
        router.push('/organization/location-creator')
    }

    return (
        <div className={'locations-list'}>
            <IonList class={'account-switcher-options'} lines={'full'}>
                <IonItem key={'header'}>
                    <p>
                        <strong>Locations</strong> ({locations.length})
                    </p>
                    {addEnabled &&
                        <IonIcon
                            onClick={addLocation}
                            className={'add-location-button'}
                            slot={'end'}
                            src={'/assets/app-icons/add.svg'}
                        />
                    }
                </IonItem>
                {locations.map((location, index) => (
                    <LocationItem
                        key={location.id}
                        businessLogoUrl={business.logo_url}
                        location={location}
                        onClick={() => locationSelected(location)}/>
                ))}
            </IonList>
        </div>
    );
}

export default LocationsList;
