import React from 'react';
import {useParams} from 'react-router';
import "./index.scss"

const templateHtml = '<section id="home"\n' +
    '                     style="z-index:0;overflow:hidden;grid-template-columns:auto 100rem auto;display:grid;position:relative;align-items:center;">\n' +
    '                <div id="nAUi4BDvTHJ8fOwV"\n' +
    '                     style="display:grid;min-height:100%;position:absolute;grid-area:1 / 1 / 2 / 4;min-width:100%;">\n' +
    '                    <div id="xlUkc9gYpF7KKkrn" style="z-index:0;">\n' +
    '                        <div id="Eyev1IMAeStCAEVq"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="h3jUEY2cgXDFgJaR" style="width:100%;opacity:1.0;height:100%;">\n' +
    '                                <div id="db7UHyUOvogfcOP0" className="video_container"\n' +
    '                                     style="background-color:#ffffff;transform:scale(-1, 1);overflow:hidden;width:100%;position:relative;opacity:1.0;height:100%;">\n' +
    '                                    <div id="mAeErpAoGqjv50KB"\n' +
    '                                         style="transform:rotate(0deg);top:-0.02441406%;left:0%;width:100%;position:absolute;height:100.04882812%;opacity:1.0;">\n' +
    '                                        <video src="https://gedditlocal.my.canva.site/invite-landing-page/videos/c281328f0f09ac9cab2ae3403f4faa3c.mp4" playsInline=""\n' +
    '                                               poster="https://gedditlocal.my.canva.site/invite-landing-page/videos/f8a04b3a45c71ec1ad8afde349883e82.jpg" preload="none"\n' +
    '                                               autoPlay="" muted="" loop=""\n' +
    '                                               style="display:block;object-fit:cover;width:100%;height:100%;"></video>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="ODvDbKPhe79UzcOc" style="z-index:1;">\n' +
    '                        <div id="SyAUSaK25rVTfTPy"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <svg id="XjBkNeMUhUG7Iyd4" viewBox="0 0 379.4444 213.3333" preserveAspectRatio="none"\n' +
    '                                 style="overflow:hidden;top:0%;left:0%;width:100%;position:absolute;opacity:0.37;height:100%;">\n' +
    '                                <g id="tX4wu5OfHTrlXzGj" style="transform:scale(1, 1);">\n' +
    '                                    <path id="wdl5b0RxGDvituzH"\n' +
    '                                          d="M0.0,0.0 L379.4444444444444,0.0 L379.4444444444444,213.33333333333337 L0.0,213.33333333333337 L0.0,0.0"\n' +
    '                                          style="fill:#000000;opacity:1.0;"></path>\n' +
    '                                </g>\n' +
    '                            </svg>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div id="PTA49D5K5kH4ycZ8" style="display:grid;position:relative;grid-area:1 / 2 / 2 / 3;">\n' +
    '                    <div id="LK0k1GJcNzZRQTKD">\n' +
    '                        <div id="pn5FjhspMzsvI2ip" style="display:grid;position:relative;">\n' +
    '                            <div id="LGtLTEJNlt2I5qQp" style="z-index:4;">\n' +
    '                                <div id="DFlLwehI53h1d9ip"\n' +
    '                                     style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                                    <div id="qfIfX4VTsJ2hWvMu"\n' +
    '                                         style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                        <p id="oZIdcffPoo0fs0De"\n' +
    '                                           style="text-shadow:0em 0.0375em 0.159375em rgba(0, 0, 0, 0.325000);color:#ffffff;letter-spacing:0em;font-family:YAD1frSSTdM-0;line-height:1.29015343em;">\n' +
    '                                            <span id="X6OgYO2SRqNmtGtz"\n' +
    '                                                  style="color:#ffffff;font-weight:700;font-style:italic;">GEDDIT LOCAL</span><br>\n' +
    '                                        </p></div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                            <div id="YvXZUZhby4AJBxIb" style="z-index:3;">\n' +
    '                                <div id="uA8jTjvKFIEPv1Ic" style="padding-top:113.7254902%;transform:rotate(0deg);">\n' +
    '                                    <div id="Ix9sEC9xrT78e4mJ"\n' +
    '                                         style="top:0px;left:0px;width:100%;position:absolute;height:100%;">\n' +
    '                                        <div id="Yld7qRSuVorQvCF0" style="width:100%;opacity:1.0;height:100%;">\n' +
    '                                            <div id="mbYFGo8rs2GvNHiO"\n' +
    '                                                 style="transform:scale(1, 1);overflow:hidden;width:100%;position:relative;height:100%;">\n' +
    '                                                <div id="WofjDlawqZgMXzYu"\n' +
    '                                                     style="transform: rotate(0deg); top: 0%; left: 0%; width: 100%; position: absolute; height: 100%; opacity: 1;">\n' +
    '                                                    <img src="https://gedditlocal.my.canva.site/invite-landing-page/images/04188407a2d6a4f4f9c4c2d0ffb1f5a3.png"\n' +
    '                                                         loading="lazy"\n' +
    '                                                         style="display:block;object-fit:cover;width:100%;height:100%;">\n' +
    '                                                </div>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="rCjde5zteDZVioQg" style="z-index:5;">\n' +
    '                        <div id="hbOHCD4fecQCJ6LV"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="ppvBheGsK1OsY3DE"\n' +
    '                                 style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                <p id="pUH2I3BRqnr3j8A1"\n' +
    '                                   style="text-transform:uppercase;text-shadow:0em 0.0375em 0.159375em rgba(0, 0, 0, 0.325000);color:#ffffff;letter-spacing:0em;font-family:YAC4lyP12IE-0;line-height:0.99107099em;">\n' +
    '                                    <span id="JKHMNhjZjMkFnVzW" style="color:#ffffff;">trust the locals</span><br></p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="leM2Sm6NmeHzWjxW" style="z-index:6;">\n' +
    '                        <div id="k4E53Gt4TIg3Gs6E"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="fuWCha6iNE5JOJy3"\n' +
    '                                 style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                <p id="wO4q9P3ZNTjW1cG5"\n' +
    '                                   style="text-shadow:0em 0.0375em 0.159375em rgba(0, 0, 0, 0.325000);color:#ffffff;letter-spacing:0em;font-family:YAC4lyP12IE-0;line-height:1em;">\n' +
    '                                    <span id="J8bZ8fepUNhIJ3xo" style="color:#ffffff;">Discover and recommend neighborhood businesses, arts, and events</span><br>\n' +
    '                                </p></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="h4mlWwlpktwYpvez">\n' +
    '                        <div id="VgtGcnNhgDcFFZiy" style="display:grid;position:relative;"><a href="/welcome/sign-up/invitation-code/:token">\n' +
    '                            <div id="MwCC96sBRW9h7gTI" style="z-index:9;">\n' +
    '                                <div id="u46B5omo2qO68hdv"\n' +
    '                                     style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                                    <div id="WFcR1wx8Yi5WdvUz"\n' +
    '                                         style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                        <p id="YZcCvlwEpoqYjYcj"\n' +
    '                                           style="color:#ffffff;letter-spacing:0em;font-family:YAC4lyP12IE-0;line-height:1em;text-align:center;">\n' +
    '                                            <span id="LmTIto6VwOzvOQ9W" style="color:#ffffff;">Sign Up</span><br></p>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </a></div>\n' +
    '                            <div id="Mbm7S1qDrapExbsH" style="z-index:8;">\n' +
    '                                <div id="O0Zxyzcyhu3me4Vh" style="padding-top:22.85964557%;transform:rotate(0deg);">\n' +
    '                                    <div id="aJwmsgfYJKHsw37x"\n' +
    '                                         style="top:0px;left:0px;width:100%;position:absolute;height:100%;">\n' +
    '                                        <svg id="rMaDVCP4K6KEV6fO" viewBox="0 0 227.4751 52.0"\n' +
    '                                             style="overflow:hidden;top:0%;left:0%;background:url(\'\');width:100%;position:absolute;opacity:1.0;height:100%;">\n' +
    '                                            <g id="celN54nfaQhnZ7tn" style="transform:scale(1, 1);">\n' +
    '                                                <path id="WqnyoodbYb0GI0ml"\n' +
    '                                                      d="M217.67508677425923,51.999996948242185 L9.800000190734863,51.999996948242185 C4.400000095367432,51.999996948242185 0.0,47.60000305175781 0.0,42.19999389648437 L0.0,9.800000190734863 C0.0,4.400000095367432 4.400000095367432,0.0 9.800000190734863,0.0 L217.6751020330483,0.0 C223.07509592953267,0.0 227.4751050848061,4.400000095367432 227.4751050848061,9.800000190734863 L227.4751050848061,42.200009155273435 C227.4751050848061,47.60000305175781 223.07509592953267,52.00001220703125 217.6751020330483,52.00001220703125"\n' +
    '                                                      style="fill:#fe9024;opacity:1.0;"></path>\n' +
    '                                            </g>\n' +
    '                                        </svg>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </section>\n' +
    '            <section id="about"\n' +
    '                     style="z-index:0;overflow:hidden;grid-template-columns:auto 100rem auto;display:grid;margin-top:-1px;position:relative;align-items:center;">\n' +
    '                <div id="n4zqIpQJod7Ck82E"\n' +
    '                     style="display:grid;min-height:100%;position:absolute;grid-area:1 / 1 / 2 / 4;min-width:100%;">\n' +
    '                    <div id="REsu1wvPSdUePwD0" style="z-index:0;">\n' +
    '                        <div id="v5EPOXpNvDva850M"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="JpPLDcVNAVsM8Dm0" style="width:100%;opacity:1.0;height:100%;">\n' +
    '                                <div id="AO2wPx2BOtHwFCA5"\n' +
    '                                     style="transform:scale(1, 1);overflow:hidden;width:100%;position:relative;height:100%;"></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div id="mu8WK5FUfw3nhOFR" style="display:grid;position:relative;grid-area:1 / 2 / 2 / 3;">\n' +
    '                    <div id="ZHuE3pkgorSw1qaD">\n' +
    '                        <div id="yPiCIcyGmknw6gQC" style="display:grid;position:relative;">\n' +
    '                            <div id="haEfEFnUCuJUv9z0" style="z-index:2;">\n' +
    '                                <div id="Jd9Q5iFO2VXUhune" style="padding-top:96.09529414%;transform:rotate(0deg);">\n' +
    '                                    <div id="z5Zho2T16ca5AC0B"\n' +
    '                                         style="top:0px;left:0px;width:100%;position:absolute;height:100%;">\n' +
    '                                        <div id="DQy9qO4Y2AdjnKq2" style="width:100%;opacity:1.0;height:100%;">\n' +
    '                                            <div id="DQEAB6QI6IVjv5Uf"\n' +
    '                                                 style="transform:scale(1, 1);overflow:hidden;width:100%;position:relative;height:100%;">\n' +
    '                                                <div id="WdaBnNJyTjsptjKG"\n' +
    '                                                     style="transform: rotate(0deg); top: 0%; left: 0%; width: 100%; position: absolute; height: 100%; opacity: 1;">\n' +
    '                                                    <img src="https://gedditlocal.my.canva.site/invite-landing-page/images/8f288e47dcea3505a91f16941b48afd8.png"\n' +
    '                                                         loading="lazy"\n' +
    '                                                         srcSet="https://gedditlocal.my.canva.site/invite-landing-page/images/8925b9402418530640e2b6fd4be48ad6.png 812w, https://gedditlocal.my.canva.site/invite-landing-page/images/8f288e47dcea3505a91f16941b48afd8.png 1217w"\n' +
    '                                                         sizes="(max-width: 375px) 91.46666667vw, (min-width: 375.05px) and (max-width: 480px) 93.33333333vw, (min-width: 480.05px) and (max-width: 768px) 88.93229167vw, (min-width: 768.05px) and (max-width: 1024px) 66.69921875vw, (min-width: 1024.05px) 50vw"\n' +
    '                                                         style="display:block;object-fit:cover;width:100%;height:100%;">\n' +
    '                                                </div>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="bJqMaC93I184cFSe" style="z-index:4;">\n' +
    '                        <div id="qFS9tgl6Zli4ysjm"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="AjvYvrNbH6pQgDVL"\n' +
    '                                 style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                <p id="SYOuKNSILZBjmncl"\n' +
    '                                   style="color:#000000;font-family:YAC4l27yaSc-0;line-height:1.39583333em;text-align:center;">\n' +
    '                                    <span id="U8iwwntuSQOqy52R" style="color:#000000;">Here\'s Our Story</span><br></p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="EYDTOCiC2Adw4dKF" style="z-index:3;">\n' +
    '                        <div id="TwPINQKxylsU8xCh"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="yo0Gp7I5skqPeB90"\n' +
    '                                 style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                <p id="PbVeqbLvZdIHJPYV"\n' +
    '                                   style="color:#000000;font-family:YAC4lyP12IE-0;line-height:1.35937712em;text-align:center;">\n' +
    '                                    <span id="Exzk1WpqQr9i2XZx" style="color:#000000;">In 2019 we started work on a mobile app with the idea of creating a place people could go to discover what the locals are recommending in terms of shops, dining, arts, and entertainment in their community.</span><span\n' +
    '                                    id="pEJfk0BM124kjTaz" style="color:#000000;white-space:pre-wrap;">  </span><span\n' +
    '                                    id="e4iHbehIjB1XqR5e" style="color:#000000;">We\'re based out of Milwaukee, WI, and we\'re lucky to be in a city with so many amazing local options!</span><span\n' +
    '                                    id="dGqvk9yzhFOHwdIR" style="color:#000000;white-space:pre-wrap;">  </span><span\n' +
    '                                    id="UhXWf9JRLafihXzA" style="color:#000000;">We dine out.</span><span\n' +
    '                                    id="YqUWlotIbzju6cjK" style="color:#000000;white-space:pre-wrap;">  </span><span\n' +
    '                                    id="Sg9cb2ynz5N3opH4" style="color:#000000;">We see shows.</span><span\n' +
    '                                    id="mCAuwfqF4hNM9y27" style="color:#000000;white-space:pre-wrap;">  </span><span\n' +
    '                                    id="fzgmKKzZPPMcSLtP" style="color:#000000;">We stay off social media, and we care about community.</span><span\n' +
    '                                    id="OElVPJ8RJbmZC3zx" style="color:#000000;white-space:pre-wrap;">  </span><span\n' +
    '                                    id="Uk0r39jP0ENxn5JF" style="color:#000000;">We\'re making this app the way we like it, and we hope you like it too.</span><br>\n' +
    '                                </p></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div id="VokEj7jPgn3XjTUV" style="z-index:5;">\n' +
    '                        <div id="X6aab8cvtSAiJO90"\n' +
    '                             style="transform:rotate(0deg);width:100%;box-sizing:border-box;height:100%;">\n' +
    '                            <div id="AKFbXlESW6bYO8gC"\n' +
    '                                 style="flex-direction:column;display:flex;width:100%;justify-content:flex-start;opacity:1.0;height:100%;">\n' +
    '                                <p id="gZRFuFZjlxK7hEaI"\n' +
    '                                   style="color:#000000;font-family:YAC4l27yaSc-0;line-height:1.39285466em;text-align:center;">\n' +
    '                                    <span id="KbIk7DlCWW74D5WI" style="color:#000000;">Trust The Locals.</span><br></p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </section>\n' ;

interface RouteParams {
    token?: string,
}

const InviteSplash: React.FC = () => {

    let {token} = useParams<RouteParams>();

    return (
        <div className="invite-splash-wrapper">
            <div dangerouslySetInnerHTML={{__html:templateHtml.replace(":token", token!)}}>
            </div>
        </div>
    )
}

export default InviteSplash;
