import React, {useEffect, useState} from 'react';
import {IonContent, IonMenu, IonRouterOutlet, IonSplitPane} from '@ionic/react';
import {Route, useLocation} from 'react-router';
import "./index.scss";
import Dashboard from './Dashboard';
import BusinessEditor from './BusinessEditor';
import Category from './BusinessEditor/Category';
import LocationEditor from './LocationEditor';
import Website from './LocationEditor/Website';
import Email from './LocationEditor/Email';
import Phone from './LocationEditor/Phone';
import Description from './LocationEditor/Description';
import Address from './LocationEditor/Address';
import CustomLinks from './LocationEditor/CustomLinks';
import OrganizationMenu from '../../components/OrganizationManagement/OrganizationMenu';
import BusinessesList from './BusinessesList';
import LocationsList from './LocationsList';
import LocationCreator from './LocationCreator';
import Billing from './Billing';
import BillingHistory from './Billing/BillingHistory';
import BillingInformation from './Billing/BillingInformation';
import CurrentSubscription from './Billing/CurrentSubscription';
import PaymentReceipt from './PaymentReceipt';
import BusinessCreator from './BusinessCreator';
import {UserManagement} from './UserManagement';
import BusinessNameEditor from './BusinessEditor/BusinessName';
import LocationNameEditor from './LocationEditor/LocationName';
import PostCreator from './PostCreator';
import PostPublished from './PostCreator/PostPublished';
import PostPreviewPage from './PostCreator/PostPreview';
import SubscriptionUpgraded from './Billing/SubscriptionUpgraded';
import FreeSubscriptionSelected from './Billing/FreeSubscriptionSelected';
import ContactUs from './ContactUs';
import {UserEditor} from './UserEditor';
import {Redirect} from 'react-router-dom';
import User from './User';
import OrganizationHeader, { OrganizationHeaderContextProvider } from '../../components/OrganizationManagement/OrganizationHeader';
import OrganizationTemplate from '../../components/OrganizationManagement/OrganizationTemplate';
import Instagram from './Instagram';
import BusinessDeepLinkListener from './BusinessDeepLinkListener';
import Post from './Post';

const Organization: React.FC = () => {

    return (
        <OrganizationHeaderContextProvider>
            <OrganizationTemplate>
                <IonRouterOutlet id={'organization'}>
                    <Redirect exact path={'/organization'} to={`/organization/dashboard`}/>
                    <Route path={`/organization/business/:businessId/*`} render={() => <BusinessDeepLinkListener/>} />
                    <Route exact path={`/organization/billing`} render={() => <Billing/>}/>
                    <Route exact path={`/organization/billing/history`} render={() => <BillingHistory/>}/>
                    <Route exact path={`/organization/billing/information`} render={() => <BillingInformation/>}/>
                    <Route exact path={`/organization/billing/current-subscription`} render={() => <CurrentSubscription/>}/>
                    <Route exact path={`/organization/billing/subscription-upgraded`} render={() => <SubscriptionUpgraded/>}/>
                    <Route exact path={`/organization/billing/free-subscription-selected`} render={() => <FreeSubscriptionSelected/>}/>
                    <Route exact path={`/organization/businesses`} render={() => <BusinessesList/>}/>
                    <Route exact path={`/organization/business-creator`} render={() => <BusinessCreator/>}/>
                    <Route exact path={`/organization/business-editor`} render={() => <BusinessEditor/>}/>
                    <Route exact path={`/organization/business-editor/category`} render={() => <Category/>}/>
                    <Route exact path={`/organization/business-editor/name`} render={() => <BusinessNameEditor/>}/>
                    <Route exact path={`/organization/contact-us`} render={() => <ContactUs/>}/>
                    <Route path={`/organization/dashboard*`} render={() => <Dashboard/>}/>
                    <Route exact path={`/organization/instagram`} render={() => <Instagram/>}/>
                    <Route exact path={`/organization/location-creator`} render={() => <LocationCreator/>}/>
                    <Redirect exact path={`/organization/business/:businessId/location/:locationId`} to={`/organization/location-editor/:locationId`}/>
                    <Route exact path={`/organization/location-editor/:locationId`} render={() => <LocationEditor/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/address`} render={() => <Address/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/custom-links`} render={() => <CustomLinks/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/description`} render={() => <Description/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/email`} render={() => <Email/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/name`} render={() => <LocationNameEditor/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/phone`} render={() => <Phone/>}/>
                    <Route exact path={`/organization/location-editor/:locationId/website`} render={() => <Website/>}/>
                    <Route exact path={`/organization/location-list`} render={() => <LocationsList/>}/>
                    <Route exact path={`/organization/payment-receipt/:paymentId`} render={() => <PaymentReceipt/>}/>
                    <Route exact path={`/organization/post/:postId`} render={() => <Post/>}/>
                    <Route exact path={`/organization/post-creator`} render={() => <PostCreator/>}/>
                    <Route exact path={`/organization/post-editor/:postId`} render={() => <PostCreator/>}/>
                    <Route exact path={`/organization/post-creator/published`} render={() => <PostPublished/>}/>
                    <Route exact path={`/organization/post-creator/preview/:postId`} render={() => <PostPreviewPage/>}/>
                    <Route exact path={`/organization/user/:userId`} render={() => <User/>}/>
                    <Route exact path={`/organization/user-management`} render={() => <UserManagement/>}/>
                    <Route exact path={`/organization/user-editor`} render={() => <UserEditor/>}/>
                </IonRouterOutlet>
            </OrganizationTemplate>
        </OrganizationHeaderContextProvider>
    );
};

export default Organization;
