import React, {PropsWithChildren, useState} from 'react';
import {IonIcon, useIonRouter} from '@ionic/react';
import {caretBack, caretDown, pencil} from 'ionicons/icons';
import './index.scss';

interface Props extends PropsWithChildren<any> {
    title: string,
    editLink?: string
}

const GreyHeaderContentArea: React.FC<Props> = ({ children,title, editLink }) => {

    const router = useIonRouter();

    const editClicked = () => {
        if (editLink) {
            router.push(editLink);
        }
    };

    return (
        <div className={'expandable-content-area'}>
            <div onClick={editClicked} className={'header'}>
                {title}
                {editLink &&
                    <IonIcon icon={pencil}/>
                }
            </div>
            <div className={'content'}>
                {children}
            </div>
        </div>
    )
}

export default GreyHeaderContentArea
