import React from 'react'
import {IonContent, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import './index.scss';
import BusinessLocationContent, {BusinessLocationContentProps} from '../../../components/BusinessLocationContent';
import {chevronBack} from "ionicons/icons";

const BusinessLocationPage: React.FC<BusinessLocationContentProps> = (props) => {
	return (
		<IonPage className={'business-location-page'}>
			<LoggedInHeaderComponent iconType={chevronBack}/>
			<IonContent>
				<BusinessLocationContent allowActions={true} {...props}/>
			</IonContent>
		</IonPage>
	)
}

export default BusinessLocationPage
