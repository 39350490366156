import React, {useEffect} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../../contexts/organization/MyBusinessContext';
import {IonContent, IonItem, IonList, IonPage} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../../components/OrganizationManagement/OrganizationHeader';
import Payment from '../../../../models/payment/payment';
import {
    OrganizationPaymentContext,
    OrganizationPaymentContextProvider
} from '../../../../contexts/organization/OrganizationPaymentsContext';
import Subscription from '../../../../models/subscription/subscription';
import Moment from 'react-moment';

interface BillingHistoryItemProps {
    payment: Payment,
    subscriptions: Subscription[],
}

const BillingHistoryItem: React.FC<BillingHistoryItemProps> = ({payment, subscriptions}) => {

    const primarySubscription = payment.line_items ? subscriptions.find(subscription => {
        return payment.line_items.find(lineItem => lineItem.item_type == 'subscription' && lineItem.item_id == subscription.id)
    }) : null;

    return (
        <IonItem className={'billing-history-item'}
                 key={payment.id}
           //      routerLink={'/organization/payment-receipt/' + payment.id}
        >
            <div className={'details'}>
                <strong>
                    {primarySubscription?.membership_plan_rate?.membership_plan ?
                        primarySubscription?.membership_plan_rate?.membership_plan.name :
                        // Add more purchase descriptions here
                        'Unknown Subscription'
                    }
                </strong>
                <br/>
                {payment?.line_items?.map(lineItem => {

                    switch (lineItem.item_type) {
                        case 'subscription':
                            const subscription = subscriptions.find(i => (i.id == lineItem.item_id));
                            return ( subscription ?
                                <span>
                                    {lineItem.quantity} Locations / {subscription.membership_plan_rate.membership_plan?.post_radius} Mile Radius
                                    <br/>
                                </span> : ''
                            );
                    }

                    return '';
                })}
                <small>
                    <Moment format={'ddd, MMM DD, YYYY h:mm a'}>
                        {payment.created_at}
                    </Moment>
                </small>
            </div>
            <div slot={'end'} className={'amount'}>${payment.amount}</div>
        </IonItem>
    );
}

interface BillingHistoryContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const BillingHistoryContent: React.FC<BillingHistoryContentProps> = ({setHeaderProps}) => {

    useEffect(() => {
        setHeaderProps({
            children: 'Billing History',
        });
    }, [])

    return (
        <IonPage className={'billing-history-page'}>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <IonContent>
                            <h5>{myBusinessContext.organization.name}</h5>
                            <OrganizationPaymentContextProvider organizationId={myBusinessContext.business.organization_id} >
                                <OrganizationPaymentContext.Consumer>
                                    {organizationPaymentsContext => (
                                        <IonList>
                                            {organizationPaymentsContext.loadedData.filter(payment => payment.id != null).map(payment => (
                                                <BillingHistoryItem
                                                    payment={payment}
                                                    subscriptions={myBusinessContext.organization.subscriptions ?? []}
                                                />
                                            ))}
                                        </IonList>
                                    )}
                                </OrganizationPaymentContext.Consumer>
                            </OrganizationPaymentContextProvider>

                        </IonContent>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </IonPage>
    );
}

const BillingHistory: React.FC = () => {

    return (
        <OrganizationHeaderContext.Consumer>
            {organizationHeaderContext =>
                <BillingHistoryContent
                    setHeaderProps={organizationHeaderContext.setSharedProps}
                />
            }
        </OrganizationHeaderContext.Consumer>
    )
}

export default BillingHistory;
