import React from 'react';
import './index.scss';
import {IonPage} from '@ionic/react';

interface SideBySideProps {
    desktopOnly?: boolean
    className?: string,
}

const SideBySideComponent: React.FC<SideBySideProps> = ({ children, desktopOnly, className }) => {

    return (
        <div className={'side-by-side ' + (desktopOnly ? 'desktop-only ' : '') + (className ? className : '')}>
            {children}
        </div>
    )
}

export default SideBySideComponent
