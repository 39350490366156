import React from 'react';
import SimpleInputLocationEditPage from '../../../../components/OrganizationManagement/SimpleInputLocationEditPage';

const Description: React.FC = () => {

    return (
        <SimpleInputLocationEditPage
            iconUrl={'business-description'}
            inputLabel={'Description'}
            inputPlaceholder={'Briefly describe your business'}
            fieldName={'description'}
            title={'Business Description'}
            isMultiLine={true}
        />
    );
}

export default Description;
