import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import MembershipPlan from '../models/subscription/membership-plan';
import LoadingScreen from '../components/LoadingScreen';

/**
 * The state interface for our state
 */
export interface MembershipPlansContextState extends BasePaginatedContextState<MembershipPlan> {}

const defaultContext = {
    ...defaultBaseContext(),
} as MembershipPlansContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const MembershipPlansContext = React.createContext<MembershipPlansContextState>(defaultContext);

export interface MembershipPlansContextProviderProps extends BasePaginatedContextProviderProps{
}

export const MembershipPlansContextProvider: React.FC<MembershipPlansContextProviderProps> = (props => {
    const [membershipPlansState, setMembershipPlansState] = useState(persistentContext);

    useEffect(() => {
        prepareContextState(setMembershipPlansState, membershipPlansState,'/membership-plans')
    }, []);

    return (
        <MembershipPlansContext.Provider value={membershipPlansState}>
            <MembershipPlansContext.Consumer>
                {context => {
                    /**
                     * set our persistent context whenever it is updated here
                     */
                    persistentContext = context;
                    return context.initialLoadComplete ? props.children : <LoadingScreen/>;
                }}
            </MembershipPlansContext.Consumer>
        </MembershipPlansContext.Provider>
    )
});
