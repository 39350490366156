import React from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {IonContent, IonPage,} from '@ionic/react';
import BusinessCreationHeader from '../../../components/BusinessCreationHeader';
import Subscription, {findCurrentActiveSubscription} from '../../../models/subscription/subscription';
import MembershipPlan, {
    MembershipPlanDuration,
    findDefaultMembershipPlan
} from '../../../models/subscription/membership-plan';
import {MembershipPlansContext, MembershipPlansContextProvider} from '../../../contexts/MembershipPlansContext';
import SubscriptionEditor from '../../../components/OrganizationManagement/SubscriptionEditor';
import Business from '../../../models/organization/business';

interface PlanPreviewSelectorProps {
    business: Business,
    subscriptions: Subscription[],
    membershipPlans: MembershipPlan[],
}

const PlanPreviewContent: React.FC<PlanPreviewSelectorProps> = ({business, subscriptions, membershipPlans, ...props}) => {

    const currentSubscription = findCurrentActiveSubscription(subscriptions);

    return (
         <SubscriptionEditor
            previewMode
            onPlanSelected={() => {}}
            business={business}
            maybeSubscription={currentSubscription}
            selectedLocationAmount={1}
            membershipPlans={membershipPlans}
        />
    );
}

const PlanPreview: React.FC = () => {

    return (
        <MyBusinessContextProvider>
            <MyBusinessContext.Consumer>
                {myBusinessContext => (
                    <IonPage className={'plan-preview-page'}>
                        <BusinessCreationHeader noLogo/>
                        <IonContent>
                            <MembershipPlansContextProvider>
                                <MembershipPlansContext.Consumer>
                                    {membershipPlanContext => (
                                        <PlanPreviewContent
                                            business={myBusinessContext.business}
                                            membershipPlans={membershipPlanContext.loadedData}
                                            subscriptions={myBusinessContext.organization.subscriptions ? myBusinessContext.organization.subscriptions : []}
                                        />
                                    )}
                                </MembershipPlansContext.Consumer>
                            </MembershipPlansContextProvider>
                        </IonContent>
                    </IonPage>
                )}
            </MyBusinessContext.Consumer>
        </MyBusinessContextProvider>
    );
}

export default PlanPreview;
