import React, {useEffect, useState} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {
    IonButton,
    IonContent,
    IonItem,
    IonList, IonPage, useIonViewWillEnter, useIonViewWillLeave,
} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import OrganizationManager from '../../../models/organization/organization-manager';
import {getRoleName} from '../../../models/role';
import {
    OrganizationManagerContext,
    OrganizationManagerContextProvider
} from '../../../contexts/organization/OrganizationManagersContext';
import {useHistory} from 'react-router';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import Moment from 'react-moment';
import { App } from '@capacitor/app';
import {closeOutline} from 'ionicons/icons';
import Page from '../../../models/page';

interface OrganizationManagerItemProps {
    loggedInUserId: number,
    organizationManager: OrganizationManager,
    deleteClicked: () => void,
}

const OrganizationManagerItem: React.FC<OrganizationManagerItemProps> = ({loggedInUserId, organizationManager, deleteClicked}) => {

    const getInviteIdentifier = (): string => {
        if (organizationManager.invitation) {
            return organizationManager.invitation.phone ?
                organizationManager.invitation.phone : organizationManager.invitation.email!
        }
        return '';
    }

    return (
        <IonItem key={organizationManager.id!}>
            <p>
                {organizationManager.user ?
                    <strong>{organizationManager.user.full_name}</strong> :
                    <strong>Invite sent <Moment format={' MMMM D'}>{organizationManager.created_at}</Moment> ({getInviteIdentifier()})</strong>
                }
                <br/>
                <small>{getRoleName(organizationManager.role_id)}</small>
            </p>
            {loggedInUserId != organizationManager.user_id ?
                <IonButton onClick={deleteClicked} slot={'end'}>Delete</IonButton> : ''
            }
        </IonItem>
    );
}

interface OrganizationManagerListProps {
    businessName: string,
    organizationManagers: OrganizationManager[],
    refreshData: (persist?: boolean) => Promise<Page<OrganizationManager[]>>,
    removeOrganizationManager: (organizationManager: OrganizationManager) => void,
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const OrganizationManagerList: React.FC<OrganizationManagerListProps> = ({businessName, setHeaderProps, organizationManagers, refreshData, removeOrganizationManager}) => {

    const history = useHistory();

    const deleteOrganizationManager = (organizationManager: OrganizationManager) => {
        removeOrganizationManager(organizationManager);
        OrganizationRequests.deleteOrganizationManager(organizationManager);
    }

    const addUser = () => history.push(history.location.pathname.replace('management', 'editor'));

    useEffect(() => {
        App.addListener('appStateChange', ({ isActive }) => {
            if (isActive) {
                refreshData(false);
            }
        });
    }, [])

    useEffect(() => {
        setHeaderProps({
            createButtonClicked: addUser,
        });
    }, [history])

    return (
        <IonContent>
            <IonButton onClick={addUser} className={'btn-add-user hide-in-mobile'} color={'secondary'} fill={'clear'}>
                + Add User
            </IonButton>
            <IonList>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext =>
                            (organizationManagers.map(organizationManager => (
                                <OrganizationManagerItem
                                    key={organizationManager.id}
                                    loggedInUserId={meContext.me.id!}
                                    organizationManager={organizationManager}
                                    deleteClicked={() => deleteOrganizationManager(organizationManager)}
                                />
                            ))
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonList>
        </IonContent>
    )
}

export const UserManagement: React.FC = () => {

    const [clearCache, setClearCache] = useState(!document.referrer.includes('/organization/user-'));

    return (
        <IonPage className={'user-management-page'}>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <OrganizationManagerContextProvider organizationId={myBusinessContext.organization.id!} clearCache={clearCache} onCacheCleared={() => setClearCache(false)}>
                            <OrganizationManagerContext.Consumer>
                                {organizationManagerContext => (
                                    <OrganizationHeaderContext.Consumer>
                                        {organizationHeaderContext =>
                                            <OrganizationManagerList
                                                businessName={myBusinessContext.business.name}
                                                organizationManagers={organizationManagerContext.loadedData}
                                                refreshData={organizationManagerContext.refreshData}
                                                removeOrganizationManager={organizationManagerContext.removeModel}
                                                setHeaderProps={organizationHeaderContext.setSharedProps}
                                            />
                                        }
                                    </OrganizationHeaderContext.Consumer>
                                )}
                            </OrganizationManagerContext.Consumer>
                        </OrganizationManagerContextProvider>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </IonPage>
    );
}
