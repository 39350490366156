import {IonContent, IonPage} from '@ionic/react';
import React, {useState} from 'react';

import LoggedOutHeaderComponent from '../../../../components/WelcomeSection/LoggedOutHeader';
import './index.scss';
import SignInContextProvider, {
	SignInContext,
	SignInData
} from '../../../../contexts/SignIn/SignInContext';
import VerificationCodeEntry from '../../../../components/WelcomeSection/VerificationCodeEntry';
import AuthRequests from '../../../../services/requests/AuthRequests';
import {useHistory} from 'react-router-dom';
import ResponsiveContent from '../../../../components/ResponsiveContent';
import {Capacitor} from '@capacitor/core';
import WelcomeTemplate from '../../../../components/WelcomeSection/WelcomeTemplate';

interface VerificationCodeProps { }

const VerificationCode: React.FC<VerificationCodeProps> = () => {

	const [credentialError, setCredentialError] = useState(false)
	const history = useHistory();

	const submit = (values: SignInData) => {

		AuthRequests.signIn(values).then(async () => {
			let loginUrl = localStorage.getItem('login_redirect');
			localStorage.removeItem('login_redirect');

			if (loginUrl) {
				history.push(loginUrl);
			} else if (!Capacitor.isNativePlatform()) {
				window.location = '/home' as any
			} else {
				history.push('/welcome/notifications')
			}

		}).catch(error => {
			console.error(error);
			if (error.status && (error.status == 401 || error.status == 400)) {
				setCredentialError(true);
			}
			return false;
		})
		return true;
	}

	const cancel = () => {
		window.location.replace('/welcome/splash')
	}

	return (
		<IonPage className={'sign-up-page'}>
			<LoggedOutHeaderComponent backText='Cancel' onBackClick={cancel} />
			<ResponsiveContent maxWidth={'480px'}>
				{credentialError &&
					<p className={'error'}>Invalid Verification Code</p>
				}
				<SignInContextProvider>
					<SignInContext.Consumer>
						{signInContext =>
							<VerificationCodeEntry
								onSubmit={submit}
								verificationCodeContext={signInContext}
								mustExist={true}
							/>
						}
					</SignInContext.Consumer>
				</SignInContextProvider>
			</ResponsiveContent>
		</IonPage>
	)
}

export default VerificationCode
