import React, {useState, useEffect} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../../contexts/organization/MyBusinessContext';
import {
    IonButton,
    IonContent,
    IonPage,
} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../../components/OrganizationManagement/OrganizationHeader';
import Subscription, {
    findCurrentActiveSubscription, hasPendingSubscriptionCharge, isSubscriptionInTrialWithoutRenewal,
} from '../../../../models/subscription/subscription';
import MembershipPlan, {
    MembershipPlanDuration,
    findDefaultMembershipPlan, humanizeDurationText
} from '../../../../models/subscription/membership-plan';
import {MembershipPlansContext, MembershipPlansContextProvider} from '../../../../contexts/MembershipPlansContext';
import Organization from '../../../../models/organization/organization';
import Moment from 'react-moment';
import {useHistory} from 'react-router';
import Business from '../../../../models/organization/business';
import SubscriptionForm from '../../../../components/OrganizationManagement/SubscriptionForm';


interface OrganizationProps {
    organization: Organization,
    setOrganization: (organization: Organization) => void,
}

interface BackHandlerProps {
    swiper: any,
    setSwiper: (swiper: any) => void,
}


interface ActiveSubscriptionDetailsProps {
    subscription: Subscription,
    onChangeSubscription: () => void
}

const ActiveSubscriptionDetails: React.FC<ActiveSubscriptionDetailsProps> = ({subscription, onChangeSubscription}) => {
    const membershipPlanRate = subscription.membership_plan_rate;
    const membershipPlan = membershipPlanRate.membership_plan!;
    const subscriptionTotal = (subscription.location_count * membershipPlanRate.cost).toFixed(2);
    const billingCycle = humanizeDurationText(membershipPlan.duration)

    return (
        <IonContent className={'active-subscription-details'}>
            <p>
                <strong>Your current plan:</strong>
                <br/>
                {membershipPlan.name}
            </p>
            <p className={'plan-details-header'}><strong>Current Plan Details</strong></p>
            <div className={'plan-details'}>
                <p><strong>Locations:</strong> {subscription.location_count}</p>
                <p><strong>Billing Cycle:</strong> {billingCycle}</p>
                <p><strong>Subscription Rate:</strong> ${membershipPlanRate.cost.toFixed(0)} per location</p>
            </div>
            <div className={'upcoming-renewal-estimate'}>
                {subscription.expires_at ? (
                    <h4>Estimate for
                        <Moment format={' MMMM D'}>
                            {subscription.expires_at}
                        </Moment>
                    </h4>
                ) : <h4>Free Plan</h4>}
                <h3>${subscriptionTotal}</h3>
                <p>These numbers aren't final until your billing cycle is over.</p>
                <IonButton color={'primary'} onClick={onChangeSubscription}>Change Plan</IonButton>
            </div>
            <div className={'upcoming-renewal-line-items'}>
                <p>
                    <strong>{membershipPlan.name}<span className={'amount'}>${membershipPlanRate.cost.toFixed(2)}</span></strong>
                    <br/>
                    x {subscription.location_count} Location{subscription.location_count > 1 ? 's' : ''}
                </p>
                <p>
                    <strong>Tax<span className={'amount'}>$0.00</span></strong>
                </p>
                <hr/>
                <p>
                    <strong>Estimated Total<span className={'amount'}>${subscriptionTotal}</span></strong>
                    <br/>
                    Autopay on
                    <Moment format={' MMMM D, YYYY'}>
                        {subscription.expires_at}
                    </Moment>
                </p>
            </div>
        </IonContent>
    );
}

const SubscriptionUnavailable: React.FC = () => {
    return (
        <IonContent>
            <h2>Sorry</h2>
            <p>Subscription upgrades are currently not available</p>
        </IonContent>
    )
}

interface CurrentSubscriptionSelectorProps extends BackHandlerProps, OrganizationProps {
    business: Business,
    subscriptions: Subscription[],
    membershipPlans: MembershipPlan[],
    changingSubscription: boolean,
    setChangingSubscription: (changingSubscription: boolean) => void,
}

const CurrentSubscriptionSelector: React.FC<CurrentSubscriptionSelectorProps> = ({organization, changingSubscription, setChangingSubscription, swiper, setSwiper, setOrganization, business, subscriptions, membershipPlans, ...props}) => {

    const currentSubscription = findCurrentActiveSubscription(subscriptions);

    const isUnUpgradedTrial = currentSubscription ? isSubscriptionInTrialWithoutRenewal(currentSubscription) : false

    useEffect(() => {
        setChangingSubscription(false)
    }, [currentSubscription])

    if (membershipPlans.filter(i => !i.default).length == 0) {
        return (
            <SubscriptionUnavailable/>
        );
    }

    return (currentSubscription && !changingSubscription && !isUnUpgradedTrial) ? (
        <ActiveSubscriptionDetails
            onChangeSubscription={() => setChangingSubscription(true)}
            subscription={currentSubscription}
        />
    ) : (
        <SubscriptionForm
            membershipPlans={membershipPlans}
            setOrganization={setOrganization}
            organization={organization}
            swiper={swiper}
            setSwiper={setSwiper}
            business={business}
            maybeSubscription={currentSubscription}
        />
    );
}

interface CurrentSubscriptionContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const CurrentSubscriptionContent: React.FC<CurrentSubscriptionContentProps> = ({setHeaderProps}) => {

    const [swiper, setSwiper] = useState<any>({});
    const [changingSubscription, setChangingSubscription] = useState(false);
    const history = useHistory();

    const backClicked = () => {
        if (swiper.activeIndex) {
            swiper.slidePrev();
        } else if (changingSubscription) {
            setChangingSubscription(false);
        } else {
            history.goBack();
        }
    }

    useEffect(() => {
        setHeaderProps({
            children: 'Subscription Plan',
            backClicked: backClicked
        });
    }, [swiper, changingSubscription])

    return (
        <IonPage className={'current-subscription-page'}>
            <IonContent className={'main-wrapper'}>
                <MyBusinessContextProvider>
                    <MyBusinessContext.Consumer>
                        {myBusinessContext => (
                            <MembershipPlansContextProvider>
                                <MembershipPlansContext.Consumer>
                                    {membershipPlanContext => (
                                        <CurrentSubscriptionSelector
                                            swiper={swiper}
                                            setSwiper={setSwiper}
                                            changingSubscription={changingSubscription}
                                            setChangingSubscription={setChangingSubscription}
                                            business={myBusinessContext.business}
                                            organization={myBusinessContext.organization}
                                            setOrganization={myBusinessContext.setOrganization}
                                            membershipPlans={membershipPlanContext.loadedData}
                                            subscriptions={myBusinessContext.organization.subscriptions ? myBusinessContext.organization.subscriptions : []}
                                        />
                                    )}
                                </MembershipPlansContext.Consumer>
                            </MembershipPlansContextProvider>
                        )}
                    </MyBusinessContext.Consumer>
                </MyBusinessContextProvider>
            </IonContent>
        </IonPage>
    );
}

const CurrentSubscription: React.FC = () => {

    return (
        <OrganizationHeaderContext.Consumer>
            {organizationHeaderContext =>
                <CurrentSubscriptionContent
                    setHeaderProps={organizationHeaderContext.setSharedProps}
                />
            }
        </OrganizationHeaderContext.Consumer>
    )
}

export default CurrentSubscription;
