import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Thread from '../../models/user/thread';
import Message from '../../models/user/message';

/**
 * The state interface for our state
 */
export interface ThreadMessagesContextState extends BasePaginatedContextState<Message> {}

const defaultContext = {
    ...defaultBaseContext(),
    expands: [
        'from',
    ],
} as ThreadMessagesContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
const cachedStates: { [key: string]: ThreadMessagesContextState; } = {};

/**
 * The actual context component
 */
export const ThreadMessagesContext = React.createContext<ThreadMessagesContextState>(defaultContext);

export interface ThreadMessagesContextProviderProps extends BasePaginatedContextProviderProps{
    threadId: number,
}

// Shares our state setter in order to run updates across all consumers
let setters: any = {};

export const ThreadMessagesContextProvider: React.FC<ThreadMessagesContextProviderProps> = (({threadId, ...props}) => {

    if (!cachedStates[threadId]) {
        cachedStates[threadId] = {
            ...defaultBaseContext(),
            expands: [
                'from',
            ],
        };
    }

    const [threadMessagesState, setThreadMessagesState] = useState(cachedStates[threadId]);

    useEffect(() => {
        setters[threadId] = setThreadMessagesState

        prepareContextState(setThreadMessagesState, cachedStates[threadId],'/threads/' + threadId + '/messages')
    }, [threadId]);

    useEffect(() => {
        cachedStates[threadId] = threadMessagesState;
        Object.keys(setters).forEach((key: string) => {
            if (parseInt(key) === threadId) {
                setters[key](threadMessagesState)
            }
        })
    }, [threadMessagesState])
    return (
        <ThreadMessagesContext.Provider value={createCallbacks(setThreadMessagesState, threadMessagesState, '/threads/' + threadId + '/messages')}>
            {props.children}
        </ThreadMessagesContext.Provider>
    )
});
