import BaseModel from '../base-model';
import {AvailableEntityTypes} from '../entities';
import Subscription from './subscription';

export enum MembershipPlanDuration
{
    Lifetime = 'lifetime',
    Year = 'year',
    Month = 'month',
}

export type MembershipPlanTypes = 'basic' | 'pro';

export default interface MembershipPlan extends BaseModel {

    /**
     * The name of the membership plan
     */
    name: string;

    /**
     * The duration for this membership plan
     */
    duration: MembershipPlanDuration;

    /**
     * The radius available for this membership plan
     */
    post_radius: number;

    /**
     * Whether or not this is the default membership plan
     */
    default: boolean;

    /**
     * The description for this membership plan
     */
    description: string;

    /**
     * How much the plan is if someone signs up today
     */
    current_cost: number;

    /**
     * The id of the current active rate in the system
     */
    current_rate_id: number;

    /**
     * The amount of days the trial is if this membership plan has a trial
     */
    trial_period?: number;

    /**
     * What sort of entities can subscribe to this membership plan
     */
    entity_type: AvailableEntityTypes;

    /**
     * What type of plan this is
     */
    type: MembershipPlanTypes;
}

/**
 * Calculates how much a subscription to this wil lcost
 * @param newMembershipPlan
 * @param currentSubscription
 * @param newLocationCount
 */
export function calculateProratedCost(newMembershipPlan: MembershipPlan, currentSubscription: Subscription, newLocationCount: number): number
{
    if (currentSubscription.is_trial || newLocationCount < currentSubscription.location_count) {
        return 0;
    }

    const currentSubscriptionCost = currentSubscription.last_renewal_rate.cost;

    const oneDay = 24 * 60 * 60 * 1000;
    const daysRemaining = Math.ceil((Date.parse(currentSubscription.expires_at) - Date.now()) / oneDay);
    const durationDays = Math.round((Date.parse(currentSubscription.expires_at) - Date.parse(currentSubscription.last_renewed_at)) / oneDay);
    const dailyCost = currentSubscriptionCost / durationDays;
    const locationDiff = newLocationCount - currentSubscription.location_count;

    return daysRemaining * dailyCost * locationDiff;
}

/**
 * Gets the free membership plan out of the list of passed in membership plans
 * @param membershipPlans
 */
export function findDefaultMembershipPlan(membershipPlans: MembershipPlan[]): MembershipPlan|undefined
{
    return membershipPlans.find(i => i.default);
}

/**
 * Transforms the duration passed in into something more easily read by humans
 * @param duration
 */
export function humanizeDurationText(duration: MembershipPlanDuration){
    switch(duration) {
        case 'year':
            return 'Yearly'
        case 'lifetime':
            return 'Lifetime'
        default:
            return 'Monthly'
    }
}
