import './index.scss';
import React from 'react';
import MyBusinessContextProvider, {
	MyBusinessContext,
	MyBusinessContextState
} from '../../../contexts/organization/MyBusinessContext';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import {IonButton, IonIcon, IonItem, IonMenuToggle} from '@ionic/react';
import {arrowUndoOutline, gridOutline, home, logoUsd, mail, peopleCircleOutline, peopleSharp} from 'ionicons/icons';
import {
	findCurrentActiveSubscription,
	isSubscriptionInTrialWithoutRenewal
} from '../../../models/subscription/subscription';
import User, {canFillRole} from "../../../models/user/user";
import {AvailableRoles} from "../../../models/role";
import {isBusinessEligibleForProPlan} from "../../../models/organization/business";
import {Capacitor} from '@capacitor/core';
import {connect} from '../../../data/connect';
import {logOut} from '../../../data/persistent/persistent.actions';
import {clearLoadingCount} from '../../../data/session/session.actions';
import AccountSwitcher from '../../AccountSwitcher';

interface OrganziationMenuContentProps extends DispatchProps {
	me: User,
	myBusiness: MyBusinessContextState,
}

const OrganizationMenuContent: React.FC<OrganziationMenuContentProps> = ({me, myBusiness, logOut, clearLoadingCount}) => {
	const eligibleForProPlan = isBusinessEligibleForProPlan(myBusiness.business);
	const currentSubscription = findCurrentActiveSubscription(myBusiness.organization.subscriptions ?? []);

	/**
	 * Logs out our user from the app properly
	 */
	const handleLogOut = () => {
		logOut();
		clearLoadingCount();
		window.location.pathname = '/welcome/sign-in/phone'
	}

	return (
		<div className={'organization-menu-content'}>
			<h4 className={'mobile-only'}>{myBusiness.business.name}</h4>
			<div className={'desktop-only'}>
				<MyBusinessContextProvider hideLoadingSpace={true}>
					<MyBusinessContext.Consumer>
						{myBusinessContext =>
							<AccountSwitcher selectedText={myBusinessContext.business.name}/>
						}
					</MyBusinessContext.Consumer>
				</MyBusinessContextProvider>
			</div>
			{/*{(currentSubscription == null || isSubscriptionInTrialWithoutRenewal(currentSubscription)) && eligibleForProPlan ? (*/}
			{/*	<IonMenuToggle menu = {'organization'} >*/}
			{/*		<IonButton expand={'block'} routerLink={'/organization/billing/current-subscription'}>Upgrade Plan</IonButton>*/}
			{/*	</IonMenuToggle>*/}
			{/*) : null}*/}
			<hr/>
			<h4 className={'account-admin-header'}>Account Admin</h4>
				<IonItem className={'desktop-only'} lines={'none'} routerLink={'/organization/dashboard/profile'} detail={false}>
					<IonIcon icon={home} slot={'start'}/>
					Home
				</IonItem>
			{canFillRole(me, myBusiness.organization, [AvailableRoles.Administrator]) ? (
				<React.Fragment>
					<IonMenuToggle menu={'organization'} autoHide={false}>
						<IonItem lines={'none'} routerLink={'/organization/businesses'} detail={false}>
							<IonIcon icon={gridOutline} slot={'start'}/>
							Manage Business
						</IonItem>
					</IonMenuToggle>
					<IonMenuToggle menu={'organization'} autoHide={false}>
						<IonItem lines={'none'} routerLink={'/organization/user-management'} detail={false}>
							<IonIcon icon={peopleCircleOutline} slot={'start'}/>
							Manage User Access
						</IonItem>
					</IonMenuToggle>
					{/*<IonMenuToggle menu={'organization'}>*/}
					{/*	<IonItem lines={'none'} routerLink={'/organization/billing'} detail={false}>*/}
					{/*		<IonIcon icon={logoUsd} slot={'start'}/>*/}
					{/*		Plans & Billing*/}
					{/*	</IonItem>*/}
					{/*</IonMenuToggle>*/}
				</React.Fragment>
			) : null}
			{isBusinessEligibleForProPlan(myBusiness.business) ?
				<IonMenuToggle menu={'organization'} autoHide={false}>
					<IonItem lines={'none'} routerLink={'/organization/instagram'} detail={false}>
						<div slot={'start'} className={'instagram-logo'}>
							<img src={'/assets/instagram-logo.svg'}/>
						</div>
						Connect Instagram
					</IonItem>
				</IonMenuToggle> : false
			}
			<hr/>
			<IonMenuToggle menu={'organization'} autoHide={false}>
				<IonItem lines={'none'} routerLink={'/organization/contact-us'}>
					<IonIcon icon={mail} slot={'start'}/>
					Contact Geddit Local
				</IonItem>
			</IonMenuToggle>
			{!Capacitor.isNativePlatform() &&

				<IonMenuToggle menu={'organization'} autoHide={false}>
					<IonItem lines={'none'} onClick={handleLogOut} button>
						<IonIcon icon={arrowUndoOutline} slot={'start'}/>
						Logout
					</IonItem>
				</IonMenuToggle>
			}
		</div>
	)
}

interface DispatchProps {
	logOut: typeof logOut,
	clearLoadingCount: typeof clearLoadingCount,
}

interface Props extends DispatchProps {
}

const OrganizationMenu: React.FC<Props> = (props) => {

	return (
		<MyBusinessContextProvider hideLoadingSpace>
			<MyBusinessContext.Consumer>
				{myBusiness => (
					<MeContextProvider>
						<MeContext.Consumer>
							{meContext => (
								<OrganizationMenuContent me={meContext.me} myBusiness={myBusiness} {...props}/>
							)}
						</MeContext.Consumer>
					</MeContextProvider>
				)}
			</MyBusinessContext.Consumer>
		</MyBusinessContextProvider>
	)
}

const ConnectedOrganizationMenu = connect<{  }, { }, DispatchProps>({
	mapDispatchToProps: ({
		logOut,
		clearLoadingCount,
	}),
	component: OrganizationMenu,
});

export default ConnectedOrganizationMenu;
