import {connect} from '../../../data/connect';
import {setManagingBusinessId} from '../../../data/persistent/persistent.actions';
import {IonContent, IonPage} from '@ionic/react';
import React, {useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';


interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessDeepLinkListenerProps extends DispatchProps {
}

interface RouteParams {
    businessId: string,
    path: string,
}

const BusinessDeepLinkListener: React.FC<BusinessDeepLinkListenerProps> = ({setManagingBusinessId}) => {

    const {businessId} = useParams<RouteParams>();
    const history = useHistory();

    useEffect(() => {
        const parsedBusinessId = Number.parseInt(businessId);
        const parts = window.location.pathname.split('business/' + businessId);
        const path = parts.length > 1 ? parts.pop() : undefined;
        if (parsedBusinessId && path) {
            setManagingBusinessId(Number.parseInt(businessId));
            window.location.pathname = ('/organization' + path);
        }
    }, [window.location.pathname])

    return (
        <IonPage><IonContent/></IonPage>
    )
}

export default connect<{ }, { }, DispatchProps >({
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: BusinessDeepLinkListener
});
