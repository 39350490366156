import {IonButton, IonContent, IonItem, IonList, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import React, {useEffect, useState} from 'react';
import './index.scss';
import {
    UserFollowsContext,
    UserFollowsContextProvider, UserFollowsContextState
} from '../../../../contexts/UserFollowsContext';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
import User from '../../../../models/user/user';
import Location from '../../../../models/location/location';
import ProfileImage from '../../../../components/ProfileImage';
import Follower from '../../../../models/user/follower';
import FollowerRequests from '../../../../services/requests/FollowerRequests';
import {chevronBack} from "ionicons/icons";
import FollowButton from '../../../../components/MembersPages/FollowButton';


interface FollowedLocationDetailsProps {
    location: Location,
}

const FollowedLocationDetails: React.FC<FollowedLocationDetailsProps> = ({location}) => {
    return (
        <React.Fragment>
            <ProfileImage slot={'start'} url={location.logo} name={location.name}/>
            <div>
                <p>
                    <strong>{location.name}</strong>
                    <br/>
                    {location.business?.main_category_name ? location.business.main_category_name : ''}
                </p>
            </div>
        </React.Fragment>
    )
}

interface FollowedUserDetailsProps {
    user: User,
}

const FollowedUserDetails: React.FC<FollowedUserDetailsProps> = ({user}) => {
    return (
        <React.Fragment>
            <ProfileImage slot={'start'} url={user.profile_image_url} name={user.full_name}/>
            <div>
                <p>
                    <strong>{user.full_name}</strong>
                </p>
            </div>
        </React.Fragment>
    );
}

interface FollowedItemProps {
    follower: Follower,
    stopFollowing: (follower: Follower) => void,
}

/**
 * The actual component that renders an individual list item
 * @param business
 * @param stopFollowing
 * @constructor
 */
const FollowedItem: React.FC<FollowedItemProps> = ({follower, stopFollowing}) => {

    return (
        <IonItem
            className={'follower-item'}
            detail={false}
            routerLink={follower.follows_type == 'user' ? '/home/dashboard/user/' + follower.follows_id : '/home/dashboard/business/' + (follower.follows as Location).business_id + '/location/' + follower.follows_id}>
            {follower.follows_type == 'location' ?
                <FollowedLocationDetails location={follower.follows as Location}/> :
                <FollowedUserDetails user={follower.follows as User}/>
            }

            <MeContextProvider optional hideLoadingSpace>
                <MeContext.Consumer>
                    {meContext =>
                        <UserFollowsContextProvider userId={meContext.me.id!}>
                            <UserFollowsContext.Consumer>
                                {userFollowsContext =>
                                    <FollowButton
                                        relatedId={follower.follows_id}
                                        relatedType={follower.follows_type}
                                        related={follower.follows!}
                                        userFollowsContext={userFollowsContext}
                                    />
                                }
                            </UserFollowsContext.Consumer>
                        </UserFollowsContextProvider>
                    }
                </MeContext.Consumer>
            </MeContextProvider>
        </IonItem>
    );
}

interface FollowingListProps {
    followers: Follower[],
    removeFollower: (follower: Follower) => void,
}

const FollowingList: React.FC<FollowingListProps> = ({followers, removeFollower}) => {

    const [cachedFollowers, setCachedFollowers] = useState(followers);

    const stopFollowingHandler = (follower: Follower) => {
        FollowerRequests.unFollow(follower).then(() => {
            removeFollower(follower);
        });
    }

    useEffect(() => {
        const cachedFollowerIds = cachedFollowers.map(i => i.id);
        const newFollowers = followers.filter(i => !cachedFollowerIds.includes(i.id));
        setCachedFollowers([
            ...cachedFollowers,
            ...newFollowers,
        ])
    }, [followers]);

    return (
        <IonList>
            {cachedFollowers.filter(i => !i.hidden && i.follows).map(follower => (
                <FollowedItem
                    key={follower?.id}
                    follower={follower}
                    stopFollowing={stopFollowingHandler}
                />
            ))}
        </IonList>
    )
}

const NoFollowing: React.FC = () => (
    <IonContent id={'no-followed-businesses'}>
        <h4>You're not following any people or places yet!</h4>
        <img src={'/assets/splash.png'}/>
        <p>
            Follow your favorite local people and places so you don't miss out on things that interest you and your community!
        </p>
        <IonButton routerLink={'/home/dashboard/explore'}>Explore</IonButton>
    </IonContent>
)

interface FollowingDataProcessorProps {
    userFollowedBusinessesContext: UserFollowsContextState
}

/**
 * This little guy processes a set of data, and makes sure to sort everything out properly
 * @param userFollowedBusinessesContext
 * @constructor
 */
const FollowingDataProcessor: React.FC<FollowingDataProcessorProps> = ({userFollowedBusinessesContext}) => {

    return (!userFollowedBusinessesContext.noResults ?
        <FollowingList
            followers={userFollowedBusinessesContext.loadedData}
            removeFollower={userFollowedBusinessesContext.removeModel}
        /> : <NoFollowing/>
    )
}

interface FollowingLoaderProps {
    me: User
}

/**
 * This handles loading all follows businesses for a user,
 * and then hands off rendering of said businesses to another functional component
 * @param me
 * @constructor
 */
const FollowingLoader: React.FC<FollowingLoaderProps> = ({me}) => {

    return (
        <UserFollowsContextProvider userId={me.id!}>
            <UserFollowsContext.Consumer>
                {userFollowedBusinessesContext => (
                    <FollowingDataProcessor
                        userFollowedBusinessesContext={userFollowedBusinessesContext}
                    />
                )}
            </UserFollowsContext.Consumer>
        </UserFollowsContextProvider>
    )
}

/**
 * This is our base component for this page. It simply handles loading the logged in user,
 * and then hands off proper displays to our previously defined elements.
 * @constructor
 */
const Following: React.FC = () => {

    return (
        <IonPage id={'following-page'}>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Following
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <FollowingLoader me={meContext.me}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default Following;
