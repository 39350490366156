import {IonContent, IonIcon, IonPage, IonRouterLink} from '@ionic/react';
import React from 'react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../../../contexts/MeContext';
import GreyBackgroundSection from '../../../../../components/GreyBackgroundSection';
import UserProfileImageUploader from '../../../../../components/UserProfileImageUploader';
import './index.scss';
import {chevronBack, mail, pencilOutline} from 'ionicons/icons';
import {formatUserPhoneNumber} from '../../../../../models/user/user';
import { useLocation } from 'react-router';

interface ProfileCardProps {
    meContext: MeContextStateConsumer,
}

const ProfileCard: React.FC<ProfileCardProps> = ({meContext}) => {

    const location = useLocation();

    return (
        <GreyBackgroundSection>
            <UserProfileImageUploader meContext={meContext}/>
            <IonRouterLink routerLink={`/home/dashboard/account/settings/profile/editor`}>
                <IonIcon icon={pencilOutline}/>
            </IonRouterLink>
            <div className={'contact-info'}>
                <p>{meContext.me.full_name}</p>
                <p><IonIcon icon={mail}/>&nbsp;{meContext.me.email}</p>
                {meContext.me.phone ?
                    <p><IonIcon src={'/assets/app-icons/phone.svg'}/>&nbsp;{formatUserPhoneNumber(meContext.me)}</p> : ''
                }
            </div>
        </GreyBackgroundSection>
    );
}

const Profile: React.FC = () => {

    return (
        <IonPage id={'profile-page'}>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Profile
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <ProfileCard meContext={meContext}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default Profile;
