import React from 'react';
import './index.scss';
import SubscriptionUpgraded from '../SubscriptionUpgraded';

const FreeSubscriptionSelected: React.FC = () => {

    return (
        <SubscriptionUpgraded className={'free-subscription-selected'}>
            <p>Upgrade to our Pro plan when you're ready to start reaching out to the community around you.</p>
        </SubscriptionUpgraded>
    );
}

export default FreeSubscriptionSelected;
