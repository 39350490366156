import {IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar} from '@ionic/react';
import {closeOutline} from 'ionicons/icons';
import ThreadList from '../../Forum/ThreadList';
import React from 'react';
import Post from '../../../models/post/post';
import './index.scss';
import CanParticipateInForum from '../../../models/can-participate-in-forum';
import Thread from '../../../models/user/thread';
import Message from '../../../models/user/message';

export interface PostCommentInteractions {
    onCommentCountChanged: (newCount: number) => void,
    participant?: CanParticipateInForum,
    createPostThread?: (post: Post, topic: string) => Promise<Thread>,
    createMessage?: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
}

interface PostCommentsProps {
    post: Post,
    isOpen: boolean,
    onDismissed: () => void,
    interactions: PostCommentInteractions,
}

const PostComments: React.FC<PostCommentsProps> = ({post, isOpen, onDismissed, interactions, ...rest}) => {

    const commentCount = post.comment_count ?? 0;

    return (
        <IonModal
            backdropDismiss
            isOpen={isOpen}
            onDidDismiss={onDismissed}
            class={'post-comments-modal'}
            breakpoints={[.3, .75, 1]}
            initialBreakpoint={1}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Comments
                    </IonTitle>
                    <IonButtons slot={'end'}>
                        <IonIcon onClick={onDismissed} icon={closeOutline}/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent class={'ion-no-padding'} scrollY={false}>
                <ThreadList
                    refresh={isOpen}
                    post={post}
                    onCommentSubmitted={() => interactions.onCommentCountChanged(commentCount + 1)}
                    {...interactions}
                />
            </IonContent>
        </IonModal>
    )
}

export default PostComments;
