import React, {PropsWithChildren, HTMLAttributes, useState} from 'react';
import {
    IonAlert,
    IonButton,
    IonContent,
    IonPage,
    IonRouterLink,
} from '@ionic/react';

import './index.scss';
import BusinessCreationHeader from '../BusinessCreationHeader';

interface OrganizationCreationResultsFooterProps{
}

const OrganizationCreationFooterContent: React.FC<OrganizationCreationResultsFooterProps> = ({}) => {

    const [earlyAdaptorShowing, setEarlyAdaptorShowing] = useState(false);
    const goToSetup = () => window.location.replace('/organization-creator/business-header-setup')
    return (
        <div className="organization-creation-footer">
            <IonButton onClick={goToSetup}>Get Started</IonButton>
            <p>
                By clicking Get Started, you agree to the <IonRouterLink routerLink={'/organization-creator/terms'}>Terms&nbsp;of&nbsp;Use</IonRouterLink> and <IonRouterLink routerLink={'/organization-creator/privacy-policy'}>Privacy&nbsp;Policy</IonRouterLink>
            </p>
            <IonAlert
                header={'We reward early adopters!'}
                message={'The first 100 businesses to subscribe to our Pro Plan will lock-in at our Basic Plan rate. Subscribe now and billing won\'t begin until after your free trial period ends.'}
                buttons={[
                    'Got it!'
                ]}
                isOpen={earlyAdaptorShowing}
                onDidDismiss={goToSetup}
            />
        </div>
    )
}

interface OwnProps extends HTMLAttributes<HTMLElement> {
}

interface OrganizationCreationResultsPageProps extends OwnProps {
    closeLink?: string,
    linkText?: string,
    linkURL?: string,
    showGetStartedFooter?: boolean
}

const OrganizationCreationResultsPage: React.FC<PropsWithChildren<OrganizationCreationResultsPageProps>> = ({closeLink, linkText, linkURL, showGetStartedFooter, children, ...props}) => {
    return (
        <IonPage {...props}>
            <BusinessCreationHeader closeButton={true} routerLink={closeLink}/>
            <IonContent className="organization-creation-content">
                <div className={'results-children-wrapper'}>{children}</div>
                {linkURL ? <p><IonRouterLink routerLink={linkURL}>{linkText} →</IonRouterLink></p> : null }
                {showGetStartedFooter ? <OrganizationCreationFooterContent/> : null}
            </IonContent>
        </IonPage>
    )
}

export default OrganizationCreationResultsPage
