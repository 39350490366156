import React from 'react'
import {IonContent} from '@ionic/react';

const TermsOfUseText: React.FC = () => {
	return (
		<IonContent>
			<p>Geddit Local (“Geddit Local,” “we,” “us,” or “our”) welcomes you.  We’re really excited that you have decided to access and use the Geddit Local website (the “Website”), our social media plugins (the “Social Media Plugins”), and/or our mobile applications (the “Mobile Apps,” and collectively with the Social Media Plugins and the Website, the “Service”).  </p>
			<p>We provide our Service to you subject to the following Terms of Use, which may be updated by us from time to time without notice to you.  By browsing the public areas or by accessing and using the Service, you acknowledge that you have read, understood, and agree to be legally bound by the terms and conditions of these Terms of Use and the terms and conditions of our Privacy Policy, which is hereby incorporated by reference (collectively, this “Agreement.”).  If you do not agree to any of these terms, then please do not use the Service.  </p>
			<p>Capitalized terms not defined in these Terms of Use shall have the meaning set forth in our Privacy Policy.</p>
			<h3>1.	DESCRIPTION AND USE OF SERVICES</h3>
			<p>We are a location-based mobile platform connecting local businesses and organizations to people in their community.  Our mission is to strengthen local communities by empowering the small business markets across America. </p>
			<p>Although you are able to find information about these participating businesses through the Service, we are not a party to any transaction you make with these businesses.  Accordingly, we shall have no liability to any party in connection with such transactions.</p>
			<p>We provide Visitors and Registered Users with access to the Service as described in this Agreement.</p>
			<p><u>Visitors</u>.  Visitors, as the term implies, are people who don’t register with us, but want to poke around and see what the Service is all about.  No login is required for Visitors.  Visitors can (a) view all publicly-accessible content, and (b) e-mail us.</p>
			<p><u>Registered Users</u>.  Login is required for all Registered User services.  Registered Users can do all the things that Visitors can do, and may also be able to (a) ‘Like’ or share content posted by businesses, (b) follow businesses to stay better informed, (c) search for participating businesses based on location, product, and/or service, (d) use our suggestion engine for recommendations, (e) research a particular participating business, (f) enable alerts and other notifications, (g) post comments, and (h) feel great about spending their money with independent, neighborhood retailers with the knowledge that they are helping promote diversity in our communities.  </p>
			<p>We are under no obligation to accept any individual as a Registered User, and may accept or reject any registration in our sole and complete discretion.  </p>
			<p>Also, when using the Service via our Mobile Apps, Registered Users may elect to consent to: (a) the use of their Mobile Device’s Geolocational Information to provide the Service; (b) receive text messages; and (c) pay carrier data, messaging, and other fees resulting from their usage of the Service. </p>
			<h3>2.	COMMUNITY GUIDELINES</h3>
			<p>The Geddit Local community, like any community, functions best when its people follow a few simple rules.  By accessing and/or using the Service, you hereby agree to comply with these community rules and that:  </p>
			<ul>
				<li>You will not use the Service for any unlawful purpose;</li>
				<li>You will not access or use the Services to collect any market research for a competing business;</li>
				<li>
					You will not upload, post, e-mail, transmit, or otherwise make available any content that:
					<ul>
						<li>infringes any copyright, trademark, right of publicity, or other proprietary rights of any person or entity; or</li>
						<li>is threatening, tortious, defamatory, libelous, indecent, obscene, pornographic, invasive of another’s privacy, or promotes violence; or</li>
						<li>discloses any sensitive information about another person, including that person’s e-mail address, postal address, phone number, credit card information, or any similar information;</li>
					</ul>
				</li>
				<li>You will not “stalk” or otherwise harass another; </li>
				<li>You will not impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity; and</li>
				<li>You will not interfere with or attempt to interrupt the proper operation of the Service through the use of any virus, device, information collection or transmission mechanism, software or routine, or access or attempt to gain access to any data, files, or passwords related to the Service through hacking, password or data mining, or any other means.  </li>
			</ul>
			<p>We reserve the right, in our sole and absolute discretion, to deny you access to the Service, or any portion of the Service, without notice, and to remove any comments that do not adhere to these guidelines.</p>
			<h3>3.	RESTRICTIONS</h3>
			<p>The Services are available for individuals aged 17 years or older.  </p>
			<h3>4.	PAYMENT</h3>
			<p>You agree that Geddit Local may immediately authorize your credit card (or other approved facility) for payment for all registration fees made under your login credentials (as defined below).   </p>
			<p>As set forth more fully in Section 5 below, you must keep your login credentials strictly confidential.  You are fully responsible for all activities that occur under your Account, and you agree to be personally liable for all charges incurred under your Account. Your liability for such charges shall continue after termination of this Agreement.</p>
			<p>If you have a question about a transaction on your credit card statement, please use the “Contact Us” section of the Application to contact customer service.</p>
			<h3>5.	SIGN-IN NAME; PHONE NUMBER; UNIQUE IDENTIFIERS </h3>
			<p>During the registration process for Registered Users, we will ask you to create an account, which includes an active mobile phone number (“Sign-In Number”), and perhaps certain additional information that will assist in authenticating your identity when you log-in in the future (“Unique Identifiers”).  Once registered, the User will receive a unique code via text message to complete the Sign In process.  When creating your account, you must provide true, accurate, current, and complete information.  Each Sign-In Number can be used by only one Registered User.  You are solely responsible for the confidentiality and use of your Sign-In Number, and Unique Identifiers, as well as for any use, misuse, or communications entered through the Service using one or more of them.  You will promptly inform us of any need to deactivate a Sign-In Number, or change any Unique Identifier.  Geddit Local will not be liable for any loss or damage caused by any unauthorized use of your account.</p>
			<h3>6.	INTELLECTUAL PROPERTY</h3>
			<p>The Service contains material, such as software, text, graphics, images, sound recordings, audiovisual works, and other material provided by or on behalf of Geddit Local (collectively referred to as the “Content”).  The Content may be owned by us or by third parties.  The Content is protected under both United States and foreign laws.  Unauthorized use of the Content may violate copyright, trademark, and other laws.  You have no rights in or to the Content, and you will not use the Content except as permitted under this Agreement.  No other use is permitted without prior written consent from us.  You must retain all copyright and other proprietary notices contained in the original Content on any copy you make of the Content.  You may not sell, transfer, assign, license, sublicense, or modify the Content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the Content in any way for any public or commercial purpose.  The use or posting of the Content on any other website or in a networked computer environment for any purpose is expressly prohibited. </p>
			<p>If you violate any part of this Agreement, your permission to access and/or use the Content and the Services automatically terminates and you must immediately destroy any copies you have made of the Content.</p>
			<p>The trademarks, service marks, and logos of Geddit Local (“Geddit Local Trademarks”) used and displayed on the Services are registered and unregistered trademarks or service marks of Geddit Local.  Other company, product, and service names located on the Service may be trademarks or service marks owned by others (the “Third-Party Trademarks”, and, collectively with Geddit Local Trademarks, the “Trademarks”).  Nothing on the Service should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Trademarks, without our prior written permission specific for each such use.  Use of the Trademarks as part of a link to or from any site is prohibited unless establishment of such a link is approved in advance by us in writing.  All goodwill generated from the use of Geddit Local Trademarks inures to our benefit. </p>
			<p>Elements of the Service are protected by trade dress, trademark, unfair competition, and other state and federal laws and may not be copied or imitated in whole or in part, by any means, including but not limited to the use of framing or mirrors.  None of the Content may be retransmitted without our express, written consent for each and every instance.</p>
			<h3>7.	COMMUNICATIONS TO US; USER SUBMISSIONS; AND PUBLICITY</h3>
			<p>Although we encourage you to e-mail us, we do not want you to, and you should not, e-mail us any content that contains confidential information.  With respect to all e-mails you send to us, including but not limited to, feedback, questions, comments, suggestions, and the like, we shall be free to use any ideas, concepts, know-how, or techniques contained in your communications for any purpose whatsoever, including but not limited to, the development, production and marketing of products and services that incorporate such information.</p>
			<p>You retain all copyrights and other intellectual property rights in and to anything you post to the Service, including text and photographs.  You do, however, grant us an irrevocable, non-exclusive, worldwide, perpetual, royalty-free license to use, modify, copy, distribute, publish, perform, sublicense, and create derivative works from all submissions you provide to us in any media now known or hereafter devised and for any purpose, and the right, but not the obligation, to use any such material, including, without limitation, your name, image, likeness, and photograph to advertise and promote the Service and/or Geddit Local.    </p>
			<h3>8.	NO WARRANTIES/LIMITATION OF LIABILITY</h3>
			<p>WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENTS OF THE SERVICE OR THE PRODUCTS AND SERVICES OF THE PARTICIPATING BUSINESSES.  WE SHALL NOT BE SUBJECT TO LIABILITY FOR ANY DELAYS OR INTERRUPTIONS OF THE SERVICE FROM WHATEVER CAUSE.  YOU AGREE THAT YOU USE THE CONTENT AND THE SERVICE AT YOUR OWN RISK, INCLUDING, WITHOUT LIMITATION, ALLOWING OTHERS TO VIEW YOUR APPROXIMATE GEOLOCATIONAL INFORMATION.    </p>
			<p>WE DO NOT WARRANT THAT THE SERVICE WILL OPERATE ERROR-FREE OR THAT THE SERVICE, ITS SERVERS, OR ITS CONTENT ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES.  IF YOUR USE OF THE CONTENT OR THE SERVICE RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, WE SHALL NOT BE RESPONSIBLE FOR THOSE COSTS.</p>
			<p>THE CONTENT AND THE SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND.  WE DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTY OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. </p>
			<p>IN NO EVENT SHALL WE BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM (I) THE USE, DISCLOSURE, DISPLAY, OR MAINTENANCE OF YOUR APPROXIMATE GEOLOCATIONAL INFORMATION; OR (II) THE USE OR INABILITY TO USE THE CONTENT OR THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  SOME STATES DO NOT ALLOW EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.  IN SUCH STATES, OUR LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.  </p>
			<p>THE SERVICE MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS OR OMISSIONS. WE ARE NOT RESPONSIBLE FOR ANY SUCH TYPOGRAPHICAL, TECHNICAL, OR PRICING ERRORS LISTED ON THE SERVICE.  THE SERVICE MAY CONTAIN INFORMATION ON THE PRODUCTS AND SERVICES OF PARTICIPATING BUSINESSES, NOT ALL OF WHICH ARE AVAILABLE IN EVERY LOCATION.  A REFERENCE TO A PRODUCT OR SERVICE ON THE SERVICE DOES NOT IMPLY THAT SUCH PRODUCT OR SERVICE IS OR WILL BE AVAILABLE IN YOUR LOCATION.  WE RESERVE THE RIGHT TO MAKE CHANGES, CORRECTIONS, AND/OR IMPROVEMENTS TO THE SERVICE AT ANY TIME WITHOUT NOTICE. </p>
			<h3>9.	EXTERNAL SITES</h3>
			<p>The Service may contain links to third-party websites (“External Sites”).  These links are provided solely as a convenience to you and not as an endorsement by us of the content on such External Sites.  The content of such External Sites is developed and provided by others.  You should contact the site administrator or webmaster for those External Sites if you have any concerns regarding such links or any content located on such External Sites.  We are not responsible for the content of any linked External Sites and do not make any representations regarding the content or accuracy of materials on such External Sites.  You should take precautions when downloading files from all websites to protect your computer from viruses and other destructive programs.  If you decide to access linked External Sites, you do so at your own risk.</p>
			<h3>10.	INDEMNIFICATION  </h3>
			<p>You agree to defend, indemnify, and hold us and our officers, directors, employees, successors, licensees, and assigns harmless from and against any claims, actions, or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from your breach of this Agreement or your access to, use, or misuse of the Content or the Service.  We shall provide notice to you of any such claim, suit, or proceeding and shall assist you, at your expense, in defending any such claim, suit, or proceeding.  We reserve the right to assume the exclusive defense and control of any matter that is subject to indemnification under this section.  In such case, you agree to cooperate with any reasonable requests assisting our defense of such matter.</p>
			<h3>11.	COMPLIANCE WITH APPLICABLE LAWS</h3>
			<p>The Service is based in the United States.  We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States.  If you access the Service or the Content from outside of the United States, you do so at your own risk.  Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.</p>
			<h3>12.	TERMINATION OF THE AGREEMENT</h3>
			<p>We reserve the right, in our sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Service, at any time and for any reason without prior notice or liability.  We reserve the right to change, suspend, or discontinue all or any part of the Service at any time without prior notice or liability.</p>
			<h3>13.	DIGITAL MILLENNIUM COPYRIGHT ACT</h3>
			<p>Geddit Local respects the intellectual property rights of others and attempts to comply with all relevant laws. We will review all claims of copyright infringement received and remove any Content or user submissions deemed to have been posted or distributed in violation of any such laws.</p>
			<p>Our designated agent under the Digital Millennium Copyright Act (the “Act”) for the receipt of any Notification of Claimed Infringement which may be given under that Act is as follows:</p>
			<p><strong>
				Geddit Local
				<br/>
				Attention: DMCA
				<br/>
				224 W. Vine St.
				<br/>
				Unit 202
				<br/>
				Milwaukee, WI 53212
			</strong></p>
			<p>If you believe that your work has been copied on the Service in a way that constitutes copyright infringement, please provide our agent with notice in accordance with the requirements of the Act, including (i) a description of the copyrighted work that has been infringed and the specific location on the Service where such work is located; (ii) a description of the location of the original or an authorized copy of the copyrighted work; (iii) your address, telephone number and e-mail address; (iv) a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent or the law; (v) a statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf; and (vi) an electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest.</p>
			<p>14.	MISCELLANEOUS  </p>
			<p>This Agreement is governed by the internal substantive laws of the State of New York, without respect to its conflict of laws provisions.  You expressly agree: (i) to submit to the exclusive personal jurisdiction of the state and federal courts sitting in the State of New York; and (ii) that the Service shall be deemed a passive one that does not give rise to personal jurisdiction over Geddit Local, either specific or general, in jurisdictions other than New York.  If any provision of this Agreement is found to be invalid by any court having competent jurisdiction or terminated in accordance with the Termination provision above, the invalidity or termination of such provision shall not affect the validity of the following provisions of this Agreement, which shall remain in full force and effect:  “Intellectual Property,” “Communications to Geddit Local,” “No Warranties/Limitation of Liability,” “Indemnification,” “Termination of the Agreement,” and “Miscellaneous.”  </p>
			<p>Our failure to act on or enforce any provision of the Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement.  No waiver shall be effective against us unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance.  Except as expressly agreed by us and you in writing, this Agreement constitutes the entire Agreement between you and us with respect to the subject matter, and supersedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter.  The section headings are provided merely for convenience and shall not be given any legal import.  This Agreement will inure to the benefit of our successors, assigns, licensees, and sublicensees.</p>
			<p>Copyright 2022 Geddit Local.  All rights reserved.</p>
	</IonContent>
	)
}

export default TermsOfUseText
