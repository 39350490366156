import React from 'react'
import './index.scss'
import {
    IonBackButton,
    IonButton, IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
} from '@ionic/react';
import ApplicationLogo from '../../../../components/ApplicationLogo';
import {Link, useHistory} from 'react-router-dom';
import Footnote from "../../../../components/Footnote";
import LoggedOutHeaderComponent from "../../../../components/WelcomeSection/LoggedOutHeader";

const BetaInformationStep: React.FC = () => {

    const history = useHistory();

    const getStarted = () => history.push('/welcome/sign-up/invitation-code')

    return (
        <IonPage className={'beta-information'}>
            <LoggedOutHeaderComponent backText={''} onBackClick={() => history.goBack()}>
                <IonTitle>in Beta</IonTitle>
            </LoggedOutHeaderComponent>
            <IonContent>
                <div className={'content-wrapper'}>
                    <div>
                        <h4 className={'subtitle'}>Welcome to Geddit Local,<br/>a new app from Small Shops United.</h4>
                        <ApplicationLogo/>
                        <h4>Thanks for joining the project!</h4>
                        <p>Geddit Local is a new app we're developing to support local arts groups and small businesses in the community.</p>
                        <p>We're using this Beta phase to launch the app, test new features, and grow the user base. With your invitation code, you can create your account today to start discovering new local events and attractions in your area..</p>
                    </div>
                    <div>
                        <IonButton onClick={getStarted}>
                            Let's Get Started!
                        </IonButton>
                        <Footnote>
                            Already have an account? <Link to='/welcome/sign-in/phone'>Sign In</Link>
                        </Footnote>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default BetaInformationStep
