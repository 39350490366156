import React, {useEffect, useState} from 'react';

interface GoogleLatLngDetailsContextProviderConsumerState {
    result: google.maps.GeocoderResult|undefined,
    loaded: boolean,
}

const defaultState = {
    result: undefined,
    loaded: false,
};

export const GoogleLatLngDetailsContext = React.createContext<GoogleLatLngDetailsContextProviderConsumerState>(defaultState);


interface GoogleLatLngDetailsContextProviderProps {
    latitude: number,
    longitude: number,
}

const GoogleLatLngDetailsContextProvider: React.FC<GoogleLatLngDetailsContextProviderProps> = ({ children, latitude, longitude}) => {

    const [service, setService] = useState<google.maps.Geocoder|undefined>(undefined);
    const [currentResults, setCurrentResults] = useState<GoogleLatLngDetailsContextProviderConsumerState>(defaultState);

    useEffect(() => {
        if (service) {
            setCurrentResults({
                result: undefined,
                loaded: false,
            });
            service.geocode({
                location: new google.maps.LatLng(latitude, longitude),
            }, (result, status) => {
                if (result.length) {
                    setCurrentResults({
                        result: result[0],
                        loaded: true,
                    });
                }
            })
        }
    }, [longitude, longitude, service])

    useEffect(() => {
        if (typeof google != "undefined" && google.maps.Geocoder) {
            setService(new google.maps.Geocoder());
        }
    }, [])

    return (
        <GoogleLatLngDetailsContext.Provider value={currentResults}>
            {children}
        </GoogleLatLngDetailsContext.Provider>
    )
}

export default GoogleLatLngDetailsContextProvider;
