import React from 'react'
import {
	IonContent,
	IonPage,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import './index.scss';
import {chevronBack} from "ionicons/icons";
import PostResponses from '../../../../components/PostManagement/PostResponses';
import {useParams} from 'react-router';


type RouteParams = {
	postId: string,
	interaction:string,
}
const PostInteractionsPage: React.FC = () => {

	const {interaction, postId} = useParams<RouteParams>()

	return (
		<IonPage className={'post-response-page'}>
			<LoggedInHeaderComponent iconType={chevronBack}>
				{interaction}
			</LoggedInHeaderComponent>
			<IonContent className={'results-wrapper'}>
				<PostResponses
					postId={parseInt(postId!)}
					interaction={interaction!}
					memberPageBaseUrl={'/home/dashboard'}
				/>
			</IonContent>
		</IonPage>
	)
}

export default PostInteractionsPage
