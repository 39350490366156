import React, { useRef, useState} from 'react';
import './index.scss';
import User from '../../models/user/user';
import {Navigation} from "swiper";
import {Swiper} from "swiper/react";

interface UserPostSwiperContentProps {
    firstLabel: string,
    secondLabel?: string,
}

const PostSwiper: React.FC<UserPostSwiperContentProps> = ({firstLabel, secondLabel, children}) => {

    const [_, setInit] = useState();
    const [prevClassName, setPrevClassName] = useState('prev-slide-' + Math.floor(Math.random() * 1000))
    const [nextClassName, setNextClassName] = useState('next-slide-' + Math.floor(Math.random() * 1000))

    return (
        <div className="posts-swiper">
            <div className={"swiper-navigation"}>
                <h6><button className={prevClassName}>{firstLabel}</button></h6>
                {secondLabel  && (
                    <h6>
                        <button className={nextClassName}>{secondLabel}</button>
                    </h6>
                )}
            </div>
            <Swiper
                modules={[Navigation]}
                navigation={secondLabel ? {
                    prevEl: "." + prevClassName,
                    nextEl: "." + nextClassName,
                }: {}}>
                {children}
            </Swiper>
        </div>
    )
}

export default PostSwiper;
