import React, {useEffect, useState} from 'react'
import NewsFeed from '../../../../components/NewsFeed';
import {IonPage, IonContent, IonIcon, IonTabButton, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import './index.scss';
import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../../contexts/MeContext';
import UserPostsContextProvider, {
	UserPostContextState,
	UserPostsContext,
} from '../../../../contexts/feed/UserPostsContext';
import Follower from '../../../../models/user/follower';
import Location from '../../../../models/location/location';
import {
	UserFollowsContext,
	UserFollowsContextProvider, UserFollowsContextState
} from '../../../../contexts/UserFollowsContext';
import LoggedInHeader from '../../../../components/LoggedInHeader';
import Post from '../../../../models/post/post';
import {useHistory} from 'react-router';

interface FollowingContentProps {
	userFollowsContextConsumer: UserFollowsContextState,
	postsContext: UserPostContextState,
	checkForRefresh: boolean,
	onRefreshCheckComplete: () => void,
	meContext: MeContextStateConsumer,
}

const FollowingContent: React.FC<FollowingContentProps> = ({userFollowsContextConsumer, postsContext, checkForRefresh, meContext, onRefreshCheckComplete}) => {

	const history = useHistory();
	const [inFocus, setInFocus] = useState(false);

	const matchLocationModel = (followedLocations: Follower[], locations: Location[]) : Location|undefined => {
		return locations.find(location => {
			const followedLocationsIndex = followedLocations.findIndex(followedLocation => followedLocation.follows_id == location.id);

			return followedLocationsIndex !== -1;
		});
	}

	const isFollowingUser = (followers: Follower[], userId: number): boolean => {
		return followers.find(i => i.follows_type == 'user' && i.follows_id == userId) != undefined;
	}

	useEffect(() => {
		if (checkForRefresh && postsContext.initialLoadComplete && postsContext.loadedData.length == 0) {
			postsContext.refreshData();
		}
		onRefreshCheckComplete();
	}, [checkForRefresh])

	useEffect(() => {
		setInFocus(history.location.pathname.indexOf('following') != -1);
	}, [history.location.pathname])

	return (
		<NewsFeed
			skipPost={i => {
				return i.publisher_type == 'organization' ?
					!matchLocationModel(userFollowsContextConsumer.loadedData, i.locations ?? []) :
					!isFollowingUser(userFollowsContextConsumer.loadedData, i.publisher_id!)
			}}
			newPosts={postsContext.loadedData}
			refreshPosts={postsContext.refreshData}
			selectLocation={locations => matchLocationModel(userFollowsContextConsumer.loadedData, locations)}
			loadMorePosts={() => postsContext.loadNext()}
			onPostSeen={postsContext.removeModel}
			locationPageUrlRoot={'/home/dashboard'}
			userPageUrlRoot={'/home/dashboard'}
			inFocus={inFocus}
			noPostsSlide={{
				copy: "There are no posts from the people and places you follow. Switch to the 'nearby' feed, or check back later.",
				icon: '/assets/app-icons/offers.svg'
			}}
			meContext={meContext}
		/>
	)
}


const Following: React.FC = () => {
	const [checkForRefresh, setCheckForRefresh] = useState(false);

	useIonViewWillEnter(() => {
		setCheckForRefresh(true);
	})
	return (
		<IonPage>
			<MeContextProvider>
				<MeContext.Consumer>
					{meContext =>
						<UserFollowsContextProvider userId={meContext.me.id!}>
							<UserFollowsContext.Consumer>
								{userFollowsContextConsumer =>
									<UserPostsContextProvider userId={meContext.me.id!}>
										<UserPostsContext.Consumer>
											{postsContext =>
												<FollowingContent
													meContext={meContext}
													userFollowsContextConsumer={userFollowsContextConsumer}
													postsContext={postsContext}
													checkForRefresh={checkForRefresh}
													onRefreshCheckComplete={() => setCheckForRefresh(false)}
												/>
											}
										</UserPostsContext.Consumer>
									</UserPostsContextProvider>
								}
							</UserFollowsContext.Consumer>
						</UserFollowsContextProvider>
					}
				</MeContext.Consumer>
			</MeContextProvider>
		</IonPage>

	)
}

export default Following
