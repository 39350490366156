import React from 'react';
import BaseBusinessEditPage from '../../../../components/OrganizationManagement/BaseBusinessEditPage';
import Category, {generateEmptyCategory, removeCategoryFromList} from '../../../../models/category';
import {IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList} from '@ionic/react';
import CategorySearchBar from '../../../../components/CategorySearchBar';
import './index.scss';
import {BusinessEditorContextConsumerState} from '../../../../contexts/organization/BusinessEditorContext';
import Business from '../../../../models/organization/business';

interface CategoryListState {
    mainCategoryText: string,
    categories: Category[]
}

interface CategoryListProps {
    currentData: CategoryListState,
    setCurrentData: (data: CategoryListState) => void
}

const CategoryList: React.FC<CategoryListProps> = ({currentData, setCurrentData}) => {

    const replaceCategory = (index: number, category: Category) => {
        const updatedCategories = [...currentData.categories];

        updatedCategories[index] = category

        setCurrentData({
            ...currentData,
            categories: updatedCategories,
        });
    }

    const removeCategory = (category: Category) => {

        const updatedCategories = [...currentData.categories]

        setCurrentData({
            ...currentData,
            // filter the array to reset our indexes
            categories: removeCategoryFromList(updatedCategories, category.name)
        });
    }

    const addCategory = () => {

        const updatedCategories = currentData.categories;

        updatedCategories.push(generateEmptyCategory())

        setCurrentData({
            ...currentData,
            categories: updatedCategories,
        });
    }

    if (currentData.categories.length === 0) {
        currentData.categories.push(generateEmptyCategory());
    }

    return (
        <div className={'categories-editor'}>
            <IonLabel>Industry</IonLabel>
            <IonInput
                className={'industry-input'}
                disabled
                value={currentData.mainCategoryText}
            />
            <IonLabel>Add Search Terms</IonLabel>
            <IonList className={'categories-list'}>
                {currentData.categories.map((category, index) =>
                    <IonItem lines={'none'} key={category.id ?? index}>
                        <CategorySearchBar
                            setCategory={updated => replaceCategory(index, updated)}
                            placeholder={"e.g. pizza"}
                            category={category}/>
                        <IonIcon slot={'end'} src={'/assets/app-icons/trash.svg'} onClick={() => removeCategory(category)} />
                    </IonItem>
                )}
            </IonList>
            <IonButton color={'light'} onClick={() => addCategory()}>
                Add Another
            </IonButton>
        </div>
    );
}

const CategoryEditor: React.FC = () => {

    function createInitialState(businessEditorContext: BusinessEditorContextConsumerState): CategoryListState {
        const business = businessEditorContext.dirtyBusiness;
        return {
            categories: business.categories && business.categories.length ?
                [...business.categories] : [],
            mainCategoryText: business.main_category_name ?? '',
        }
    }

    function constructBusiness(currentData: CategoryListState, business: Business): Business {
        return {
            ...business,
            categories: currentData.categories,
        }
    }

    return (
        <BaseBusinessEditPage title={'Industry & Search Terms'} iconUrl={'category'} createInitialState={createInitialState} constructBusiness={constructBusiness}>
            {currentDataContext =>
                <CategoryList
                    currentData={currentDataContext.currentData}
                    setCurrentData={data => {
                        currentDataContext.setCurrentData(data)
                    }}
                />
            }
        </BaseBusinessEditPage>
    );
}

export default CategoryEditor
