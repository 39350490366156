import React, {useEffect, useState} from 'react'
// @ts-ignore
import {Swipeable} from 'gedditlocal-react-deck-swiper'
import Post, {isPostRecentlyPublished} from '../../models/post/post';
import './index.scss'
import {connect} from "../../data/connect";
import {App} from '@capacitor/app';
import Page from '../../models/page';
import {
	UserFollowsContext,
	UserFollowsContextProvider,
	UserFollowsContextState
} from '../../contexts/UserFollowsContext';
import {Badge} from '@capawesome/capacitor-badge';
import {
	MinimalUserPostResponsesContext,
	MinimalUserPostResponsesContextProvider
} from '../../contexts/MinimalUserPostResponsesContext';
import {NewsFeedSharedProps} from './interfaces';
import NewsFeedSlides from './NewsFeedSlides';
import {filterUnique} from '../../models/base-model';
import SeenPostsContextProvider, {
	SeenPostsContext,
	SeenPostsContextConsumerState
} from '../../contexts/feed/SeenPostsContext';
import {FeedSettings} from '../../data/persistent/persistent.state';
import {decrementBadgeCount} from '../../util/badge';

interface SharedProps extends NewsFeedSharedProps {
	refreshPosts: (persistData?: boolean) => Promise<Page<Post[]>>,
}

interface SharedStateProps {
	hasSeenSavedTutorial: boolean
}

interface NewsFeedProps extends SharedProps, SharedStateProps {
	userFollowsContextConsumer: UserFollowsContextState,
	seenPostsContext: SeenPostsContextConsumerState,
	currentPosts: Post[]
	onRemovePost: (post: Post) => void,
	onClearCache: () => void,
	resetIndex: boolean,
	onIndexReset: () => void,
}

const NewsFeed: React.FC<NewsFeedProps> = ({currentPosts, meContext, userFollowsContextConsumer, seenPostsContext, onPostSeen, hasSeenSavedTutorial, refreshPosts, onClearCache, children, ...rest}) => {

	const [canShowSavedTutorial, setCanShowSavedTutorial] = useState(false)
	const [lastLoad, setLastLoad] = useState<number>(Date.now());

	const postSeen = (post: Post) => {
		onPostSeen(post)
		const following = userFollowsContextConsumer.loadedData
			.find(follower => (post.locations ?? []).map(i => i.id!).indexOf(follower.follows_id) !== -1)

		if (following && Date.now() - Date.parse(following.created_at!) > 5000) {
			decrementBadgeCount();
		}
	}

	useEffect(() => {
		setLastLoad(Date.now());
	}, [currentPosts])

	useEffect(() => {

		App.addListener('appStateChange', ({ isActive }) => {
			if (Date.now() - lastLoad > 60 * 60 * 1000) {
				onClearCache();
				refreshPosts(false).catch(console.error);
			}
		});
	}, [])

	return (
		<div className={'news-feed-component'} >
			<MinimalUserPostResponsesContextProvider  userId={meContext.me.id!}>
				<MinimalUserPostResponsesContext.Consumer>
					{userPostResponseContext => (
						<React.Fragment>
							<div className={'feed-placeholder'}>
								{children}
							</div>
							<NewsFeedSlides
								meContext={meContext}
								currentPosts={[...currentPosts]}
								onPostSeen={postSeen}
								seenPostsContext={seenPostsContext}
								setCanShowSavedTutorial={setCanShowSavedTutorial}
								addPostResponse={userPostResponseContext.addModel}
								{...rest}
							/>
						</React.Fragment>
					)}
				</MinimalUserPostResponsesContext.Consumer>
			</MinimalUserPostResponsesContextProvider>
		</div>
	)
}

interface OwnProps extends SharedProps {
	newPosts: Post[],
}

interface StateProps extends SharedStateProps {
	feedSettings: FeedSettings,
}

interface NewsFeedWrapperProps extends OwnProps, StateProps {}

const NewsFeedWrapper: React.FC<NewsFeedWrapperProps> = ({meContext, feedSettings, newPosts, ...rest}) => {

	const [currentPosts, setCurrentPosts] = useState(newPosts);
	const [cacheClearing, setCacheClearing] = useState(false);
	const [needsIndexReset, setNeedsIndexReset] = useState(false);

	useEffect(() => {
		setCurrentPosts([]);
	}, [feedSettings])

	useEffect(() => {
		const newPostsCopy = [...newPosts];
		if (cacheClearing) {
			setCacheClearing(false);
			setCurrentPosts(filterUnique([
				...newPostsCopy,
			]))
			setNeedsIndexReset(true);
		} else {
			const firstPost = newPostsCopy.find(() => true);

			const firstAlreadyInFeed = firstPost && currentPosts.find(i => i.id == firstPost.id) != undefined;

			if (firstPost && !firstAlreadyInFeed && firstPost.publisher_type == 'user' && firstPost.publisher_id == meContext.me.id && isPostRecentlyPublished(firstPost)) {

				newPostsCopy.shift();
				currentPosts.unshift(firstPost);
				setNeedsIndexReset(true);
			}
			setCurrentPosts(filterUnique([
				...currentPosts,
				...newPostsCopy,
			]))
		}
	}, [newPosts])

	const removePost = (post: Post) => {
		setCurrentPosts(
			[...currentPosts].filter(i => i.id !== post.id)
		)
	}

	return (
		<UserFollowsContextProvider userId={meContext.me.id!}>
			<UserFollowsContext.Consumer>
				{userFollowsContextConsumer =>
					<SeenPostsContextProvider>
						<SeenPostsContext.Consumer>
							{seenPostsContext => (
								<NewsFeed
									meContext={meContext}
									currentPosts={currentPosts}
									seenPostsContext={seenPostsContext}
									userFollowsContextConsumer={userFollowsContextConsumer}
									onClearCache={() => setCacheClearing(true)}
									onRemovePost={removePost}
									resetIndex={needsIndexReset}
									onIndexReset={() => setNeedsIndexReset(false)}
									{...rest}
								/>
							)}
						</SeenPostsContext.Consumer>
					</SeenPostsContextProvider>
				}
			</UserFollowsContext.Consumer>
		</UserFollowsContextProvider>
	)
}

export default connect<OwnProps, StateProps, { }>({
	mapStateToProps: (state) => ({
		hasSeenSavedTutorial: state.persistent.hasSeenSavedTutorial,
		feedSettings: state.persistent.feedSettings,
	}),
	component: NewsFeedWrapper
});
