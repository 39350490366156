import React, {useEffect, useState} from 'react';
import Post, {PublisherEndpointModel} from '../../../models/post/post';
import Location from '../../../models/location/location';
import './index.scss';
import PostResponse, {defaultPostResponse, postResponseToServerSubmission} from '../../../models/post/post-response';
import CanParticipateInForum from '../../../models/can-participate-in-forum';
import Thread from '../../../models/user/thread';
import Message from '../../../models/user/message';
import {PostInteractions} from '../PostActions';
import PostDetails from '../PostDetails';
import User from '../../../models/user/user';
import UserPostRequests from '../../../services/requests/UserPostRequests';
import {PostCommentInteractions} from '../PostComments';
import {connect} from '../../../data/connect';
import {setNotificationPostInit, setSharedPostInit} from '../../../data/session/session.actions';
import PostManagementRequests from '../../../services/requests/PostManagementRequests';
import moment from 'moment';
import {decrementBadgeCount} from '../../../util/badge';
import {UserFollowsContextState} from '../../../contexts/UserFollowsContext';
import {isFollowingEntity} from '../../../models/user/follower';


interface OwnProps {
    post: Post,
    onPostMetaChanged: (post: Post) => void,
    inFocus: boolean,
    locationPageUrlRoot: string,
    userPageUrlRoot?: string,
    location?: Location,
    loggedInUser?: User,
    participant: CanParticipateInForum,
    createPostThread: (post: Post, topic: string) => Promise<Thread>,
    onPostRemoved: () => void,
    onUnfollow?: () => void,
    createMessage: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
    onPostResponseChanged?: (postResponse: PostResponse) => void,
    onLikeClicked?: () => void,
    userFollowsContext?: UserFollowsContextState,
}

interface StateProps {
    sharedPostInit: boolean,
    notificationPostInit: boolean,
}

interface DispatchProps {
    setSharedPostInit: typeof setSharedPostInit,
    setNotificationPostInit: typeof setNotificationPostInit,
}

interface InteractivePostProps extends OwnProps, StateProps, DispatchProps {}

interface PostResponseData {
    current: PostResponse,
    restored?: PostResponse
}

const defaultPostResponseData = {current: {...defaultPostResponse}}

const InteractivePost: React.FC<InteractivePostProps> = ({post, onPostMetaChanged, inFocus, participant, onUnfollow, createPostThread, loggedInUser, createMessage, onPostResponseChanged, onLikeClicked, sharedPostInit, onPostRemoved, notificationPostInit, setSharedPostInit, setNotificationPostInit, userFollowsContext, location, ...rest}) => {

    const [postResponseData, setPostResponseData] = useState<PostResponseData>({...defaultPostResponseData})

    const [originalPost, setOriginalPost] = useState(post);
    const [searchCompleted, setSearchCompleted] = useState(false);

    const uploadPostResponse = (newData: PostResponse) => {
        setPostResponseData({
            ...postResponseData,
            current: newData,
        })

        if (loggedInUser?.id && onPostResponseChanged) {

            if (sharedPostInit) {
                newData.share_clicked = sharedPostInit;
                setSharedPostInit(false);
            }
            if (notificationPostInit) {
                newData.notification_clicked = notificationPostInit;
                setNotificationPostInit(false);
            }

            const submitData = postResponseToServerSubmission(newData);

            const creating = !postResponseData.current.id;
            const followerType = post.publisher_type == 'user' ? 'user' : 'location';
            const maybeFollowerId = followerType == 'user' ? post.publisher_id : location?.id;
            const isFollowing = userFollowsContext && maybeFollowerId&&
                isFollowingEntity(userFollowsContext.loadedData, maybeFollowerId, followerType);
            (creating ?
                    UserPostRequests.reportPostResponse(post, submitData) :
                    UserPostRequests.updatePostResponse(postResponseData.current, submitData)
            ).then(updated => {

                if (creating && isFollowing) {
                    decrementBadgeCount();
                }
                const constructed = {...updated, post: postResponseData.current.post};
                onPostResponseChanged(constructed)
                setPostResponseData({
                    ...postResponseData,
                    current: constructed,
                })
            }).catch(console.error)
        }
    }

    const updatePostResponse = (newData: PostResponse) => {
        if (inFocus && searchCompleted) {
            uploadPostResponse(newData);
        }
    }

    const actions: PostInteractions = {
        onButtonClicked: () => {
            if (!postResponseData.current.clicked) {
                onPostMetaChanged({
                    ...post,
                    click_count: post.click_count + 1,
                })
                updatePostResponse({
                    ...postResponseData.current,
                    clicked: true,
                })
            }
        },
        onSaved: () => {
            const newState = !postResponseData.current.saved
            const originalState = postResponseData.restored ? postResponseData.restored.saved : false;
            const change = originalState == newState ? 0 :
                (newState ? 1 : -1)
            updatePostResponse({
                ...postResponseData.current,
                saved: newState,
            })
            onPostMetaChanged({
                ...post,
                save_count: originalPost.save_count + change,
            })
        },
        onFollow: (followsId: number) => {
            updatePostResponse({
                ...postResponseData.current,
                follows_id: followsId,
            })
        },
        onUnfollow: onUnfollow,
        onLikeToggled: () => {
            const newState = !postResponseData.current.liked
            const originalState = postResponseData.restored ? postResponseData.restored.liked : false;
            const change = originalState == newState ? 0 :
                (newState ? 1 : -1)
            updatePostResponse({
                ...postResponseData.current,
                liked: newState,
            })
            onPostMetaChanged({
                ...post,
                like_count: (originalPost.like_count ?? 0) + change,
            })
        },
        onShare: () => {
            if (!postResponseData.current.shared) {
                onPostMetaChanged({
                    ...post,
                    share_count: post.share_count + 1,
                })
                updatePostResponse({
                    ...postResponseData.current,
                    shared: true,
                })
            }
        },
        onRemove: () => {
            PostManagementRequests.updatePost(
                post.publisher_type == 'user' ? 'users': 'businesses',
                {id: post.publisher_id} as PublisherEndpointModel, post,{
                removed_at: moment().toISOString(),
            }).then(onPostRemoved)
        },
        onReport: () => {
            if (!postResponseData.current.reported) {
                updatePostResponse({
                    ...postResponseData.current,
                    reported: true,
                })
            }
        },
    }

    const commenting: PostCommentInteractions = {
        onCommentCountChanged: (newCount: number) => {
            onPostMetaChanged({
                ...post,
                comment_count: newCount,
            })
        },
        createPostThread,
        createMessage,
        participant
    };

    useEffect(() => {
        setOriginalPost({...post});
        setPostResponseData({...defaultPostResponseData});
        if (post.id && loggedInUser?.id && onPostResponseChanged) {
            setSearchCompleted(false);
            UserPostRequests.searchForPostResponse(loggedInUser.id!, post.id, false).then(result => {
                setSearchCompleted(true);
                const restoredPostResponse = result.data.find(i => i.post_id == post.id);
                if (restoredPostResponse) {
                    setPostResponseData({
                        current: restoredPostResponse,
                        restored: restoredPostResponse,
                    });
                    onPostResponseChanged(restoredPostResponse)
                } else {
                    uploadPostResponse(postResponseData.current)
                }
            }).catch(console.error)
        }
    }, [post.id, loggedInUser?.id]);

    useEffect(() => {
        if (inFocus) {
            updatePostResponse({
                ...postResponseData.current,
                dismissed: true,
            })
        }
    }, [inFocus])

    return (
        <PostDetails
            post={post}
            interactions={loggedInUser && actions}
            commenting={commenting}
            onLikeClicked={onLikeClicked}
            postResponse={postResponseData.current}
            inFocus={inFocus}
            userFollowsContext={userFollowsContext}
            location={location}
            {...rest}
        />
    )
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
        sharedPostInit: state.session.sharedPostInit,
        notificationPostInit: state.session.notificationPostInit,
    }),
    mapDispatchToProps: ({
        setSharedPostInit,
        setNotificationPostInit
    }),
    component: InteractivePost
});
