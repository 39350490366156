import React, {useEffect, useState} from 'react';
import {
    defaultState,
    GeoLocationContextProviderOwnProps,
    GeoLocationContextState
} from '../interfaces';
import "./index.scss";
import {Capacitor, CapacitorHttp} from '@capacitor/core';
import {Position} from '@capacitor/geolocation/dist/esm/definitions';
import LoadingScreen from '../../LoadingScreen';
import {Network} from '@capacitor/network';


export const NetworkBasedLocationContext = React.createContext<GeoLocationContextState>(defaultState);

let persistedState = defaultState

interface NetworkBasedLocationContextProviderProps extends GeoLocationContextProviderOwnProps {
    wifiOnly?: boolean,
}

const NetworkBasedLocationContextProvider: React.FC<NetworkBasedLocationContextProviderProps> = ({locationRequired, wifiOnly, children }) => {

    const [contextState, setContextState] = useState(persistedState);

    const updateContext = (newState: GeoLocationContextState) => {
        setContextState({...newState});
        persistedState = newState;
    }

    const loadLocation = () => {
        const options = {
            url: 'https://www.googleapis.com/geolocation/v1/geolocate?key=' + process.env.REACT_APP_GOOGLE_KEY
        };
        updateContext({
            ...contextState,
            status: 'pending',
        })

        CapacitorHttp.post(options)
            .then(response => {
                if (response.status == 200) {
                    updateContext({
                        receivedAt: Date.now(),
                        location: {
                            timestamp: Date.now(),
                            coords: {
                                latitude: response.data.location.lat,
                                longitude: response.data.location.lng,
                                accuracy: response.data.accuracy
                            }
                        } as Position,
                        status: 'success'
                    })
                } else {
                    updateContext({
                        ...contextState,
                        status: 'failed',
                    })
                }
            }, error => {
                updateContext({
                    ...contextState,
                    status: 'failed',
                })
            })
    }

    useEffect(() => {
        if (contextState.status == 'initializing') {
            if (wifiOnly) {
                Network.getStatus().then(status => {
                    if (status.connectionType == 'wifi') {
                        loadLocation();
                    }
                })
            } else {
                loadLocation();
            }
        }
    }, [])

    const isReady = (currentState: GeoLocationContextState) => {
        return locationRequired ? (!Capacitor.isNativePlatform() && !!currentState.location.coords) : true;
    }

    return (
        <NetworkBasedLocationContext.Provider value={contextState}>
            {isReady(contextState) ? children : <LoadingScreen text={"One Moment"}/>}
        </NetworkBasedLocationContext.Provider>
    );
}

export default NetworkBasedLocationContextProvider;
