import React from 'react';
import {IonIcon, IonSlide} from '@ionic/react';
import './index.scss';

export interface NoPostsSlideProps {
	headline?: string,
	copy?: string,
	icon?: string,
}
// offers and browse
const NoPostsSlide: React.FC<NoPostsSlideProps> = ({headline, copy, icon}) => (
	<div
		className={'no-posts-card'}
	>
		<div className={'no-posts-details'}>
			<IonIcon src={icon ?? '/assets/app-icons/browse.svg'}/>
			<h4>{headline ?? "You're all caught up!" }</h4>
			<p>{copy ?? 'There are no more updates at this time. Check back soon!'}</p>
		</div>
	</div>
)

export default NoPostsSlide
