import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'

import {IonAlert, IonContent, IonPage, IonButton} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import MeContextProvider, {MeContext} from '../../../../../contexts/MeContext';
import './index.scss';
import {chevronBack} from 'ionicons/icons';
import {logOut} from '../../../../../data/persistent/persistent.actions';
import {connect} from '../../../../../data/connect';
import AuthRequests from "../../../../../services/requests/AuthRequests";
import User from "../../../../../models/user/user";
import ServerAlert from '../../../../../components/ServerAlert';
import {RequestError} from '../../../../../models/request-error';
import {clearLoadingCount} from '../../../../../data/session/session.actions';

interface DispatchProps {
    logOut: typeof logOut,
    clearLoadingCount: typeof clearLoadingCount,
}

interface AccountProps extends DispatchProps {}

const DeleteAccount: React.FC<AccountProps> = ({logOut, clearLoadingCount}) => {
    const navigate = useHistory();
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);

    /**
     * Logs out our user from the app properly
     */
    const handleLogOut = () => {
        logOut();
        clearLoadingCount();
        navigate.replace('/splash', 'REPLACE')
    }

    const handleDelete = async (me: User) => {
        try{
            await AuthRequests.eraseMe()
            handleLogOut()
        } catch(error) {
            setRequestError(error as RequestError)
        }
    }

    return (
        <IonPage id={'delete-account-page'}>
            <LoggedInHeaderComponent hideBackButton={false} iconType={chevronBack}>
                Profile
            </ LoggedInHeaderComponent>
            <IonContent class={'ion-no-padding'}>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <React.Fragment>
                                <IonButton color={'light'} onClick={() => setShowDeleteAlert(true)}>Delete Account</IonButton>
                                <IonAlert
                                    isOpen={showDeleteAlert}
                                    onDidDismiss={() => setShowDeleteAlert(false)}
                                    header={'Are you sure?'}
                                    message={"Deleting your account will remove all your records and settings."}
                                    cssClass={'delete-alert'}
                                    buttons={[
                                        {
                                            text: 'Back',
                                            handler: () => setShowDeleteAlert(false),
                                            cssClass: 'back-button'
                                        },
                                        {
                                            text: 'Delete',
                                            handler: () => handleDelete(meContext.me),
                                            cssClass: 'delete-button'
                                        },
                                    ]}
                                />
                            </React.Fragment>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
            {requestError &&
            <ServerAlert
                requestError={requestError}
                onCloseAlert={() => setRequestError(undefined)}
            />
            }
        </IonPage>
    );
}

const ConnectedAccountDelete = connect<{}, { }, DispatchProps>({
    mapDispatchToProps: ({
        logOut,
        clearLoadingCount,
    }),
    component: DeleteAccount
});

export default ConnectedAccountDelete;
