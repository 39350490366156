import {IonContent, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import React from 'react';
import TermsOfUseText from '../../../../../components/TermsOfUseText';
import {chevronBack} from "ionicons/icons";

const TermsOfUse: React.FC = () => {

    return (
        <IonPage>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Terms of Use
            </LoggedInHeaderComponent>
            <TermsOfUseText/>
        </IonPage>
    );
}

export default TermsOfUse;
