import {
    IonButton,
    IonCol,
    IonContent,
    IonDatetime,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRadio,
    IonRadioGroup,
    IonRow, IonToast
} from '@ionic/react';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import './index.scss';

import LoggedInHeaderComponent from '../../../../../../components/LoggedInHeader';
import GreyBackgroundSection from '../../../../../../components/GreyBackgroundSection';
import UserProfileImageUploader from '../../../../../../components/UserProfileImageUploader';
import PhoneNumberInput from '../../../../../../components/PhoneNumberInput';
import InputWrapper from '../../../../../../components/InputWrapper';
import UnderlinedInput from '../../../../../../components/GeneralUIElements/UnderlinedInput';

import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../../../../contexts/MeContext';
import User from '../../../../../../models/user/user';
import AuthRequests from '../../../../../../services/requests/AuthRequests';
import {phoneRegExp} from '../../../../../../util/regex';
import {arrowBackOutline, chevronBack} from "ionicons/icons";

interface ProfileEditorFormValues {
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
}

interface ProfileEditorFormProps {
    meContext: MeContextStateConsumer,
}

const ProfileEditorForm: React.FC<ProfileEditorFormProps> = ({meContext}) => {

    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const UpdatePasswordSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        phone: Yup.string()
            .length(10, 'Phone Number must be 10 digits')
            .matches(phoneRegExp, 'Phone Number must be a valid US number'),
    })
    const form = useFormik({
        initialValues: {
            first_name: meContext.me.first_name,
            last_name: meContext.me.last_name,
            email: meContext.me.email,
            phone: meContext.me.phone ?? '',
        },
        validationSchema: UpdatePasswordSchema,
        onSubmit: (values) => submit(values)
    });

    const submit = ({email, phone, ...values}: ProfileEditorFormValues) => {

        const maybeEmail = form.initialValues.email != email ? {email: email}: {};
        const maybePhone = form.initialValues.phone != phone ? {phone: phone && phone.length ? phone : null}: {};
        const user = {
            ...maybeEmail,
            ...maybePhone,
            ...values,
        };
        AuthRequests.updateMe(meContext.me.id!, user).then(() => {
            meContext.setMe({
                ...meContext.me,
                ...user
            } as User);
            setShowSuccess(true);
            history.goBack();
        }).catch(error => {
            let message;
            if (error.data.errors) {
                if (error.data.errors.phone) {
                    message = error.data.errors.phone[0];
                } else if (error.data.errors.email) {
                    message = error.data.errors.email[0];
                } else {
                    message = "Unknown request error. If this problem exists, please contact SSU.";
                }
            } else {
                message = "Unknown server error. If this problem exists, please contact SSU.";
            }

            setErrorMessage(message);
        });
    }

    return (
        <form>
            <UserProfileImageUploader meContext={meContext}/>
            <GreyBackgroundSection>
                <h4>Personal Information</h4>
                <UnderlinedInput>
                    <InputWrapper
                        label={'First Name'}
                        error={form.errors.first_name && form.touched.first_name ? form.errors.first_name : undefined}
                    >
                        <IonInput
                            type={'text'}
                            name={'first_name'}
                            onIonChange={form.handleChange}
                            value={form.values.first_name}
                        />
                    </InputWrapper>
                </UnderlinedInput>
                <UnderlinedInput>
                    <InputWrapper
                        label={'Last Name'}
                        error={form.errors.last_name && form.touched.last_name ? form.errors.last_name : undefined}
                        >
                        <IonInput
                            type={'text'}
                            name={'last_name'}
                            onIonChange={form.handleChange}
                            value={form.values.last_name}
                            />
                    </InputWrapper>
                </UnderlinedInput>

            </GreyBackgroundSection>
            <GreyBackgroundSection>
                <h4>Contact Information</h4>
                <UnderlinedInput>
                    <InputWrapper
                        label={'Email'}
                        error={form.errors.email && form.touched.email ? form.errors.email : undefined}
                    >
                        <IonInput
                            type={'email'}
                            name={'email'}
                            onIonChange={form.handleChange}
                            value={form.values.email}
                        />
                    </InputWrapper>
                </UnderlinedInput>
                <UnderlinedInput>
                    <InputWrapper
                        label={'Phone Number'}
                        error={form.errors.phone && form.touched.phone ? form.errors.phone : undefined}
                    >
                        <PhoneNumberInput
                            name={'phone'}
                            onPhoneNumberChange={(phoneNumber: string) => form.setFieldValue( 'phone', phoneNumber )}
                            value={form.values.phone}
                        />
                    </InputWrapper>
                </UnderlinedInput>
            </GreyBackgroundSection>
            <IonButton onClick={() => form.handleSubmit()}>Save</IonButton>
            <IonToast
                isOpen={errorMessage != null}
                onDidDismiss={() => setErrorMessage(null)}
                message={errorMessage as any}
                duration={4000}
            />
            <IonToast
                isOpen={showSuccess}
                message={"Saved Successfully!"}
                duration={4000}
            />
        </form>
    );
}

const ProfileEditor: React.FC = () => {

    return (
        <IonPage id={'profile-editor'}>
            <LoggedInHeaderComponent iconType={chevronBack}>
                Profile Editor
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <ProfileEditorForm meContext={meContext}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    );
}

export default ProfileEditor;
