import React from 'react';
import "./index.scss"

interface PostTextContentLocationDetailsProps {
    name: string,
    neighborhood?: string,
    city?: string,
    state?: string,
    isGoogle?: boolean,
}

const PostTextContentLocationDetails: React.FC<PostTextContentLocationDetailsProps> = ({name, neighborhood,city,state, isGoogle}) => {
    const placeName = (
        neighborhood ? neighborhood : ''
    ) + (
        city ? (neighborhood ? " – " : ' ') + city : ''
    ) + (
        state ? (city ? ", " : (neighborhood ? " – " : '')) + state : ''
    )

    return(
        <div className={"post-location-details"}>
            <p>
                {isGoogle ? name : <strong>{name}</strong>}
                <br/>
                <small>{placeName}</small>
            </p>
        </div>
    )
}

export default PostTextContentLocationDetails;
