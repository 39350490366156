import React from 'react'
import {setHasRespondedToLocationPrompt, setLastKnownLocation} from '../../data/persistent/persistent.actions';
import {connect} from '../../data/connect';
import {Capacitor} from '@capacitor/core';
import NativeGeoLocationContextProvider from './NativeGeoLocationContextProvider';
import {
	defaultState,
	DispatchProps, GeoLocationContextProviderOwnProps,
	GeoLocationContextProviderProps,
	GeoLocationContextState,
	StateProps
} from './interfaces';
import NetworkBasedLocationContextProvider, { NetworkBasedLocationContext } from './NetworkBasedLocationContextProvider';


export const GeoLocationContext = React.createContext<GeoLocationContextState>(defaultState);

/**
 * The main component that handles our location permission, and serving of actual location data
 * @constructor
 */
const GeoLocationContextProvider: React.FC<GeoLocationContextProviderProps> = (props) => {

	return (
		Capacitor.isNativePlatform() ?
			<NativeGeoLocationContextProvider {...props}/> :
			<NetworkBasedLocationContextProvider {...props}>
				<NetworkBasedLocationContext.Consumer>
					{networkContext =>
						<GeoLocationContext.Provider value={networkContext}>
							{props.children}
						</GeoLocationContext.Provider>
					}
				</NetworkBasedLocationContext.Consumer>
			</NetworkBasedLocationContextProvider>

	)
}

export default connect<GeoLocationContextProviderOwnProps, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		hasRespondedToLocationPrompt: state.persistent.hasRespondedToLocationPrompt,
		lastKnownLocation: state.persistent.lastKnownLocation,
	}),
	mapDispatchToProps: ({
		setHasRespondedToLocationPrompt,
		setLastKnownLocation
	}),
	component: GeoLocationContextProvider
});
