import React from 'react';
import {
    IonContent,
    IonButton,
    IonTextarea,
    IonPage,
    IonToast
} from '@ionic/react';
import {useState} from "react";
import './index.scss';
import {useFormik} from 'formik';
import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../../contexts/MeContext';
import {useHistory} from 'react-router';
import User from '../../../../models/user/user';
import AuthRequests from '../../../../services/requests/AuthRequests';
import LoggedInHeaderComponent from "../../../../components/LoggedInHeader";
import {chevronBack} from "ionicons/icons";

interface BioEditorFormValues {
    about_me: string,
}

interface BioEditorFormProps {
    meContext: MeContextStateConsumer,
}

const BioEditorForm: React.FC<BioEditorFormProps> = ({meContext}) => {

    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const form = useFormik({
        initialValues: {
            about_me: meContext.me.about_me,
        },
        onSubmit: (values) => submit(values)
    });

    const submit = ({about_me, ...values}: BioEditorFormValues) => {

        AuthRequests.updateMe(meContext.me.id!, {about_me: about_me}).then(() => {
            meContext.setMe({
                ...meContext.me,
                about_me: about_me,
            } as User);
            setShowSuccess(true);
            history.goBack();
        }).catch(error => {
            let message;
            /**this needs to be fixed**/
            if (error.data.errors) {
                message = "Unknown request error. If this problem exists, please contact SSU.";
            } else {
                message = "Unknown server error. If this problem exists, please contact SSU.";
            }

            setErrorMessage(message);
        });
    }

    return (
        <form>
            <IonTextarea
                className={'add-bio'}
                name={'about_me'}
                value={form.values.about_me}
                onIonChange={form.handleChange}
                autoCapitalize={'sentence'}
                maxlength={500}
                rows={12}
                autoGrow={true}
            />
            <IonButton onClick={()=>form.handleSubmit()}>Done</IonButton>
            <IonToast
                isOpen={errorMessage != null}
                onDidDismiss={() => setErrorMessage(null)}
                message={errorMessage as any}
                duration={4000}
            />
            <IonToast
                isOpen={showSuccess}
                message={"Saved Successfully!"}
                duration={4000}
            />
        </form>
    )
}

const AddBio: React.FC = () => {
    console.log('add');
    return (
        <IonPage id={'bio-editor-page'}>
            <LoggedInHeaderComponent hideBackButton={true} iconType={chevronBack}>
                Update your bio
            </LoggedInHeaderComponent>
            <IonContent>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext => (
                            <BioEditorForm meContext={meContext}/>
                        )}
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonContent>
        </IonPage>
    )
}

export default AddBio;
