import React, {ReactNode, useEffect, useState} from 'react'
import {
	IonContent,
	IonPage,
} from '@ionic/react';
import './index.scss';
import OrganizationHeader, {
	OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import PostResponses from '../../../components/PostManagement/PostResponses';
import {useParams} from 'react-router';

interface PostInteractionsPageContentProps {
	setHeaderProps: (props: OrganizationHeaderProps) => void,
}

type RouteParams = {
	postId: string,
	interaction:string,
}
const PostInteractionsPageContent: React.FC<PostInteractionsPageContentProps> = ({setHeaderProps}) => {

	const {interaction, postId} = useParams<RouteParams>()

	useEffect(() => {
		if (window.location.pathname.indexOf(interaction) != -1) {
			setHeaderProps({
				children: interaction,
			})
		}
	}, [interaction, window.location.pathname])

	return (
		<IonPage className={'post-response-page'}>
			<IonContent className={'results-wrapper'}>
				<PostResponses
					postId={parseInt(postId!)}
					interaction={interaction!}
					memberPageBaseUrl={'/organization'}
				/>
			</IonContent>
		</IonPage>
	)
}

const PostInteractionsPage: React.FC = () => {
	return (
		<OrganizationHeaderContext.Consumer>
			{context =>
				<PostInteractionsPageContent setHeaderProps={context.setSharedProps}/>
			}
		</OrganizationHeaderContext.Consumer>
	)
}

export default PostInteractionsPage
