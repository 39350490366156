import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import GeoLocationContextProvider, {GeoLocationContext} from '../GeoLocationContextProvider';
import SearchGooglePlacesContextProvider, {
	SearchGooglePlacesContext,
	SearchGooglePlacesContextProviderConsumerState
} from '../Providers/GooglePlaces/SearchGooglePlacesContextProvider';
import {IonIcon, IonInput, IonItem, IonList, IonSearchbar, IonSlide} from '@ionic/react';
import SideBySideComponent from '../SideBySide';
import {searchOutline} from 'ionicons/icons';
import {getPlaceDetails} from '../Providers/GooglePlaces/FetchGooglePlaceDetailsContextProvider';
import {LocationsContext, LocationsContextProvider} from '../../contexts/LocationsContext';
import Location from '../../models/location/location';
import LocationDetails from '../MembersPages/LocationDetails';

interface GooglePlacesSearchFieldResultsProps {
	searchGooglePlacesContext: SearchGooglePlacesContextProviderConsumerState
	onComplete: (result: google.maps.places.PlaceResult|Location) => void,
	locations: Location[],
	gedditLocalAmount?: number,
	isFocused: boolean,
	mode: 'input' | 'page'
	format: 'detailed' | 'simple'
}

const GooglePlacesSearchFieldResults: React.FC<GooglePlacesSearchFieldResultsProps> = ({onComplete, locations, gedditLocalAmount, mode, isFocused , format, searchGooglePlacesContext}) => {

	const getDetails = (service: google.maps.places.PlacesService, placeId: string) => {

		getPlaceDetails(service, placeId, details => {
			onComplete(details);
		}, () => {
			// TODO handle error
		});
	}

	return (
		<React.Fragment>
			{(searchGooglePlacesContext.loaded && (isFocused || mode == 'page')) &&
				<IonList>
					{locations.slice(0, gedditLocalAmount ?? 5).map(location => format == 'simple' ?
						<IonItem
							key={location.id}
							onClick={() => onComplete(location)}
						>
							{location.name}
						</IonItem> :
						<LocationDetails
							key={location.id}
							location={location}
							button={{
								onClick: () => onComplete(location),
								text: 'Select',
							}}
						/>
					)}
					{searchGooglePlacesContext.result.map(entry =>
						<IonItem
							key={entry.place_id!}
							onClick={() => getDetails(searchGooglePlacesContext.service!, entry.place_id!)}>
							<div>
								{entry.name}
								<br/>
								<small>{entry.formatted_address}</small>
							</div>
						</IonItem>
					)}
					<IonItem><img src={'/assets/legal/powered_by_google_on_white.png'}/></IonItem>
				</IonList>
			}
		</React.Fragment>
	)
}

interface GooglePlacesSearchFieldProps {
	privacyPolicyURL: string,
	onComplete: (result: google.maps.places.PlaceResult|Location) => void,
	mode: 'input' | 'page'
	format: 'detailed' | 'simple'
	gedditLocalAmount?: number,
	includeGedditLocalResults?: boolean
	placeholder?: string,
	help?: string,
}

/**
 * @param url
 * @constructor
 */
const GooglePlacesSearchField: React.FC<GooglePlacesSearchFieldProps> = ({privacyPolicyURL, onComplete, includeGedditLocalResults, gedditLocalAmount, placeholder, help, mode, format})  => {

	const [searchTerm, setSearchTerm] = useState('');
	const [isFocused, setIsFocused] = useState(false);

	return (
		<GeoLocationContextProvider
			privacyPolicyURL={privacyPolicyURL}
			locationRequired
		>
			<GeoLocationContext.Consumer>
				{geoLocationContext =>
					<SearchGooglePlacesContextProvider
						query={searchTerm}
						location={new google.maps.LatLng(
							geoLocationContext.location.coords.latitude,
							geoLocationContext.location.coords.longitude
						)}
					>
						<SearchGooglePlacesContext.Consumer>
							{searchGooglePlacesContext =>
								<div className={'location-search ' + mode}>
									<SideBySideComponent>
										{mode == 'input' && <IonIcon icon={searchOutline}/>}
										<div className={'search-input-wrapper'}>
											<div className={'search-input'}>
												{mode == 'page' ?
													<IonSearchbar
														placeholder={placeholder}
														onIonChange={event => setSearchTerm(event.detail.value!)}
													/> :
													<IonInput
														placeholder={placeholder}
														onIonChange={event => setSearchTerm(event.detail.value!)}
														onIonFocus={() => setIsFocused(true)}
													/>
												}
												{includeGedditLocalResults ?
													<LocationsContextProvider
														latitude={geoLocationContext.location.coords.latitude}
														longitude={geoLocationContext.location.coords.longitude}
														searchText={searchTerm}
														limit={gedditLocalAmount}
													>
														<LocationsContext.Consumer>
															{locationsContext =>
																<GooglePlacesSearchFieldResults
																	searchGooglePlacesContext={searchGooglePlacesContext}
																	locations={locationsContext.loadedData}
																	onComplete={onComplete}
																	isFocused={isFocused}
																	mode={mode}
																	format={format}
																	gedditLocalAmount={gedditLocalAmount}
																/>
															}
														</LocationsContext.Consumer>
													</LocationsContextProvider> :
													<GooglePlacesSearchFieldResults
														searchGooglePlacesContext={searchGooglePlacesContext}
														locations={[]}
														onComplete={onComplete}
														isFocused={isFocused}
														mode={mode}
														format={format}
													/>
												}
											</div>
											{help && <p><small>{help}</small></p>}
										</div>
									</SideBySideComponent>
								</div>
							}
						</SearchGooglePlacesContext.Consumer>
					</SearchGooglePlacesContextProvider>
				}
			</GeoLocationContext.Consumer>
		</GeoLocationContextProvider>
	)
}

export default GooglePlacesSearchField;
