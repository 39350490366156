import React from 'react';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import {IonItem, IonLabel, IonToggle} from '@ionic/react';
import User from '../../../models/user/user';
import AuthRequests from '../../../services/requests/AuthRequests';

interface NotificationToggleProps {
    label: string,
    notificationName: string,
}

/**
 * This is responsible for asking the user how they want to select an asset
 */
function toggleNotification(checked: boolean, notificationName: string, me: User, setMe: (user: User) => void) {
    const updateData = {} as any;
    updateData[notificationName] = checked;
    setMe({
        ...me,
        ...updateData,
    });
    AuthRequests.updateMe(me.id!, updateData).catch(console.error);
}

/**
 * Tells us whether or not the settings field is checked
 * @param me
 * @param notificationName
 */
function isSettingChecked(me: User, notificationName: string) {
    return Object.keys(me).indexOf(notificationName) == -1 ? false :
        (me as any)[notificationName];
}

const NotificationToggle: React.FC<NotificationToggleProps> = ({label, notificationName}) => {
    return (
        <MeContextProvider>
            <MeContext.Consumer>
                {meContext => (
                    <IonItem>
                        <IonLabel>{label}</IonLabel>
                        <IonToggle
                            checked={isSettingChecked(meContext.me, notificationName)}
                            onIonChange={event =>
                                toggleNotification(event.detail.checked, notificationName, meContext.me, meContext.setMe)
                            }
                            slot={'end'}
                        />
                    </IonItem>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    );
}

export default NotificationToggle;
