import React, {useEffect} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {IonContent, IonPage} from '@ionic/react';
import {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import DropShadowRouterLink from '../../../components/DropShadowRouterLink';

interface BillingContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const BillingContent: React.FC<BillingContentProps> = ({setHeaderProps}) => {

    useEffect(() => {
        setHeaderProps({
            children: 'Billing',
        })
    }, [])

    return (
        <IonPage>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <IonContent>
                            <h5>{myBusinessContext.organization.name}</h5>
                            <DropShadowRouterLink routerLink={`/organization/billing/current-subscription`}>
                                Subscription Plan
                            </DropShadowRouterLink>
                            <DropShadowRouterLink routerLink={`/organization/billing/information`}>
                                Billing Information
                            </DropShadowRouterLink>
                            <DropShadowRouterLink routerLink={`/organization/billing/history`}>
                                Billing History
                            </DropShadowRouterLink>
                        </IonContent>
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </IonPage>
    );
}

const Billing: React.FC = () => {

    return (
        <OrganizationHeaderContext.Consumer>
            {organizationHeaderContext =>
                <BillingContent
                    setHeaderProps={organizationHeaderContext.setSharedProps}
                />
            }
        </OrganizationHeaderContext.Consumer>
    )
}

export default Billing;
