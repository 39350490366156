import {
    IonButton,
    IonContent,
    IonItem,
    IonLabel,
    IonPage,
    IonRadio,
    IonRadioGroup
} from '@ionic/react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import './index.scss';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import BusinessCreationHeader from '../../../components/BusinessCreationHeader';
import {Redirect, useHistory} from 'react-router';
import Footnote from '../../../components/Footnote';

interface GuidelineProps{
    value: string,
    name: string,
    handleChange(event: any): void
    labelYes?: string,
    labelNo?: string,
}

const Guideline: React.FC<PropsWithChildren<GuidelineProps>> = ({value, name, handleChange, labelYes, labelNo, children}) => (
    <div className="guideline">
        {children}
        <IonRadioGroup
            name={name}
            onIonChange={handleChange}
            value={value}
        >
            <GuidelineOption value="Yes" label={labelYes ?? "Yes"}/>
            <GuidelineOption value="No" label={labelNo ?? "No"}/>
        </IonRadioGroup>
    </div>
)

interface GuidlineOptionProps{
    value: string,
    label: string,
}

const GuidelineOption: React.FC<GuidlineOptionProps> = ({value, label}) => (
    <IonItem lines={'none'}>
        <IonLabel>{label}</IonLabel>
        <IonRadio value={value} mode={'md'} slot={'start'} />
    </IonItem>
)

interface QualifyingGuidelinesData {
    notChain: string,
    brandDecisionMaker: string,
    ownerLocatedInBusinessState: string,
}

const initialQualifyingGuidelinesState: QualifyingGuidelinesData = {
    notChain: '',
    brandDecisionMaker: '',
    ownerLocatedInBusinessState: '',
};

interface QualifyingGuidelinesFormProps {
}

const QualifyingGuidelinesForm: React.FC<QualifyingGuidelinesFormProps> = () => {
    const history = useHistory();

    const submit = async (qualificationData: QualifyingGuidelinesData) => {

        if( qualificationData.notChain == "Yes" && qualificationData.brandDecisionMaker == "Yes" && qualificationData.ownerLocatedInBusinessState == "Yes" ) {
            history.push('/organization-creator/business-information');
        } else {
            history.push('/organization-creator/business-rejection');
        }
    }

    const QualifyingGuidelinesSchema = Yup.object().shape({
        notChain: Yup.string().trim().required(),
        brandDecisionMaker: Yup.string().trim().required(),
        ownerLocatedInBusinessState: Yup.string().trim().required(),
    });

    const form = useFormik({
        initialValues: initialQualifyingGuidelinesState,
        validationSchema: QualifyingGuidelinesSchema,
        onSubmit: (values) => submit(values)
    });

    /**
     * Gets the current error if there is one
     */
    function getCurrentError(): string {
        if (form.submitCount > 0 && !form.isValid) {
            return 'Please respond to all guidelines'
        }

        return "";
    }

    return (

        <IonContent>
            <h4>Qualifying Guidelines</h4>
            <Guideline name={'notChain'} value={form.values.notChain} handleChange={form.handleChange}>
                <p>Is your business privately owned and free from control of any national chain?</p>
            </Guideline>
            <Guideline name={'brandDecisionMaker'} value={form.values.brandDecisionMaker} handleChange={form.handleChange}>
                <p>Is your business privately owned and NOT part of a larger franchise?</p>
            </Guideline>
            <Guideline name={'ownerLocatedInBusinessState'} value={form.values.ownerLocatedInBusinessState} handleChange={form.handleChange}>
                <p>Do the owners live within 50 miles of their business locations?</p>
            </Guideline>
            <p className={'error'}>
                {getCurrentError()}
            </p>
            <IonButton onClick={() => form.handleSubmit()}>
                Next
            </IonButton>
        </IonContent>
    );
}

interface QualifyingGuidelinesProfitQuestionData {
    profit: string,
}

const initialQualifyingGuidelinesProfitQuestionState: QualifyingGuidelinesProfitQuestionData = {
    profit: '',
};

interface QualifyingGuidelinesProfitQuestionProps {
    onSubmit: (isForProfit: boolean) => any
}

const QualifyingGuidelinesProfitQuestion: React.FC<QualifyingGuidelinesProfitQuestionProps> = ({onSubmit}) => {

    const QualifyingGuidelinesProfitQuestionSchema = Yup.object().shape({
        profit: Yup.string().trim().required(),
    });
    const form = useFormik({
        initialValues: initialQualifyingGuidelinesProfitQuestionState,
        validationSchema: QualifyingGuidelinesProfitQuestionSchema,
        onSubmit: (values) => onSubmit(values['profit'] === 'Yes')
    });

    /**
     * Gets the current error if there is one
     */
    function getCurrentError(): string {
        if (form.submitCount > 0 && !form.isValid) {
            return 'Please respond to the question'
        }

        return "";
    }

    return (
        <IonContent>
            <h4>Qualifying Guidelines</h4>
            <Guideline name={'profit'} value={form.values.profit} labelYes={"For-Profit"} labelNo={"Non-Profit"} handleChange={form.handleChange}>
                <p>What type of business are you setting up?</p>
            </Guideline>
            <p className={'error'}>
                {getCurrentError()}
            </p>
            <IonButton onClick={() => form.handleSubmit()}>
                Next
            </IonButton>
        </IonContent>
    )
}

interface QualifyingGuidelinesProps {
}

const QualifyingGuidelines: React.FC<QualifyingGuidelinesProps> = () => {
    const [forProfit, setForProfit] = useState( false )
    const [businessStatusAsked, setBusinessStatusAsked] = useState( false )
    const businessIsForProfit = businessStatusAsked && forProfit
    const history = useHistory()

    const onProfitQuestionSubmitted = (isForProfit: boolean) => {
        setForProfit(isForProfit)
        setBusinessStatusAsked(true)
        if (!isForProfit) {
            history.push('/organization-creator/organization-information')
        }
    }

    const onBackNavigation = () => {
        if (businessStatusAsked && forProfit) {
            setBusinessStatusAsked(false)
        } else {
            history.goBack();
        }
    }

    return (
        <IonPage id={'qualifying-guidelines-page'}>
            <BusinessCreationHeader onBackArrowClick={onBackNavigation}/>
            {!businessIsForProfit ?
                <QualifyingGuidelinesProfitQuestion onSubmit={onProfitQuestionSubmitted} /> :
                <QualifyingGuidelinesForm/>
            }
        </IonPage>
    );
}

export default QualifyingGuidelines
