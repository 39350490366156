import {api} from '../api'
import Organization from '../../models/organization/organization';
import Business from '../../models/organization/business';
import Location from '../../models/location/location';
import Subscription from '../../models/subscription/subscription';
import PaymentMethod from '../../models/payment/payment-method';
import OrganizationManager from '../../models/organization/organization-manager';
import MembershipPlan from '../../models/subscription/membership-plan';
import Page from '../../models/page';
import SocialMediaConnection from '../../models/organization/social-media-connection';

export default class OrganizationRequests {

    private static subscriptionCreationRequest: Promise<Subscription>|undefined;

    /**
     * Gets the users initial information, and returns them to
     */
    static async getMyOrganization(organizationId: number) : Promise<Organization> {
        const { data } = await api.get('/organizations/' + organizationId, {
            params: {
                'expand[businesses]': '*',
                'expand[paymentMethods]': '*',
                'expand[subscriptions]': '*',
                'expand[subscriptions.lastRenewalRate]': '*',
                'expand[subscriptions.lastRenewalRate.membershipPlan]': '*',
                'expand[subscriptions.membershipPlanRate]': '*',
                'expand[subscriptions.membershipPlanRate.membershipPlan]': '*',
            }
        });

        const organization = data as Organization;

        if (!organization.subscriptions?.length) {
            const membershipPlan = await OrganizationRequests.findTrialMembershipPlan();
            if (membershipPlan) {
                OrganizationRequests.subscriptionCreationRequest = OrganizationRequests.subscriptionCreationRequest ?? OrganizationRequests.createSubscription(organizationId, {
                    membership_plan_rate_id: membershipPlan.current_rate_id,
                    is_trial: true,
                    location_count: 1,
                });
                console.log('current_rate_id', membershipPlan.current_rate_id)
                const subscription = await OrganizationRequests.subscriptionCreationRequest;
                OrganizationRequests.subscriptionCreationRequest = undefined;
                const membershipPlanRate = {
                    active: true,
                    cost: membershipPlan.current_cost,
                    membership_plan_id: membershipPlan.current_rate_id,
                    membership_plan: membershipPlan
                };
                subscription.membership_plan_rate = membershipPlanRate;
                subscription.last_renewal_rate = membershipPlanRate;
                organization.subscriptions = [subscription];
            }
        }

        return organization
    }

    /**
     * Gets the users business for us
     * @param businessId
     */
    static async getPublicBusiness(businessId: number): Promise<Business> {
        const { data } = await api.get('/businesses/' + businessId, {
            params: {
                'expand[categories]': '*',
                'expand[mainCategory]': '*',
                'expand[featuredImages]': '*',
                'expand[locations]': '*',
                'expand[locations.customLinks]': '*',
            }
        });
        return data as Business;
    }

    /**
     * Gets the users business for us
     * @param businessId
     */
    static async getMyBusiness(businessId: number): Promise<Business> {
        const { data } = await api.get('/businesses/' + businessId, {
            params: {
                'expand[categories]': '*',
                'expand[mainCategory]': '*',
                'expand[featuredImages]': '*',
                'expand[locations]': '*',
                'expand[locations.customLinks]': '*',
                'expand[socialMediaConnections]': '*',
            }
        });
        return data as Business;
    }

    /**
     * Creates a business
     * @param organizationData
     */
    static async createOrganization(organizationData: any): Promise<Organization> {
        const { data } = await api.post('/organizations', organizationData);
        return data as Organization;
    }

    /**
     * Creates a subscription properly
     * @param organizationId
     * @param subscriptionData
     */
    static async createSubscription(organizationId: number, subscriptionData: any): Promise<Subscription> {
        const {data} = await api.post('/organizations/' + organizationId + '/subscriptions', subscriptionData);
        return data as Subscription;
    }

    /**
     * Creates a subscription properly
     * @param organizationId
     * @param subscription
     * @param subscriptionData
     */
    static async updateSubscription(organizationId: number, subscription: Subscription, subscriptionData: any): Promise<Subscription> {
        const {data} = await api.put('/organizations/' + organizationId + '/subscriptions/' + subscription.id, subscriptionData);
        return data as Subscription;
    }

    /**
     * Creates a business
     * @param organizationId
     * @param businessData
     */
    static async createBusiness(organizationId: number, businessData: any): Promise<Business> {
        const { data } = await api.post('/organizations/' + organizationId + '/businesses', businessData);
        return data as Business;
    }

    /**
     * Updates a business properly for us
     * @param business
     * @param updateData
     */
    static async updateBusiness(business: Business, updateData: any = null): Promise<Business> {
        updateData = updateData ?? {
            name: business.name,
            categories: business.categories?.filter(category => category.id).map(category => category.id),
            featured_images: business.featured_images?.map((image, index) => (
                {
                    asset_id: image.id,
                    order: index,
                }
            ))
        } as any;

        const { data } = await api.put('/organizations/' + business.organization_id + '/businesses/' + business.id!, updateData);
        return data as Business;
    }

    /**
     * sets an organization default payment method
     * @param paymentMethod
     */
    static async setDefaultPaymentMethod(paymentMethod: PaymentMethod) : Promise<any> {
        return api.put('/organizations/' + paymentMethod.owner_id +  '/payment-methods/' + paymentMethod.id, {default: true})
    }

    /**
     * Delete a business properly
     * @param business
     */
    static async deleteBusiness(business: Business): Promise<boolean> {
        await api.delete('/organizations/' + business.organization_id + '/businesses/' + business.id!);
        return true;
    }

    /**
     * Runs the creation promise for a location for us
     * @param businessId
     * @param locationData
     */
    static async createLocation(businessId: number, locationData: any): Promise<Location> {
        const {data} = await api.post('/businesses/' + businessId + '/locations', locationData);
        return data as Location;
    }

    /**
     * Updates a location properly for us
     * @param location
     */
    static async updateLocation(location: Location): Promise<Location> {
        const { data } = await api.put('/businesses/' + location.business_id + '/locations/' + location.id!, {
            name: location.name,
            address: location.address,
            city: location.city,
            state: location.state,
            zip: location.zip,
            website: location.website,
            phone: location.phone,
            email: location.email,
            hide_address: location.hide_address,
            logo_asset_id: location.logo_asset_id,
            description: location.description,
            custom_links: location.custom_links?.map((customLink) => {
                const data = {
                    url: customLink.url,
                    label: customLink.label,
                } as any;

                if (customLink.id) {
                    data.id = customLink.id;
                }

                return data;
            }),
        });
        return data as Location;
    }

    /**
     * Delete a location properly
     * @param location
     */
    static async deleteLocation(location: Location): Promise<boolean> {
        await api.delete('/businesses/' + location.business_id + '/locations/' + location.id!);
        return true;
    }

    /**
     * Creates a payment method for us properly
     * @param organizationId
     * @param roleId
     * @param email
     * @param phone
     */
    static async createOrganizationManager(organizationId: number, roleId: number, email: string|null = null, phone: string|null = null): Promise<OrganizationManager> {
        let requestData = {
            role_id: roleId,
        } as any;
        if (email) {
            requestData.email = email;
        }
        if (phone) {
            requestData.phone = phone;
        }
        const { data } = await api.post('/organizations/' + organizationId + '/organization-managers', requestData);
        return data as OrganizationManager;
    }

    /**
     *
     * @param organizationManager
     * @param contactEmail
     */
    static async updateOrganizationManagerContactEmail(organizationManager: OrganizationManager, contactEmail: string): Promise<OrganizationManager> {

        const url ='/organizations/' + organizationManager.organization_id
                + '/organization-managers/' + organizationManager.id!;
        const { data } = await api.put(url, {
            contact_email: contactEmail
        });
        return data as OrganizationManager;
    }

    /**
     * Accepts the invitation for us
     * @param invitationToken
     * @param verificationCode
     */
    static async acceptInvitation(invitationToken: string, verificationCode: string): Promise<OrganizationManager> {
        const { data } = await api.post('/accept-organization-invitation', {
            invitation_token: invitationToken,
            verification_code: verificationCode,
        })
        return data as OrganizationManager;
    }

    /**
     * Deletes an organization manager!
     * @param paymentMethod
     */
    static async deletePaymentMethod(paymentMethod: PaymentMethod) : Promise<any> {
        return api.delete('/organizations/' + paymentMethod.owner_id +  '/payment-methods/' + paymentMethod.id)
    }

    /**
     * Deletes an organization manager!
     * @param organizationManager
     */
    static async deleteOrganizationManager(organizationManager: OrganizationManager) : Promise<any> {
        return api.delete('/organizations/' + organizationManager.organization_id +  '/organization-managers/' + organizationManager.id)
    }

    /**
     * Connects us to a social media account
     *
     * @param businessId
     * @param type
     * @param token
     * @param tokenReceivedAt
     * @param accountId
     */
    static async connectSocialMediaAccount(businessId: number, type: string, token: string, tokenReceivedAt: string, accountId: string): Promise<SocialMediaConnection> {
        const { data } = await api.post('/businesses/' + businessId + '/social-media-connections', {
            type: type,
            token: token,
            token_received_at: tokenReceivedAt,
            account_id: accountId,
            auto_publish: true,
            pending_minutes: 15
        })

        return data as SocialMediaConnection;
    }

    /**
     * Updates our social media connection for us
     * @param socialMediaConnection
     * @param autoPublish
     * @param pendingMinutes
     */
    static async updateSocialMediaConnection(socialMediaConnection: SocialMediaConnection, autoPublish: boolean, pendingMinutes: number): Promise<SocialMediaConnection> {
        const { data } = await api.put('/businesses/' + socialMediaConnection.business_id + '/social-media-connections/' + socialMediaConnection.id!, {
            auto_publish: autoPublish,
            pending_minutes: pendingMinutes,
        });

        return data as SocialMediaConnection;
    }

    /**
     * Deletes our connection for us
     * @param socialMediaConnection
     */
    static async deleteSocialMediaConnection(socialMediaConnection: SocialMediaConnection): Promise<any> {
        return api.delete('/businesses/' + socialMediaConnection.business_id +  '/social-media-connections/' + socialMediaConnection.id)
    }

    /**
     * Gets the trial membership plan so that we can
     */
    private static async findTrialMembershipPlan(): Promise<MembershipPlan|undefined> {
        const {data} = await api.get('/membership-plans', {
            params: {
                'filter[trial_period]': 'notnull'
            }
        });

        const page = data as Page<MembershipPlan>;
        console.log('page', page);

        return page.data.length > 0 ? page.data[0] : undefined;
    }
}
