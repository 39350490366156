import React, {useEffect, useState} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import {
    IonButton,
    IonContent,
    IonInput,
    IonLabel,
    IonPage,
} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import Organization from '../../../models/organization/organization';
import {connect} from '../../../data/connect';
import {setManagingBusinessId} from '../../../data/persistent/persistent.actions';
import {useHistory} from 'react-router';
import UnderlinedInput from '../../../components/GeneralUIElements/UnderlinedInput';
import SubscriptionRequired from '../../../components/OrganizationManagement/SubscriptionRequired';
import {RequestError} from '../../../models/request-error';
import ServerAlert from '../../../components/ServerAlert';

interface BusinessCreatorData {
    name: string,
}
const initialBusinessCreatorState: BusinessCreatorData = {
    name: '',
};

interface BusinessCreatorFormProps {
    organization: Organization,
    setOrganization: (organization: Organization) => void,
    setManagingBusinessId: (businessId: number) => void,
}

const BusinessCreatorForm: React.FC<BusinessCreatorFormProps> = ({organization, setOrganization, setManagingBusinessId}) => {
    const history = useHistory();
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);
    const BusinessCreatorSchema = Yup.object().shape({
        name: Yup.string().trim().required('Business Name is required'),
    });

    // This should never happen
    const organizationId = organization.id ? organization.id : 0;

    const form = useFormik({
        initialValues: initialBusinessCreatorState,
        validationSchema: BusinessCreatorSchema,
        onSubmit: (values) => submit(values)
    });

    const submit = (businessCreationData: BusinessCreatorData) => {
        OrganizationRequests.createBusiness(organizationId, businessCreationData).then(async (business) => {
            const businesses = organization.businesses ? organization.businesses : [];
            businesses.push(business);
            setOrganization({
                ...organization,
                businesses: [...businesses]
            });
            setManagingBusinessId(business.id ? business.id : 0);
            history.replace('/organization/location-list');
        }).catch((error: RequestError) => {
            setRequestError(error)
        })
    }

    /**
     * Gets the current error if there is one
     */
    function getCurrentError(): string {
        if (form.errors.name && form.touched.name) {
            return form.errors.name;
        }

        return "";
    }

    return (
        <>
            <h4>Create Business</h4>
            <UnderlinedInput>
                <IonInput
                    name='name'
                    type='text'
                    autocapitalize={'word'}
                    onIonChange={form.handleChange}
                />
                <IonLabel>Business Name</IonLabel>
            </UnderlinedInput>
            <p className={'error'}>
                {getCurrentError()}
            </p>
            <IonButton onClick={() => form.handleSubmit()}>
                Submit
            </IonButton>
            {requestError &&
                <ServerAlert
                    requestError={requestError}
                    onCloseAlert={() => setRequestError(undefined)}
                />
            }
        </>
    );
}

interface BusinessCreatorContentProps extends BusinessCreatorProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const BusinessCreatorContent: React.FC<BusinessCreatorContentProps> = ({setHeaderProps, setManagingBusinessId}) => {

    useEffect(() => {
        setHeaderProps({
            children: 'Create Business',
        })
    }, [])

    return (
        <MyBusinessContextProvider>
            <MyBusinessContext.Consumer>
                {myBusinessContext => (
                    <IonContent>
                        <SubscriptionRequired>
                            <BusinessCreatorForm
                                setManagingBusinessId={setManagingBusinessId}
                                setOrganization={myBusinessContext.setOrganization}
                                organization={myBusinessContext.organization}
                            />
                        </SubscriptionRequired>
                    </IonContent>
                )}
            </MyBusinessContext.Consumer>
        </MyBusinessContextProvider>
    )
}

interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessCreatorProps extends DispatchProps {
}

const BusinessCreator: React.FC<BusinessCreatorProps> = ({setManagingBusinessId}) => {

    return (
        <IonPage>
            <OrganizationHeader>
                <OrganizationHeaderContext.Consumer>
                    {organizationHeaderContext =>
                        <BusinessCreatorContent
                            setHeaderProps={organizationHeaderContext.setSharedProps}
                            setManagingBusinessId={setManagingBusinessId}
                        />
                    }
                </OrganizationHeaderContext.Consumer>
            </OrganizationHeader>
        </IonPage>
    );
}

export default connect<{ }, { }, DispatchProps >({
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: BusinessCreator
});
