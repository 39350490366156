import {IonContent, IonPage} from '@ionic/react';
import React, {useEffect} from 'react';
import LoggedOutHeaderComponent from '../../../components/WelcomeSection/LoggedOutHeader';
import {TokenState} from '../../../data/persistent/persistent.state';
import {connect} from '../../../data/connect';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import './index.scss';
import ContactUsForm from '../../../components/ContactUsForm';
import {
    OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';

interface ContactUsContentProps extends ContactUsProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const ContactUsContent: React.FC<ContactUsContentProps> = ({tokenData, setHeaderProps}) => {

    useEffect(() => {
        setHeaderProps({
            children: 'Contact Us',
        });
    }, [])

    return (
        <IonPage className={'contact-us-page'}>
        	<IonContent>
                {tokenData ?
                    <MeContextProvider>
                        <MeContext.Consumer>
                            {meContext =>
                                <ContactUsForm
                                    loggedInUser={meContext.me}
                                />
                            }
                        </MeContext.Consumer>
                    </MeContextProvider> :
                    <ContactUsForm/>
                }
            </IonContent>
        </IonPage>
    );
}

interface StateProps {
    tokenData?: TokenState;
}

interface ContactUsProps extends StateProps {
}

const ContactUs: React.FC<ContactUsProps> = (props) => {

    return (
        <OrganizationHeaderContext.Consumer>
            {organizationHeaderContext =>
                <ContactUsContent
                    setHeaderProps={organizationHeaderContext.setSharedProps}
                    {...props}
                />
            }
        </OrganizationHeaderContext.Consumer>
    )
}

export default connect<{ }, StateProps, { }>({
    mapStateToProps: (state) => ({
        tokenData: state.persistent.tokenData
    }),
    component: ContactUs
});

