import React, {ConsumerProps, useEffect, useState} from 'react';
import './index.scss';
import LocationEditorContextProvider, {LocationEditorContext, LocationEditorContextConsumerState} from '../../../contexts/organization/LocationEditorContext';
import {useParams} from 'react-router';
import Location from '../../../models/location/location';
import BaseEditPageTemplate from '../BaseEditPageTemplate';

interface BaseLocationEditorFormConsumerProps<PageData>  {
    currentData: PageData,
    setCurrentData: (data: PageData) => void,
}

export interface BaseLocationEditPagePrimaryProps<PageData> {
    title: string
    iconUrl?: string
    customLayout?: boolean,
    constructLocation?: (data: PageData, location: Location) => Location,
    onCurrentDataSave?: (value: any) => string,
    required?: boolean
}

interface BaseLocationEditPageWrapperProps<PageData> extends BaseLocationEditPagePrimaryProps<PageData>, ConsumerProps<BaseLocationEditorFormConsumerProps<PageData>> {
    createInitialState: (locationEditorContextState: LocationEditorContextConsumerState) => PageData,
    locationEditorContextState: LocationEditorContextConsumerState,
}

const BaseLocationEditPageWrapper: React.FC<BaseLocationEditPageWrapperProps<any>> = ({createInitialState, locationEditorContextState, iconUrl, title, customLayout, required, constructLocation, onCurrentDataSave, children, ...rest}) => {

    const [initialFormState, setInitialFormState] = useState(undefined);
    const [error, setError] = useState('');
    const [currentData, setCurrentData] = useState<any>(initialFormState);

    const consumerState = {currentData: currentData, setCurrentData: setCurrentData}

    const isDirty = currentData != initialFormState;

    useEffect(() => {
        const initialState = createInitialState(locationEditorContextState);
        setCurrentData(initialState)
        setInitialFormState(initialState);
    }, []);

    useEffect(() => {
        if (error) {
            setError( onCurrentDataSave && (required || (currentData && currentData.length > 0)) ? onCurrentDataSave(currentData) : '');
        }
    }, [currentData])

    if (initialFormState === undefined) {
        return (
            <span/>
        );
    }
    const onSave = () => {
        const fieldMessage = onCurrentDataSave && (required || (currentData && currentData.length > 0)) ? onCurrentDataSave(currentData) : ''

        if (!fieldMessage) {
            if (constructLocation) {
                locationEditorContextState.setLocation({
                    ...constructLocation(currentData, locationEditorContextState.dirtyLocation),
                });
            }
            return Promise.resolve();
        }
        else {
            setError(fieldMessage)
            return Promise.reject();
        }
    }

    return (
        <BaseEditPageTemplate
            title={title}
            onSave={onSave}
            isDirty={isDirty}
            iconUrl={iconUrl}
            customLayout={customLayout}
            editingData={currentData}
        >
            {children(consumerState)}
        </BaseEditPageTemplate>
    );
}

type BaseLocationRouteParams = {
    locationId: string
}

interface BaseLocationEditPageProps<PageData> extends BaseLocationEditPagePrimaryProps<PageData>, ConsumerProps<BaseLocationEditorFormConsumerProps<PageData>> {
    createInitialState: (locationEditorContextState: LocationEditorContextConsumerState) => PageData,
}

const BaseLocationEditPage: React.FC<BaseLocationEditPageProps<any>> = ({...rest}) => {
    const {locationId} = useParams<BaseLocationRouteParams>()
    return (
        <LocationEditorContextProvider locationId={parseInt(locationId!)}>
            <LocationEditorContext.Consumer>
                {locationEditorContextState =>
                    <BaseLocationEditPageWrapper
                        locationEditorContextState={locationEditorContextState}
                        {...rest}
                    />
                }
            </LocationEditorContext.Consumer>
        </LocationEditorContextProvider>
    )
}

export default BaseLocationEditPage
