import {IonContent, IonItem, IonList, IonPage} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../../components/LoggedInHeader';
import React from 'react';
import './index.scss';
import {chevronBack} from "ionicons/icons";

interface Question {
    q: string,
    a: JSX.Element,
}

interface FAQGroupProps {
    questions: Question[]
}

const FAQGroup: React.FC<FAQGroupProps> = ({questions}) => {
    return (
        <IonList>
            {questions.map(question => (
                <IonItem>
                    <div>

                        <h4>{question.q}</h4>
                        {question.a}
                    </div>
                </IonItem>
            ))}
        </IonList>
    );
}

const FAQ: React.FC = () => {

    const loyaltyQuestions = [
        {
            q: 'How do I earn loyalty points?',
            a: <p>Provide each merchant your account phone number or member card to earn credits with every in-store purchase.</p>
        },
        {
            q: 'How do I redeem my rewards?',
            a: <p>Once you have created your member account, provide your account phone number or member card at the point of purchase, and let the merchant know you want to redeem your credits. Just be aware that each business sets the rules for their own loyalty program, so there may be some restrictions on when or how much you can redeem at any one time.</p>
        },
        {
            q: 'Can I earn new points when I redeem rewards?',
            a: <p>Yes, provided the purchase amount is larger than the amount you’re redeeming, of course. (Example: A member redeeming $10 on a $25 purchase will earn new points on the $15 spent after discount)</p>
        },
        {
            q: 'Where can I use my loyalty credits?',
            a: <p>Credits earned with one merchant may only be redeemed with that same merchant; however, businesses with multiple locations may choose to 'group' their locations, in which case credits may be earned and redeemed interchangeably across locations.</p>
        }
    ];

    const giftPackQuestions = [
        {
            q: 'What is a Gift Pack?',
            a: <p>A Gift Pack is a collection of one-time offers from local businesses and popular attractions in the community. What makes them special is that we never deal with physical coupons. All offers can be redeemed in-stores using a single member card (included), or just your phone number.</p>
        },
        {
            q: 'How do I activate my Gift Pack?',
            a: <p>Go to <a href={'www.smallshopsunited.com/giftpacks/activate'}>www.smallshopsunited.com/giftpacks/activate</a>, then simply follow the prompts to redeem your code and link your new card.</p>
        },
        {
            q: 'Can I add a Gift Pack directly to my member account?',
            a: <p>Yes, if you already have a member account, you’ll have the option to skip the shipping process and immediately add a Gift Pack directly to your account during Checkout.</p>
        },
        {
            q: 'How frequently can I redeem each Gift Pack offer?',
            a: <p>Generally speaking, each offer may be redeemed one time; however, there may be online offers that can be redeemed multiple times.</p>
        },
        {
            q: 'How do I redeem my Gift Pack offers?',
            a: <p>For in-store offers, cash in on your savings by providing your member card or your account phone number at the point of purchase. For online offers, first open the offer in your member account, then generate your unique promo code. Then apply your promo code when making your purchase on the vendor’s site.</p>
        },
        {
            q: 'How do I send Gift Packs to friends?',
            a: <p>We strongly recommend that you have all Gift Packs first shipped to your own address, then distribute to friends as you see fit. We do not provide gift messaging at this time.</p>
        },
        {
            q: 'How many Gift Packs can I add to my account?',
            a: <p>Members are limited to one of each type of Gift Pack in their account at any one time.</p>
        },
        {
            q: 'What is your return policy?',
            a: <p>We cannot accept returns of this product.</p>
        }
    ];

    return (
        <IonPage>
            <LoggedInHeaderComponent iconType={chevronBack}>
                FAQ
            </LoggedInHeaderComponent>
            <IonContent>
                <h3>Loyalty</h3>
                <FAQGroup questions={loyaltyQuestions}/>
                <h3>Gift Packs</h3>
                <FAQGroup questions={giftPackQuestions}/>
            </IonContent>
        </IonPage>
    );
}

export default FAQ;
