import React, {ReactNode, useEffect, useState} from 'react'
import {
	IonContent,
	IonIcon,
	IonItem,
	IonList,
	IonPage,
	IonSearchbar,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import GeoLocationContextProvider, {GeoLocationContext} from '../../../components/GeoLocationContextProvider';
import Location from '../../../models/location/location';
import './index.scss';
import LocationDetails from '../../../components/MembersPages/LocationDetails';
import {chevronBack, chevronDown, searchOutline} from "ionicons/icons";
import Resource from "../../../models/resource";
import User from "../../../models/user/user";
import {SearchContext, SearchContextProvider} from '../../../contexts/SearchContext';
import {BasePaginatedContextState} from '../../../contexts/BasePaginatedContext';
import Post from '../../../models/post/post';
import BaseModel from '../../../models/base-model';
import EntityDetails from '../../../components/MembersPages/EntityDetails';
import PostItem from '../../../components/MembersPages/PostItem';
import {App} from '@capacitor/app';
import UserItem from '../../../components/MembersPages/UserItem';

interface ResultProps<Model> {
	model: Model
}

const LocationResult: React.FC<ResultProps<Location>> = ({model}) => {

	return (
		<IonItem
			lines={'none'}
			detail={false}
			routerLink={`/home/dashboard/business/${model.business_id}/location/${model.id!}`}
		>
			<LocationDetails location={model}/>
		</IonItem>
	)
}

const PostResult: React.FC<ResultProps<Post>> = ({model}) => {

	return (
		<PostItem
			post={model}
			lines={'none'}
			baseUrl={`/home/dashboard`}
		/>
	)
}

interface ResultsProps<Model> {
	title: string,
	noResultsCopy?: string,
	models: Model[],
	render: (model: Model) => ReactNode,
	limit?: number,
	hasMoreData?: boolean,
	loadNextPage?: () => Promise<any>
}

const Results: React.FC<ResultsProps<BaseModel>> = ({models, hasMoreData, loadNextPage, title, noResultsCopy, render, limit}) => {

	const firstModel = models && models.length > 0 ? models[0].id: undefined
	const [showingAll, setShowingAll] = useState(limit === undefined);
	useEffect(() => {
		setShowingAll(limit === undefined);
	}, [firstModel]);

	const shownModels = (limit && !showingAll) ? models.slice(0, limit) : models;
	const hasMore = hasMoreData || models.length > shownModels.length;

	const showMore = () => {
		if (!showingAll) {
			setShowingAll(true);
		} else if (hasMoreData && loadNextPage) {
			loadNextPage();
		}
	}

	const uniqueModels = Array.from(new Map(shownModels.map(x => [x.id, x])).values());
	return (
		<div className={'results-section'}>
			<h2>{title}</h2>
			{uniqueModels.length ? (
				<IonList>
					{uniqueModels.map(model =>
						render(model)
					)}
				</IonList>
			) : (
				<p><strong>{noResultsCopy}</strong></p>
			)}
			{hasMore && <h3 onClick={showMore}>Show more <IonIcon icon={chevronDown}/></h3>}
		</div>
	)
}

interface NoSearchResultsProps {
	lastSearch: string
}

const NoSearchResults: React.FC<NoSearchResultsProps> = ({lastSearch}) => (
	<div className={"no-results"}>
		<IonIcon icon={searchOutline}/>
		<p>No results for "{lastSearch}"</p>
	</div>
)

interface DefaultSearchResultsProps {
	users: User[],
	locations: Location[],
	hasMoreData: boolean,
	loadNextPage: () => Promise<any>
}

const DefaultSearchResults: React.FC<DefaultSearchResultsProps> = ({users, locations, hasMoreData, loadNextPage}) => {

	return (
		<div className={'default-search-results'}>
			{/*<Results*/}
			{/*	models={users}*/}
			{/*	render={i => <UserResult key={i.id} model={i as User}/>}*/}
			{/*	title={"Trending people to follow"}*/}
			{/*	noResultsCopy={'There are no people posting in this area yet'}*/}
			{/*	limit={3}*/}
			{/*/>*/}
			{/*<hr/>*/}
			<Results
				models={locations}
				render={i => <LocationResult key={i.id} model={i as Location}/>}
				title={'Trending businesses to follow'}
				noResultsCopy={'There are no business accounts setup in this area yet'}
				limit={5}
				hasMoreData={hasMoreData}
				loadNextPage={loadNextPage}
			/>
		</div>
	)
}

interface SearchResultsProps extends DefaultSearchResultsProps {
	posts: Post[],
}

const SearchResults: React.FC<SearchResultsProps> = ({posts, users, locations}) => {

	const resultGroups: ResultsProps<any>[] = [];
	if (locations.length > 0) {
		resultGroups.push({
			title: 'Locations',
			models: locations,
			render: (model: Location) => <LocationResult key={model.id} model={model}/>,
			limit: locations.length > 3 ? 3 : undefined,
		});
	}

	if (users.length > 0) {
		resultGroups.push({
			title: 'Members',
			models: users,
			render: (model: User) => <UserItem key={model.id} user={model} />,
			limit: locations.length > 3 ? 3 : undefined,
		});
	}

	const sortedGroups = resultGroups.sort((a, b) =>  b.models.length - a.models.length);

	if (posts.length > 0) {
		sortedGroups.push({
			title: 'Posts',
			models: posts,
			render: (model: Post) => <PostResult key={model.id} model={model}/>,
			limit: posts.length > 4 ? 4 : undefined,
		});
	}

	return (
		<div className={'search-results'}>
			{sortedGroups.map(group => <Results key={group.title} {...group} />)}
		</div>
	)
}

interface SearchResultsProcessorProps {
	searchText: string,
	searchContext: BasePaginatedContextState<Resource<Location|User|Post>>,
}

const SearchResultsProcessor: React.FC<SearchResultsProcessorProps> = ({searchText, searchContext}) => {

	const users = searchContext.loadedData
		.filter(resource => {
			const canBeShown = searchText.length == 0 ? resource.relevance > 10 : true;
			return canBeShown && resource.resource && resource.resource_type == 'user';
		})
		.map(resource => resource.resource as User);
	const locations = searchContext.loadedData
		.filter(resource => resource.resource && resource.resource_type == 'location')
		.map(resource => resource.resource as Location);
	const posts = searchContext.loadedData
		.filter(resource => resource.resource && resource.resource_type == 'post')
		.map(resource => resource.resource as Post)
		.filter(post => post && post.published_at);
	return (searchText.length > 0 && !searchContext.noResults ?
		<SearchResults
			users={users}
			locations={locations}
			posts={posts}
			hasMoreData={searchContext.hasAnotherPage}
			loadNextPage={searchContext.loadNext}
		/> :
		<DefaultSearchResults
			users={users}
			locations={locations}
			hasMoreData={searchContext.hasAnotherPage}
			loadNextPage={searchContext.loadNext}
		/>
	);
}

const Explore: React.FC = () => {

	const [searchText, setSearchText] = useState('');
	const [forceLocationRefresh, setForceLocationRefresh] = useState(false);

	useEffect(() => {
		App.addListener('appStateChange', ({ isActive }) => {
			if (isActive) {
				setForceLocationRefresh(true);
			}
		});
	}, [])

	return (
		<IonPage className={'browse-page'}>
			<LoggedInHeaderComponent>
				Explore
			</LoggedInHeaderComponent>
			<IonContent scrollY={false} className={'ion-no-padding'}>
				<IonSearchbar
					placeholder={'Search by name or category'}
					inputmode={'search'}
					enterkeyhint={"search"}
					onIonChange={e => setSearchText(e.detail.value!)}
					onIonClear={() => setSearchText('')}
				/>
				<IonContent className={'results-wrapper'}>
					<GeoLocationContextProvider
						privacyPolicyURL={'/home/dashboard/account/privacy-policy'}
						locationRequired
						forceRefresh={forceLocationRefresh}
						onLocationReceived={() => setForceLocationRefresh(false)}
					>
						<GeoLocationContext.Consumer>
							{geoLocationContext => (
								<SearchContextProvider
									searchText={searchText}
									latitude={geoLocationContext.location.coords.latitude}
									longitude={geoLocationContext.location.coords.longitude}>
									<SearchContext.Consumer>
										{searchContext => (searchContext.noResults && searchText.length ?
											<NoSearchResults lastSearch={searchText} />:
											<SearchResultsProcessor
												searchText={searchText}
												searchContext={searchContext}
											/>
										)}
									</SearchContext.Consumer>
								</SearchContextProvider>
							)}
						</GeoLocationContext.Consumer>
					</GeoLocationContextProvider>
				</IonContent>
			</IonContent>
		</IonPage>
	)
}

export default Explore
