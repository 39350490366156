import React, {useEffect, useRef, useState} from 'react';
import './index.scss';
import Thread from '../../../models/user/thread';
import {IonList} from '@ionic/react';
import Post from '../../../models/post/post';
import {
    PostThreadsContext,
    PostThreadsContextProvider,
    PostThreadsContextState
} from '../../../contexts/messaging/PostThreadsContext';
import ThreadItem from '../ThreadItem';
import CommentInput from '../CommentInput';
import Message from '../../../models/user/message';
import CanParticipateInForum from '../../../models/can-participate-in-forum';

interface ThreadsDataProcessorProps extends ThreadListProps {
    postThreadsContext: PostThreadsContextState,
}

const ThreadsDataProcessor: React.FC<ThreadsDataProcessorProps> = ({postThreadsContext, participant,  createPostThread, createMessage, post, refresh, onCommentSubmitted}) => {

    const [commentingData, setCommentingData] = useState<{thread: Thread, replyingTo?: Message}|undefined>(undefined)
    const [newMessage, setNewMessage] = useState<Message|undefined>(undefined);
    const bottomDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (postThreadsContext.initialLoadComplete && refresh) {
            postThreadsContext.refreshData().catch(console.error);
        }
    }, [refresh])

    const submitComment = (comment: string) => {
        if (createMessage && createPostThread) {
            if (commentingData && commentingData.thread) {
                createMessage(commentingData.thread, comment, commentingData.replyingTo?.id)
                    .then(message => {
                        const fullMessage = {
                            ...message,
                            from: participant,
                        };
                        if (!commentingData.thread.first_message) {
                            postThreadsContext.addModel({
                                ...commentingData.thread,
                                first_message: fullMessage,
                            })
                        }
                        onCommentSubmitted()
                        setNewMessage(fullMessage);
                        setCommentingData(undefined);
                    })
            } else {
                createPostThread(post, comment).then(thread => {
                    onCommentSubmitted()
                    postThreadsContext.addModel({
                        ...thread,
                        created_by: participant,
                    })
                    setCommentingData(undefined);
                });
            }
        }
    }

    const sortedData = postThreadsContext.loadedData
        .sort((a, b) => Date.parse(a.created_at!) - Date.parse(b.created_at!));

    useEffect(() => {
        if (bottomDiv.current && bottomDiv.current.scrollIntoView) {
            bottomDiv.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [sortedData]);

    return (
        <div className={'thread-list'}>
            {participant &&
                <div className={'comment-wrapper'}>
                    <CommentInput
                        onCommentSubmitted={submitComment}
                        replyingToThread={commentingData?.thread}
                        replyingToMessage={commentingData?.replyingTo}
                        commentingEntity={participant}
                    />
                </div>
            }
            <IonList>
                {sortedData.map(thread =>
                    <ThreadItem
                        key={thread.id}
                        thread={thread}
                        newMessage={newMessage && newMessage.thread_id == thread.id ? newMessage : undefined}
                        onReply={replyingTo => setCommentingData({thread, replyingTo})}
                        replyingToThread={commentingData?.thread}
                        replyingToMessage={commentingData?.replyingTo}
                    />
                )}
                <div ref={bottomDiv}/>
            </IonList>
        </div>
    )
}

interface ThreadListProps {
    post: Post,
    onCommentSubmitted: () => any,
    participant?: CanParticipateInForum,
    createPostThread?: (post: Post, topic: string) => Promise<Thread>,
    createMessage?: (thread: Thread, comment: string, replyingToId?: number) => Promise<Message>,
    refresh: boolean,
}

const ThreadList: React.FC<ThreadListProps> = ({post, ...rest}) => {

    return (
        <PostThreadsContextProvider postId={post.id!}>
            <PostThreadsContext.Consumer>
                {postThreadsContext => <ThreadsDataProcessor postThreadsContext={postThreadsContext} post={post} {...rest}/>}
            </PostThreadsContext.Consumer>
        </PostThreadsContextProvider>
    )
}

export default ThreadList;
