import React from 'react';
import {
    IonSelect,
    IonSelectOption
} from '@ionic/react';
import {IndustriesContextProvider, IndustriesContext} from '../../contexts/IndustriesContext';
import Category from '../../models/category'

interface IndustrySelectorProps {
    name: string,
    placeholder: string,
    onChange:(industry: Category) => void
}

const IndustrySelector: React.FC<IndustrySelectorProps> = ({name, placeholder, onChange}) => (
    <IonSelect className={'industry-selector'} name={name} placeholder={placeholder} onIonChange={event => onChange(event.detail.value!)}>
        <IndustriesContextProvider>
            <IndustriesContext.Consumer>
                {industriesContext => industriesContext.loadedData.map((industry) => (
                    <IonSelectOption key={industry.id} value={industry}>{industry.name}</IonSelectOption>
                ))}
            </IndustriesContext.Consumer>
        </IndustriesContextProvider>
    </IonSelect>
)

export default IndustrySelector
