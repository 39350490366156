import {connect} from '../../data/connect';
import React, {useState} from 'react';
import {
    IonButton,
    IonButtons, IonCheckbox,
    IonContent, IonFooter,
    IonHeader, IonIcon, IonItem,
    IonLabel,
    IonModal,
    IonRange,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {FeedSettings} from '../../data/persistent/persistent.state';
import {setFeedSettings} from '../../data/persistent/persistent.actions';
import './index.scss'
import {home, personCircle, personCircleOutline} from 'ionicons/icons';
import BottomStickySection from '../BottomStickySection';

interface OwnProps  extends React.ComponentProps<typeof IonModal> {}

interface StateProps {
    feedSettings: FeedSettings,
}

interface DispatchProps {
    setFeedSettings: typeof setFeedSettings,
}

interface NewsFeedOptionsModalProps extends OwnProps, StateProps, DispatchProps {}

const KM_IN_MILES = 1.60934;

const NewsFeedOptionsModal: React.FC<NewsFeedOptionsModalProps> = ({feedSettings, onDidDismiss, setFeedSettings, ...rest}) => {

    const availableMiles = [
        1,
        5,
        10,
        25,
        50,
        100,
        150,
    ]

    const makeKMReadableAsMiles = (value: number): number => {
        return Math.round(value / KM_IN_MILES)
    }

    const calculateStartingRange = (value: number): number => {
        const calculatedIndex = availableMiles.indexOf(makeKMReadableAsMiles(value))

        return calculatedIndex !== -1 ? calculatedIndex : 2;
    }

    const [selectedRange, setSelectedRange] = useState(calculateStartingRange(feedSettings.radius))
    const [showMembers, setShowMembers] = useState(feedSettings.showMembers)
    const [showBusinesses, setShowBusinesses] = useState(feedSettings.showBusinesses)

    const convertSelectedRangeToKM = () => {
        return availableMiles[selectedRange] * KM_IN_MILES;
    }

    const toggleMembers = () => {
        if (showMembers && !showBusinesses) {
            setShowBusinesses(true);
        }
        setShowMembers(!showMembers);
    }

    const toggleBusinesses = () => {
        if (showBusinesses && !showMembers) {
            setShowMembers(true);
        }
        setShowBusinesses(!showBusinesses);
    }

    const dismiss = () => onDidDismiss ? (onDidDismiss as any)() : {};

    const save = () => {
        setFeedSettings({
            radius: convertSelectedRangeToKM(),
            showBusinesses,
            showMembers,
        })
        dismiss();
    }

    const isDirty =
        showBusinesses !== feedSettings.showBusinesses ||
        showMembers !== feedSettings.showMembers ||
        convertSelectedRangeToKM() != feedSettings.radius;

    return (
        <IonModal
            initialBreakpoint={0.9}
            breakpoints={[0.9]}
            onDidDismiss={onDidDismiss}
            className={'news-feed-options-modal'}
            {...rest}
        >
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={'start'}>
                        <IonButton
                            fill={'clear'}
                            color={'dark'}
                            onClick={dismiss}
                        >
                            X
                        </IonButton>
                    </IonButtons>
                    <IonTitle>Filters</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <h4>Search Radius</h4>
                <p>How far do you want us to search for content when using your current location?</p>
                <IonRange
                    ticks
                    snaps
                    min={0}
                    max={availableMiles.length - 1}
                    value={selectedRange}
                    onIonChange={({ detail }) => setSelectedRange(detail.value as number)}
                />
                <div className={'range-wrapper'}>
                    <div className={'range-display'}>
                        <p><small>Miles</small></p>
                        <p><strong>{availableMiles[selectedRange]}</strong></p>
                    </div>
                </div>
                <hr/>
                <h4 className={'filter-header'}>Filter by</h4>
                <IonItem lines={'none'}>
                    <IonLabel>
                        <IonIcon icon={home}/> Business Posts
                    </IonLabel>
                    <IonCheckbox
                        slot={'end'}
                        checked={showBusinesses}
                        onIonChange={toggleBusinesses}
                    />
                </IonItem>
                <IonItem lines={'none'}>
                    <IonLabel>
                        <IonIcon icon={personCircleOutline}/> Member Posts
                    </IonLabel>
                    <IonCheckbox
                        slot={'end'}
                        checked={showMembers}
                        onIonChange={toggleMembers}
                    />
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonButton disabled={!isDirty} color={'success'} onClick={save}>
                    Show results
                </IonButton>
            </IonFooter>
        </IonModal>
    )
}

export default connect<OwnProps, StateProps, DispatchProps>({
    mapStateToProps: (state) => ({
        feedSettings: state.persistent.feedSettings
    }),
    mapDispatchToProps: ({
       setFeedSettings
    }),
    component: NewsFeedOptionsModal
});
