import React, {PropsWithChildren} from 'react';
import './index.scss';
import {IonBackButton, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar} from '@ionic/react';
import LoadingIndicatorComponent from '../../LoadingIndicator';
import {chevronBack} from "ionicons/icons";

interface Props {
	backText?: string
	hideBackButton?: boolean
	onBackClick?: (event: any) => void
	className?: string,
}

const LoggedOutHeaderBackButton: React.FC<Props> = ({ backText, hideBackButton, onBackClick }) => {
	const backClicked = (event: any) => {
		if(onBackClick) {
			onBackClick(event)
		}
	}
	return (!hideBackButton &&
		onBackClick ? (
			backText ? (
				<IonButton className={'back-button'} onClick={backClicked}>{backText}</IonButton>
			) : (
				<IonIcon icon={chevronBack} onClick={backClicked}/>
			)
		) : (
			<IonBackButton text={backText} icon='' />
		)
	)
}

const LoggedOutHeaderComponent: React.FC<PropsWithChildren<Props>> = ({children, className, ...props}) => (
	<IonHeader mode={'ios'} class={'logged-out ' + className}>
		<IonToolbar>
			<IonButtons slot="start">
				<LoggedOutHeaderBackButton {...props} />
			</IonButtons>
			<IonTitle>
				{children ? children : <img className='swipe-logo hide-in-desktop' src='/assets/main-logo.svg'/>}
			</IonTitle>
			<LoadingIndicatorComponent/>
		</IonToolbar>
	</IonHeader>
)

export default LoggedOutHeaderComponent
