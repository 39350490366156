import React, {useContext, useEffect, useState} from 'react';
import {IonAlert, IonButton, IonContent, IonLabel, IonList, IonPage, NavContext, useIonRouter} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext,
    OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import './index.scss';
import FeaturedImages from '../../../components/FeaturedImages';
import {closeOutline} from 'ionicons/icons';
import BottomStickySection from '../../../components/BottomStickySection';
import BusinessEditorContextProvider, {
    BusinessEditorContext,
    BusinessEditorContextConsumerState
} from '../../../contexts/organization/BusinessEditorContext';
import Business from '../../../models/organization/business';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import ProfileEditorLink from '../../../components/OrganizationManagement/ProfileEditorLink';
import {useHistory} from 'react-router-dom';
import { setManagingBusinessId } from '../../../data/persistent/persistent.actions';
import {connect} from "../../../data/connect";
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import {useLocation} from 'react-router';
import {Capacitor} from '@capacitor/core';

interface BusinessInformationLinksProps {
    businessEditorContext: BusinessEditorContextConsumerState
}

const BusinessInformationLinks: React.FC<BusinessInformationLinksProps> = ({businessEditorContext}) => {

    const business = businessEditorContext.dirtyBusiness;
    const location = useLocation()
    return (
        <div>
            <div className={'header-images'}>
                <FeaturedImages
                    images={business.featured_images ? business.featured_images : []}
                    editEnabled={{setBusiness: businessEditorContext.setBusiness, business: businessEditorContext.business}}
                />
            </div>
            <IonList className={'location-edit-links'}>
                <ProfileEditorLink addMessage={'a main category'} iconType={'storefront'} routerLink={`/organization/business-editor/name`}>
                    {business.name}
                </ProfileEditorLink>
                <ProfileEditorLink addMessage={'a main category'} iconType={'category'} routerLink={`/organization/business-editor/category`}>
                    Industry & Search Terms
                </ProfileEditorLink>
            </IonList>
        </div>
    );
}

interface BusinessEditorContentProps extends BusinessEditorProps {
    onUpdateManagedBusiness: (business: Business) => void
    setHeaderProps: (props: OrganizationHeaderProps) => void,
    businessEditorContext: BusinessEditorContextConsumerState,
}

const BusinessEditorContent: React.FC<BusinessEditorContentProps> = ({setManagingBusinessId, setHeaderProps, businessEditorContext, onUpdateManagedBusiness}) => {

    const [alertShowing, setAlertShowing] = useState(false);
    const {goBack} = useContext(NavContext);
    const navigate = useHistory();

    const navigateBackToDashboard = (business: Business) => {
        if (business && business.featured_images && business.featured_images?.length < 3) {
            setManagingBusinessId()
            navigate.replace('/home');
        } else {
            goBack('/organization');
        }
    }

    const saveData = (businessEditorContext: BusinessEditorContextConsumerState) => {
        const {dirtyBusiness, updateCompleted, setBusiness} = businessEditorContext
        OrganizationRequests.updateBusiness(dirtyBusiness).then(updatedBusiness => {
            dirtyBusiness.locations?.forEach( location => {
                location.business = updatedBusiness
            })
            setBusiness(dirtyBusiness)
            onUpdateManagedBusiness(updatedBusiness)
            updateCompleted(updatedBusiness);
            navigateBackToDashboard(updatedBusiness);
        }).catch(error => {
            alert('business update error - ' + JSON.stringify(error));
        })
    }

    const backHandler = () => {
        if (businessEditorContext.isBusinessDirty) {
            setAlertShowing(true);
        } else {
            navigateBackToDashboard(businessEditorContext.business);
        }
    }


    useEffect(() => {
        setHeaderProps({
            children: "Edit Business",
            backClicked: backHandler,
            closeButtonIcon: closeOutline,
            urlOverride: '/organization/business-editor'
        })
    }, [businessEditorContext.dirtyBusiness, businessEditorContext.isBusinessDirty])

    return (
        <React.Fragment>
            <IonContent>
                <BusinessInformationLinks  businessEditorContext={businessEditorContext}/>
            </IonContent>
            <BottomStickySection>
                <IonButton
                    disabled={!businessEditorContext.isBusinessDirty}
                    expand={'full'}
                    color={Capacitor.isNativePlatform() ? 'light': 'primary'}
                    onClick={() => saveData(businessEditorContext)}
                >
                    Submit
                </IonButton>
            </BottomStickySection>
            <IonAlert
                isOpen={alertShowing}
                onDidDismiss={() => setAlertShowing(false)}
                header={'Discard Changes?'}
                message={'Would you like to save your changes, or discard them before going back?'}
                buttons={[
                    {
                        text: 'Discard',
                        handler: navigateBackToDashboard,
                    },
                    {
                        text: 'Save',
                        handler: () => saveData(businessEditorContext),
                    },
                ]}
            />
        </React.Fragment>
    );
}

interface DispatchProps {
    setManagingBusinessId: typeof setManagingBusinessId,
}

interface BusinessEditorProps extends DispatchProps  {
}

const BusinessEditor: React.FC<BusinessEditorProps> = (props) => (
    <IonPage className={'business-editor'}>
        <MeContextProvider>
            <MeContext.Consumer>
                {meContext => (
                    <OrganizationHeaderContext.Consumer>
                        {organizationHeaderContext =>
                            <BusinessEditorContextProvider>
                                <BusinessEditorContext.Consumer>
                                    {businessEditorContext =>
                                        <BusinessEditorContent
                                            businessEditorContext={businessEditorContext}
                                            setHeaderProps={organizationHeaderContext.setSharedProps}
                                            onUpdateManagedBusiness={meContext.updateManagedBusiness}
                                            {...props}
                                        />
                                    }
                                </BusinessEditorContext.Consumer>
                            </BusinessEditorContextProvider>
                        }
                    </OrganizationHeaderContext.Consumer>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    </IonPage>
)

export default connect<{}, {}, DispatchProps >({
    mapDispatchToProps: ({
        setManagingBusinessId,
    }),
    component: BusinessEditor
});
