import React, {useEffect, useState} from 'react';
import './index.scss';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../../contexts/organization/MyBusinessContext';
import {IonContent, IonPage} from '@ionic/react';
import OrganizationHeader, {
    OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../../components/OrganizationManagement/OrganizationHeader';
import OrganizationRequests from '../../../../services/requests/OrganizationRequests';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
import Organization from '../../../../models/organization/organization';
import PaymentMethod from '../../../../models/payment/payment-method';
import PaymentMethodSelector from '../../../../components/PaymentMethods/PaymentMethodSelector';

interface BillingInformationPageProps {
    setOrganization: (organization: Organization) => void,
    organization: Organization,
}

const BillingInformationPage: React.FC<BillingInformationPageProps> = ({organization, setOrganization}) => {

    const paymentMethods = organization.payment_methods ? organization.payment_methods : [];
    const [paymentMethod, setPaymentMethod] = useState(undefined as PaymentMethod|undefined)

    const changeDefaultMethod = (newDefaultPaymentMethod: PaymentMethod) => {

        OrganizationRequests.setDefaultPaymentMethod(newDefaultPaymentMethod).then(() => {
            const updatedMethods = paymentMethods.map((paymentMethod) => {
                paymentMethod.default = newDefaultPaymentMethod == paymentMethod
                return paymentMethod
            });

            setOrganization({
                ...organization,
                payment_methods: [...updatedMethods]
            });
        }).catch(console.error)
    }

    return (
        <MeContextProvider hideLoadingSpace={true}>
            <MeContext.Consumer>
                {meContext => (
                    <IonContent>
                        <p>
                            {meContext.me.full_name}
                            <br/>
                            {organization.name}
                        </p>
                        <p>
                            {meContext.me.email}
                        </p>
                        <h4>Payment Method</h4>
                        <PaymentMethodSelector
                            entity={organization}
                            setEntity={setOrganization as any}
                            entityType={'organization'}
                            paymentMethods={paymentMethods}
                            activeMethod={paymentMethod}
                            onPaymentMethodChange={(paymentMethod) => setPaymentMethod(paymentMethod)}
                            onUpdateDefaultMethod={(paymentMethod) => paymentMethod ? changeDefaultMethod(paymentMethod) : null}
                            setDefault={true}
                        />
                    </IonContent>
                )}
            </MeContext.Consumer>
        </MeContextProvider>
    );
}

interface BillingInformationContentProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const BillingInformationContent: React.FC<BillingInformationContentProps> = ({setHeaderProps}) => {

    useEffect(() => {
        setHeaderProps({
            children: 'Billing Information',
        });
    }, [])

    return (
        <IonPage className={'billing-information-page'}>
            <MyBusinessContextProvider>
                <MyBusinessContext.Consumer>
                    {myBusinessContext => (
                        <BillingInformationPage
                            organization={myBusinessContext.organization}
                            setOrganization={myBusinessContext.setOrganization}
                        />
                    )}
                </MyBusinessContext.Consumer>
            </MyBusinessContextProvider>
        </IonPage>
    );
}

const BillingInformation: React.FC = () => {

    return (
        <OrganizationHeaderContext.Consumer>
            {organizationHeaderContext =>
                <BillingInformationContent
                    setHeaderProps={organizationHeaderContext.setSharedProps}
                />
            }
        </OrganizationHeaderContext.Consumer>
    )
}

export default BillingInformation;
