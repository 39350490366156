import React from 'react';
import PostLocation, {findGooglePlaceAddressComponent} from '../../../models/post/post-location';
import FetchGooglePlaceDetailsContextProvider, {
    FetchGooglePlaceDetailsContext
} from '../../Providers/GooglePlaces/FetchGooglePlaceDetailsContextProvider';
import PostTextContentLocationDetails from '../PostTextContentLocationDetails';

interface GooglePlaceLocationDisplayProps {
    postLocation: PostLocation,
}

const GooglePlaceLocationDisplay: React.FC<GooglePlaceLocationDisplayProps> = ({postLocation}) => {

    return (
        <FetchGooglePlaceDetailsContextProvider
            placeId={postLocation.reference_id as string}
        >
            <FetchGooglePlaceDetailsContext.Consumer>
                {googlePlaceDetails => {
                    const neighborhood = findGooglePlaceAddressComponent('neighborhood', googlePlaceDetails.result)
                    const city = findGooglePlaceAddressComponent('locality', googlePlaceDetails.result)
                    const state = findGooglePlaceAddressComponent('administrative_area_level_1', googlePlaceDetails.result)

                    return (
                        <PostTextContentLocationDetails
                            name={googlePlaceDetails.result?.name ? googlePlaceDetails.result?.name : ''}
                            neighborhood={neighborhood?.short_name}
                            city={city?.short_name}
                            state={state?.short_name}
                            isGoogle
                        />
                    )
                }}
            </FetchGooglePlaceDetailsContext.Consumer>
        </FetchGooglePlaceDetailsContextProvider>
    )
}

export default GooglePlaceLocationDisplay;
