import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import PostResponse from '../models/post/post-response';

/**
 * The state interface for our state
 */
export interface MinimalUserPostResponsesContextState extends BasePaginatedContextState<PostResponse> {}

const defaultContext = {
    ...defaultBaseContext(),
    limit: 50,
    order: {
        'created_at' : 'desc'
    },
    filter: {
        dismissed_at: 'notnull',
    },
} as MinimalUserPostResponsesContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const MinimalUserPostResponsesContext = React.createContext<MinimalUserPostResponsesContextState>(defaultContext);

export interface MinimalUserPostResponsesContextProviderProps extends BasePaginatedContextProviderProps{
    userId: number,
    minimal?: boolean,
    searchText?: string,
}

export const MinimalUserPostResponsesContextProvider: React.FC<MinimalUserPostResponsesContextProviderProps> = (({searchText, userId, ...props}) => {
    const [MinimalUserPostResponsesState, setMinimalUserPostResponsesState] = useState(persistentContext);

    useEffect(() => {
        prepareContextState(setMinimalUserPostResponsesState, MinimalUserPostResponsesState,'/users/' + userId + '/post-responses')
    }, []);

    useEffect(() => {
        MinimalUserPostResponsesState.setSearch('post.resource.content', searchText);
    }, [searchText])

    persistentContext = {
        ...createCallbacks(setMinimalUserPostResponsesState as any, MinimalUserPostResponsesState, '/users/' + userId + '/post-responses'),
    };
    return (
        <MinimalUserPostResponsesContext.Provider value={persistentContext}>
            {props.children}
        </MinimalUserPostResponsesContext.Provider>
    )
});
